import $ from 'jquery';
import _ from 'underscore';

import { Strings } from '@biteinc/common';

import { BackboneEvents } from '~/app/js/backbone-events';

import { localizeStr } from '../localization/localization';
import Analytics from '../utils/analytics';
import { GCNView } from './gcn_view';

export const GCNMenuQuickNavView = GCNView.extend({
  className: 'quick-nav-view clearfix',
  buttonTemplate: _.template(
    '<span role="button" class="scroll-button-view font-title"><%= name %></span>',
  ),

  initialize(options, ...args) {
    GCNView.prototype.initialize.apply(this, [options, ...args]);

    // If sticky nav is enabled, add class.
    if (gcn.menu.shouldUseStickyQuickNavigation()) {
      this.$el.addClass('sticky');
    }
    // Contains a bunch of jquery section elements.
    this._sections = options.sections;
    this._toScroll = options.toScroll;
    this.headlessMode = options.headlessMode;
    if (options.menuHasSideNav) {
      this.$el.addClass('hybrid-navigation');
      this.menuPageViewWidth = options.containerWidth;
    }
  },

  render() {
    const self = this;
    this.$el.html(`<div class="caption">${localizeStr(Strings.QUICK_NAV_TAP_TO_VIEW)}</div>`);

    // Need to set the section elements first, otherwise there is nothing to
    // render.
    if (!this._sections) {
      return this;
    }

    this.$el.toggleClass('headless', this.headlessMode);

    const $buttons = $('<div class="buttons"></div>');
    _.each(this._sections, (section) => {
      const $buttonEl = $(
        self.buttonTemplate({
          name: section.displayName(),
        }),
      );
      $buttonEl.onButtonTapOrHold('mqnv', () => {
        Analytics.trackEvent({
          eventName: Analytics.EventName.QuickNavSectionSelected,
          eventData: {
            sectionName: section.displayName(),
          },
        });
        self.trigger(BackboneEvents.GCNMenuQuickNavView.DidSelectMenuSection, section.id);
      });
      $buttons.append($buttonEl);
    });
    this.$el.append($buttons);

    // Put the buttons into individual rows and center them.
    setTimeout(() => {
      const maxWidth = $buttons.width();
      let widthTotal = 0;
      let $row = $('<div class="button-row"></div>');
      _.each($buttons.children(), (child) => {
        const $child = $(child);
        let childWidth = $child.outerWidth(true);
        const innerWidth = $child.innerWidth();
        const childSpanWidth = $child.find('span').outerWidth(true);
        if (childWidth > (this.menuPageViewWidth > 600 ? 540 : 200)) {
          $child.toggleClass('double-line', true);
          const delta = childSpanWidth - $child.find('span').outerWidth(true);
          $child.css('width', `${innerWidth - delta + 10}px`);
          childWidth = $child.outerWidth(true);
        }
        widthTotal += childWidth;
        $child.detach();
        if (widthTotal > maxWidth) {
          // Break.
          widthTotal = childWidth;
          $buttons.append($row);
          $row = $('<div class="button-row"></div>');
        }
        $row.append($child);
      });
      if ($row) {
        $buttons.append($row);
      }

      // Once buttons are rendered if using sticky quick nav
      // Add the appropriate margin to the top of the menu to start before this quick nav
      if (gcn.menu.shouldUseStickyQuickNavigation() && $('.quick-nav-view').length > 0) {
        self.$el.parent().css('margin-top', `${self.$el.outerHeight()}px`);
      }
    }, 1);

    // BITE-4399: find .footer-center and adjust the bottom margin to account for the quick nav
    setTimeout(() => {
      let bottomBarHeight = parseFloat(
        getComputedStyle(document.documentElement).getPropertyValue('--bottom-bar-height'),
      );
      $('.footer-center').css(
        'margin-bottom',
        `${
          this.headlessMode
            ? bottomBarHeight * parseFloat(getComputedStyle(document.documentElement).fontSize)
            : this.$el.outerHeight()
        }px`,
      );
    });
    return this;
  },
});
