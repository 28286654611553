export function blockedWordValidation(str: string): {
  validation: 'generic' | 'custom' | 'profanity';
  result: boolean;
} {
  const enableBlockedWords = !!gcn.menu.settings.get('enableBlockedWords');
  const customBlockedWords = enableBlockedWords ? gcn.menu.settings.get('customBlockedWords') : '';
  if (!enableBlockedWords) {
    const regex = new RegExp(/^([a-zA-Z0-9]+[\s\\-\\.]*)+$/);
    return {
      validation: 'generic',
      result: regex.test(str),
    };
  }

  const whiteListedWords = ['van dyke', 'shitake', 'shital', 'parshit', 'kinoshita', 'inoshita'];
  if (whiteListedWords.includes(str)) {
    return {
      validation: 'custom',
      result: true,
    };
  }

  if (customBlockedWords) {
    // we split the `str` and validate each one of them
    const customBlockedWordArray = customBlockedWords.split(',');
    const contentArray = str.split(' ');
    let blockedWordFound = false;
    for (let i = 0; i < contentArray.length && !blockedWordFound; i++) {
      if (customBlockedWordArray.includes(contentArray[i])) {
        blockedWordFound = true;
      }
    }
    if (blockedWordFound) {
      return {
        validation: 'custom',
        result: false,
      };
    }
  }

  const regexArray: RegExp[] = [
    /.*f\s*u\s*c\s*k.*/,
    /c\s*[*u]+\s*n\s*t/,
    /s\s*h\s*i\s*t/,
    /n\s*i\s*g\s*g\s*e\s*r/,
    /n[i1!*|][69g][69g]+(?:er|3r|a|r)s?/,
    /sh[1|i*]t/,
    /f[0u*]+ck/,
    /c[u*]+nt/,
    /c[0o]ck/,
    /d[a4]mn/,
    /fux[o0]r/,
    /f[4a]gg+[o0i]ts?/,
    /nig\s*nogs?/,
    /b\s*i\s*t\s*c\s*h/,
    /w\s*h\s*o\s*r\s*e/,
    /n[i1!*|][69g][69g]+(?:er|3r|a|r|)s?/,
    /f[0uo*]+ck/,
    /b[i!1*~][t7+]chy?/,
    /l3[i!1*~][t7+]chy?/,
    /"retard(?:ed|s)?"/,
  ];

  for (const regex of regexArray) {
    if (regex.test(str)) {
      return {
        validation: 'profanity',
        result: false,
      };
    }
  }

  return {
    validation: 'profanity',
    result: true,
  };
}

/**
 * list of bad words
  whitelist:
  - "van dyke",
  - "shitake",
  - "shital",
  - "parshit",
  - "kinoshita",
  - "inoshita",

  super_bad_names:
  - "fuck",
  - "cunt",
  - "bitch",
  - "nigger",

  super_bad:
  - "fuck",
  - "shit",
  - "whore",
  - "cunt",
  - "bitch",
  - "nigger",

  regexen_names:
  - ".*f\s*u\s*c\s*k.*"
  - "c\s*[*u]+\s*n\s*t"
  - "s\s*h\s*i\s*t"
  - "n\s*i\s*g\s*g\s*e\s*r"
  - n[i1!*|][69g][69g]+(?:er|3r|a|r)s?
  - sh[1|i*]t
  - f[0u*]+ck
  - c[u*]+nt
  - c[0o]ck
  - d[a4]mn
  - fux[o0]r
  - f[4a]gg+[o0i]ts?
  - nig\s*nogs?

  regexen:
  - ".*f\s*u\s*c\s*k.*"
  - "c\s*[*u]+\s*n\s*t"
  - "b\s*i\s*c\s*t\s*h"
  - "w\s*h\s*o\s*r\s*e"
  - "s\s*h\s*i\s*t"
  - "n\s*i\s*g\s*g\s*e\s*r"
  - n[i1!*|][69g][69g]+(?:er|3r|a|r|)s?
  - sh[1|i*]t
  - f[0uo*]+ck
  - c[u*]+nt
  - b[i!1*~][t7+]chy?
  - l3[i!1*~][t7+]chy?
  - c[0o]ck
  - d[a4]mn
  - fux[o0]r
  - f[4a]gg+[o0i]ts?
  - nig\s*nogs?
  - "retard(?:ed|s)?"
 */
