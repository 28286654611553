import { I9nSchemaBySystem } from '@biteinc/common';
import type { IntegrationSystem } from '@biteinc/enums';

import type GcnLocation from '~/app/js/models/gcn_location';

type StoredValueOrCompCardI9n =
  | GcnLocation.PartialStoredValueI9n<IntegrationSystem.StoredValue>
  | GcnLocation.PartialCompCardI9n<IntegrationSystem.CompCard>;

export function getShouldShowPinInput(
  storedValueOrCompCardI9n: StoredValueOrCompCardI9n | undefined,
): boolean {
  return !!storedValueOrCompCardI9n?.requiresPin;
}

export function getValidCardNumberLengths(
  storedValueOrCompCardI9n: StoredValueOrCompCardI9n | undefined,
): number[] {
  const defaultLengths = [16];
  if (!storedValueOrCompCardI9n) {
    return defaultLengths;
  }
  return (
    I9nSchemaBySystem[storedValueOrCompCardI9n.system].validCardNumberLengths || defaultLengths
  );
}

export function getValidCardNumberInputRegex(
  storedValueOrCompCardI9n: StoredValueOrCompCardI9n | undefined,
): RegExp {
  const defaultRegExStr = '^[0-9]$';
  if (!storedValueOrCompCardI9n) {
    return new RegExp(defaultRegExStr);
  }
  return new RegExp(
    I9nSchemaBySystem[storedValueOrCompCardI9n.system].validCardNumberInputRegex || defaultRegExStr,
  );
}

export function validCardNumberInputRegexIsNumericOnly(
  storedValueOrCompCardI9n: StoredValueOrCompCardI9n | undefined,
): boolean {
  const defaultRegExStr = '^[0-9]$';
  if (!storedValueOrCompCardI9n) {
    return true;
  }

  const regexStr =
    I9nSchemaBySystem[storedValueOrCompCardI9n.system].validCardNumberInputRegex || defaultRegExStr;
  return regexStr === defaultRegExStr;
}

export function getValidPinLengths(
  storedValueOrCompCardI9n: StoredValueOrCompCardI9n | undefined,
): number[] | undefined {
  if (!storedValueOrCompCardI9n) {
    return undefined;
  }
  return I9nSchemaBySystem[storedValueOrCompCardI9n.system].validPinLengths;
}

export function getValidPinInputRegex(
  storedValueOrCompCardI9n: StoredValueOrCompCardI9n | undefined,
): RegExp | null {
  if (!storedValueOrCompCardI9n) {
    return null;
  }
  const regexStr = I9nSchemaBySystem[storedValueOrCompCardI9n.system].validPinInputRegex;
  return regexStr ? new RegExp(regexStr) : null;
}
