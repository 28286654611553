import * as React from 'react';

import { Log } from '@biteinc/common';

import useLatest from './use-latest';

export type FetcherState<T> =
  | {
      data: undefined;
      status: 'idle';
      error: null;
    }
  | {
      data: undefined;
      status: 'fetching';
      error: null;
    }
  | {
      data: T;
      status: 'refetching';
      error: null;
    }
  | {
      data: T;
      status: 'success';
      error: null;
    }
  | {
      data: undefined;
      status: 'error';
      error: unknown;
    };

export type Fetcher<T> = FetcherState<T> & {
  get: () => Promise<T>;
};

export function useFetcher<T>(
  fetcher: (options: { signal: AbortSignal }) => Promise<T>,
): Fetcher<T> {
  const abortController = React.useRef<AbortController>(new AbortController());
  const [state, setState] = React.useState<FetcherState<T>>({
    data: undefined,
    status: 'idle',
    error: null,
  });

  React.useEffect(() => {
    return () => {
      // on component unmount, abort any pending fetches
      abortController.current.abort();
    };
  }, []);

  async function get(): Promise<T | undefined> {
    abortController.current.abort();
    abortController.current = new AbortController();
    setState(
      (prevState) =>
        ({
          ...prevState,
          status: typeof prevState.data !== 'undefined' ? 'refetching' : 'fetching',
        } as any),
    );
    return fetcher({ signal: abortController.current.signal })
      .then((data) => {
        if (!abortController.current.signal.aborted) {
          setState({ data, status: 'success', error: null });
          return data;
        }
      })
      .catch((error) => {
        // this error is handled
        Log.info(error);
        if (!abortController.current.signal.aborted) {
          setState({ data: undefined, status: 'error', error });
          return undefined;
        }
      });
  }

  return { ...state, get: useLatest(get) } as Fetcher<T>;
}
