import $ from 'jquery';

import { KioskDeviceType } from '@biteinc/enums';

/**
 * GCNForcePlainKeyboard class is designed to address a keyboard issue with Elo devices.
 * The default keyboard on Elo devices includes a suggestion strip that can be hard to remove.
 * To mitigate this issue, we mark text fields as password fields to ensure they display a clean keyboard.
 * This is a workaround to prevent users from encountering problems like GIF image insertion,
 * keyboard splitting, keyboard minimization, etc.
 */
export class GCNForcePlainKeyboard {
  /**
   * Initializes a new instance of the GCNForcePlainKeyboard class.
   * @param {jquery} $inputElement - The input element to which the keyboard adjustments will be applied.
   */
  constructor($inputElement) {
    this.$inputElement = $inputElement;
  }

  /**
   * If the inputElement is a <textarea>, this function switches it to an <input> element
   * to ensure the clean keyboard behavior is applied. Please note that this conversion
   * transforms the multiline input into a single-line input.
   */
  _switchTextAreaToInputIfNeeded() {
    const $inputElement = this.$inputElement;
    const original = $inputElement.get(0);
    if (original.nodeName.toLowerCase() === 'textarea') {
      const { id, class: className, placeholder } = original.attributes;
      const $input = $(`<input
                          id="${id.value}"
                          class="${className.value}"
                          placeholder="${placeholder.value}"
                          value="${original.value}"
                        />`);
      // Copy the content (text) from the textarea to the input
      $input.val(original.value);
      // Replace the textarea with the input in the DOM
      if ($inputElement.length) {
        $inputElement.replaceWith($input);
      }
      // Update the reference to the new input element
      this.$inputElement = $input;
      // Dismiss the keyboard when the return key is pressed
      this.$inputElement.keyup((e) => {
        // keyCode 13 is the return key
        if (e.keyCode === 13) {
          this.$inputElement.blur();
        }
      });
    }
  }

  shouldForcePlainKeyboard() {
    const forcePlainKeyboardDeviceTypes = new Set([
      KioskDeviceType.AndroidElo15,
      KioskDeviceType.AndroidElo22,
      KioskDeviceType.AndroidElo22Backpack,
      KioskDeviceType.AndroidElo22DriveThru,
      KioskDeviceType.AndroidElo32Backpack,
    ]);

    return forcePlainKeyboardDeviceTypes.has(gcn.kiosk?.get('deviceType'));
  }

  enableForcePlainKeyboardIfNeeded() {
    if (this.$inputElement && this.shouldForcePlainKeyboard()) {
      this._switchTextAreaToInputIfNeeded();
      this.$inputElement.attr('pattern', '[a-zA-Z0-9]+');
      this.$inputElement.attr('inputmode', 'password');
      this.$inputElement.attr('type', 'password');
      this.$inputElement.focus();
      this._setupEventListeners();
    }
  }

  /**
   * Sets up event listeners to handle input type switching.
   *
   * Initially, the input type is set to password to ensure a clean password keyboard on Android.
   * Then it request to focus, after focus, the input type is switched to text to allow the user
   * to see the input. The keyboard remains as clean keyboard afterwards even after a re-focus.
   *
   * PS: Do not use `.on('input', ...)` listener as that will lag / slow down on some devices.
   */
  _setupEventListeners() {
    this.$inputElement.on('focus', () => {
      this.$inputElement.attr('type', 'text');
      this.$inputElement.off('keydown');
      this.$inputElement.off('focus');
    });
  }

  /**
   * The onFocus block used for react onFocus event.
   */
  onFocus(event) {
    if (event.target.type === 'password') {
      event.target.type = 'text';
    }
  }
}
