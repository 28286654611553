import { TimeHelper } from '@biteinc/core-react';

import type GcnLocation from './models/gcn_location';

// Logic is duplicated in maitred lead time service
module GcnOrderLeadTimeService {
  // returns millisecond value of lead time based on menu sections and lead time rules
  export function getOrderLeadTime(orderedItems: any[]): number {
    if (!gcn.location.get('orderLeadTimeRules')?.length) {
      return 0;
    }

    const orderedItemCountBySectionId: Record<string, number> = {};
    orderedItems.forEach((orderedItem) => {
      if (!orderedItemCountBySectionId[`${orderedItem.section.id}`]) {
        orderedItemCountBySectionId[`${orderedItem.section.id}`] = 0;
      }
      const quantity = orderedItem.orderedPO.get('quantity');
      orderedItemCountBySectionId[`${orderedItem.section.id}`] += quantity;
    });

    let orderLeadTime = 0;
    gcn.location
      .get('orderLeadTimeRules')
      ?.forEach((leadTimeRule: GcnLocation.OrderLeadTimeRule) => {
        let leadTimeRuleItemCounter = 0;
        leadTimeRule.menuSectionIds.forEach((menuSectionId) => {
          if (orderedItemCountBySectionId[`${menuSectionId}`]) {
            leadTimeRuleItemCounter += orderedItemCountBySectionId[`${menuSectionId}`];
          }
        });
        if (leadTimeRuleItemCounter > 0) {
          const {
            maxItemCountFor15Minutes,
            maxItemCountFor30Minutes,
            maxItemCountFor45Minutes,
            maxItemCountFor60Minutes,
            maxItemCountFor90Minutes,
            maxItemCountFor120Minutes,
            maxItemCountFor180Minutes,
            maxItemCountFor240Minutes,
            maxItemCountFor300Minutes,
            maxItemCountFor360Minutes,
          } = leadTimeRule;

          // filter out unset limits, and map time value
          const leadTimesWithValue = [
            { limit: maxItemCountFor15Minutes, timeValue: 15 * TimeHelper.MINUTE },
            { limit: maxItemCountFor30Minutes, timeValue: 30 * TimeHelper.MINUTE },
            { limit: maxItemCountFor45Minutes, timeValue: 45 * TimeHelper.MINUTE },
            { limit: maxItemCountFor60Minutes, timeValue: 60 * TimeHelper.MINUTE },
            { limit: maxItemCountFor90Minutes, timeValue: 90 * TimeHelper.MINUTE },
            { limit: maxItemCountFor120Minutes, timeValue: 120 * TimeHelper.MINUTE },
            { limit: maxItemCountFor180Minutes, timeValue: 180 * TimeHelper.MINUTE },
            { limit: maxItemCountFor240Minutes, timeValue: 240 * TimeHelper.MINUTE },
            { limit: maxItemCountFor300Minutes, timeValue: 300 * TimeHelper.MINUTE },
            { limit: maxItemCountFor360Minutes, timeValue: 360 * TimeHelper.MINUTE },
          ].filter(({ limit }) => {
            return limit && limit > 0;
          });

          let appliedLimit = false;
          leadTimesWithValue.forEach(({ limit, timeValue }, index) => {
            if (limit! >= leadTimeRuleItemCounter && !appliedLimit) {
              appliedLimit = true;
              orderLeadTime = Math.max(orderLeadTime, timeValue);
            } else if (index + 1 === leadTimesWithValue.length && !appliedLimit) {
              orderLeadTime = Math.max(orderLeadTime, timeValue);
            }
          });
        }
      });
    return orderLeadTime;
  }
}

export default GcnOrderLeadTimeService;
