import $ from 'jquery';
import _ from 'underscore';

import { Strings } from '@biteinc/common';

import { BackboneEvents } from '~/app/js/backbone-events';
import { localizeStr } from '~/app/js/localization/localization';
import { GCNForcePlainKeyboard } from '~/helpers/gcn_force_plain_keyboard';

import Analytics from '../utils/analytics';
import { GCNView } from './gcn_view';

export const GCNCouponView = GCNView.extend({
  className: 'coupon-view',
  template: _.template(
    // prettier-ignore
    '<div class="gift-card-view-top">' +
      '<div class="icon"></div>' +
      '<div id="coupon-popup-title-wrapper"></div>' +
    '</div>' +
    '<div class="gift-card-view-bottom">' +
      '<div class="form-group"></div>' +
    '</div>',
  ),

  initialize(options, ...args) {
    GCNView.prototype.initialize.apply(this, [options, ...args]);

    this._successCallback = options.successCallback;
    this.listenTo(
      gcn.orderManager,
      BackboneEvents.GCNOrderManager.CouponDidValidate,
      this._couponDidValidate,
    );
    this.listenTo(
      gcn.orderManager,
      BackboneEvents.GCNOrderManager.CouponDidFailValidation,
      this._couponDidFailValidation,
    );
    this.addRefreshOnLanguageChangeSubscription();
  },

  _displayError(errorText) {
    this.$('.coupon-popup-title').htmlOrText(errorText).addClass('error');
    gcn.menuView.dismissSpinner();
  },

  _displayFindCouponError(errorText) {
    this.$searchButton.removeClass('disabled');
    this._displayError(errorText);
  },

  _couponDidValidate() {
    gcn.menuView.dismissSpinner();
    this._successCallback();
  },

  _couponDidFailValidation(errorText) {
    gcn.menuView.dismissSpinner();
    this._displayFindCouponError(errorText || localizeStr(Strings.ERROR_COUPON_CONFIRMATION));
  },

  _submitCouponCode() {
    const couponCode = this.$couponCodeInput.val();
    this.$searchButton.addClass('disabled');
    gcn.orderManager.setCouponCode(couponCode);
    gcn.menuView.showSpinner(localizeStr(Strings.LOOKUP_COUPON));
  },

  render() {
    const html = this.template();
    this.$el.html(html);

    // coupon code form
    this.$giftCardNumberLabel = $(
      `<label for="coupon-code-input">${localizeStr(Strings.ENTER_YOUR_COUPON_CODE)}:</label>`,
    );
    this.$couponCodeInput = $(
      '<input class="form-control" id="coupon-code-input" autocomplete="off" autocorrect="off" />',
    );

    new GCNForcePlainKeyboard(this.$couponCodeInput).enableForcePlainKeyboardIfNeeded();

    this.$searchButton = $(
      `<div role="button" class="button ok" id="search-btn">${localizeStr(Strings.SEARCH)}</div>`,
    ).addClass('disabled');

    this.$formGroup = this.$('.form-group');
    this.$formGroup.append(this.$giftCardNumberLabel, this.$couponCodeInput, this.$searchButton);
    this.$giftCardPopupTitleWrapper = this.$('#coupon-popup-title-wrapper');
    this.$giftCardPopupTitleWrapper.append(
      `<div class="coupon-popup-title">${localizeStr(Strings.USE_COUPON)}</div>`,
    );

    this.$searchButton.onButtonTapOrHold('couponSearch', () => {
      if (this.$searchButton.hasClass('disabled')) {
        return;
      }
      Analytics.trackEvent({
        eventName: Analytics.EventName.CouponSearch,
        eventData: {
          couponCode: this.$couponCodeInput.val(),
        },
      });
      this._submitCouponCode();
    });

    this.$couponCodeInput.keyup((e) => {
      const input = $(e.target).val();
      this.$searchButton.toggleClass('disabled', input.length === 0);
    });

    return this;
  },
});
