import _ from 'underscore';

import { Strings } from '@biteinc/common';

import { localizeStr } from '../localization/localization';
import { GCNView } from './gcn_view';

export const GCNAlertView = GCNView.extend({
  className: 'alert-view',
  attributes: {
    'aria-modal': true,
    role: 'dialog',
  },
  template: _.template(
    // prettier-ignore
    '<div class="body" role="heading" aria-level="1" tabindex="-1"></div>' +
    '<div class="bottom-nav">' +
      '<span role="button" class="button cancel"></span>' +
      '<span role="button" class="button ok"></span>' +
    '</div>',
  ),

  initialize(options, ...args) {
    GCNView.prototype.initialize.apply(this, [options, ...args]);

    this.options = options;
    if (options.timeout) {
      const self = this;
      this._timeoutTimer = setTimeout(() => {
        if (self.options.cancelCallback) {
          self.options.cancelCallback();
        }
      }, options.timeout);
    }
  },

  destroy(...args) {
    GCNView.prototype.destroy.apply(this, args);
    this.options = null;
    clearTimeout(this._timeoutTimer);
  },

  _okTap() {
    if (this.options && this.options.okCallback) {
      this.options.okCallback();
    }
  },

  _cancelTap() {
    if (this.options && this.options.cancelCallback) {
      this.options.cancelCallback();
    }
  },

  render() {
    this.$el.html(this.template);
    this.$body = this.$('.body');
    this.$okButton = this.$('.button.ok');
    this.$cancelButton = this.$('.button.cancel');

    if (this.options && this.options.text) {
      this.$body.htmlOrText(this.options.text);
    }
    if (this.options && this.options.content) {
      this.$body.append(this.options.content);
    }
    this.$okButton.onButtonTapOrHold('alertOk', this._okTap.bind(this));

    if (this.options && this.options.cancelCallback) {
      this.$cancelButton.onButtonTapOrHold('alertCancel', this._cancelTap.bind(this));
    } else {
      this.$cancelButton.remove();
    }

    const okText = localizeStr(
      this.options && this.options.okText ? this.options.okText : Strings.OK,
    );
    const cancelText = localizeStr(
      this.options && this.options.cancelText ? this.options.cancelText : Strings.CANCEL,
    );
    this.$okButton.htmlOrText(okText);
    this.$cancelButton.htmlOrText(cancelText);

    if (gcn.screenReaderIsActive) {
      this.$body.requestFocusAfterDelay();
    }

    return this;
  },
});
