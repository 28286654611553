import type { StateCreator } from 'zustand';

import type { UIOrderedItem } from '@biteinc/core-react';

import type { UnvalidatedOrder } from '../types';

type CartState = Omit<UnvalidatedOrder, 'orderedItems'> & {
  orderedItems: UIOrderedItem[];
};

type CartActions = {
  fetch: () => void;
  removeOrderedItem: (uid: string) => void;
  editOrderedItem: (uid: string) => void;
};

export type CartStore = { cart: CartState & CartActions };

const cartStoreInitialState = {
  _id: undefined,
  wasValidated: undefined,
  subTotal: 0,
  orderedItems: [],
};

export const initCartStore: StateCreator<CartStore> = (set) => ({
  cart: {
    ...cartStoreInitialState,
    fetch: () => {
      set((state) => ({
        cart: {
          ...state.cart,
          ...(typeof gcn !== 'undefined'
            ? gcn.orderManager.getOrderForStore()
            : cartStoreInitialState),
        },
      }));
    },
    removeOrderedItem: (uid: string) => {
      gcn.orderManager.removeFromOrderV2(uid);
      set((state) => ({ cart: { ...state.cart, ...gcn.orderManager.getOrderForStore() } }));
    },
    editOrderedItem: (uid: string) => {
      gcn.orderManager.editItem(uid);
      set((state) => ({ cart: { ...state.cart, ...gcn.orderManager.getOrderForStore() } }));
    },
  },
});
