import * as ReactDOM from 'react-dom';

export function Portal({
  children,
  container,
}: {
  children: React.ReactNode;
  container?: string;
}): React.ReactNode {
  const el = container ? document.getElementById(container)! : document.body;
  return ReactDOM.createPortal(children, el);
}
