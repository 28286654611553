import _ from 'underscore';

import { Strings } from '@biteinc/common';
import type { DeliveryAddress, Order } from '@biteinc/core-react';
import { CustomerIdentifier, CustomerIdentifierInputMethod } from '@biteinc/enums';

import { str } from '~/app/js/localization/localization';

import type GcnLocation from '../app/js/models/gcn_location';
import type { GcnOrder } from '../types/gcn_order';

module GcnFulfillmentHelper {
  // Copied almost verbatim from /packages/node-service/src/helpers/receipt_helper.ts
  export function addressToString(
    address: GcnLocation.Address | (DeliveryAddress & { crossStreet?: string }) | undefined,
    skipCrossStreet = false,
  ): string {
    if (!address) {
      return '';
    }
    const addressParts = [address.line1, address.line2, address.city, address.postalCode];
    const addressStr = addressParts
      .filter((val) => {
        return val;
      })
      .join(', ');
    if (skipCrossStreet || !address.crossStreet) {
      return addressStr;
    }
    return `${addressStr}\n(${address.crossStreet})`;
  }

  // Copied almost verbatim from /packages/node-service/src/helpers/receipt_helper.ts
  export function addressAsMultipleLines(
    address: GcnLocation.Address | DeliveryAddress | undefined,
  ): string {
    if (!address) {
      return '';
    }
    const { line1, line2, city, postalCode, state } = address;
    const addressLines: string[] = [];
    if ('crossStreet' in address && address.crossStreet) {
      addressLines.push(address.crossStreet);
    }
    addressLines.push(line1);
    if (line2) {
      addressLines.push(line2);
    }
    let line3 = `${city},`;
    if (state) {
      line3 += ` ${state}`;
    }
    line3 += ` ${postalCode}`;
    addressLines.push(line3);
    return addressLines.join('\n');
  }

  // Copied almost verbatim from /packages/node-service/src/helpers/receipt_helper.ts
  export function getVehicleDescription(
    guestVehicle: Order['guestVehicle'] | undefined,
  ): string | undefined {
    if (!guestVehicle) {
      return undefined;
    }
    return `${guestVehicle.color} ${guestVehicle.make}, ${guestVehicle.model}`;
  }

  // Copied almost verbatim from /packages/node-service/src/helpers/receipt_helper.ts
  export function getOutpostName(outpost?: Order['outpost']): string {
    return outpost?.roomNumber ? `${outpost?.name} ${outpost.roomNumber}` : outpost?.name || '';
  }

  function getFulfillmentInstructionsAndIdentifier(
    location: GcnLocation,
    order: GcnOrder,
    diningOption: GcnLocation.DiningOption,
    orderNumber: string,
  ): {
    fulfillmentInstructions: string[];
    customerIdentifierValue: string;
  } {
    const firstCustomerIdentifierOption = diningOption.customerIdentifierOptions[0];
    const fulfillmentInstructions = [];
    let customerIdentifierValue: string = '';
    const { customerIdentifier, inputMethod } = firstCustomerIdentifierOption;
    switch (customerIdentifier) {
      case CustomerIdentifier.GuestName:
        fulfillmentInstructions.push(str(Strings.FULFILLMENT_INSTRUCTION_GUEST_NAME));
        customerIdentifierValue = order.get('guestName')!;
        break;
      case CustomerIdentifier.TableNumber:
        if (inputMethod === CustomerIdentifierInputMethod.TableTentPicker) {
          fulfillmentInstructions.push(str(Strings.FULFILLMENT_INSTRUCTION_TABLE_NUMBER));
        } else {
          fulfillmentInstructions.push(str(Strings.FULFILLMENT_INSTRUCTION_TRACKER_NUMBER));
        }
        customerIdentifierValue = order.get('tableNumber')!;
        break;
      case CustomerIdentifier.BiteOrderNumber:
      case CustomerIdentifier.PosOrderNumber:
        fulfillmentInstructions.push(str(Strings.FULFILLMENT_INSTRUCTION_ORDER_NUMBER));
        customerIdentifierValue = orderNumber;
        break;
      case CustomerIdentifier.VehicleDescription: {
        fulfillmentInstructions.push(str(Strings.FULFILLMENT_INSTRUCTION_VEHICLE_DESCRIPTION));
        customerIdentifierValue = getVehicleDescription(order.get('guestVehicle'))!;
        break;
      }
      case CustomerIdentifier.Address: {
        fulfillmentInstructions.push(str(Strings.FULFILLMENT_INSTRUCTION_ADDRESS));
        customerIdentifierValue = addressToString(order.get('deliveryAddress'));
      }
    }
    if (
      customerIdentifier === CustomerIdentifier.PhoneNumber ||
      (order.get('guestPhoneNumber') &&
        (location.get('notifyUserOfOrderStatusUpdates') ||
          location.getDiningOption(diningOption.fulfillmentMethod)?.smsNotificationEnabled))
    ) {
      fulfillmentInstructions.push(str(Strings.TEXT_YOU_WHEN_READY));
    }
    return { fulfillmentInstructions, customerIdentifierValue };
  }

  export function getFulfillmentInfo(
    location: GcnLocation,
    order: GcnOrder,
    orderNumber: string,
    diningOption?: GcnLocation.DiningOption,
  ): {
    fulfillmentInstructions: string[];
    customerIdentifier: CustomerIdentifier;
    customerIdentifierLabel: string;
    customerIdentifierValue: string;
  } {
    if (!diningOption) {
      return {
        customerIdentifier: CustomerIdentifier.BiteOrderNumber,
        customerIdentifierLabel: str(Strings.FULFILLMENT_LABEL_ORDER_NUMBER),
        customerIdentifierValue: orderNumber,
        fulfillmentInstructions: [str(Strings.FULFILLMENT_INSTRUCTION_ORDER_NUMBER)],
      };
    }
    const firstCustomerIdentifierOption = diningOption.customerIdentifierOptions[0];

    let customerIdentifierLabel;
    switch (firstCustomerIdentifierOption.customerIdentifier) {
      case CustomerIdentifier.GuestName:
        customerIdentifierLabel = str(Strings.FULFILLMENT_LABEL_GUEST_NAME);
        break;
      case CustomerIdentifier.TableNumber:
        if (
          firstCustomerIdentifierOption.inputMethod ===
          CustomerIdentifierInputMethod.TableTrackerPicker
        ) {
          customerIdentifierLabel = str(Strings.FULFILLMENT_LABEL_TRACKER_NUMBER);
        } else {
          customerIdentifierLabel = str(Strings.FULFILLMENT_LABEL_TABLE_NUMBER);
        }
        break;
      case CustomerIdentifier.PosOrderNumber:
      case CustomerIdentifier.BiteOrderNumber:
        customerIdentifierLabel = str(Strings.FULFILLMENT_LABEL_ORDER_NUMBER);
        break;
      case CustomerIdentifier.VehicleDescription:
        customerIdentifierLabel = str(Strings.FULFILLMENT_LABEL_VEHICLE_DESCRIPTION);
        break;
      case CustomerIdentifier.Address:
        customerIdentifierLabel = str(Strings.FULFILLMENT_LABEL_ADDRESS);
        break;
      case CustomerIdentifier.PhoneNumber:
        customerIdentifierLabel = str(Strings.FULFILLMENT_LABEL_PHONE_NUMBER);
        break;
      case CustomerIdentifier.GuestEmail:
        customerIdentifierLabel = str(Strings.FULFILLMENT_LABEL_GUEST_EMAIL);
        break;
    }

    return {
      customerIdentifierLabel,
      customerIdentifier: firstCustomerIdentifierOption.customerIdentifier,
      ...getFulfillmentInstructionsAndIdentifier(location, order, diningOption, orderNumber),
    };
  }

  export function getOrderNumber(location: GcnLocation, order: GcnOrder): string {
    const posOrderNumberIdentifier = location
      .getCustomerIdentifierOptions(order.get('fulfillmentMethod'))
      ?.find(({ customerIdentifier }) => {
        return customerIdentifier === CustomerIdentifier.PosOrderNumber;
      });
    if (posOrderNumberIdentifier && order.getPosCheckIds().length) {
      const posOrderNumber = order.getPosCheckIds()[0];
      if (
        posOrderNumberIdentifier.customerIdentifier === CustomerIdentifier.PosOrderNumber &&
        _.isNumber(posOrderNumberIdentifier.subStringLength) &&
        posOrderNumberIdentifier.subStringLength !== 0
      ) {
        const { subStringLength } = posOrderNumberIdentifier;
        return subStringLength > 0
          ? posOrderNumber.substring(0, subStringLength)
          : posOrderNumber.slice(subStringLength);
      }

      return order.getPosCheckIds()[0];
    }

    return order.get('clientNumber');
  }
}

export default GcnFulfillmentHelper;
