import React from 'react';

import type { CustomStringMap, LocalizableString } from '@biteinc/common';
import { LocalizationHelper } from '@biteinc/core-react';
import { LanguageCode } from '@biteinc/enums';

import { useStore } from '~/stores';
import type { NormalizedLocalizableModel } from '~/types';

function currentLanguage(): LanguageCode {
  return useStore.getState().config.language;
}

export function useLocalize(): (
  localizableString: LocalizableString | string,
  params?: string[],
) => JSX.Element | string {
  const language = useStore((state) => state.config.language);
  const customStrings = useStore((state) => state.bridge.menu.settings.customStrings);

  // eslint-disable-next-line react/display-name
  return React.useCallback(
    (localizableString, params) => {
      if (typeof localizableString === 'string') {
        return localizableString;
      }
      const localizedStr = str(localizableString, params, language, customStrings);
      if (!window.showCustomStringsKeys) {
        return localizedStr;
      }
      const localizedStrKey =
        typeof localizableString === 'string' ? localizableString : localizableString?.key;
      return (
        <span className="gcn-custom-string">
          {localizedStr}
          <span className="tooltip">{localizedStrKey} </span>
        </span>
      );
    },
    [language, customStrings],
  );
}

// this recursively loops the model fields to find any localization overrides and maps them
// turns something like { name: string, localizationOverrides: { language: LanguageCode, overrideByFieldName: { name: string } }[] }
// into { name: { [LanguageCode]: string } }
// if no localization overrides are found, it just returns the model as is
export function normalizeModelLocalization<T extends Record<string, any> | string | number>(
  model: T,
): NormalizedLocalizableModel<T> {
  if (typeof model !== 'object' || !model) {
    return model as any;
  }
  const normalizedModel = { ...model } as any;

  if (normalizedModel.localizationOverrides) {
    for (let i = 0; i < normalizedModel.localizationOverrides.length; i++) {
      const override = normalizedModel.localizationOverrides[i];
      Object.keys(override.overrideByFieldName).forEach((field) => {
        const localizableString = override.overrideByFieldName[field];
        // overrides do not include english, so we need to make sure we have it
        // by default, the model field is the english value
        if (typeof normalizedModel[field] !== 'object') {
          normalizedModel[field] = {
            [LanguageCode.EN_US]: normalizedModel[field],
          };
        }
        normalizedModel[field][override.language] = localizableString;
      });
    }
  }

  Object.keys(model).forEach((key) => {
    const value = normalizedModel[key];
    if (Array.isArray(value)) {
      normalizedModel[key] = value.map((item) => normalizeModelLocalization(item));
    } else if (typeof value === 'object') {
      normalizedModel[key] = normalizeModelLocalization(value);
    }
  });

  return normalizedModel;
}

// Get a localized string for a given string id.
export function str(
  localizableString: LocalizableString | string,
  params: string[] = [],
  optLanguage?: LanguageCode,
  optCustomStringMap?: CustomStringMap,
  format?: Function,
): string {
  const language = optLanguage || currentLanguage();
  const customStringMap =
    optCustomStringMap || useStore.getState().bridge.menu.settings.customStrings;
  if (format) {
    return format(
      LocalizationHelper.localize(localizableString, params, language, customStringMap),
    );
  }
  return LocalizationHelper.localize(localizableString, params, language, customStringMap);
}

export function kioskPreviewCustomStringWithTooltip(args: {
  localizableString: LocalizableString | string;
  params: string[];
  optLanguage?: LanguageCode;
  optCustomStringMap?: CustomStringMap;
  format: Function;
}): string {
  const { localizableString, params = [], optLanguage, optCustomStringMap, format } = args;
  const isLocalizableStringAString = typeof localizableString === 'string';
  if (isLocalizableStringAString) {
    // we return the string that might contain localizableString
    // happens in GCNAlertView where `okText` or `cancelText` might already be localized
    return localizableString;
  }

  const localizedStr = str(localizableString, params, optLanguage, optCustomStringMap, format);

  const template = `<span class="gcn-custom-string">${localizedStr}<span class="tooltip">${localizableString?.key}</span></span>`;
  return template;
}

export function localizeStr(
  localizableString: LocalizableString | string,
  params: string[] = [],
  format: Function = (content: string): string => {
    return content;
  },
  optLanguage?: LanguageCode,
  optCustomStringMap?: CustomStringMap,
): string {
  if (window.showCustomStringsKeys) {
    return kioskPreviewCustomStringWithTooltip({
      localizableString,
      params,
      optLanguage,
      optCustomStringMap,
      format,
    });
  }

  return str(localizableString, params, optLanguage, optCustomStringMap, format);
}
