import type { Placement } from '@floating-ui/react-dom-interactions';
import {
  FloatingFocusManager,
  autoUpdate,
  flip,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useId,
  useInteractions,
  useRole,
} from '@floating-ui/react-dom-interactions';
import React, { cloneElement, useMemo, useState } from 'react';

import { composeRefs } from '../../app/js/utils/use-composed-refs';

interface Props {
  render: (data: { close: () => void; labelId: string; descriptionId: string }) => React.ReactNode;
  placement?: Placement;
  children: JSX.Element;
}

export const Popover: React.FC<Props> = ({ children, render, placement }: Props) => {
  const [open, setOpen] = useState(false);

  const { x, y, reference, floating, strategy, context } = useFloating({
    open,
    onOpenChange: setOpen,
    placement,
    middleware: [offset(10), flip(), shift()],
    // ResizeObserver is not supported in < Chrome 64
    // Auto updating the popover position is not supported in < Chrome 65
    // https://caniuse.com/resizeobserver
    // eslint-disable-next-line compat/compat
    whileElementsMounted: window.ResizeObserver ? autoUpdate : undefined,
  });

  const id = useId();
  const labelId = `${id}-label`;
  const descriptionId = `${id}-description`;

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useRole(context),
    useDismiss(context),
  ]);

  const ref = useMemo(
    () => composeRefs(reference, (children as React.FunctionComponentElement<unknown>).ref),
    [reference, children],
  );

  return (
    <>
      {cloneElement(children, getReferenceProps({ ref, ...children.props }))}
      {open && (
        <FloatingFocusManager
          context={context}
          modal={false}
          order={['reference', 'content']}
          returnFocus={true}
        >
          <>
            <div
              ref={floating}
              className="basic-react-popover"
              style={{
                position: strategy,
                left: x ?? 0,
                top: y ?? 0,
              }}
              aria-labelledby={labelId}
              aria-describedby={descriptionId}
              {...getFloatingProps()}
            >
              {render({
                labelId,
                descriptionId,
                close: () => {
                  setOpen(false);
                },
              })}
            </div>
          </>
        </FloatingFocusManager>
      )}
    </>
  );
};
