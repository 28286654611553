import type { StateCreator } from 'zustand';

import { normalizeModelLocalization } from '~/app/js/localization/localization';
import type { KioskLocation, KioskMenu } from '~/types';

// typing this is a bit more complicated since this is async data, that is initiated by the native layer
// we can afford to be a bit loose here since we can type the hooks that use this store
export type BridgeState = {
  location: KioskLocation;
  menu: KioskMenu;
  sessionStart: boolean;
} & Record<string, any>;

export type BridgeActions = {
  update: (key: string, data: any) => void;
};

export type BridgeStore = {
  bridge: BridgeState & BridgeActions;
};

// This will act as the global store for the bridge
// it is updated whenever the native layer sends data
// Eventually we should allow GCN to control its data requirements
// and only listen to data that it needs from the native layer (printer, etc)
export const initBridgeStore: StateCreator<BridgeStore> = (set) => ({
  bridge: {
    // this gets updated immediately
    location: {} as any,
    // this gets updated immediately
    menu: {} as any,
    kiosk: {},
    sessionStart: false,
    update: (key: string, data: any) =>
      set((state) => ({ bridge: { ...state.bridge, [key]: normalizeModelLocalization(data) } })),
  },
});
