import { IonButton, IonCol, IonRow } from '@ionic/react';
import React, { Component } from 'react';

import { Strings } from '@biteinc/common';
import type { Schema } from '@biteinc/core-react';
import { ValidationHelper } from '@biteinc/core-react';

import { str } from '~/app/js/localization/localization';

import type GcnLocation from '../app/js/models/gcn_location';
import { GcnCustomerAccountHelper, GcnCustomerIdentifierHelper } from '../helpers';
import { AuthService, CustomerIdentifierService } from '../services';
import type {
  CustomerIdentifierData,
  CustomerIdentifierState,
  KioskLocation,
  KioskMenuSettings,
} from '../types';

interface LoginWallProps {
  guestInfo: CustomerIdentifierState;
  customerIdentifierOptions: GcnLocation.CustomerIdentifierOption[];
  createOrder: () => void;
  location: KioskLocation;
  settings: KioskMenuSettings;
}

export class LoginWall extends Component<LoginWallProps, {}> {
  private readonly schema: Schema;

  constructor(props: LoginWallProps) {
    super(props);
    this.schema =
      GcnCustomerIdentifierHelper.getCustomerIdentifierSchemasMergedIntoSingleSchemaWithEmail(
        this.props.customerIdentifierOptions,
      );
  }

  private setCustomerIdentifiers(data: CustomerIdentifierData): void {
    CustomerIdentifierService.setCustomerIdentifierState(data);
    this.props.createOrder();
  }

  private collectCustomerIdentifiers(guestInfo: CustomerIdentifierState | null): void {
    CustomerIdentifierService.collectCustomerIdentifiers(this.props.settings, {
      // Enable exit button if we have customer accounts enabled otherwise enforce collection of info
      init: !GcnCustomerAccountHelper.customerAccountsAreEnabled(),
      guestData: guestInfo,
      customerIdentifierOptions: this.props.customerIdentifierOptions,
      onSubmit: async (data: CustomerIdentifierData) => {
        this.setCustomerIdentifiers(data);
      },
    });
  }

  private signInCallback(): void {
    const guestInfo = CustomerIdentifierService.getCustomerIdentifierState();
    const validCustomerIdentifiers = ValidationHelper.validateWithSchema(
      guestInfo,
      this.schema,
    ).isValid;
    if (!validCustomerIdentifiers) {
      this.collectCustomerIdentifiers(guestInfo);
    } else {
      this.setCustomerIdentifiers(guestInfo);
    }
  }

  private signin(): void {
    AuthService.showLogin(this.props.location, this.signInCallback.bind(this));
  }

  private signup(): void {
    if (window.signupUrl) {
      window.location.href = window.signupUrl;
      return;
    }
    AuthService.showSignup(this.props.location, this.signInCallback.bind(this));
  }

  private continueAsGuest(): void {
    const guestData = gcn.orderManager.getPersistedGuestData();
    if (guestData) {
      CustomerIdentifierService.setCustomerIdentifierState(guestData);
    }
    this.collectCustomerIdentifiers(guestData);
  }

  private getLoginWall(): React.ReactNode {
    const mdUpSize = window.signupUrl ? '12' : '6';
    return (
      <IonRow className="login-wall-container">
        <IonCol
          size="12"
          className="login-wall-title-container"
        >
          <h3 className="title">{str(Strings.LOGIN_WALL_WELCOME)}</h3>
        </IonCol>
        <IonCol
          sizeXs="12"
          sizeSm="12"
          sizeMd={mdUpSize}
          sizeLg={mdUpSize}
          sizeXl={mdUpSize}
          className="login-wall-button-container"
        >
          <IonButton
            color="primary"
            expand="block"
            className="signin"
            onClick={() => {
              this.signin();
            }}
          >
            {str(Strings.CUSTOMER_ACCOUNT_BUTTON_LOG_IN)}
          </IonButton>
        </IonCol>
        {
          /**
           * If there is an external link for signup - don't show signup until they have paid as this
           * disrupts the flow.
           */
          !window.signupUrl && (
            <IonCol
              sizeXs="12"
              sizeSm="12"
              sizeMd={mdUpSize}
              sizeLg={mdUpSize}
              sizeXl={mdUpSize}
              className="login-wall-button-container"
            >
              <IonButton
                color="medium"
                fill="outline"
                expand="block"
                className="signup"
                onClick={() => {
                  this.signup();
                }}
              >
                {str(Strings.SIGNUP_CTA)}
              </IonButton>
            </IonCol>
          )
        }
        <IonCol
          size="12"
          className="login-wall-button-container"
        >
          <IonButton
            color="dark"
            fill="clear"
            expand="block"
            className="continue-as-guest"
            onClick={() => {
              this.continueAsGuest();
            }}
          >
            {str(Strings.CONTINUE_AS_GUEST)}
          </IonButton>
        </IonCol>
      </IonRow>
    );
  }

  render(): React.ReactNode {
    const flattened = CustomerIdentifierService.flattenDataForValidation(this.props.guestInfo);
    if (!ValidationHelper.validateWithSchema(flattened, this.schema).isValid) {
      return this.getLoginWall();
    }
    return null;
  }
}
