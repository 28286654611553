import async from 'async';
import _ from 'underscore';

import { Log } from '@biteinc/common';

import type { GcnOrder } from '~/types/gcn_order';

import type GcnLocation from '../models/gcn_location';
import type { I9nClient } from './i9n_client';
import { makeI9nClient } from './i9n_factory';

export class I9nManager {
  private static i9nClientByI9nId: Record<string, I9nClient> = {};

  static async configure(location: GcnLocation): Promise<void> {
    try {
      Log.info('I9nManager | Configuring Integration Clients');

      await async.forEachSeries(location.getTableTrackerIntegrations(), (partialI9n, cb) => {
        try {
          const i9nIdStr = partialI9n._id.toString();
          const existingI9nClient = this.i9nClientByI9nId[i9nIdStr];

          if (!(existingI9nClient && _.isEqual(partialI9n, existingI9nClient.i9nData))) {
            if (existingI9nClient) {
              Log.warn(`I9nManager | Reconfiguring Integration Client: ${i9nIdStr}`);
            }

            const newI9nClient = makeI9nClient(partialI9n);
            this.i9nClientByI9nId[i9nIdStr] = newI9nClient;

            newI9nClient
              .configure()
              .then(() => {
                cb(null);
              })
              .catch(cb);
          } else {
            Log.info(`I9nManager | Skipping Integration Client: ${i9nIdStr}`);
            cb(null);
          }
        } catch (e) {
          cb(e);
        }
      });

      Log.info('I9nManager | Configuration Success');
    } catch (e) {
      Log.info(`I9nManager | Configuration Failure | ${e}`);
      throw e;
    }
  }

  static async sendOrder(gcnOrder: GcnOrder): Promise<void> {
    try {
      Log.info('I9nManager | Sending Order');

      await async.forEachSeries(Object.values(this.i9nClientByI9nId), (client, cb) => {
        try {
          client
            .sendOrder(gcnOrder)
            .then(() => {
              cb(null);
            })
            .catch(cb);
        } catch (e) {
          cb(e);
        }
      });

      Log.info('I9nManager | Send Order Success');
    } catch (e) {
      Log.info(`I9nManager | Send Order Failure | ${e}`);
      throw e;
    }
  }
}
