import { KioskPeripheralType } from '@biteinc/enums';

import type { GcnModelData } from './gcn_model_ts';
import GcnModelTs from './gcn_model_ts';

class GcnKiosk extends GcnModelTs<GcnKiosk.Data> {
  static isScaleConnected(): boolean {
    if (!window.hardwareConfig) {
      return false;
    }

    // Android
    if (window.hardwareConfig.scaleClient) {
      return true;
    }

    // iOS
    return Object.values(window.hardwareConfig.printers).some((printer) => {
      return printer.peripherals?.includes(KioskPeripheralType.Scale);
    });
  }

  static isScannerConnected(): boolean {
    if (!window.hardwareConfig) {
      return false;
    }
    // Android
    if (window.hardwareConfig.scannerClient) {
      return true;
    }

    // iOS
    return Object.values(window.hardwareConfig.printers).some((printer) => {
      return printer.peripherals?.includes(KioskPeripheralType.Scanner);
    });
  }
}

namespace GcnKiosk {
  // NOTE: This type is duplicated in /db-models/models/kiosk.ts
  export type Data = GcnModelData &
    Readonly<{
      orgId: string;
      locationId: string;
      name: string;

      hasHid?: boolean;
      hasMsr?: boolean;
      paymentTerminalSerialNumber?: string;

      // On Prem
      useOnPremOrdering?: boolean;
    }>;
}

export default GcnKiosk;
