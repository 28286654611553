import { IonButton, IonItem, IonLabel, IonList } from '@ionic/react';
import React, { Component } from 'react';

import type { Order, UIOrderedItem } from '@biteinc/core-react';

import type { OrderPayload } from '../types';
import { OrderedItemComponent } from './ordered-item';

interface OrderSummaryProps {
  order: OrderPayload;
  canEdit: boolean;
  onEdit: () => void;
}
interface OrderSummaryState {
  showAllItems: boolean;
}

export class OrderSummary extends Component<OrderSummaryProps, OrderSummaryState> {
  constructor(props: OrderSummaryProps) {
    super(props);

    this.state = {
      showAllItems: false,
    };
  }

  private getOrderedItems(): React.ReactNode[] {
    return this.props.order.orderedItems.map((orderedItem, index) => {
      // Only show 1st 2 items in list at first
      if (!this.state.showAllItems && index > 1) {
        return null;
      }
      return (
        <OrderedItemComponent
          // eslint-disable-next-line no-underscore-dangle
          key={(orderedItem as UIOrderedItem)._uid}
          orderedItem={orderedItem as UIOrderedItem}
          index={index}
          {...(!this.props.canEdit && {
            showFavoriteButton: true,
            order: this.props.order as Order,
          })}
        />
      );
    });
  }

  private getShowMoreButton(): React.ReactNode {
    if (this.props.order.orderedItems.length < 3) {
      return null;
    }
    return (
      <IonItem className="show-all-button-container">
        <IonButton
          className="show-all-button"
          fill="clear"
          expand="block"
          onClick={() => {
            this.setState({
              showAllItems: !this.state.showAllItems,
            });
          }}
        >
          {this.state.showAllItems ? 'Collapse List' : 'Expand List'}
        </IonButton>
      </IonItem>
    );
  }

  render(): React.ReactNode {
    return (
      <IonList className="order-summary-list">
        <IonItem
          lines="none"
          className="order-summary-header"
        >
          <IonLabel className="title">
            <h3>Order Summary</h3>
          </IonLabel>
          {this.props.canEdit && (
            <IonButton
              className="edit-order-details-button"
              fill="clear"
              onClick={() => {
                this.props.onEdit();
              }}
            >
              Edit
            </IonButton>
          )}
        </IonItem>
        {this.getOrderedItems()}
        {this.getShowMoreButton()}
      </IonList>
    );
  }
}
