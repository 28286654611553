import { MenuModifierPriceDisplayStyle } from '@biteinc/enums';

import { BackboneEvents } from '~/app/js/backbone-events';

import GcnAddonHelper from '../../gcn_addon_helper';
import GcnHtml from '../../gcn_html';
import ScreenReaderHelper from '../../screen_reader_helper';
import { GCNAddonButtonView } from '../gcn_addon_button_view';
import { GCNAddonSetPickerView } from '../gcn_addon_set_picker_view';
import { GCNCustomizeFlowStepView } from './gcn_customize_flow_step_view';
import { GCNFullScreenFlowStepView } from './gcn_full_screen_flow_step_view';

export const GCNAddonSetStepView = GCNCustomizeFlowStepView.extend({
  className: 'customize-flow-step-view addon-set',
  stepType: GCNFullScreenFlowStepView.StepType.AddonSet,

  initialize(options, ...args) {
    GCNCustomizeFlowStepView.prototype.initialize.apply(this, [options, ...args]);

    this.addonSet = this.options.addonSet;
    this.parentId = this.options.parentId;
    this.parentAddonId = this.options.parentAddonId;
    this.isEditingPrevious = !!this.options.isEditingPrevious;
    this.fullScreenSeparateFlowView = !!this.options.fullScreenSeparateFlowView;
    this.addonSetStepsGenealogy = [...(this.options.genealogyArray || [])];
    this._optionCodeSelectionStructByAddonId = {};
    this._selectedPriceOptionByAddonId = {};
  },

  confirmStep() {
    const orderedItem = this.parent.orderedItem;
    if (this.fullScreenSeparateFlowView) {
      orderedItem.extendSelectionStructForSetId(
        this.addonSetPickerView.getSelectionStruct(),
        this.addonSet.id,
        this.addonSetStepsGenealogy,
      );
    } else {
      orderedItem.setSelectionStructForSetId(
        this.addonSetPickerView.getSelectionStruct(),
        this.addonSet.id,
      );
    }
    return true;
  },

  cancelStep() {
    // Remove everything from the parent incase it was added before.
    this.parent.orderedItem.removeAllAddonsForSet(this.addonSet);
  },

  _updateErrorState(isError) {
    const $warning = this.$('.max-warning');
    $warning.toggleClass('highlighted', isError);
    if (isError) {
      const errorMessage = this.addonSet.selectableText();
      this.trigger(BackboneEvents.GCNAddonSetPickerView.AddonErrorMessageShown, errorMessage);
      const animation = 'flash';
      $warning.toggleClass('animated', true);
      $warning.toggleClass(animation, true);
      $warning.one(
        'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend',
        () => {
          $warning.toggleClass('animated', false);
          $warning.toggleClass(animation, false);
        },
      );
    }
  },

  _pickerSelectionDidChange() {
    const isValid = this.addonSetPickerView.isSelectionStructValid();
    this.setNextDisabled(!isValid);
    this._updateErrorState(!isValid);
  },

  setIsCurrent(isCurrent, ...args) {
    GCNCustomizeFlowStepView.prototype.setIsCurrent.apply(this, [isCurrent, ...args]);

    if (isCurrent && gcn.screenReaderIsActive) {
      this.$el
        .find(`#mod-group-step-view-header-${this.addonSet.id}`)
        .closest('.header-container')
        .requestFocusAfterDelay();
    }
  },

  render(...args) {
    GCNCustomizeFlowStepView.prototype.render.apply(this, args);

    this.$el.attr('id', GCNAddonButtonView.IdPrefix + this.addonSet.id);
    this.$el.toggleClass(`addonSet-${this.addonSet.getSlugName()}`, true);

    let hideIndividualPrices = false;
    if (this.addonSet.displayNameHtml().length) {
      let addonSetName = '';

      // If this is a nested addon set, prepend the parent addon name.
      if (this.parentAddonId && gcn.menu.settings.get('prependParentModNamesForNestedMods')) {
        addonSetName += `${gcn.menu.getMenuItemWithId(this.parentAddonId).displayName()}: `;
      }

      addonSetName += this.addonSet.displayName();

      let addonSetAriaLabel = ScreenReaderHelper.prepareAriaLabel(addonSetName);
      let addonSetHtml = GcnHtml.htmlFromString(addonSetName);
      if (
        this.addonSet.addonsHaveTheSameNonZeroPrice() &&
        gcn.menu.settings.get('modifierPriceDisplayStyle') !==
          MenuModifierPriceDisplayStyle.ShowPriceOnAllMods
      ) {
        const price = this.addonSet.addonPriceInSet(this.addonSet.items[0]);
        addonSetAriaLabel += ` $${GcnHtml.stringFromPrice(price)}`;
        addonSetHtml += ` ${GcnHtml.htmlFromPrice(price, { className: 'braces' })}`;
        hideIndividualPrices = true;
      }
      this.$stepBody.append(
        // prettier-ignore
        `<div class="header-container" aria-label="${addonSetAriaLabel}" tabindex="-1" role="heading" aria-level="1">` +
          `<div id="mod-group-step-view-header-${this.addonSet.id}" class="header bg-color-spot-1" aria-hidden="true">${addonSetHtml}</div>` +
        '</div>',
      );
    }

    if (this.addonSet.displayDescriptionHtml()) {
      const modGroupDescriptionHTML = this.addonSet.displayDescriptionHtml();
      this.$stepBody
        .find('.header-container')
        .append(`<div class="font-body description-subtitle">${modGroupDescriptionHTML}</div>`);
    }

    if (this.addonSet.hasSelectableRules()) {
      const text = this.addonSet.selectableText();
      if (text) {
        this.$stepBody
          .find('.header-container')
          .append(`<div class="max-warning" aria-hidden="true">${text}</div>`);

        // Aria hidden = true as the text will be append to the addonSet display name
        const $headerContainer = this.$stepBody.find(`.header-container`);
        const headerAriaLabel = $headerContainer.attr('aria-label');
        const edited = headerAriaLabel + ', ' + text;
        $headerContainer.attr('aria-label', edited);
      }

      // Initial state.
      const minSelectable = this.addonSet.get('minSelectable');
      if (minSelectable && minSelectable > 0) {
        this.setNextDisabled(true);
      }
    }

    if (this.addonSet.hasWalletText()) {
      const walletText = this.addonSet.getWalletText();
      if (walletText) {
        this.$stepBody
          .find('.header-container')
          .append(`<div class="wallet-message">${walletText}</div>`);
      }
    }

    // Create price option buttons.
    this.addonSetPickerView = new GCNAddonSetPickerView({
      addonSet: this.addonSet,
      hideIndividualPrices,
      addonsChangedCallback: this._pickerSelectionDidChange.bind(this),
      fullScreenSeparateFlowView: this.fullScreenSeparateFlowView,
      // assumption that customizedFlowSetup cannot have assorted addons
      isAssorted: false,
      _optionCodeSelectionStructByAddonId: this._optionCodeSelectionStructByAddonId,
      selectedPriceOptionByAddonId: this._selectedPriceOptionByAddonId,
      parentItem: this.parent.orderedItem.item,
      isEditingPrevious: this.isEditingPrevious,
    });
    this.$stepBody.append(this.addonSetPickerView.render().$el);

    // Listen before setting the selectionStruct because it could trigger events
    if (this.options.fullScreenSeparateFlowView) {
      // trickle upwards to customize flow view
      this.listenTo(
        this.addonSetPickerView,
        BackboneEvents.GCNAddonSetPickerView.NestedPickerNewPageAdded,
        ({ nestedAddonSets, parentAddonSetId, addon }) => {
          this.trigger(BackboneEvents.GCNAddonSetPickerView.NestedPickerNewPageAdded, {
            nestedAddonSets,
            parentAddonSetId,
            addon,
            addonSetStepsGenealogy: this.addonSetStepsGenealogy,
          });
        },
      );
      this.listenTo(
        this.addonSetPickerView,
        BackboneEvents.GCNAddonSetPickerView.NestedPickerNewPageRemoved,
        (removedNestedAddonSetInfo) => {
          this.trigger(
            BackboneEvents.GCNAddonSetPickerView.NestedPickerNewPageRemoved,
            removedNestedAddonSetInfo,
          );
        },
      );
    }

    this.addonSetPickerView.setSelectionStruct(
      this.parent.orderedItem.getSelectionStructForAddonSetId(this.addonSet.id),
    );

    this.listenTo(
      this.addonSetPickerView,
      BackboneEvents.GCNAddonSetPickerView.AddonSelectionComplete,
      (view, callback) => {
        const $pickerElements = this.$('.addon-set-picker-view');
        const $next = GcnAddonHelper.findNextAddonPickerEl(view.$el, $pickerElements);
        if ($next && !$next.is(':hidden')) {
          GcnAddonHelper.scrollToContentEl($next, this.$stepBody, callback);
        }
      },
    );
    this.listenTo(
      this.addonSetPickerView,
      BackboneEvents.GCNAddonSetPickerView.AddonErrorMessageShown,
      (errMsg) => {
        this.trigger(BackboneEvents.GCNAddonSetPickerView.AddonErrorMessageShown, errMsg);
      },
    );

    // Track the modifier code options for the addon set.
    this.listenTo(
      this.addonSetPickerView,
      BackboneEvents.GCNAddonSetPickerView.AddonOptionCodeSelectionComplete,
      (optionCodeSelectionStructByAddonId) => {
        this._optionCodeSelectionStructByAddonId = optionCodeSelectionStructByAddonId;
      },
    );

    this.listenTo(
      this.addonSetPickerView,
      BackboneEvents.GCNAddonSetPickerView.AddonOptionSelectionComplete,
      (selectedPriceOptionByAddonId) => {
        this._selectedPriceOptionByAddonId = selectedPriceOptionByAddonId;
      },
    );

    return this;
  },
});
