import async from 'async';

import { ErrorCode, ErrorMessage, Log } from '@biteinc/common';
import { TimeHelper } from '@biteinc/core-react';
import { ClientCapability } from '@biteinc/enums';

import type { TimeoutConfig } from '~/types/request';

import type { BridgeCallback, BridgeResponse } from './gcn_bridge_interface';
import { sleep } from './utils/promises';

export type ApiError = {
  code: number;
  message?: string;
  debugMessage?: string;
};
interface ErrorCallback<T = any> {
  (err?: ApiError, data?: T): void;
}
export type Callback<T = any> = ErrorCallback<T>;

type Response = undefined | Record<string, any>;
// Copied from bull
type JobState = 'completed' | 'waiting' | 'active' | 'delayed' | 'failed' | 'paused';
type QueueJobStatus<T extends Response> =
  | {
      jobState: 'completed';
      result: T;
    }
  | {
      jobState: 'failed';
      err: any;
      lastError?: {
        message: string;
        code?: number;
      };
    }
  | {
      jobState: Exclude<JobState, 'completed' | 'failed'>;
    };

export enum ApiService {
  LogsApiV1 = 'logs-api-v1',
  LoyaltyApiV1 = 'loyalty-api-v1',
  LoyaltyApiV2 = 'loyalty-api-v2',
  Maitred = 'maitred',
  OrdersApiV1 = 'orders-api-v1',
  OrdersApiV2 = 'orders-api-v2',
  PaymentsApiV2 = 'payments-api-v2',
  RecommendationsApi = 'recommendations-api',
  RecommendationsApiMaitred = 'recommendations-api-maitred',
}

const MAX_RETRIABLE_REQUEST_COUNT = 3;

export class RequestData {
  public requestId?: string;

  public qs?: any;

  private timeout: number;

  private constructor(
    public readonly apiService: ApiService,
    public readonly method: string,
    public path: string,
    public readonly body?: object,
  ) {
    // default timeout is 25 seconds for flash because cell signal can be bad
    this.timeout = window.isFlash ? 25000 : 15000;
  }

  getTimeout(): number {
    return this.timeout;
  }

  setTimeout(timeout: number): void {
    if (timeout) {
      this.timeout = timeout;
    }
  }

  toJSON(): Record<string, any> {
    return {
      method: this.method,
      path: this.path,
      body: this.body,
      requestId: this.requestId,
      qs: this.qs,
      timeout: this.timeout,
      apiService: this.apiService,
    };
  }

  static newPostRequest(apiService: ApiService, path: string, body?: object): RequestData {
    return new RequestData(apiService, 'POST', path, body);
  }

  static newPutRequest(apiService: ApiService, path: string, body: object): RequestData {
    return new RequestData(apiService, 'PUT', path, body);
  }

  static newGetRequest(apiService: ApiService, path: string): RequestData {
    return new RequestData(apiService, 'GET', path);
  }

  // TODO: don't accept body for delete calls
  static newDeleteRequest(apiService: ApiService, path: string, body?: object): RequestData {
    return new RequestData(apiService, 'DELETE', path, body);
  }
}

class RequestStruct<T = any> {
  timer?: NodeJS.Timeout;

  callback?: Callback<T>;

  retryCount?: number;

  constructor(public readonly data: RequestData) {}

  destroy(): void {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = undefined;
    }
    this.callback = undefined;
  }
}

export interface MaitredRequestMaker {
  (requestData: RequestData, bridgeCallback: BridgeCallback): void;
}

export class GcnMaitredRequestManager {
  private requestStructs: RequestStruct[] = [];

  constructor(private requestMaker: MaitredRequestMaker) {}

  removeAllRequests(): void {
    const requestStructs = this.requestStructs;
    this.requestStructs = [];
    requestStructs.forEach((requestStruct: RequestStruct) => {
      this.removeRequest(requestStruct);
    });
  }

  makeRequest<T = any>(requestData: RequestData, callback?: Callback<T>): void {
    if (!requestData.path.startsWith('/api')) {
      requestData.path = `/api/orgs/${gcn.location.get('orgId')}/locations/${gcn.location.id}${
        requestData.path
      }`;
    }
    const qsString = $.param(requestData.qs);
    if (qsString) {
      requestData.path += `?${qsString}`;
    }
    requestData.requestId = '1';

    const requestStruct = new RequestStruct(requestData);
    if (callback) {
      requestStruct.callback = callback;
      this.addRequest(requestStruct);

      requestStruct.timer = setTimeout(() => {
        this.requestDidTimeout(requestStruct);
      }, requestStruct.data.getTimeout());
    }

    this.makeBridgeRequest(requestStruct);
  }

  async makeRequestAsync<T extends Response>(requestData: RequestData): Promise<T> {
    return new Promise((resolve, reject) => {
      this.makeRequest<T>(requestData, (err, data) => {
        if (err) {
          reject(err);
          return;
        }

        resolve(data as T);
      });
    });
  }

  makeQueuedRequest<T = any>(requestData: RequestData, callback: Callback<T>): void {
    const self = this;
    async.auto(
      {
        initialRequest(cb) {
          // @ts-expect-error async types aren't great here
          self.makeRequest(requestData, cb);
        },
        checkStatus: [
          'initialRequest',
          ({ initialRequest }, cb) => {
            // safely handle routes that conditionally return queuePath
            if (!initialRequest.queuePath) {
              cb(null, { result: initialRequest });
              return;
            }

            let attempts = 0;
            async.doUntil(
              (cb) => {
                // 0, 1, 2, 4, 8, 16, 16, 16...
                const delay = attempts === 0 ? 0 : 1000 * Math.min(16, Math.pow(2, attempts - 1));
                setTimeout(() => {
                  const jobCheckRequest = RequestData.newGetRequest(
                    requestData.apiService,
                    initialRequest.queuePath,
                  );
                  self.makeRequest(jobCheckRequest, (err, data) => {
                    // Only consider it a fatal error if the server tells us there's no job
                    if (err?.code === 404) {
                      // @ts-expect-error async types aren't great here
                      cb(err);
                      return;
                    }
                    cb(null, data);
                  });
                }, delay);
              },
              // @ts-expect-error async types aren't great here
              (response: any, cb: Function) => {
                attempts++;
                // response could be undefined for scenarios like network timeouts
                cb(null, ['completed', 'failed'].includes(response?.jobState));
              },
              cb,
            );
          },
        ],
      },
      (err, results) => {
        if (err || results?.checkStatus.err) {
          callback(err || results?.checkStatus.err);
        } else {
          callback(undefined, results?.checkStatus.result || {});
        }
      },
    );
  }

  async makeQueuedRequestAsync<T extends Response>(
    requestData: RequestData,
    timeoutConfig: TimeoutConfig = {
      timeout: 30 * TimeHelper.SECOND,
      startedAt: Date.now(),
      message: ErrorMessage.GENERIC,
      code: ErrorCode.NetworkRequestTimedOut,
    },
  ): Promise<T> {
    const initialRequest = await this.makeRequestAsync<T & { queuePath?: string }>(requestData);

    // safely handle routes that conditionally return queuePath
    if (!initialRequest.queuePath) {
      return initialRequest;
    }

    // Exponential backoff until 4 seconds: 0, 1, 2, 4, 4, ..
    // timeout configuration will limit the retry time to 30 seconds
    return this.waitForQueuedJob<T>(
      requestData.apiService,
      initialRequest.queuePath,
      timeoutConfig,
    );
  }

  async waitForQueuedJob<T extends Response>(
    apiService: ApiService,
    queuePath: string,
    timeoutConfig: TimeoutConfig,
    delay: number = 0,
  ): Promise<T> {
    const jobCheckResponse = await this.makeJobCheckRequest<T>(apiService, queuePath);

    const validJobCheckResponse = this.verifyJobCheckResponse<T>(
      apiService,
      jobCheckResponse,
      this.waitForQueuedJob.bind(this),
      timeoutConfig,
    );

    if (validJobCheckResponse) {
      return validJobCheckResponse;
    }

    const { timeout, startedAt, message, code } = timeoutConfig;

    if (Date.now() - startedAt > timeout) {
      throw { code, message };
    }

    await sleep(delay);

    // Wait a maximum of 4 seconds between one poll and the next
    return this.waitForQueuedJob(
      apiService,
      queuePath,
      timeoutConfig,
      Math.min(4000, delay * 2) || 1000,
    );
  }

  async waitForPaymentQueuedJob<T extends Response>(
    apiService: ApiService,
    queuePath: string,
    timeoutConfig: TimeoutConfig,
  ): Promise<T> {
    const jobCheckResponse = await this.makeJobCheckRequest<T>(apiService, queuePath);

    const validJobCheckResponse = this.verifyJobCheckResponse<T>(
      apiService,
      jobCheckResponse,
      this.waitForPaymentQueuedJob.bind(this),
      timeoutConfig,
    );

    if (validJobCheckResponse) {
      return validJobCheckResponse;
    }

    const { timeout, startedAt, message, code } = timeoutConfig;

    if (Date.now() - startedAt > timeout) {
      throw { code, message };
    }

    await sleep(200);
    return this.waitForPaymentQueuedJob(apiService, queuePath, timeoutConfig);
  }

  private async makeJobCheckRequest<T extends Response>(
    apiService: ApiService,
    queuePath: string,
  ): Promise<QueueJobStatus<T & { queuePath?: string | undefined }> | undefined> {
    const jobCheckRequest = RequestData.newGetRequest(apiService, queuePath);
    try {
      return await this.makeRequestAsync<
        QueueJobStatus<
          T & {
            queuePath?: string;
          }
        >
      >(jobCheckRequest);
    } catch (err) {
      if (err.code === ErrorCode.ApiJobNotFound) {
        throw err;
      }
      // fall through to retry
    }
    return undefined;
  }

  private verifyJobCheckResponse<T extends Response>(
    apiService: ApiService,
    jobCheckResponse:
      | QueueJobStatus<
          T & {
            queuePath?: string | undefined;
          }
        >
      | undefined,
    callBackWaitFunction: (
      apiService: ApiService,
      queuePath: string,
      timeoutConfig: TimeoutConfig,
      delay?: number,
    ) => Promise<T>,
    timeoutConfig: TimeoutConfig,
  ): Promise<T> | T | undefined {
    if (jobCheckResponse) {
      if ('failed' === jobCheckResponse.jobState) {
        // jobCheckResponse.err is actually the error message
        const err = new Error(jobCheckResponse.err);
        if (jobCheckResponse.lastError) {
          // throwing non-error objects is a bad practice
          // but we do it throughout gcn with callback first arguments
          throw jobCheckResponse.lastError;
        }
        // this might happen if the job failed but then failed to update itself
        throw err;
      }
      if ('completed' === jobCheckResponse.jobState) {
        if (jobCheckResponse.result?.queuePath) {
          return callBackWaitFunction(
            apiService,
            jobCheckResponse.result.queuePath,
            timeoutConfig,
            0,
          );
        }
        return jobCheckResponse.result;
      }
      // fall through to retry
    }

    return undefined;
  }

  private makeBridgeRequest(requestStruct: RequestStruct): void {
    const self = this;
    const bridgeCallback: BridgeCallback = (response: BridgeResponse) => {
      if (!self.hasRequest(requestStruct)) {
        Log.warn('got response for dead request', response);
        Log.warn('request was', requestStruct);
        return;
      }

      const callback = requestStruct.callback;
      if (response.success) {
        self.removeRequest(requestStruct);
        if (callback) {
          callback(undefined, response.data);
        }
      } else {
        const error = response.error;
        if (error.error && window.gcnCapability < ClientCapability.ProperErrorFormat) {
          // This is the format returned by the native clients; dumb
          error.message = error.error;
          delete error.error;
        }
        if (typeof error !== 'string' && !error.message) {
          error.message = ErrorMessage.GENERIC;
        }
        const retryableErrors = [
          ErrorCode.NetworkError,
          ErrorCode.MongoDBGenericError,
          ErrorCode.ExternalServiceNetworkError,
        ];
        if (
          retryableErrors.includes(error.code) &&
          (requestStruct.retryCount || 0) < MAX_RETRIABLE_REQUEST_COUNT
        ) {
          setTimeout(
            () => {
              if (self.hasRequest(requestStruct)) {
                requestStruct.retryCount = (requestStruct.retryCount || 0) + 1;
                self.makeBridgeRequest(requestStruct);
              }
            },
            // exponential backoff, starting 1 second after initial request,
            // then 2, and 4. 3 retries total.
            requestStruct.retryCount ? 1000 * requestStruct.retryCount ** 2 : 1000,
          );
        } else {
          self.removeRequest(requestStruct);
          if (callback) {
            callback(error);
          }
        }
      }
    };

    // Explicitly log the url so we can see it in Sentry
    const { method, path } = requestStruct.data;
    Log.info(`will make request to ${method} ${path} with data:`, requestStruct.data);

    this.requestMaker(requestStruct.data, bridgeCallback);
  }

  private addRequest(request: RequestStruct): void {
    this.requestStructs.push(request);
  }

  private hasRequest(request: RequestStruct): boolean {
    return this.requestStructs.indexOf(request) >= 0;
  }

  private requestDidTimeout(requestStruct: RequestStruct): void {
    Log.warn('request timed out', requestStruct);
    const callback = requestStruct.callback;
    this.removeRequest(requestStruct);
    if (callback) {
      callback({
        code: ErrorCode.NetworkRequestTimedOut,
        message: ErrorMessage.GENERIC,
      });
    }
  }

  private removeRequest(requestStruct: RequestStruct): void {
    requestStruct.destroy();
    const index = this.requestStructs.indexOf(requestStruct);
    if (index >= 0) {
      this.requestStructs.splice(index, 1);
    }
  }
}
