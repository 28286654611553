import type { StateCreator } from 'zustand';

import type { Order, Transaction, VehicleDescription } from '@biteinc/core-react';

import { GCNTransaction } from '~/app/js/models/gcn_transaction';

import GcnOpeningSequenceManager from '../app/js/gcn_opening_sequence_manager';
import Analytics from '../app/js/utils/analytics';
import type { GcnTransaction, OrderPayload } from '../types';
import type { CartStore } from './cart.store';

interface CheckoutFieldState {
  value: string;
  isValid: boolean;
}

type CustomerIdentifierData = Partial<VehicleDescription> & {
  name?: string;
  email?: string;
  phone?: string;
};

export type CheckoutState = Readonly<{
  order: OrderPayload;
  transactions: GcnTransaction[];
  orderUpdatedAt: number;
  ecommPayment: CheckoutFieldState;
  showErrorState: boolean;
  orderNumber?: string;
  guestInfo: CustomerIdentifierData;
}>;

export type CheckoutActions = {
  onOrderUpdated: (order: OrderPayload) => void;
  onUpdateDeliveryInstructions: (deliveryInstructions: string) => void;
  onStoredValueUpdated: () => void;
  onCustomerIdentifiersUpdated: (guestInfo: CustomerIdentifierData) => void;
  onEcommPaymentUpdated: (fieldState: CheckoutFieldState) => void;
  onInvalidSubmitOrderAttempt: () => void;
  onOrderClosed: (transactions: Transaction[]) => void;
  onOrderCloseError: () => void;
  onStart: (order: Order) => void;
};

export type CheckoutStore = {
  checkout: CheckoutState & CheckoutActions;
} & Partial<CartStore>;

const checkoutStoreInitialState: CheckoutState = {
  order: {
    _id: undefined,
    wasValidated: undefined,
    orderedItems: [],
    subTotal: 0,
  },
  orderUpdatedAt: 0,
  ecommPayment: {
    value: '',
    isValid: false,
  },
  transactions: [],

  guestInfo: {},

  showErrorState: false,
};

export const initCheckoutStore: StateCreator<CheckoutStore> = (set, get) => ({
  checkout: {
    ...checkoutStoreInitialState,
    onOrderUpdated: (orderJson: Order) => {
      gcn.orderManager.setOrderFromJSON(orderJson);
      set((state) => ({
        checkout: {
          ...state.checkout,
          order: gcn.orderManager.getOrderForStore(),
          orderUpdatedAt: Date.now(),
        },
      }));
    },
    onUpdateDeliveryInstructions: (deliveryInstructions: string) => {
      gcn.orderManager.setDeliveryInstructions(deliveryInstructions);
      set((state) => ({
        checkout: {
          ...state.checkout,
          order: gcn.orderManager.getOrderForStore(),
          orderUpdatedAt: Date.now(),
        },
      }));
    },
    onStoredValueUpdated: () => {
      set((state) => ({
        checkout: {
          ...state.checkout,
          transactions: gcn.orderManager.getTransactions(),
        },
      }));
    },
    onCustomerIdentifiersUpdated: (guestInfo: CustomerIdentifierData) => {
      set((state) => ({
        checkout: {
          ...state.checkout,
          guestInfo,
        },
      }));
    },
    onEcommPaymentUpdated: (fieldState: CheckoutFieldState) => {
      set((state) => ({
        checkout: {
          ...state.checkout,
          ecommPayment: fieldState,
        },
      }));
    },
    onInvalidSubmitOrderAttempt: () => {
      set((state) => ({
        checkout: {
          ...state.checkout,
          showErrorState: true,
        },
      }));
    },
    onOrderClosed: (transactions: Transaction[]) => {
      GcnOpeningSequenceManager.clearStoredFulfillmentData();
      set((state) => ({
        checkout: {
          ...state.checkout,
          order: gcn.orderManager.getOrderForStore(),
          orderUpdatedAt: Date.now(),
          loyaltyTransaction: gcn.loyaltyManager.getLoyaltyTransaction(),
          transactions: transactions.map((transactionJson) => {
            return new GCNTransaction(transactionJson);
          }),
        },
      }));
    },
    onOrderCloseError: () => {
      set((state) => ({
        checkout: {
          ...state.checkout,
          order: gcn.orderManager.getOrderForStore(),
          orderUpdatedAt: Date.now(),
          loyaltyTransaction: gcn.loyaltyManager.getLoyaltyTransaction(),
          transactions: gcn.orderManager.getTransactions(),
        },
      }));
    },
    onStart: () => {
      const orderSize = gcn.orderManager.getOrderSize();
      Analytics.trackEvent({
        eventName: Analytics.EventName.CheckoutStart,
        eventData: {
          cartSize: orderSize,
          cartSubTotal: gcn.orderManager.getSubTotal(),
        },
      });
      gcn.orderManager.eventRepo.trackCheckoutStart(orderSize);
      set((state) => ({
        checkout: {
          ...state.checkout,
          order: get().cart!,
          orderUpdatedAt: Date.now(),
        },
      }));
    },
  },
});
