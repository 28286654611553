import * as Sentry from '@sentry/browser';
import async from 'async';
import Backbone from 'backbone';
import _ from 'underscore';

import { ErrorCode, I9nSchemaBySystem, Log, Strings } from '@biteinc/common';
import { ServeUpAppHelper } from '@biteinc/core-react';
import {
  IntegrationSystem,
  LoyaltyAuthEntryMethod,
  LoyaltyAuthMethod,
  PunchhPrinterBarcodeType,
  SimpleLoyaltySignupMode,
} from '@biteinc/enums';

import { BackboneEvents } from '~/app/js/backbone-events';
import { localizeStr } from '~/app/js/localization/localization';

import { useStore } from '../../stores';
import GcnHtml from './gcn_html';
import GcnKiosk from './models/gcn_kiosk';
import { GCNReward } from './models/gcn_reward';
import { GCNTransaction } from './models/gcn_transaction';
import Analytics from './utils/analytics';
import Errors from './utils/errors';

// Helper class that manages all business logic for loyalty/rewards integrations.
export const GCNLoyaltyManager = Backbone.Model.extend({
  clear(clearAll) {
    this._loyaltyAuthData = null;
    this._compCardAuthData = null;
    this._authFriendlyName = '';
    this._rewards = [];
    this._preFetchedRewards = [];
    this._preSelectedRewards = [];
    this._redemptionCodeFetched = false;
    this._loyaltyLoginFromStart = false;
    this._statusDescription = null;
    this._pointsBalance = null;
    if (clearAll) {
      this._smsInvitationSent = false;
      useStore.getState().loyalty.reset();
    }
    this._estimatedPointsEarned = -1;
    useStore.getState().loyalty.fetch();
  },

  supportsAuthedRewards() {
    if (gcn.screenReaderIsActive) {
      return false;
    }

    const loyaltyI9n = gcn.location.getLoyaltyIntegration();
    if (loyaltyI9n) {
      const schema = I9nSchemaBySystem[loyaltyI9n.system];
      // If schema specifies that this can be enabled, it's disabled by default.
      if (schema.fields.enableAuthedLoyalty) {
        return loyaltyI9n.enableAuthedLoyalty;
      }
      // Otherwise, it's always enabled.
      return true;
    }
    if (gcn.location.showRewardsDemo()) {
      return true;
    }
    return false;
  },

  areCouponsAndLoyaltyEarningCompatible() {
    const allPosCompatible = gcn.location.getPosPartialI9ns().every((partialPosI9n) => {
      const i9nSchema = I9nSchemaBySystem[partialPosI9n.system];
      return !!i9nSchema.couponsAndLoyaltyEarningCompatible;
    });

    const loyaltyI9nSchema = gcn.location.getLoyaltyI9nSchema();
    const loyaltyIsCompatible = !!loyaltyI9nSchema?.couponsAndLoyaltyEarningCompatible;
    return allPosCompatible && loyaltyIsCompatible;
  },

  getFallbackLoyaltyAuthMethodToBarcode() {
    const authMethods = gcn.location.getLoyaltyIntegration().authMethods;

    if (!authMethods?.length) {
      return null;
    }

    if (
      authMethods.length === 2 &&
      authMethods[0] === LoyaltyAuthMethod.Barcode &&
      [LoyaltyAuthMethod.CardNumber, LoyaltyAuthMethod.PhoneNumber].includes(authMethods[1])
    ) {
      return authMethods[1];
    }
    return null;
  },

  shouldEnableBarcodeAsFallbackToPhoneNumber() {
    const authMethods = gcn.location.getLoyaltyIntegration().authMethods;

    if (!authMethods?.length) {
      return false;
    }

    return (
      GcnKiosk.isScannerConnected() &&
      authMethods.length === 2 &&
      authMethods[0] === LoyaltyAuthMethod.PhoneNumber &&
      authMethods[1] === LoyaltyAuthMethod.Barcode
    );
  },

  _authMethodMatches(expectedAuthType) {
    const loyaltyI9n = gcn.location.getLoyaltyIntegration();
    return loyaltyI9n && loyaltyI9n.authMethods[0] === expectedAuthType;
  },

  getAuthMethod() {
    const loyaltyI9n = gcn.location.getLoyaltyIntegration();
    return loyaltyI9n && loyaltyI9n.authMethods[0];
  },

  canShowLoyaltyRedemptionButton() {
    return (
      this.hasLoyaltyAuthData() &&
      !this._loyaltyAuthData.redemptionCode &&
      this.isPunchhDirect() &&
      gcn.location.getLoyaltyIntegration().allowRedemptionCodeWithReward
    );
  },

  hasRedemptionCodeAndAuthedGuest() {
    return (
      this.hasLoyaltyAuthData() &&
      this._loyaltyAuthData.redemptionCode &&
      this.isPunchhDirect() &&
      gcn.location.getLoyaltyIntegration().allowRedemptionCodeWithReward
    );
  },

  shouldPrintBarcode() {
    if (!gcn.location.getLoyaltyIntegration()) {
      return false;
    }
    if (!this.isPunchhDirect() && !this.isPunchhOlo()) {
      return false;
    }
    return (
      gcn.location.getLoyaltyIntegration().printerBarcodeType !== PunchhPrinterBarcodeType.None
    );
  },

  isPunchhOlo() {
    return gcn.location.getLoyaltyIntegration()?.system === IntegrationSystem.PunchhOlo;
  },

  isPunchhDirect() {
    return gcn.location.getLoyaltyIntegration()?.system === IntegrationSystem.Punchh;
  },

  isPaytronixDirect() {
    return gcn.location.getLoyaltyIntegration()?.system === IntegrationSystem.PaytronixLoyalty;
  },

  isChoptLoyalty() {
    return gcn.location.getLoyaltyIntegration()?.system === IntegrationSystem.ChoptLoyalty;
  },

  isComo() {
    return gcn.location.getLoyaltyIntegration()?.system === IntegrationSystem.Como;
  },

  isLevelUp() {
    return gcn.location.getLoyaltyIntegration()?.system === IntegrationSystem.LevelUp;
  },

  loyaltyUsesBarcode() {
    return this._authMethodMatches(LoyaltyAuthMethod.Barcode) || gcn.location.showRewardsDemo();
  },

  loyaltyProvidesPayment() {
    return this.isLevelUp() || this.isChoptLoyalty();
  },

  loyaltyUsesEmailAuth() {
    return (
      this._authMethodMatches(LoyaltyAuthMethod.EmailPhoneNumber) ||
      this._authMethodMatches(LoyaltyAuthMethod.EmailPassword)
    );
  },

  loyaltyUsesEmailOnlyAuth() {
    return this._authMethodMatches(LoyaltyAuthMethod.Email);
  },

  loyaltyUsesPasswordAuth() {
    return (
      this._authMethodMatches(LoyaltyAuthMethod.EmailPassword) ||
      this._authMethodMatches(LoyaltyAuthMethod.UsernamePassword)
    );
  },

  loyaltyUsesEmailPasswordAuth() {
    return this._authMethodMatches(LoyaltyAuthMethod.EmailPassword);
  },

  loyaltyUsesCardNumber() {
    return this._authMethodMatches(LoyaltyAuthMethod.CardNumber);
  },

  loyaltyUsesEmailPhoneAuth() {
    return this._authMethodMatches(LoyaltyAuthMethod.EmailPhoneNumber);
  },

  loyaltyUsesPhoneAuth() {
    return this._authMethodMatches(LoyaltyAuthMethod.PhoneNumber);
  },

  loyaltyUsesTokenAuth() {
    return (
      this.isChoptLoyalty() ||
      this.isComo() ||
      this.isLevelUp() ||
      this.isPunchhDirect() ||
      this.isPaytronixDirect()
    );
  },

  getCompCardAuthData() {
    return this._compCardAuthData;
  },

  hasLoyaltyAuthData() {
    return !!_.size(this._loyaltyAuthData);
  },

  hasRedemptionCode() {
    return !!this._loyaltyAuthData?.redemptionCode;
  },

  getLoyaltyAuthData() {
    return this._loyaltyAuthData;
  },

  getLoyaltyAuthDataForRequest() {
    if (this._loyaltyAuthData.redemptionCode && !this._redemptionCodeFetched) {
      return {
        redemptionCode: this._loyaltyAuthData.redemptionCode,
        redemptionCodeEntryMethod: this._loyaltyAuthData.redemptionCodeEntryMethod,
      };
    }
    return {
      ...this._loyaltyAuthData,
      redemptionCode: undefined,
      redemptionCodeEntryMethod: undefined,
    };
  },

  hasRewardsAndRedemptionCode() {
    const hasRedemptionCodeReward = this._rewards.some((reward) => {
      return reward.attributes.i9nType === 'redemption_code';
    });
    const appliedRewards = gcn.orderManager.getAppliedRewards();
    return (
      this._loyaltyAuthData.redemptionCode && hasRedemptionCodeReward && appliedRewards.length >= 2
    );
  },

  setCustomerAuthData(customer) {
    const authToken = customer.authProviderData?.authToken;
    if (authToken) {
      const authEntryMethod = customer.authProviderData?.authEntryMethod;
      this.restoreLoyaltyAuthData({
        authToken,
        ...(authEntryMethod && {
          authEntryMethod,
        }),
      });
      return;
    }
    this._customerLoyaltyAuthData = {};
    switch (true) {
      case this.loyaltyUsesEmailPhoneAuth():
        this._customerLoyaltyAuthData.identifier = customer.email; // identifier;
        this._customerLoyaltyAuthData.authValue = customer.phoneNumber; // authValue;
        this._customerLoyaltyAuthData.authEntryMethod =
          LoyaltyAuthEntryMethod.EmailAndPhoneNumberProvidedExternally;
        break;
      case this.loyaltyUsesPhoneAuth():
        this._customerLoyaltyAuthData.identifier = customer.phoneNumber; // identifier;
        this._customerLoyaltyAuthData.authEntryMethod =
          LoyaltyAuthEntryMethod.PhoneNumberProvidedExternally;
        break;
      case this.loyaltyUsesEmailPasswordAuth():
        // Do nothing requires manual auth
        break;
      default:
        // Do nothing requires username
        break;
    }

    useStore.getState().loyalty.fetch();

    if (
      gcn.location.loyaltySupportsShowingQrCode() &&
      this.hasCustomerAuthData() &&
      ServeUpAppHelper.getServeUpAppPlatform()
    ) {
      const customerAuthData = this.getCustomerAuthData();

      this.submitLoyaltyAuth(
        customerAuthData.identifier,
        null,
        customerAuthData.authEntryMethod,
        (err) => {
          if (err) {
            if (err.code === ErrorCode.LoyaltyNoMatchingUser) {
              this.clearLoyaltyAuthData();
              useStore.getState().loyalty.fetch();
            } else {
              Sentry.captureException(err);
            }
            return;
          }

          this.fetchRewardsNoOrder(() => {});
        },
      );
    }
  },

  hasCustomerAuthData() {
    return !!_.size(this._customerLoyaltyAuthData);
  },

  getCustomerAuthData() {
    return this._customerLoyaltyAuthData;
  },

  clearCustomerAuthData() {
    this._customerLoyaltyAuthData = null;
    this.clearLoyaltyAuthData();
  },

  clearLoyaltyAuthData() {
    this._loyaltyAuthData = null;
    this._compCardAuthData = null;
    useStore.getState().loyalty.signOutGuest();
  },

  restoreLoyaltyAuthData(loyaltyAuthData) {
    if (loyaltyAuthData) {
      this._loyaltyAuthData = loyaltyAuthData;
    }
  },

  getAuthedGuestFriendlyName() {
    return this._authFriendlyName;
  },

  getEstimatedPointsEarned() {
    return this._estimatedPointsEarned;
  },

  getRewardsProgramImageUrl() {
    const i9n = gcn.location.getLoyaltyIntegration();
    return i9n && i9n.rewardsProgramLogo ? i9n.rewardsProgramLogo.url : null;
  },

  _applyRewardDidFail(err, reward) {
    const badApplyRewardErrorCodes = [
      ErrorCode.LoyaltyRewardCannotBeApplied,
      ErrorCode.LoyaltyBadResponseError,
    ];
    if (err && err.code === ErrorCode.CannotModifyLoyaltyAfterPayment) {
      gcn.menuView.showSimpleAlert(localizeStr(Strings.LOYALTY_CANNOT_MODIFY_REWARD));
    } else if (err && _.contains(badApplyRewardErrorCodes, err.code) && err.message) {
      gcn.menuView.showSimpleAlert(err.message);
    } else if (reward) {
      gcn.menuView.showSimpleAlert(
        localizeStr(Strings.LOYALTY_APPLY_REWARD_FAILED_SPECIFIC, [reward.displayName(true)]),
      );
    } else {
      gcn.menuView.showSimpleAlert(localizeStr(Strings.LOYALTY_APPLY_REWARD_FAILED));
    }
  },

  applyReward(reward, showError = false) {
    gcn.menuView.showSpinner(localizeStr(Strings.LOYALTY_APPLYING_REWARD));
    const orderPayload = gcn.orderManager.getOrderPayload();
    gcn.maitred.applyReward(reward, orderPayload, (err, data) => {
      gcn.menuView.dismissSpinner();
      if (err) {
        this.trigger(BackboneEvents.GCNLoyaltyManager.DidFailApplyReward, err);
        if (showError) {
          this._applyRewardDidFail(err, reward);
        }
        return;
      }
      gcn.orderManager.setOrderFromJSON(data.order);

      if (data.transaction) {
        this._loyaltyTransaction = new GCNTransaction(data.transaction);
        this.listenToOnce(
          gcn.orderManager,
          BackboneEvents.GCNOrderManager.AboutToClearCheckout,
          () => {
            this._refundLoyaltyTransaction();
          },
        );
      }

      this.trigger(BackboneEvents.GCNLoyaltyManager.DidApplyReward, data);

      useStore.getState().loyalty.onLoyaltyUpdated(data.order);
    });
  },

  applyRewardAsync(reward, cb) {
    gcn.menuView.showSpinner(localizeStr(Strings.LOYALTY_APPLYING_REWARD));
    const orderPayload = gcn.orderManager.getOrderPayload();
    gcn.maitred.applyReward(reward, orderPayload, (err, data) => {
      gcn.menuView.dismissSpinner();
      if (err) {
        this.trigger(BackboneEvents.GCNLoyaltyManager.DidFailApplyReward, err);
        this._applyRewardDidFail(err, reward);
        cb(err);
        return;
      }

      gcn.orderManager.setOrderFromJSON(data.order);

      if (data.transaction) {
        this._loyaltyTransaction = new GCNTransaction(data.transaction);
        this.listenToOnce(
          gcn.orderManager,
          BackboneEvents.GCNOrderManager.AboutToClearCheckout,
          () => {
            this._refundLoyaltyTransaction();
          },
        );
      }

      this.trigger(BackboneEvents.GCNLoyaltyManager.DidApplyReward, data);
      useStore.getState().loyalty.fetch();

      cb(undefined, data);
    });
  },

  removeReward(reward, skipSpinner) {
    if (!skipSpinner) {
      gcn.menuView.showSpinner(localizeStr(Strings.LOYALTY_REMOVING_REWARD));
    }

    const rewardI9nId = reward.get('rewardI9nId');
    const orderPayload = gcn.orderManager.getOrderPayload();
    gcn.maitred.removeReward(rewardI9nId, this._loyaltyTransaction, orderPayload, (err, data) => {
      if (!skipSpinner) {
        gcn.menuView.dismissSpinner();
      }
      if (err) {
        this.trigger(BackboneEvents.GCNLoyaltyManager.DidFailRemoveReward, data);
        if (err.code === ErrorCode.CannotModifyLoyaltyAfterPayment) {
          gcn.menuView.showSimpleAlert(localizeStr(Strings.LOYALTY_CANNOT_MODIFY_REWARD));
        } else if (err.code === ErrorCode.LoyaltyRewardCannotBeRemoved) {
          gcn.menuView.showSimpleAlert(localizeStr(Strings.LOYALTY_CANNOT_REMOVE_REWARD));
        } else {
          gcn.menuView.showSimpleAlert(localizeStr(Strings.LOYALTY_APPLY_REWARD_FAILED));
        }
        return;
      }
      this.removeLoyaltyTransaction();
      gcn.orderManager.setOrderFromJSON(data.order);
      this.trigger(BackboneEvents.GCNLoyaltyManager.DidRemoveReward, data);

      useStore.getState().loyalty.onLoyaltyUpdated(data.order);
    });
  },

  clearLoyaltyAuthDataRewardsAndPreSelectedRewards() {
    this.clearLoyaltyAuthData();
    this._rewards = [];
    this._preFetchedRewards = [];
    this._preSelectedRewards = [];
    this._authFriendlyName = '';
    this._statusDescription = null;
    this._pointsBalance = null;
    useStore.getState().loyalty.signOutGuest();
  },

  exitCheckoutClearRewards() {
    this._rewards = [];
    useStore.getState().loyalty.onLoyaltyBackedOut();
  },

  hadLoyaltyGuestNoOrder() {
    return this._loyaltyLoginFromStart;
  },

  preSelectReward(reward) {
    if (!this._preSelectedRewards) {
      this._preSelectedRewards = [];
    }
    this._preSelectedRewards.push(reward);

    useStore.getState().loyalty.fetch();
  },

  getPreSelectedRewards() {
    return this._preSelectedRewards || [];
  },

  removePreSelectedReward(reward) {
    this._preSelectedRewards = this._preSelectedRewards?.filter((preSelectedReward) => {
      return preSelectedReward.appliedRewardMapKey() !== reward.appliedRewardMapKey();
    });

    useStore.getState().loyalty.fetch();
  },

  fetchRewardsNoOrder(callback) {
    gcn.maitred.loyaltyAvailableRewardsRequestPreOrder((err, data) => {
      if (err) {
        callback(err);
        this.clearLoyaltyAuthData();
        useStore.getState().loyalty.fetch();
        return;
      }
      this._loyaltyLoginFromStart = true;
      this._preFetchedRewards = _.map(data.rewards, (rewardData) => {
        return new GCNReward(rewardData);
      });
      this._statusDescription = data.statusDescription;
      this._pointsBalance = data.pointsBalance;
      this._authFriendlyName = data.guestName;
      this.trigger(BackboneEvents.GCNLoyaltyManager.DidFetchRewardsNoOrder);
      this.trigger(BackboneEvents.GCNLoyaltyManager.DidFetchRewards);
      useStore.getState().loyalty.onRewardsFetched();
      callback();
    });
  },

  clearLoyaltyAuthDataRewardsAndPreAppliedRewards() {
    this.clearLoyaltyAuthData();
    this._rewards = [];
    this._authFriendlyName = '';
    this._statusDescription = null;
    this._pointsBalance = null;
    useStore.getState().loyalty.signOutGuest();
  },

  // Ensure that preselected rewards are available after fetching
  // rewards WITH order at checkout
  /**
   *
   * @returns { rewards: GCNReward[]; unapplicableRewards: GCNReward[]; }
   */
  getFilteredPreSelectedRewards() {
    if (!this._preSelectedRewards) {
      return { rewards: [], unapplicableRewards: [] };
    }

    const rewards = [];
    const unapplicableRewards = [];

    this._preSelectedRewards.forEach((preSelectedReward) => {
      const verifiedApplicableReward = this._rewards.find((reward) => {
        return preSelectedReward.appliedRewardMapKey() === reward.appliedRewardMapKey();
      });

      const hasBeenApplied = gcn.orderManager.getAppliedRewards().some((appliedReward) => {
        return appliedReward.appliedRewardMapKey() === preSelectedReward.appliedRewardMapKey();
      });

      if (hasBeenApplied) {
        return;
      }
      if (verifiedApplicableReward) {
        rewards.push(verifiedApplicableReward);
      } else {
        unapplicableRewards.push(preSelectedReward);
      }
    });

    this._preSelectedRewards = rewards;
    useStore.getState().loyalty.fetch();

    return { rewards, unapplicableRewards };
  },

  getPrefetchedRewards() {
    return this._preFetchedRewards || [];
  },

  // If we are restoring from a crash, we won't have any rewards but we also
  // won't need them since we are going straight to the payment screen.
  getRewards() {
    return this._rewards || [];
  },

  getStatus() {
    if (this._pointsBalance) {
      return localizeStr(Strings.REWARDS_BALANCE_POINT_LABEL, [this._pointsBalance]);
    }
    return this._statusDescription;
  },

  canAutoApplyReward() {
    if (
      (this.isPunchhDirect() || this.isComo()) &&
      this._rewards.length >= 1 &&
      this._rewards.find((reward) => reward.get('canAutoApply'))
    ) {
      // Auto-apply if a single redemption code reward. The server should have already confirmed
      // that it works.
      return true;
    }
    return (
      gcn.location.getLoyaltyI9nSchema()?.autoApplyFirstAvailableReward && this._rewards.length
    );
  },

  fetchRewards(callback) {
    gcn.menuView.showSpinner(localizeStr(Strings.LOYALTY_GETTING_REWARDS));
    gcn.maitred.getRewards(gcn.orderManager.getOrderPayload(), (err, data) => {
      gcn.menuView.dismissSpinner();
      if (err) {
        this.trigger(BackboneEvents.GCNLoyaltyManager.DidFailFetchRewards, err);
        // Loyalty v2 needs a way of handling loyalty errors
        if (callback) {
          callback(err);
        }
        useStore.getState().loyalty.fetch();
        return;
      }

      if (data.earnedPointEstimate) {
        this._estimatedPointsEarned = data.earnedPointEstimate;
      }
      if (this.hasRedemptionCodeAndAuthedGuest()) {
        if (this._loyaltyAuthData?.redemptionCode) {
          this._redemptionCodeFetched = true;
        }

        this._rewards = [
          ...data.rewards.map((rewardData) => {
            return new GCNReward(rewardData);
          }),
          ...(this._rewards || []),
        ].sort((a, b) => {
          // float auto-apply rewards to the top
          if (a.get('canAutoApply') && !b.get('canAutoApply')) {
            return -1;
          }
          if (!a.get('canAutoApply') && b.get('canAutoApply')) {
            return 1;
          }
          return 0;
        });
      } else {
        this._rewards = _.map(data.rewards, (rewardData) => {
          return new GCNReward(rewardData);
        });
      }

      // We need to keep the customerId which comes back in the response
      if (this.isChoptLoyalty()) {
        this._loyaltyAuthData = {
          ...this._loyaltyAuthData,
          ...data.authData,
        };
      }

      // Update pre-fetched and pre-selected rewards with the latest data from the server
      this._preFetchedRewards = this._preFetchedRewards?.map((preFetchedReward) => {
        const reward = this._rewards.find((reward) => {
          return preFetchedReward.appliedRewardMapKey() === reward.appliedRewardMapKey();
        });

        if (!reward) {
          return preFetchedReward;
        }

        return new GCNReward({
          ...preFetchedReward.attributes,
          ...reward.attributes,
        });
      });

      this._pointsBalance = data.pointsBalance;
      this._statusDescription = data.statusDescription;
      this._authFriendlyName = data.guestName;
      if (!gcn.location.useOrdersApiV2()) {
        gcn.orderManager.setOrderFromJSON(data.order);
      }

      if (!gcn.orderManager.getGuestEmail() && data.guestEmail) {
        gcn.orderManager.setGuestEmail(data.guestEmail);
      }

      this.trigger(BackboneEvents.GCNLoyaltyManager.DidFetchRewards);
      useStore.getState().loyalty.onRewardsFetched();

      const appliedRewards = gcn.orderManager.getAppliedRewards();
      const appliedRewardByMapKey = appliedRewards.reduce((rewardById, reward) => {
        rewardById[reward.appliedRewardMapKey()] = reward;
        return rewardById;
      }, {});
      if (!this.canAutoApplyReward()) {
        if (callback) {
          callback();
        }
        return;
      }

      async.waterfall([
        ...this._rewards
          .filter((reward) => {
            return (
              reward.get('canAutoApply') && !appliedRewardByMapKey[reward.appliedRewardMapKey()]
            );
          })
          .map((reward) => {
            return (cb) => {
              this.applyRewardAsync(reward, (_err) => {
                if (_err) {
                  // fail silently, in order to attempt applying remaining rewards
                  // error message will be shown to guest from applyRewardAsync
                  this.removePreSelectedReward(reward);
                  if (callback) {
                    callback();
                  }
                }
                cb();
              });
            };
          }),
        ...(callback ? [callback] : []),
      ]);
    });
  },

  // keep up to date with punchh_client.ts
  handlePunchhLoyaltyData(loyaltyData, loyaltyDataEntryMethod) {
    const tokenLength = loyaltyData.length ?? 0;

    if (tokenLength >= 10) {
      this.submitLoyaltyTokenData(loyaltyData, loyaltyDataEntryMethod);
    } else {
      this.submitLoyaltyRedemptionData(loyaltyData, loyaltyDataEntryMethod);
    }
  },

  // TODO: Generalize this to all loyalty clients and clean up token/auth objects.
  // `tokenData` can be either a barcode or a phone number.
  submitLoyaltyTokenData(tokenData, tokenDataEntryMethod, cb) {
    this._loyaltyAuthData = {
      tokenData,
      tokenDataEntryMethod,
    };
    const loyaltyI9n = gcn.location.getLoyaltyIntegration();

    if (cb) {
      cb();
    }

    if (
      [IntegrationSystem.PeetsPaytronixOlo, IntegrationSystem.ParBrinkLoyalty].includes(
        loyaltyI9n.system,
      )
    ) {
      gcn.menuView.showSpinner(localizeStr(Strings.LOOKING_UP_ACCOUNT));
      this.submitLoyaltyAuth(
        this._loyaltyAuthData.tokenData,
        null,
        this._loyaltyAuthData.tokenDataEntryMethod,
        () => {
          gcn.menuView.dismissSpinner();
        },
      );
    } else {
      this.trigger(BackboneEvents.GCNLoyaltyManager.DidUpdateAuthGuest, this);
      useStore.getState().loyalty.fetch();
    }
  },

  extendLoyaltyAuthWithTokenData(tokenData, tokenDataEntryMethod, cb) {
    this._loyaltyAuthData = {
      ...(this._loyaltyAuthData || {}),
      tokenData,
      tokenDataEntryMethod,
    };

    if (cb) {
      cb();
    }

    this.trigger(BackboneEvents.GCNLoyaltyManager.DidUpdateAuthGuest, this);
    useStore.getState().loyalty.fetch();
  },

  submitLoyaltyRedemptionData(redemptionCode, redemptionCodeEntryMethod) {
    this._loyaltyAuthData = {
      ...(this._loyaltyAuthData || {}),
      redemptionCode,
      redemptionCodeEntryMethod,
    };

    this.trigger(BackboneEvents.GCNLoyaltyManager.DidUpdateAuthGuest, this);
  },

  // Package up the auth details and make the back end request.
  submitLoyaltyAuth(identifier, authValue, authEntryMethod, callback) {
    const self = this;

    const authCredentials = {
      authEntryMethod,
    };

    if (authEntryMethod) {
      switch (authEntryMethod) {
        case LoyaltyAuthEntryMethod.EmailAndPhoneNumberProvidedExternally:
        case LoyaltyAuthEntryMethod.EmailAndPhoneNumberManuallyEntered:
          authCredentials.email = identifier;
          authCredentials.phone = authValue;
          break;
        case LoyaltyAuthEntryMethod.EmailAndPasswordManuallyEntered:
          authCredentials.email = identifier;
          authCredentials.password = authValue;
          break;
        case LoyaltyAuthEntryMethod.PhoneNumberProvidedExternally:
        case LoyaltyAuthEntryMethod.PhoneNumberManuallyEntered:
          authCredentials.phone = identifier;
          break;
        case LoyaltyAuthEntryMethod.EmailManuallyEntered:
          authCredentials.email = identifier;
          break;
        case LoyaltyAuthEntryMethod.UsernameAndPasswordManuallyEntered:
          authCredentials.username = identifier;
          authCredentials.password = authValue;
          break;
        case LoyaltyAuthEntryMethod.CardNumberManuallyEntered:
        case LoyaltyAuthEntryMethod.BarcodeScanned:
          authCredentials.cardNumber = identifier;
          break;
        default:
          Sentry.captureException('Invalid auth entry method', {
            extra: { authEntryMethod },
          });
          break;
      }
    } else {
      switch (true) {
        case this.loyaltyUsesEmailOnlyAuth(): {
          authCredentials.email = identifier;
          break;
        }
        case this.loyaltyUsesEmailPasswordAuth(): {
          authCredentials.email = identifier;
          authCredentials.password = authValue;
          break;
        }
        case this.loyaltyUsesEmailPhoneAuth(): {
          authCredentials.email = identifier;
          authCredentials.phone = authValue;
          break;
        }
        case this.loyaltyUsesBarcode():
          authCredentials.cardNumber = identifier;
          break;
        case this.loyaltyUsesPhoneAuth(): {
          authCredentials.phone = identifier;
          break;
        }
        default: {
          authCredentials.username = identifier;
          authCredentials.password = authValue;
        }
      }
    }

    if (gcn.loyaltyManager.supportsAuthedRewards()) {
      Analytics.track(Analytics.EventName.LoyaltyAuthStart);
      gcn.maitred.authLoyalty(authCredentials, (err, result) => {
        if (err) {
          const msg = Errors.stringFromErrorCode(err.code, Strings.LOYALTY_AUTH_GENERIC_ERROR);
          Analytics.trackEvent({
            eventName: Analytics.EventName.LoyaltyAuthError,
            eventData: {
              message: msg,
            },
          });
          self.trigger(BackboneEvents.GCNLoyaltyManager.DidFailAuth, msg);
          callback(err);
          return;
        }
        if (result) {
          self._loyaltyAuthData = result.loyaltyAuthData;
          self._authFriendlyName = identifier;
          Analytics.track(Analytics.EventName.LoyaltyAuthSuccess);
          self.trigger(BackboneEvents.GCNLoyaltyManager.DidUpdateAuthGuest, self);
          useStore.getState().loyalty.fetch();
        }
        callback();
      });
    } else {
      Log.error('No Loyalty');
    }
  },

  getSmsInvitationWasSent() {
    return !!this._smsInvitationSent;
  },

  smsInvitationWasSent() {
    this._smsInvitationSent = true;
  },

  getLoyaltyTransaction() {
    return this._loyaltyTransaction;
  },

  removeLoyaltyTransaction() {
    this._loyaltyTransaction = null;
  },

  _refundLoyaltyTransaction() {
    const self = this;
    if (this._loyaltyTransaction) {
      // Find the reward associated with the transaction and refund that.
      const matchingReward = _.find(gcn.orderManager.getAppliedRewards(), (reward) => {
        const rewardI9nId = reward.get('rewardI9nId');
        return rewardI9nId === self._loyaltyTransaction.get('i9nId');
      });
      if (matchingReward) {
        this.removeReward(matchingReward, true);
      }
      this.removeLoyaltyTransaction();
    }
  },

  canRedeemMultipleRewards() {
    return gcn.location.getLoyaltyIntegration().enableMultipleRewardRedemption;
  },

  _canShowLoyaltySignupView() {
    return (
      !!gcn.menu.settings.get('enableSimpleLoyaltySignup') &&
      !this.hasLoyaltyAuthData() &&
      !this.getSmsInvitationWasSent() &&
      !window.isFlash
    );
  },

  hasLoyaltySignUpQrCodeImages() {
    const loyaltySignUpQrCodeImages = gcn.location.get('loyaltySignUpQrCodeImage');
    return !!(loyaltySignUpQrCodeImages && loyaltySignUpQrCodeImages.length > 0);
  },

  hasLoyaltySmsSignUp() {
    return !!gcn.menu.settings.get('simpleSignupSmsTemplate');
  },

  canShowLoyaltySignupOnOrderSummary() {
    return (
      this._canShowLoyaltySignupView() &&
      gcn.menu.settings.get('simpleLoyaltySignupMode') === SimpleLoyaltySignupMode.OrderSummary
    );
  },

  canShowLoyaltySignupOnPaymentCompleted() {
    return (
      this._canShowLoyaltySignupView() &&
      gcn.menu.settings.get('simpleLoyaltySignupMode') === SimpleLoyaltySignupMode.PaymentCompleted
    );
  },

  canForceShowLoyaltySignupOnPaymentCompleted() {
    return (
      this._canShowLoyaltySignupView() &&
      gcn.menu.settings.get('simpleLoyaltySignupMode') ===
        SimpleLoyaltySignupMode.PaymentCompletedForced
    );
  },

  getUnusedSessionStoredValueCards() {
    return gcn.orderManager.getUnusedStoredValueCards().filter((card) => {
      return !!card.authSessionToken;
    });
  },

  async captureAppliedGiftCardAmount(authData, appliedAmount, callback) {
    gcn.menuView.showSpinner(localizeStr(Strings.PROCESSING_PAYMENT));

    let capture;
    try {
      capture = await gcn.maitred.giftCardCapture(authData, appliedAmount);
    } catch (err) {
      return { applyError: err.message || localizeStr(Strings.ERROR_CARD_CONFIRMATION) };
    } finally {
      gcn.menuView.dismissSpinner();
    }

    gcn.orderManager.setOrderFromJSON(capture.order);

    if (gcn.location.useOrdersApiV2()) {
      gcn.orderManager.setTransactionsFromJson(capture.successfulTransactions);
    } else {
      gcn.orderManager.setTransactionsFromJson([capture.transaction]);
    }

    useStore.getState().checkout.onStoredValueUpdated();

    gcn.menuView.dismissModalPopup();
    gcn.menuView.dismissStablePopup();

    callback();

    return {};
  },

  async inquireGiftCard(authData) {
    const cardNumber = authData.cardNumber;

    let inquireData;
    try {
      Analytics.track(Analytics.EventName.StoredValueInquire);
      inquireData = await gcn.maitred.giftCardInquire(authData);
    } catch (err) {
      const message = err.message || localizeStr(Strings.ERROR_CARD_CONFIRMATION);
      Analytics.trackEvent({
        eventName: Analytics.EventName.StoredValueInquireError,
        eventData: {
          message,
        },
      });
      return { inquireError: message };
    } finally {
      gcn.menuView.dismissSpinner();
    }

    const { balance, authData: updatedAuthData } = inquireData;

    gcn.orderManager.setBalanceForStoredValueCard(cardNumber, balance);

    if (balance === 0) {
      Analytics.track(Analytics.EventName.StoredValueNoBalance);
      return { inquireError: `${GcnHtml.htmlFromPrice(balance)} ${localizeStr(Strings.BALANCE)}` };
    }

    const defaultAmount = Math.min(gcn.orderManager.getGrandTotal(), balance);

    Analytics.track(Analytics.EventName.StoredValueSufficientBalance);
    return { updatedAuthData, defaultAmount };
  },

  async applyCompCard(cardNumber, cardEntryMethod, cardPin) {
    const orderPayload = gcn.orderManager.getOrderPayload();

    try {
      Analytics.track(Analytics.EventName.CompCardApply);
      const compCardAuthData = {
        system: gcn.location.get('compCardI9n'),
        cardNumber,
        cardEntryMethod,
        cardPin,
      };
      this._compCardAuthData = compCardAuthData;
      const orderResponse = await gcn.maitred.applyCompCard(compCardAuthData, orderPayload);
      gcn.orderManager.setOrderFromJSON(orderResponse.order);

      this.trigger(BackboneEvents.GCNLoyaltyManager.DidApplyReward, orderResponse);

      useStore.getState().loyalty.onLoyaltyUpdated(orderResponse.order);
      gcn.menuView.dismissSpinner();
      if (!orderResponse.order.appliedCompCards?.length) {
        return { inquireError: localizeStr(Strings.LOYALTY_NO_REWARDS_NO_EARNING) };
      }
      return {};
    } catch (err) {
      gcn.menuView.dismissSpinner();
      const message = err.message || localizeStr(Strings.ERROR_CARD_CONFIRMATION);
      Analytics.trackEvent({
        eventName: Analytics.EventName.CompCardApplyError,
        eventData: {
          message,
        },
      });
      this._compCardAuthData = null;
      return { inquireError: message };
    }
  },

  async removeCompCard() {
    const orderPayload = gcn.orderManager.getOrderPayload();

    try {
      Analytics.track(Analytics.EventName.CompCardRemove);
      this._compCardAuthData = null;
      const orderResponse = await gcn.maitred.removeCompCard(orderPayload);
      gcn.orderManager.setOrderFromJSON(orderResponse.order);

      this.trigger(BackboneEvents.GCNLoyaltyManager.DidRemoveReward, orderResponse);

      useStore.getState().loyalty.onLoyaltyUpdated(orderResponse.order);
      gcn.menuView.dismissSpinner();
      return {};
    } catch (err) {
      gcn.menuView.dismissSpinner();
      const message = err.message || localizeStr(Strings.ERROR_CARD_CONFIRMATION);
      Analytics.trackEvent({
        eventName: Analytics.EventName.CompCardRemoveError,
        eventData: {
          message,
        },
      });
      this._compCardAuthData = null;
      return { inquireError: message };
    }
  },
});

GCNLoyaltyManager.Constant = '(Constant)';
