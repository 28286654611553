import $ from 'jquery';

export default class GcnAddonHelpers {
  // Find the first - next - picker from the set, given current pickerEl.
  static findNextAddonPickerEl($currPickerEl, $pickerList) {
    for (let i = 0; i < $pickerList.length; i++) {
      if ($pickerList[i] === $currPickerEl[0]) {
        // Return the next one, if it's there.
        if (i + 1 < $pickerList.length) {
          return $($pickerList[i + 1]);
        }
      }
    }
    return null;
  }

  // Do a smooth animated scroll down to a target sub element.
  static scrollToContentEl($target, $container, callback) {
    const $firstElement = $container.children().first();
    $container.stop(true, true).animate(
      {
        scrollTop: $target.position().top - $firstElement.position().top - 16,
      },
      800,
      () => {
        if (callback) {
          callback($target);
        }
      },
    );
  }
}
