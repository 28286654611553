// NOTE: when adding new events please add documentation to:
// https://docs.google.com/spreadsheets/d/1xwTm45jHojFxeO5UyCF1hxkooeaoQ7c5JczcTznkmqg/edit#gid=367769533
import _ from 'underscore';

import { Log } from '@biteinc/common';
import { BitePlatform, FlashBridgeMessage } from '@biteinc/enums';

import pkg from '../../../../package.json';
import type { AnalyticsEvent, AnalyticsEventNameWithoutData } from '../../../types/analytics_event';
import { AnalyticsEventName } from '../../../types/analytics_event';

/**
 * to trigger `DebugView` on Google Analytics
 * on iOS:
 *   add `FIRDebugEnabled` to `Arguments Passed on Launch` under
 *   Product > Scheme > Edit Scheme > Arguments
 * on Android:
 */

module Analytics {
  export const EventName = AnalyticsEventName;

  export function track(eventName: AnalyticsEventNameWithoutData): void {
    trackEvent({ eventName });
  }

  export function trackEvent(event: AnalyticsEvent): void {
    const eventData: Record<string, string | number | boolean> = {
      orgId: gcn.location.get('orgId'),
      orgName: gcn.location.get('orgName'),
      locationId: gcn.location.id,
      locationName: gcn.location.get('name'),
      ...(gcn.kiosk && {
        kioskId: gcn.kiosk.id,
        kioskName: gcn.kiosk.get('name'),
      }),
      // iOS has its own way of keeping a session id so this may get overwritten
      id: gcn.sessionWasStartedAt || -1,
      sessionType: window.isFlash ? 'Flash' : 'Kiosk',
      gcnBuildName: `biteinc-gcn.${pkg.version}`,
      ...(event.eventData && { ...event.eventData }),
    };

    /**
     * @description Check that all our keys are compliant with what Google Analytics accepts.
     * @link https://firebase.google.com/docs/reference/cpp/group/parameter-names
     *
     * Quick summary: Param names can be up to 40 characters long, may only contain alphanumeric
     * characters and underscores ("_"), and must start with an alphabetic character. Param values
     * can be up to 100 characters long.
     *
     * @note This regex is related to the one in gcn_extensions.js#onButtonTapOrHold()
     */
    const regexCondition = new RegExp(/^[a-zA-Z][a-zA-Z0-9_]{0,40}$/);
    Object.keys(eventData).forEach((key) => {
      if (
        !regexCondition.test(key) ||
        key.startsWith('firebase_') ||
        key.startsWith('google_') ||
        key.startsWith('ga_')
      ) {
        Log.error(`Non GA-Complaint key found: ${key}`);
        Log.error(event);
        throw new Error(`Non GA-Complaint key found: ${key}; Event: ${event.eventName}`);
      }

      // iOS discards `event` and `eventName` fields and uses the rest of the fields as `eventData`
      if (['event', 'eventName'].includes(key)) {
        Log.error(`Non iOS-GA-Complaint key found: ${key}`);
        Log.error(event);
        throw new Error(`Non iOS-GA-Complaint key found: ${key}; Event: ${event.eventName}`);
      }

      const value = eventData[key];
      if (
        !_.isArray(value) &&
        !_.isBoolean(value) &&
        !_.isNumber(value) &&
        !_.isString(value) &&
        !_.isUndefined(value)
      ) {
        Log.error(`Non GA-Complaint value found for key: ${key}; value: ${value}`);
        Log.error(event);
        throw new Error(`Non GA-Complaint value found for key: ${key}; Event: ${event.eventName}`);
      }
    });

    if (gcn.bridge) {
      // iOS discards `event` and `eventName` fields and uses the rest of the fields as `eventData`
      if (window.platform === BitePlatform.KioskIos) {
        gcn.bridge.send({
          event: FlashBridgeMessage.TRACK_EVENT,
          eventName: event.eventName,
          ...eventData,
        });
      } else {
        gcn.bridge.send({
          event: FlashBridgeMessage.TRACK_EVENT,
          eventName: event.eventName,
          eventData,
        });
      }
    } else {
      Log.debug(FlashBridgeMessage.TRACK_EVENT, event);
    }
  }
}

export default Analytics;
