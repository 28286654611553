import _ from 'underscore';

import { Strings, Validators } from '@biteinc/common';
import { LoyaltyAuthEntryMethod, LoyaltyAuthMethod } from '@biteinc/enums';

import { BackboneEvents } from '~/app/js/backbone-events';
import { localizeStr, str } from '~/app/js/localization/localization';
import { GCNForcePlainKeyboard } from '~/helpers/gcn_force_plain_keyboard';

import Analytics from '../utils/analytics';
import { GCNLoyaltyProgramHeaderView } from './gcn_loyalty_program_header_view';
import { GCNView } from './gcn_view';

// Loyalty auth UI that handles any sort of email auth.
export const GCNLoyaltyManualAuthView = GCNView.extend({
  className: 'loyalty-manual-auth-view',

  template: _.template(
    // prettier-ignore
    '<div class="body">' +
      '<div class="instructions font-body"></div>' +
      '<input class="form-control" id="loyalty-identifier-input" autocomplete="off" autocorrect="off" autocapitalize="off"/>' +
      '<input class="form-control" id="loyalty-auth-value-input" autocomplete="off" autocorrect="off" autocapitalize="off"/>' +
      '<div class="sign-out-notice font-body"></div>' +
    '</div>' +
    '<div class="footer">' +
      '<button class="button done"></button>' +
    '</div>',
  ),

  initialize(options, ...args) {
    GCNView.prototype.initialize.apply(this, [options, ...args]);

    this._didAuthGuestCallback = options?.didAuthGuestCallback;

    this.listenTo(gcn.loyaltyManager, BackboneEvents.GCNLoyaltyManager.DidUpdateAuthGuest, () => {
      this.trigger(BackboneEvents.GCNLoyaltyManualAuthView.DidUpdateAuthGuest, this);
    });
    this.listenTo(
      gcn.loyaltyManager,
      BackboneEvents.GCNLoyaltyManager.DidFailAuth,
      this._updateTitleWithError,
    );
  },

  // Gets the user inputted auth values and attempts to submit them. This should be called
  // whenever the user confirms that their input is completed.
  _attemptAuth() {
    const identifier = this._$identifierInput.val().trim();
    const authValue = this._$authValueInput.val();
    if (this._validateInputAndUpdateUIState(identifier, authValue)) {
      Analytics.track(Analytics.EventName.LoyaltyAuthStart);
      this._submitLoyaltyAuth(identifier, authValue);
    }
  },

  // Changes the UI to reflect the validity of the auth input values, and returns true if both
  // pass validation.
  _validateInputAndUpdateUIState(identifier, authValue) {
    let identifierValidated = (identifier || '').length > 0;
    if (
      gcn.loyaltyManager.loyaltyUsesEmailAuth() ||
      gcn.loyaltyManager.loyaltyUsesEmailOnlyAuth()
    ) {
      identifierValidated = Validators.isEmail(identifier);
    }
    this._$identifierInput.toggleClass('error', !identifierValidated);

    let authValueValidated = (authValue || '').length > 0;
    if (gcn.loyaltyManager.loyaltyUsesEmailOnlyAuth()) {
      authValueValidated = true;
    }
    if (gcn.loyaltyManager.loyaltyUsesEmailPhoneAuth()) {
      authValueValidated = /^[0-9]{10}$/.test(authValue);
    }
    if (gcn.loyaltyManager.loyaltyUsesCardNumber()) {
      identifierValidated = /^[0-9]+$/.test(identifier);
      authValueValidated = true;
    }
    this._$authValueInput.toggleClass('error', !authValueValidated);

    return identifierValidated && authValueValidated;
  },

  _getEntryMethod() {
    switch (gcn.loyaltyManager.getAuthMethod()) {
      case LoyaltyAuthMethod.CardNumber:
        return LoyaltyAuthEntryMethod.CardNumberManuallyEntered;
      case LoyaltyAuthMethod.Email:
        return LoyaltyAuthEntryMethod.EmailManuallyEntered;
      case LoyaltyAuthMethod.EmailPassword:
        return LoyaltyAuthEntryMethod.EmailAndPasswordManuallyEntered;
      case LoyaltyAuthMethod.EmailPhoneNumber:
        return LoyaltyAuthEntryMethod.EmailAndPhoneNumberManuallyEntered;
      case LoyaltyAuthMethod.UsernamePassword:
        return LoyaltyAuthEntryMethod.UsernameAndPasswordManuallyEntered;
      default:
        return LoyaltyAuthEntryMethod.UnknownManuallyEntered;
    }
  },

  _submitLoyaltyAuth(identifier, authValue) {
    gcn.menuView.showSpinner(localizeStr(Strings.LOOKING_UP_ACCOUNT));
    gcn.loyaltyManager.submitLoyaltyAuth(identifier, authValue, this._getEntryMethod(), () => {
      gcn.menuView.dismissSpinner();
      if (this._didAuthGuestCallback) {
        this._didAuthGuestCallback();
      }
    });
  },

  _updateTitleWithError(errorMsg) {
    this._$instructions.htmlOrText(errorMsg);
    this._$instructions.toggleClass('error', true);
  },

  render() {
    const self = this;

    this.$el.html(this.template());

    this.$el.prepend(new GCNLoyaltyProgramHeaderView().render().$el);

    this._$instructions = this.$('.instructions');
    this._$doneButton = this.$('.button.done');
    this._$identifierInput = this.$('#loyalty-identifier-input');
    this._$authValueInput = this.$('#loyalty-auth-value-input');

    // Configure the UI.
    if (
      gcn.loyaltyManager.loyaltyUsesEmailAuth() ||
      gcn.loyaltyManager.loyaltyUsesEmailOnlyAuth()
    ) {
      this._$identifierInput.attr('type', 'email');
      this._$identifierInput.attr('placeholder', str(Strings.EMAIL_ADDRESS).toLowerCase());

      if (gcn.orderManager.getGuestEmail()) {
        this._$identifierInput.val(gcn.orderManager.getGuestEmail());
      }
    } else {
      this._$identifierInput.attr('placeholder', str(Strings.USERNAME).toLowerCase());
    }
    if (gcn.loyaltyManager.loyaltyUsesPasswordAuth()) {
      this._$authValueInput.attr('type', 'password');
      this._$authValueInput.attr('placeholder', str(Strings.PASSWORD).toLowerCase());
      this.$('.sign-out-notice').htmlOrText(localizeStr(Strings.LOYALTY_SIGN_OUT_NOTICE_PASSWORD));
    } else {
      this.$('.sign-out-notice').htmlOrText(localizeStr(Strings.LOYALTY_SIGN_OUT_NOTICE));
    }
    if (gcn.loyaltyManager.loyaltyUsesEmailPhoneAuth()) {
      this._$authValueInput.attr('type', 'tel');
      this._$authValueInput.attr('maxlength', '10');
      this._$authValueInput.attr(
        'placeholder',
        str(Strings.FULFILLMENT_LABEL_PHONE_NUMBER).toLowerCase(),
      );
      if (gcn.orderManager.getGuestPhoneNumber()) {
        this._$authValueInput.val(gcn.orderManager.getGuestPhoneNumber());
      }
    }
    if (gcn.loyaltyManager.loyaltyUsesCardNumber()) {
      this._$identifierInput.attr('type', 'number');
      this._$identifierInput.attr('placeholder', str(Strings.LOYALTY_CARD_NUMBER).toLowerCase());
    }
    if (
      gcn.loyaltyManager.loyaltyUsesEmailOnlyAuth() ||
      gcn.loyaltyManager.loyaltyUsesCardNumber()
    ) {
      this._$authValueInput.hide();
    }

    // For testing. Successful user.
    // Valid user for Noodles SessionM-Sandbox.
    // this._$identifierInput.val('106774394779671.69@example.com');
    // this._$authValueInput.val('Temp@1234');
    // Valid user for punchh-olo sandbox
    // this._$identifierInput.val('brysonreed+sandbox@yopmail.com');
    // this._$authValueInput.val('p@ssw0rd');
    // Valid user for paytronix-olo sandbox.
    // this._$identifierInput.val('togosmikepennington@gmail.com');
    // this._$authValueInput.val('4088078382');
    // Valid user for paytronix-only sandbox.
    // this._$identifierInput.val('test2@testing.com');
    // this._$authValueInput.val('6176493300');
    // For testing. Bad user.
    // this._$identifierInput.val('steve@gmail.com');
    // this._$authValueInput.val('6175553300');

    this._$instructions.htmlOrText(localizeStr(Strings.LOYALTY_AUTH_INSTRUCTIONS));
    this._$doneButton.htmlOrText(localizeStr(Strings.FIND_ACCOUNT));

    this._$doneButton.onButtonTapOrHold('lmavDone', () => {
      self._attemptAuth();
    });
    this._$identifierInput.keyup((e) => {
      if (e.keyCode === 13) {
        self._attemptAuth();
      }
      return false;
    });
    this._$authValueInput.keyup((e) => {
      if (e.keyCode === 13) {
        self._attemptAuth();
      }
      return false;
    });

    new GCNForcePlainKeyboard(this._$identifierInput).enableForcePlainKeyboardIfNeeded();
    if (!gcn.loyaltyManager.loyaltyUsesPasswordAuth()) {
      new GCNForcePlainKeyboard(this._$authValueInput).enableForcePlainKeyboardIfNeeded();
    }

    return this;
  },
});
