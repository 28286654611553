import _ from 'underscore';

import { Strings, Validators } from '@biteinc/common';
import { StringHelper } from '@biteinc/core-react';

import { BackboneEvents } from '~/app/js/backbone-events';
import { localizeStr } from '~/app/js/localization/localization';
import { GCNForcePlainKeyboard } from '~/helpers/gcn_force_plain_keyboard';

import { blockedWordValidation } from '../utils/blocked_word_validation';
import LocalStorage from '../utils/local_storage';
import { GCNView } from './gcn_view';

export const GCNGuestNameEntryView = GCNView.extend({
  className: 'order-destination-view',

  template: _.template(`
    <div class="guest-name">
      <div class="back-button"></div>
      <div class="header" role="heading" aria-level="1" tabindex="-1"></div>
      <div class="body">
        <input class="guest-name-input first-name" maxlength="16" autocomplete="off" autocorrect="off" />
      </div>
      <div class="header last-name" role="heading" aria-level="1" tabindex="-1"></div>
      <div class="body">
        <input class="guest-name-input last-name hidden" maxlength="16" autocomplete="off" autocorrect="off" />
      </div>
      <div class="button-container">
        <div class="done-button"></div>
      </div>
      <div class="blocked-word-warning-container">
        <p class="guest-name-input-validation-failed hidden"></p>
        <p class="blocked-word-warning hidden"></p>
      </div>
      <div class="footer">
        <div class="footnote"></div>
      </div>
    </div>
  `),

  initialize(...args) {
    GCNView.prototype.initialize.apply(this, [...args]);

    this.addRefreshOnLanguageChangeSubscription();
  },

  _renderBackButton() {
    this.$('.back-button').htmlOrText(localizeStr(Strings.BACK));
    this.$('.back-button').onButtonTapOrHold('gnevBack', () => {
      this.trigger(BackboneEvents.GCNGuestNameEntryView.BackedOut);
    });
  },

  _enableDoneButton(enable) {
    this.$doneButton.toggleClass('disabled', !enable);
  },

  _submitUserName(name) {
    gcn.orderManager.setGuestName(name);
    this.trigger(BackboneEvents.GCNGuestNameEntryView.DidEnterName);
  },

  blur() {
    if (this.$guestNameInput) {
      this.$guestNameInput.blur();
    }
    setTimeout(() => {
      // TODO: This causes an occasional janky hiding animation when pressing
      // the back button. Consider finding a way to avoid calling this if the
      // text field is focused but the keyboard is NOT shown, as doing that
      // shows the keyboard.
      gcn.notifyUserDidFocusOutOfTextField();
    }, 1);
  },

  _getSanitizedUserInput(inputValue) {
    return StringHelper.removeDiacritics(StringHelper.toTitleCase(inputValue))
      .replace(/[^a-zA-Z0-9\s\-\\.]/g, '')
      .trim();
  },

  _isLastNameEnforced() {
    return (
      gcn.location.getCustomerIdentifierOptions(gcn.orderManager.getFulfillmentMethod())?.[0]
        ?.enforceLastInitial || false
    );
  },

  _validateUserInput(inputValue) {
    const { validation, result } = blockedWordValidation(inputValue.toLowerCase());
    if (!result) {
      switch (validation) {
        case 'profanity':
        case 'custom':
          this.$blockedWordWarning.toggleClass('hidden', false);
          break;
        case 'generic':
        default:
          this.$inputValidationFailed.toggleClass('hidden', false);
          break;
      }
    }
    const regex = new RegExp(
      Validators.simpleStringRegexString({
        rejectEmptySpace: true,
      }),
    );
    const simpleRegexValidation = regex.test(inputValue.toLowerCase());
    if (!simpleRegexValidation) {
      this.$inputValidationFailed.toggleClass('hidden', false);
    }
    return result && regex.test(inputValue.toLowerCase());
  },

  _handleDoneButtonClick() {
    if (!this.$doneButton.hasClass('disabled')) {
      const guestName = this._getSanitizedUserInput(this.$guestNameInput.val());
      this.$guestNameInput.toggleClass('validation-failed', false);
      let isValid = guestName && this._validateUserInput(this.$guestNameInput.val());
      if (!isValid) {
        this.$guestNameInput.toggleClass('validation-failed', true);
        this._enableDoneButton(false);
      }

      if (this._isLastNameEnforced()) {
        const guestLastName = this._getSanitizedUserInput(this.$guestLastNameInput.val());
        this.$guestLastNameInput.toggleClass('validation-failed', false);
        isValid =
          isValid && guestLastName && this._validateUserInput(this.$guestLastNameInput.val());
        if (!isValid) {
          this.$guestLastNameInput.toggleClass('validation-failed', true);
          this._enableDoneButton(false);
        }
        this._submitUserName(`${guestName} ${guestLastName}`);
      } else {
        this._submitUserName(guestName);
      }
    }
    this.blur();
  },

  _validateAllGuestNameInputs() {
    // reset the blockedWord & inputValidationFailed warnings
    this.$blockedWordWarning.toggleClass('hidden', true);
    this.$inputValidationFailed.toggleClass('hidden', true);

    let validated = true;
    const guestNameInput = this.$guestNameInput.val();
    const guestLastNameInput = this.$guestLastNameInput.val();

    if (this._isLastNameEnforced()) {
      const sanitizedLastName = this._getSanitizedUserInput(guestLastNameInput);
      this.$guestLastNameInput.toggleClass('validation-failed', false);
      validated = !!sanitizedLastName && this._validateUserInput(guestLastNameInput);
      if (!validated) {
        this.$guestLastNameInput.toggleClass('validation-failed', true);
      }
    }

    this.$guestNameInput.toggleClass('validation-failed', false);
    const sanitizedGuestFirstName = this._getSanitizedUserInput(guestNameInput);
    const guestNameValidation =
      !!sanitizedGuestFirstName && this._validateUserInput(guestNameInput);
    if (!guestNameValidation) {
      this.$guestNameInput.toggleClass('validation-failed', true);
    }
    validated = validated && guestNameValidation;
    this._enableDoneButton(validated);
    return validated;
  },

  _getStoredGuestNameParts() {
    const storedGuestName = (
      LocalStorage.getItem('guestName') ||
      gcn.loyaltyManager.getAuthedGuestFriendlyName() ||
      ''
    )
      .trim()
      .replace(/[^\S\n]{1,}/g, ' ');
    if (!storedGuestName) {
      return null;
    }
    return (
      storedGuestName
        .split(' ')
        // Take the first two parts at most
        .slice(0, 2)
        .map((part) => {
          // We have a maxlength of 16
          return part.slice(0, 16);
        })
    );
  },

  render() {
    this.$el.html(this.template());

    this.$blockedWordWarning = this.$('.blocked-word-warning');
    this.$blockedWordWarning.htmlOrText(localizeStr(Strings.BLOCKED_WORD_WARNING));

    this.$inputValidationFailed = this.$('.guest-name-input-validation-failed');
    this.$inputValidationFailed.htmlOrText(localizeStr(Strings.ENTER_YOUR_NAME_VALIDATION_FAILED));

    this.$doneButton = this.$('.done-button');
    this.$guestNameInput = this.$('.guest-name-input.first-name');
    this.$guestLastNameInput = this.$('.guest-name-input.last-name');

    new GCNForcePlainKeyboard(this.$guestNameInput).enableForcePlainKeyboardIfNeeded();
    new GCNForcePlainKeyboard(this.$guestLastNameInput).enableForcePlainKeyboardIfNeeded();

    if (this._isLastNameEnforced()) {
      this.$guestLastNameInput.removeClass('hidden');
      this.$('.header.last-name').htmlOrText(localizeStr(Strings.ENTER_YOUR_LAST_NAME));
    }
    this.$doneButton.htmlOrText(localizeStr(Strings.DONE));
    this.$doneButton.onButtonTapOrHold(
      'guestNameDoneButton',
      this._handleDoneButtonClick.bind(this),
    );
    this.$guestNameInput.focus(() => {
      gcn.notifyUserDidFocusOnTextField();
    });

    this.$guestNameInput.focus();
    this.$guestNameInput.keyup((e) => {
      const sanitizedGuestFirstName = this._getSanitizedUserInput(this.$guestNameInput.val());
      if (!this._validateAllGuestNameInputs()) {
        return false;
      }
      if (e.keyCode === 13) {
        if (this._isLastNameEnforced()) {
          this.$guestLastNameInput.focus();
        } else {
          this._submitUserName(sanitizedGuestFirstName);
        }
      }
      return false;
    });

    this.$guestLastNameInput.keyup((e) => {
      this.$inputValidationFailed.toggleClass('hidden', true);
      const sanitizedGuestLastName = this._getSanitizedUserInput(this.$guestLastNameInput.val());
      const sanitizedGuestFirstName = this._getSanitizedUserInput(this.$guestNameInput.val());
      if (!this._validateAllGuestNameInputs()) {
        return false;
      }
      if (e.keyCode === 13) {
        this._submitUserName(`${sanitizedGuestFirstName} ${sanitizedGuestLastName}`);
      }
      return false;
    });

    this.$('.header:first').htmlOrText(localizeStr(Strings.ENTER_YOUR_NAME));
    if (gcn.screenReaderIsActive) {
      this.$('.header').requestFocusAfterDelay();
    }
    this.$('.footnote').htmlOrText(localizeStr(Strings.NAME_CALLED_ORDER_READY));

    this._renderBackButton();

    const storedGuestNameParts = this._getStoredGuestNameParts();
    if (storedGuestNameParts?.length) {
      this.$guestNameInput.val(storedGuestNameParts[0]);
      if (storedGuestNameParts.length > 1 && this._isLastNameEnforced()) {
        this.$guestLastNameInput.val(storedGuestNameParts[1]);
      }
      this._validateAllGuestNameInputs();
    } else {
      this._enableDoneButton(false);
    }

    if (gcn.screenReaderIsActive) {
      setTimeout(() => {
        this._submitUserName('KioskUser');
      }, 100);
    }

    return this;
  },
});
