import $ from 'jquery';
import _ from 'underscore';

import { Log } from '@biteinc/common';
import { MenuModifierPriceDisplayStyle, ModGroupDisplayStyle } from '@biteinc/enums';

import { BackboneEvents } from '~/app/js/backbone-events';

import { flashElement } from '../gcn_app_view';
import GcnHtml from '../gcn_html';
import GcnRecoTracker from '../gcn_reco_tracker';
import { GCNOrderedItem } from '../models/gcn_ordered_item';
import Analytics from '../utils/analytics';
import { asCallback } from '../utils/promises';
import { GCNAddonButtonView } from './gcn_addon_button_view';
import { GCNView } from './gcn_view';

export const GCNAddonSetPickerView = GCNView.extend({
  className: 'addon-set-picker-view',

  initialize(options, ...args) {
    GCNView.prototype.initialize.apply(this, [options, ...args]);

    // Map of addon IDs to "addon maps", where an addon map is an object with properties `addon`
    // and `quantity`, which are pretty self explanatory.
    this._selectedAddonById = {};
    this._deselectedAddonById = {};
    this._nestedPickersByAddonId = {};
    this._addonSet = options.addonSet;
    this._parentItem = options.parentItem;
    this._options = options;
    this._ignoreMinSelectableOnComboBuilder = !!options.ignoreMinSelectableOnComboBuilder;
    this._isAssorted = options.isAssorted;
    this._optionCodeSelectionStructByAddonId = options.optionCodeSelectionStruct || {};
    this._selectedPriceOptionByAddonId = options.selectedPriceOptionByAddonId || {};

    this._recommendedAddonById = {};

    if (!options.isInitial) {
      this._fetchAndRenderRecommendations();
    }
  },

  _fetchAndRenderRecommendations() {
    if (!this._addonSet.doWeRecommend()) {
      return;
    }

    // Check if we've already fetched recommendations for this mod group.
    if (GcnRecoTracker.modGroupRecosFetched(this._parentItem.id, this._addonSet.id)) {
      const recommendations = GcnRecoTracker.getModGroupRecos(
        this._parentItem.id,
        this._addonSet.id,
      );

      recommendations.forEach((reco) => {
        this._recommendedAddonById[reco.itemId] = reco;
      });

      this.render();
      return;
    }

    asCallback(
      GcnRecoTracker.getModifierGroupRecommendations(
        gcn.maitred,
        this._parentItem,
        this._addonSet.get('parentModGroupId') || this._addonSet.id,
        this._addonSet.get('parentModGroupId') ? this._addonSet.id : null,
      ),
      (err, recommendations) => {
        if (err) {
          Log.error('error loading recos', err);
          return;
        }

        recommendations.forEach((reco) => {
          this._recommendedAddonById[reco.itemId] = reco;
        });

        if (recommendations.length) {
          this.render();
        }
      },
    );
  },

  refreshRenderRecos() {
    if (GcnRecoTracker.modGroupRecosFetched(this._parentItem.id, this._addonSet.id)) {
      const recommendations = GcnRecoTracker.getModGroupRecos(
        this._parentItem.id,
        this._addonSet.id,
      );
      if (!recommendations.length) {
        return;
      }

      recommendations.forEach((reco) => {
        this._recommendedAddonById[reco.itemId] = reco;
      });

      this.render();
    }
  },

  getAddonSet() {
    return this._addonSet;
  },

  // Returns a "selection struct", which is a recursive JSON object that represents selected
  // addons for a single addon set, including all nested selections. This is used as a model for
  // GCNAddonSetPickerView to track what addons are selected.
  //
  // The format is defined as:
  // {
  //   model: <model of the addon set>
  //   selections: <map of addon ids to selected-addon-objects>.
  //   deselections: <map of addon ids to selected-addon-objects> for addons
  //   that are supposed to be selected by default but were deselected
  // }
  //
  // And a "selected-addon-object", which represents a selected addon, is defined as:
  // {
  //   model: <model of the selected addon>
  //   selections: <map of addon set ids to selection structs>
  //   quantity: <number of this item added. If not set, assume quantity of 1>
  // }
  //
  // Note that this structure assumes that addons have a single price option.
  getSelectionStruct() {
    const selfSelections = _.mapObject(
      this._selectedAddonById,
      ({ addon, quantity, upsellScreen, selectedByDefaultQuantity }) => {
        // Recursively get all the selection structs for each of this addon's nested addon sets.
        const addonSelections = {};
        _.each(this._nestedPickersByAddonId[addon.id], (picker) => {
          const selectionStruct = picker.getSelectionStruct();
          if (selectionStruct) {
            addonSelections[picker.getAddonSet().id] = selectionStruct;
          }
        });

        if (this._optionCodeSelectionStructByAddonId[addon.id]) {
          const addonSet = this._optionCodeSelectionStructByAddonId[addon.id].model;
          addonSelections[addonSet.id] = this._optionCodeSelectionStructByAddonId[addon.id];
        }

        const selfSelection = {
          model: addon,
          quantity,
          selections: addonSelections,
          upsellScreen,
          selectedByDefaultQuantity,
        };

        if (this._selectedPriceOptionByAddonId[addon.id]) {
          const priceOption = this._selectedPriceOptionByAddonId[addon.id];
          selfSelection.selectedPriceOption = priceOption;
        }

        return selfSelection;
      },
    );

    const selfDeselections = _.mapObject(this._deselectedAddonById, ({ addon }) => {
      return {
        model: addon,
        selections: {},
        quantity: 1,
      };
    });

    const selectionStruct = {
      model: this._addonSet,
      selections: selfSelections,
    };
    if (_.size(selfDeselections)) {
      selectionStruct.deselections = selfDeselections;
    }
    return selectionStruct;
  },

  setSelectionStruct(selectionStruct, silent, upsellScreen) {
    if (!selectionStruct) {
      return;
    }

    let changesMade = false;
    _.each(selectionStruct.selections, (addonStruct, addonId) => {
      changesMade = true;

      if (addonStruct.selectedPriceOption) {
        this._selectedPriceOptionByAddonId[addonId] = addonStruct.selectedPriceOption;
      }

      // Set our mod code selection struct
      if (
        addonStruct.selections &&
        !this._nestedPickersByAddonId[addonId] &&
        _.size(addonStruct.selections) === 1
      ) {
        const addonSetSelection = addonStruct.selections[Object.keys(addonStruct.selections)[0]];
        if (addonSetSelection.model.get('displayStyle') === ModGroupDisplayStyle.SizeGroup) {
          this._optionCodeSelectionStructByAddonId[addonId] = addonSetSelection;
        }
      }

      this._selectAddon(this._buttonViewByAddonId[addonId], undefined, upsellScreen, true);
      if (addonStruct.quantity > 1) {
        this._buttonViewByAddonId[addonId].setQuantity(addonStruct.quantity);
        this._selectedAddonById[addonId].quantity = addonStruct.quantity;
      }
      if (addonStruct.selections) {
        const nestedPickers = this._nestedPickersByAddonId[addonId];
        _.each(nestedPickers, (picker) => {
          const addonSetId = picker.getAddonSet().id;
          const setSelectionStruct = addonStruct.selections[addonSetId];
          if (setSelectionStruct) {
            picker.setSelectionStruct(setSelectionStruct, true /* silent */);
          }
        });
      }
    });

    _.each(selectionStruct.deselections, (addonStruct, addonId) => {
      const buttonView = this._buttonViewByAddonId[addonId];
      this._deselectAddon(buttonView, buttonView.getAddon());
      changesMade = true;
    });

    if (changesMade && !silent) {
      this._notifyOfAddonChange();
    }
  },

  // The logic here is that `setSelectionStruct` is an additive function, it's not a "setState".
  // So in order to cancel everything we selected we just have to invert the selection struct.
  deselectEverything() {
    const selectionStruct = this.getSelectionStruct();
    const newSelectionStruct = {
      ...selectionStruct,
      selections: {},
      deselections: selectionStruct.selections,
    };
    this.setSelectionStruct(newSelectionStruct);
  },

  setIgnoreMinSelectable(ignoreMinSelectable) {
    if (this._ignoreMinSelectableOnComboBuilder !== ignoreMinSelectable) {
      this._ignoreMinSelectableOnComboBuilder = ignoreMinSelectable;
      this._notifyOfAddonChange();
    }
  },

  isSelectionStructValid(shallowValidation = false, shouldValidateQuantityInRange = false) {
    return this.getAddonSet().isSelectionStructValid(
      this.getSelectionStruct(),
      shallowValidation,
      this._ignoreMinSelectableOnComboBuilder,
      shouldValidateQuantityInRange,
    );
  },

  validateSelectionStruct(shallowValidation = false, shouldValidateQuantityInRange = false) {
    return this.getAddonSet().validateSelectionStruct(
      this.getSelectionStruct(),
      shallowValidation,
      this._ignoreMinSelectableOnComboBuilder,
      shouldValidateQuantityInRange,
    );
  },

  _notifyOfAddonChange() {
    this.trigger(BackboneEvents.GCNAddonSetPickerView.AddonSetDidChange, this);

    if (this._options.addonsChangedCallback) {
      this._options.addonsChangedCallback(this);
    }
  },

  _selectAddon(buttonView, animated, upsellScreen, isInitial) {
    const addon = buttonView.getAddon();
    // Prevent double-selecting.
    if (this._selectedAddonById[addon.id]) {
      return;
    }
    delete this._deselectedAddonById[addon.id];

    const selectedByDefaultQuantity = this._addonSet.getAddonSelectedByDefaultCount(addon.id);
    const quantity =
      selectedByDefaultQuantity && isInitial
        ? this._addonSet.getAddonSelectedByDefaultCount(addon.id)
        : 1;

    this._selectedAddonById[addon.id] = {
      addon,
      quantity,
      ...(!!this._recommendedAddonById[addon.id] && { upsellScreen: 'GCNMenuItemOrderView' }),
      // overwrite if this is coming from a specific upsell screen
      ...(upsellScreen && { upsellScreen }),
      ...(selectedByDefaultQuantity > 0 && { selectedByDefaultQuantity }),
    };

    if (this._addonSet.allowMultipleOfSameAddon()) {
      buttonView.setQuantity(quantity);
    } else {
      buttonView.setSelected(true);
    }

    this._showNestedAddonSets(addon, animated, buttonView);
  },

  _deselectAddon(buttonView, addon) {
    delete this._selectedAddonById[addon.id];
    if (
      !this._deselectedAddonById[addon.id] &&
      this._addonSet.addonIsSelectedByDefault(addon.id) &&
      !addon.is86d()
    ) {
      this._deselectedAddonById[addon.id] = {
        addon,
      };
    }
    if (buttonView) {
      buttonView.setSelected(false);
    }
    this._hideNestedAddonSets(addon, true /* animated */);
  },

  _addonTextChanged() {
    this._notifyOfAddonChange(this);
  },

  _hasRequiredNestedAddonSets(addon) {
    // Olo specific feature is not supported for multiple price option mods
    if (addon.priceOptions.length !== 1) {
      return false;
    }

    return _.any(addon.priceOptions[0].addonSets, (addonSet) => {
      return addonSet.get('minSelectable') > 0;
    });
  },

  _onNestedPickerViewShown() {
    this.trigger(BackboneEvents.GCNAddonSetPickerView.NestedPickerViewShown, this);
  },

  _maybeTriggerAddonSetSelected() {
    const self = this;
    if (
      !this._autoScrolledOnce &&
      gcn.menu.settings.get('autoScrollCustomization') &&
      this._addonSet.canAutoScroll(this._selectedAddonById)
    ) {
      this._autoScrolledOnce = true;
      // Slight delay so user can see tap feedback.
      setTimeout(() => {
        self._onNestedPickerSelectionComplete(self, self._flashHeader.bind(self));
      }, 350);
    }
  },

  _flashHeader($element) {
    flashElement($element.find('.header .title'), 'flash');
  },

  _onNestedPickerSelectionComplete(picker, callback) {
    // Pass along the possible-nested picker up the tree.
    this.trigger(BackboneEvents.GCNAddonSetPickerView.AddonSelectionComplete, picker, callback);
  },

  _showNestedAddonSets(addon, animated, buttonView) {
    const nestedAddonSets = [];

    // Multiple price options are not compatible with virtual addon sets.
    if (addon.priceOptions.length > 1) {
      this.listenTo(
        buttonView,
        BackboneEvents.GCNAddonSetPickerView.AddonOptionSelectionComplete,
        this._onOptionSelectionComplete,
      );
      buttonView.renderOptions(
        addon.priceOptions,
        this._selectedPriceOptionByAddonId[addon.id]?.id,
      );
    } else {
      const addonSets = addon.priceOptions[0].addonSets;
      for (let i = addonSets.length - 1; i >= 0; i--) {
        let headerPrefix = this._addonSet.addonNameInSet(addon);
        if (this._options.headerPrefix) {
          headerPrefix = this._options.headerPrefix + headerPrefix;
        }
        const addonSet = addonSets[i];
        if (addonSet.get('displayStyle') === ModGroupDisplayStyle.SizeGroup) {
          buttonView.renderCodes(
            addonSet,
            Object.keys(this._optionCodeSelectionStructByAddonId[addon.id]?.selections || {})?.[0],
          );
          this.listenTo(
            buttonView,
            BackboneEvents.GCNAddonSetPickerView.AddonOptionCodeSelectionComplete,
            this._onOptionCodeSelectionComplete,
          );

          this.listenTo(
            buttonView,
            BackboneEvents.GCNAddonSetPickerView.AddonOptionCodeSelectionUnset,
            this._onOptionCodeSelectionUnset,
          );
        } else if (this._options.fullScreenSeparateFlowView) {
          nestedAddonSets.push(addonSet);
        } else {
          const setPicker = new GCNAddonSetPickerView({
            addonSet,
            headerPrefix: gcn.menu.settings.get('prependParentModNamesForNestedMods')
              ? `${headerPrefix}: `
              : null,
            showHeader: true,
            addonsChangedCallback: this._notifyOfAddonChange.bind(this),
            fullScreenSeparateFlowView: this._options.fullScreenSeparateFlowView,
            parentItem: this._parentItem,

            // that nested addonSets **cannot** be assorted
            isAssorted: false,
            isEditingPrevious: this._options.isEditingPrevious,
          });

          this.listenTo(
            setPicker,
            BackboneEvents.GCNAddonSetPickerView.NestedPickerViewShown,
            this._onNestedPickerViewShown,
          );

          this.listenTo(
            setPicker,
            BackboneEvents.GCNAddonSetPickerView.AddonSelectionComplete,
            this._onNestedPickerSelectionComplete,
          );

          if (!this._nestedPickersByAddonId[addon.id]) {
            this._nestedPickersByAddonId[addon.id] = [];
          }
          this._nestedPickersByAddonId[addon.id].push(setPicker);
          this.$nestedSets.prepend(setPicker.render().$el);

          if (animated) {
            // We need to wait because the default selections are only going to be made a
            // millisecond after the render.
            setTimeout(() => {
              const addonSetNeedsMoreSelections = !setPicker.isSelectionStructValid(true);
              if (addonSetNeedsMoreSelections) {
                this.trigger(
                  BackboneEvents.GCNAddonSetPickerView.AddonErrorMessageShown,
                  addonSet.selectableText(),
                );
              }
            }, 100);

            setPicker.$el.hide();
            setPicker.$el.slideDown(600, this._onNestedPickerViewShown.bind(this));
          }
        }
      }
    }
    if (this._options.fullScreenSeparateFlowView) {
      this.trigger(BackboneEvents.GCNAddonSetPickerView.NestedPickerNewPageAdded, {
        nestedAddonSets,
        parentAddonSetId: this._addonSet.id,
        addon,
      });
    }
  },

  _onOptionCodeSelectionComplete(optionCodesAddonSet, selectedAddonOption, parentAddon, selected) {
    if (selected) {
      this._optionCodeAddonSetId = optionCodesAddonSet.id;
      this._optionCodeSelectionStruct = {
        model: optionCodesAddonSet,
        selections: {
          [selectedAddonOption.id]: {
            model: selectedAddonOption,
            selections: {},
            quantity: 1,
          },
        },
      };
    } else {
      this._optionCodeAddonSetId = null;
      this._optionCodeSelectionStruct = null;
    }

    this._optionCodeSelectionStructByAddonId[parentAddon.id] = this._optionCodeSelectionStruct;

    if (this._options.addonsChangedCallback) {
      this._options.addonsChangedCallback(this);
    }

    // Let the parent know what selection was made to track on a re-render.
    this.trigger(
      BackboneEvents.GCNAddonSetPickerView.AddonOptionCodeSelectionComplete,
      this._optionCodeSelectionStruct,
    );
  },

  _onOptionSelectionComplete(selectedPriceOption, parentMod) {
    this._selectedPriceOptionByAddonId[parentMod.id] = selectedPriceOption;

    if (this._options.addonsChangedCallback) {
      this._options.addonsChangedCallback(this);
    }
    this.trigger(
      BackboneEvents.GCNAddonSetPickerView.AddonOptionSelectionComplete,
      this._selectedPriceOptionByAddonId,
    );
  },

  _onOptionCodeSelectionUnset() {
    this._optionCodeAddonSetId = null;
    this._optionCodeSelectionStruct = null;
  },

  _hideNestedAddonSets(addon, animated) {
    if (this._options.fullScreenSeparateFlowView) {
      this.trigger(BackboneEvents.GCNAddonSetPickerView.NestedPickerNewPageRemoved, {
        removedParentAddonSetId: this._addonSet.id,
        removedParentAddonId: addon.id,
      });
    }
    _.each(this._nestedPickersByAddonId[addon.id], (setPicker) => {
      if (animated) {
        setPicker.$el.slideUp(400, () => {
          setPicker.$el.remove();
        });
      } else {
        setPicker.$el.remove();
      }
    });
    delete this._nestedPickersByAddonId[addon.id];
  },

  _maybeUpdateErrorState() {
    const isError = !this.isSelectionStructValid(true);
    const $warning = this.$el.find(
      `#${GCNAddonButtonView.IdPrefix}${this._addonSet.id} .max-warning`,
    );
    $warning.toggleClass('highlighted', isError);
    if (isError) {
      flashElement($warning, 'flash');
      this.trigger(
        BackboneEvents.GCNAddonSetPickerView.AddonErrorMessageShown,
        this._addonSet.selectableText(),
      );
    }
  },

  _handleAddonButtonTapped(buttonView, options = {}) {
    const { ignorePreviouslySelected = false, isNewlyCreated = false } = options;
    const addon = buttonView.getAddon();

    // On some devices (notably elo backpacks), we're prone to receiving double clicks. This
    // causes problems for our addon selection, since it will get immediately deselected and the
    // device will appear as if it was actually unresponsive
    if (
      this._lastModTap &&
      addon.id === this._lastModTap.id &&
      Date.now() - this._lastModTap.time < 100
    ) {
      return;
    }
    this._lastModTap = {
      id: addon.id,
      time: Date.now(),
    };

    let selectDefaultAddons = false;
    const wasSelected = !!this._selectedAddonById[addon.id] && !ignorePreviouslySelected;
    if (this._addonSet.get('maxSelectable') === 1) {
      const minSelectable = this._addonSet.get('minSelectable');
      if (_.size(this._selectedAddonById) && (!wasSelected || !minSelectable)) {
        this._deselectAddon(null, _.values(this._selectedAddonById)[0].addon);
        // Remove class from other options; not the one that was clicked
        // this.$addonList.children().removeClass('selected');
        _.each(this._buttonViewByAddonId, (btnView) => {
          btnView.setSelected(false);
        });
      }
      if (!wasSelected) {
        this._selectAddon(buttonView, true /* animated */);
        if (!isNewlyCreated) {
          this._maybeTriggerAddonSetSelected();
        }
        selectDefaultAddons = true;
      }
    } else if (wasSelected) {
      Analytics.trackEvent({
        eventName: Analytics.EventName.ModRemoved,
        eventData: {
          itemName: addon.displayName(),
        },
      });
      this._deselectAddon(buttonView, buttonView.getAddon());
    } else {
      const shallowValidation = true;
      const shouldValidateQuantityInRange = true;
      if (!this.isSelectionStructValid(shallowValidation, shouldValidateQuantityInRange)) {
        // we validate on each tap on the addons, if user already has selected max
        // mod group then we don't recognize the selection
        return;
      }

      Analytics.trackEvent({
        eventName: Analytics.EventName.ModAdded,
        eventData: {
          itemName: addon.displayName(),
        },
      });
      this._selectAddon(buttonView, true /* animated */);
      if (!isNewlyCreated) {
        this._maybeTriggerAddonSetSelected();
      }
      selectDefaultAddons = true;
    }

    if (selectDefaultAddons) {
      // Ensure that all the nested pickers for this selected addon have their defaults
      // selected before we show them.
      // TODO - handle default selection for multi price option
      const defaultSelectionStruct = GCNOrderedItem.defaultSelectionStructFromPriceOption(
        addon.priceOptions[0],
      );
      const nestedPickers = this._nestedPickersByAddonId[addon.id];
      _.each(nestedPickers, (picker) => {
        const addonSetId = picker.getAddonSet().id;
        const selectionStruct = defaultSelectionStruct[addonSetId];
        if (selectionStruct) {
          picker.setSelectionStruct(selectionStruct, true /* silent */);
        }
      });
    }

    // Potentially update error state.
    this._maybeUpdateErrorState();

    this._notifyOfAddonChange();
  },

  /**
   * if the menuItem `isAssorted` then we place images of
   * the addons to the placeholder on the BYO image
   */
  _handleAssortedSelections() {
    if (!this._isAssorted) {
      return;
    }
    const ASSORTED_ITEMS_LENGTH = $('.assorted-container > .assorted-item').length;
    const placeholderImageMatrix = Array.from(Array(ASSORTED_ITEMS_LENGTH));

    let noOfAssortedItems = 0;
    Object.values(this._selectedAddonById).forEach((item) => {
      for (let i = 0; i < item.quantity; i++) {
        placeholderImageMatrix[noOfAssortedItems] = item.addon.get('images')?.[0]?.url;
        noOfAssortedItems += 1;
      }
    });

    placeholderImageMatrix.forEach((imageUrl, index) => {
      const $assortedItemImageContainer = $(
        `.assorted-container > .assorted-item:nth-child(${index + 1})`,
      );
      $assortedItemImageContainer.removeClass('active');
      $assortedItemImageContainer.empty();
      if (imageUrl) {
        $assortedItemImageContainer.addClass('active');
        gcn.requestImageByUrl(imageUrl, (err, imgPath) => {
          $assortedItemImageContainer.append($(`<img src="${imgPath}" />`));
        });
      }
    });

    // updates the UI with the assorted Count
    const $counterElement = $('.assorted-container span.current-count');
    $counterElement.text(noOfAssortedItems);
  },

  // Select single options with nested items if they are called 'Customize'.
  // This is a specific feature for Olo.
  _shouldAutoSelectAddon(addon) {
    return (
      addon.displayName() === 'Customize' &&
      this._addonSet.items.length === 1 &&
      this._hasRequiredNestedAddonSets(addon)
    );
  },

  _showHideAddonSetTap() {
    if (this.$addonList.is(':hidden')) {
      this.$addonList.slideDown();
      this.$nestedSets.slideDown();
    } else {
      this.$addonList.slideUp();
      this.$nestedSets.slideUp();
    }
  },

  _trackModQuantityUpdate(addonMap) {
    Analytics.trackEvent({
      eventName: Analytics.EventName.MenuItemQuantityUpdated,
      eventData: {
        itemName: addonMap.addon.get('posName'),
        quantity: addonMap.quantity,
      },
    });
  },

  render() {
    const self = this;
    this.$el.html('');

    if (!this._addonSet.items.length) {
      return this;
    }
    const displayName = this._addonSet.displayName();

    let hideIndividualPrices = this._options.hideIndividualPrices;

    let $header;
    let $toggleBtn;
    const showAsCollapsed =
      !!gcn.menu._addonSetById[this._addonSet.id].get('showAsCollapsed') &&
      // Collapsed buttons don't make sense in FSF
      !this._options.fullScreenSeparateFlowView;
    if (this._options.showHeader || showAsCollapsed) {
      $header = $(
        // prettier-ignore
        '<div class="header font-body">' +
          '<span class="title" role="heading" aria-level="1" tabindex="-1"></span>' +
          '<span class="max-warning"></span>' +
          '<div class="wallet-message"></div>' +
        '</div>',
      );

      if (showAsCollapsed) {
        $toggleBtn = $(`<div class="link-button"></div>`);
        $toggleBtn.onTapInScrollableAreaWithCalibration(
          'aspvLink',
          this._showHideAddonSetTap.bind(this),
        );
        this.$el.append($toggleBtn);
      } else {
        this.$el.append($header);
      }

      // Add identifiers for customizations.
      $header.attr('id', GCNAddonButtonView.IdPrefix + this._addonSet.id);
      $header.addClass(`mod-group-${this._addonSet.getSlugName()}`);

      let name = displayName;
      if (this._options.headerPrefix && this._options.headerPrefix !== `${name}: `) {
        name = `${this._options.headerPrefix}${name}`;
      }
      if (
        this._addonSet.addonsHaveTheSameNonZeroPrice() &&
        gcn.menu.settings.get('modifierPriceDisplayStyle') !==
          MenuModifierPriceDisplayStyle.ShowPriceOnAllMods
      ) {
        const addon = this._addonSet.items[0];
        const price = this._addonSet.addonPriceInSet(
          addon,
          (this._selectedPriceOptionByAddonId || [])[addon?.id],
        );
        name += ` ${GcnHtml.htmlFromPrice(price, { className: 'braces' })}`;
        hideIndividualPrices = true;
      }

      $header.find('.title').html(name);
      $header.find('.max-warning').html(this._addonSet.selectableText());
      if (this._addonSet.hasWalletText()) {
        $header.find('.wallet-message').html(this._addonSet.getWalletText());
      }

      const description = this._addonSet.displayDescription();
      if (description) {
        this.$el.append(`<div class="mod-group-description font-body">${description}</div>`);
      }
    }

    this.$addonList = $('<div class="addons"></div>');
    this.$el.append(this.$addonList);

    this.$nestedSets = $('<div class="nested-sets"></div>');
    this.$el.append(this.$nestedSets);

    if (showAsCollapsed) {
      $header.removeClass('font-body');
      $toggleBtn.append($header);
      this.$addonList.hide();
      this.$nestedSets.hide();
    }

    // Set maxSelectable to whatever value was set (or 99 if undefined)
    let maxSelectable = this._addonSet.get('maxSelectable') || 99;
    // Set to 99 in case maxSelectable is defined and above 99
    maxSelectable = Math.min(maxSelectable, 99);

    // Set maxSelectable to whatever value was set (or 0 if undefined)
    const minSelectable = this._addonSet.get('minSelectable') || 0;

    this._buttonViewByAddonId = {};

    const defaultAddonInSet = this._addonSet.items?.find((addon) => {
      return this._addonSet.addonIsSelectedByDefault(addon.id);
    });

    _.each(this._addonSet.items, (addon) => {
      const addonPrice = this._addonSet.addonPriceInSet(
        addon,
        (this._selectedPriceOptionByAddonId || [])[addon.id],
      );
      const buttonView = new GCNAddonButtonView({
        model: addon,
        displayName: this._addonSet.addonNameInSet(addon),
        addonPrice,
        ...(defaultAddonInSet &&
          gcn.menu.shouldDisplayPriceWithDefaultMods() &&
          maxSelectable === 1 &&
          minSelectable === 1 && {
            relativeAddonPrice:
              addonPrice -
              // default mod price
              this._addonSet.addonPriceInSet(
                defaultAddonInSet,
                (this._selectedPriceOptionByAddonId || [])[defaultAddonInSet.id],
              ),
          }),
        isRecommended: this._recommendedAddonById[addon.id],
        maxSelectable,
        hideIndividualPrices,
        onTapCallback: (targetButtonView) => {
          this._handleAddonButtonTapped(targetButtonView);
          this._handleAssortedSelections();
        },
        onIncrementCallback(targetButtonView) {
          const shallowValidation = true;
          const shouldValidateQuantityInRange = true;
          if (!self.isSelectionStructValid(shallowValidation, shouldValidateQuantityInRange)) {
            // we only check on increment since we don't have any issue with removing addons
            return;
          }
          // Increment the quantity in our selectedAddons map.
          const addonMap = self._selectedAddonById[targetButtonView.getAddon().id];
          addonMap.quantity += 1;
          targetButtonView.setQuantity(addonMap.quantity);
          self._trackModQuantityUpdate(addonMap);
          self._maybeUpdateErrorState();
          self._notifyOfAddonChange();
          self._maybeTriggerAddonSetSelected();
          self._handleAssortedSelections();
        },
        onDecrementCallback(targetButtonView) {
          // Decrement the quantity in our selectedAddons map.
          const addonMap = self._selectedAddonById[targetButtonView.getAddon().id];
          addonMap.quantity -= 1;
          if (addonMap.quantity === 0) {
            self._deselectAddon(targetButtonView, targetButtonView.getAddon());
          } else {
            targetButtonView.setQuantity(addonMap.quantity);
          }
          self._trackModQuantityUpdate(addonMap);
          self._maybeUpdateErrorState();
          self._notifyOfAddonChange();
          self._maybeTriggerAddonSetSelected();
          self._handleAssortedSelections();
        },
      });
      self._buttonViewByAddonId[addon.id] = buttonView;

      buttonView.setSelected(!!self._selectedAddonById[addon.id]);
      // If the addon is selected by default
      // then we should generate the nested addon set picker steps (if any).
      // Fake the button tap at render time (it will be selected because the structure
      // was set when the root modGroup picker step was created), and ignore that
      // it was previously selected.
      // The code path will create the nested steps in the dfsStep structure.
      setTimeout(() => {
        if (
          this._addonSet.addonIsSelectedByDefault(addon.id) &&
          !addon.is86d() &&
          !this._options.isEditingPrevious &&
          !this._selectedAddonById[addon.id]
        ) {
          this._handleAddonButtonTapped(this._buttonViewByAddonId[addon.id], {
            ignorePreviouslySelected: true,
            isNewlyCreated: true,
          });
        }
      }, 50);
      this.$addonList.append(buttonView.render().$el);

      if (this._shouldAutoSelectAddon(addon)) {
        // If we don't do this on a timer, it causes the nested values to
        // pop in a noticeable second later, which looks bad.
        // We also check the selection on a timer later on when we render nested addon sets.
        setTimeout(() => {
          this._selectAddon(buttonView, true /* animated */);
        }, 1);
      }
    });

    if (
      gcn.location.get('hideRequiredSingularModGroup') &&
      this._addonSet.get('maxSelectable') === 1 &&
      this._addonSet.get('minSelectable') === 1 &&
      this._addonSet.items.length === 1
    ) {
      $header?.hide();
      this.$addonList.hide();
    }

    _.each(this._selectedAddonById, (addonMap) => {
      this._showNestedAddonSets(addonMap.addon, true /* animated */);
    });

    return this;
  },
});
