import { Strings } from '@biteinc/common';
import { GroupOrderingNameType } from '@biteinc/enums';

import { BackboneEvents } from '~/app/js/backbone-events';
import { localizeStr } from '~/app/js/localization/localization';

import GcnAddonHelper from '../gcn_addon_helper';
import { GCNMenuItemCustomizeView } from './gcn_menu_item_customize_view';
import { GCNMenuItemOrderView } from './gcn_menu_item_order_view';
import { GCNView } from './gcn_view';

export const GCNMenuItemEditView = GCNMenuItemOrderView.extend({
  className: 'item-order-view editing',
  isEditView: true,

  initialize(options, ...args) {
    // Don't call super init here on GCNMenuItemOrderView, just on gcn_view
    // TODO: this sucks
    GCNView.prototype.initialize.apply(this, [options, ...args]);

    this._options = options || {};

    this._origModel = this.model;
    if (!this._options.recoEditor) {
      this.model = this.model.createCopy();
    }

    this.cancelButtonText = this._options.cancelButtonText;

    this.listenTo(this.model, 'change', this.__modelDidChange);

    this._customizeView = new GCNMenuItemCustomizeView({
      model: this.model,
      editMode: true,
      isNested: this._options.isNested,
      recoEditor: this._options.recoEditor,
      setDoneDisabled: (disabled) => {
        this.__$doneButton.toggleClass('disabled', disabled);
      },
    });

    this.listenTo(
      this._customizeView,
      BackboneEvents.GCNMenuItemCustomizeView.ComboButtonTapped,
      ($comboBuilderAddonSetPickersContainer, skipScroll) => {
        if (skipScroll) {
          return;
        }
        GcnAddonHelper.scrollToContentEl($comboBuilderAddonSetPickersContainer, this.__$contents);
      },
    );

    this.addRefreshOnLanguageChangeSubscription();
  },

  destroy(...args) {
    GCNView.prototype.destroy.apply(this, args);

    this._customizeView.destroy();
  },

  overlayWasClicked(...args) {
    if (!this._options.recoEditor) {
      GCNMenuItemOrderView.prototype.overlayWasClicked.apply(this, args);
    }
    this.trigger(BackboneEvents.GCNMenuItemOrderView.OverlayPressed, this);
  },

  __itemIsCustomized() {
    // Don't care if this item is edited or not.
    if (this._options.recoEditor) {
      return false;
    }
    return !this.model.isEqualToOrderedItem(this._origModel);
  },

  __actualContentHeight() {
    return this._customizeView.$el.height();
  },

  __renderContents() {
    this.__$topNav.show();
    this.__$bottomNav.show();
    const titleString = this._options.recoEditor ? Strings.CUSTOMIZE : Strings.CHANGE_ITEM;
    this.__$topNav.find('.title').htmlOrText(localizeStr(titleString));
    this.__$topNav.find('.button.back').remove();
    this.setDoneButtonText(localizeStr(Strings.DONE));
    this.__$checkoutButton.remove();
    this.__$contents.html(this._customizeView.render().$el);

    if (this._options.recoEditor) {
      this.$el.addClass('reco-order-view');
      this.__$contents.addClass('reco-editor');
    }
  },

  __finalizeItem() {
    if (!this.__canProceed()) {
      return;
    }

    if (!this._exiting) {
      this._exiting = true;

      // update the quantity field of the selectedPriceOption
      this.model.setQuantityOnSelectedPriceOption(this._getQuantity());
      if (!this._options.recoEditor) {
        const specialRequest = this._customizeView.getSpecialRequest();
        if (specialRequest === '') {
          this.model.unset('specialRequest');
        } else if (specialRequest !== this._origModel.get('specialRequest')) {
          this.model.set('specialRequest', specialRequest);
        }

        const guest = this._customizeView.getGroupAssignee();
        if (guest) {
          if (gcn.menu.settings.get('groupOrderingNameType') === GroupOrderingNameType.Name) {
            this.model.set('recipientName', guest);
          } else {
            this.model.set('recipientNumber', guest);
          }
        } else {
          if (gcn.menu.settings.get('groupOrderingNameType') === GroupOrderingNameType.Name) {
            this.model.unset('recipientName');
          } else {
            this.model.unset('recipientNumber');
          }
        }

        // TODO: Consider moving this UI stuff out and let the owner of this
        // view control what happens.
        gcn.orderManager.replaceInOrder(this._origModel, this.model);
      }

      this.trigger(BackboneEvents.GCNMenuItemOrderView.DonePressed, this);
    }
  },
});
