import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { close } from 'ionicons/icons';
import React from 'react';

import { Log, Strings } from '@biteinc/common';
import {
  FormLabelPosition,
  LocalizationHelper,
  ModalService,
  SchemaType,
} from '@biteinc/core-react';
import { CustomerIdentifier } from '@biteinc/enums';

import { localizeStr } from '~/app/js/localization/localization';

import type GcnLocation from '../app/js/models/gcn_location';
import GenericForm from '../components/form';
import { GcnCustomerIdentifierHelper } from '../helpers';
import { useStore } from '../stores';
import type { CustomerIdentifierData, CustomerIdentifierState, KioskMenuSettings } from '../types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
class TypedGenericForm extends GenericForm<any> {}

interface CustomerIdentifierCollectionOptions {
  guestData: CustomerIdentifierState | null;
  onSubmit: (data: CustomerIdentifierData) => void;
  customerIdentifierOptions: GcnLocation.CustomerIdentifierOption[];
  init?: boolean;
}

module CustomerIdentifierService {
  export function flattenDataForValidation(data: CustomerIdentifierState): CustomerIdentifierData {
    const { vehicleDescription, ...guestInfo } = data;
    return {
      ...guestInfo,
      ...vehicleDescription,
    };
  }

  export function getCustomerIdentifierState(): Omit<
    CustomerIdentifierState,
    'validCustomerIdentifiers'
  > {
    return {
      name: gcn.orderManager.getGuestName(),
      email: gcn.orderManager.getGuestEmail(),
      phone: gcn.orderManager.getGuestPhoneNumber() ?? undefined,
      vehicleDescription: gcn.orderManager.getGuestVehicleDescription() ?? undefined,
    };
  }

  function hasCustomerIdentifier(
    customerIdentifierOptions: GcnLocation.CustomerIdentifierOption[],
    customerIdentifier: CustomerIdentifier,
  ): boolean {
    return customerIdentifierOptions.some(
      (option) => option.customerIdentifier === customerIdentifier,
    );
  }

  export function setCustomerIdentifierState(data: CustomerIdentifierData, init?: boolean): void {
    const { name, email, phone, make, model, color, guestConsentedToMarketing } = data;
    const customerIdentifierOptions = gcn.location.getCustomerIdentifierOptions(
      gcn.orderManager.getFulfillmentMethod()!,
    );
    if (customerIdentifierOptions) {
      if (name && hasCustomerIdentifier(customerIdentifierOptions, CustomerIdentifier.GuestName)) {
        gcn.orderManager.setGuestName(name);
      }
      if (
        phone &&
        hasCustomerIdentifier(customerIdentifierOptions, CustomerIdentifier.PhoneNumber)
      ) {
        gcn.orderManager.setGuestPhoneNumber(phone);
      }
      if (
        make &&
        model &&
        color &&
        hasCustomerIdentifier(customerIdentifierOptions, CustomerIdentifier.VehicleDescription)
      ) {
        gcn.orderManager.setGuestVehicleDescription({ make, model, color });
      }
    }
    // Email is ALWAYS collected at a minimum
    if (email) {
      gcn.orderManager.setGuestEmail(email);
    }
    if (typeof guestConsentedToMarketing === 'boolean') {
      gcn.orderManager.setGuestConsentedToMarketing(guestConsentedToMarketing);
    }

    useStore
      .getState()
      .checkout.onCustomerIdentifiersUpdated(
        CustomerIdentifierService.getCustomerIdentifierState(),
      );

    if (!init) {
      gcn.orderManager.orderRequiresUpdate();
    }
  }

  export function collectCustomerIdentifiers(
    settings: KioskMenuSettings,
    options: CustomerIdentifierCollectionOptions,
  ): void {
    Log.debug('Collect customer identifiers');
    const { vehicleDescription, ...guestInfo } = options.guestData || {};
    const hide = ModalService.showCustomModal({
      cssClass: 'customer-identifiers-modal',
      children: (
        <>
          <IonHeader>
            <IonToolbar>
              {!options.init && (
                <IonButtons slot="start">
                  <IonButton
                    color="light"
                    onClick={() => {
                      hide();
                    }}
                  >
                    <IonIcon
                      slot="icon-only"
                      icon={close}
                    />
                  </IonButton>
                </IonButtons>
              )}
              <IonTitle className="customer-identifiers-title">
                {localizeStr(Strings.GUEST_INFO)}
              </IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <TypedGenericForm
              lang={useStore.getState().config.language}
              customStrings={useStore.getState().bridge.menu.settings.customStrings}
              schema={{
                ...GcnCustomerIdentifierHelper.getCustomerIdentifierSchemasMergedIntoSingleSchemaWithEmail(
                  options.customerIdentifierOptions,
                ),
                ...(settings.marketingConsentCheckbox && {
                  guestConsentedToMarketing: {
                    type: SchemaType.BOOLEAN,
                    wrapperClassName: 'marketing-consent-checkbox-wrapper',
                    inputClassName: 'marketing-consent-checkbox-input',
                    label: {
                      text: LocalizationHelper.closure(Strings.MARKETING_CONSENT),
                      position: FormLabelPosition.STACKED,
                      className: 'marketing-consent-checkbox-label',
                    },
                  },
                }),
              }}
              data={{
                ...guestInfo,
                ...(vehicleDescription && vehicleDescription),
                ...(settings.marketingConsentCheckbox && {
                  guestConsentedToMarketing: gcn.orderManager.getGuestConsentedToMarketing(),
                }),
              }}
              submitButtonText={localizeStr(Strings.DONE)}
              submit={async (data: CustomerIdentifierData) => {
                hide();
                options.onSubmit(data);
              }}
            />
          </IonContent>
        </>
      ),
    });
  }
}

export default CustomerIdentifierService;
