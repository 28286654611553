import classNames from 'classnames';
import * as React from 'react';

import { LocalizationHelper } from '@biteinc/core-react';

import { ReactAriaButton } from '~/components/button';
import { LocationUtils } from '~/helpers';
import { useSettings, useStore } from '~/stores';

import { Popover } from './popover';

export function LanguagePopover(): React.ReactElement | null {
  const settings = useSettings();
  const config = useStore((state) => state.config);
  const language = useStore((state) => state.config.language);
  const setLanguage = useStore((state) => state.config.setLanguage);

  if (!LocationUtils.hasLocalization(settings, config.screenReaderIsActive)) {
    return null;
  }

  return (
    <Popover
      placement="top"
      render={({ close }) => (
        <>
          <div className="popover">
            {settings.supportedLanguages.map((languageCode) => {
              return (
                <ReactAriaButton
                  key={languageCode}
                  className={`language-choice ${language === languageCode ? 'selected' : ''}`}
                  onPress={() => {
                    if (language === languageCode) {
                      return;
                    }
                    setLanguage(languageCode);
                    close();
                  }}
                >
                  <span
                    className="language-choice-pill"
                    data-language={languageCode}
                  >
                    {/** Only show the language in the pill without the region */}
                    {languageCode.split('-')[0].trim()}
                  </span>
                  {LocalizationHelper.localizeEnum.LanguageCode(languageCode)}
                </ReactAriaButton>
              );
            })}
          </div>
        </>
      )}
    >
      <ReactAriaButton
        id="click-trigger"
        className="language-popover-trigger tw-p-1.5"
      >
        {settings.supportedLanguages.map((lang) => (
          <div
            key={lang}
            role="img"
            aria-label={LocalizationHelper.localizeEnum.LanguageCode(language)}
            // we load all the images to avoid flickering when the user changes the language
            // hide the images that are not the current language
            className={classNames('language-popover-icon tw-w-9 tw-h-9 tw-bg-contain', {
              hidden: lang !== language,
            })}
            data-language={lang}
          />
        ))}
      </ReactAriaButton>
    </Popover>
  );
}
