// This file can be deleted once we implement treeshaking and are able to import the helpers module
import async from 'async';

export interface NodeCallback<T> {
  (err?: Error | null, value?: T): void;
}
export interface FunctionExpectingCallback<T> {
  (cb: NodeCallback<T>): void;
}

export async function sleep(millis: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, Math.max(millis, 0));
  });
}

export function wrapInPromise<T>(
  functionExpectingCallback: FunctionExpectingCallback<T>,
): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    functionExpectingCallback((err, value: any) => {
      return err ? reject(err) : resolve(value);
    });
  });
}

export function asCallback<T>(promise: Promise<T>, callback: NodeCallback<T>): void {
  promise.then(
    (result) => {
      async.setImmediate(() => {
        callback(null, result);
      });
    },
    (err) => {
      async.setImmediate(() => {
        callback(err);
      });
    },
  );
}
