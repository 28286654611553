import { Strings } from '@biteinc/common';

import { str } from '../localization/localization';
import GcnView from './gcn_view_ts';

export default class GcnBagOptInView extends GcnView {
  constructor(options: { model: any; onOptIn: () => void; onOptOut: () => void }) {
    super(options);

    this.onOptIn = options.onOptIn;

    this.onOptOut = options.onOptOut;
  }

  onOptIn(): void {
    if (this.onOptIn) {
      this.onOptIn();
    }
  }

  onOptOut(): void {
    if (this.onOptOut) {
      this.onOptOut();
    }
  }

  render(): this {
    const imageUrl = gcn.menu.getToGoBagOptInImage()?.url;

    // prettier-ignore
    const html = `<div class="bag-opt-in-view">` +
        `<div class="header">${str(Strings.BAG_OPT_IN_PROMPT)}</div>` +
        (imageUrl ? `<div class="image" style="background-image: url('${imageUrl}')"></div>` : '') +
        `<div class="button-container">` +
          `<div role="button" id="opt-out" class="button opt-out">${str(Strings.BAG_OPT_OUT)}</div>` +
          `<div role="button" id="opt-in" class="button opt-in">${str(Strings.BAG_OPT_IN)}</div>` +
        `</div>` +
      `</div>`;

    this.$el.html(html);

    this.$('#opt-in').on('click', this.onOptIn);

    this.$('#opt-out').on('click', this.onOptOut);

    return this;
  }
}
