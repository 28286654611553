import qs from 'qs';
import _ from 'underscore';

import type GcnLocation from './models/gcn_location';

export default class GcnHelper {
  static deepClone(obj: any): any {
    const newObj = _.clone(obj);
    _.each(_.keys(newObj), (key) => {
      const value = newObj[key];
      if (_.isArray(value)) {
        newObj[key] = _.map(value, _.clone);
      } else if (_.isObject(value)) {
        newObj[key] = GcnHelper.deepClone(value);
      }
    });
    return newObj;
  }

  static stringFromTotal(price: number): string {
    return (price / 100.0).toFixed(2);
  }

  // Returns a validation object with the value being a number representation of
  // the price in cents.
  // NOTE: taken from math_helper
  static priceFromString(string: string, canBeNegative: boolean): any {
    const minPrice = canBeNegative ? -100000 : 0;
    let priceInDollars = NaN;

    const parts = string.split('.');
    if (parts.length > 2) {
      return { error: 'more than one period' };
    }

    if (parts.length > 1) {
      priceInDollars = parseFloat(string);
      if (priceInDollars >= minPrice && priceInDollars < 100000) {
        const decimalPlaces = parts[1].length;
        if (decimalPlaces > 2) {
          parts[1] = parts[1].substr(0, parts[1].length - (decimalPlaces - 2));
        } else if (decimalPlaces < 2) {
          for (let i = 0; i < 2 - decimalPlaces; i++) {
            parts[1] += '0';
          }
        }
        return { value: parseInt(parts.join(''), 10) };
      }
    }

    priceInDollars = parseInt(string, 10);
    if (priceInDollars >= minPrice && priceInDollars < 100000) {
      return { value: priceInDollars * 100 };
    }

    return { error: 'invalid price' };
  }

  // Makes an HTTP GET query string, including the leading '?'. Input is an object where the keys
  // are parameter names and the values are arguments.
  static queryString(paramsAndArgs: any): string {
    if (!paramsAndArgs) {
      return '';
    }
    return `?${qs.stringify(paramsAndArgs)}`;
  }

  public static addGlossaryTermClickers(
    $glossaryItems: JQuery<HTMLElement>,
    cb: (
      glossaryWord: {
        name: string;
      },
      left: number,
      right: number,
      width: number,
      height: number,
    ) => {},
  ): void {
    $glossaryItems.each((idx: number, item: HTMLElement) => {
      const $glossaryItem = $(item);
      $glossaryItem.onButtonTapOrHold('glossary', () => {
        const text = $glossaryItem.attr('term');
        if (text) {
          text.toLowerCase();
          // Find the corresponding glossary word.
          const terms = gcn.menu.get('dictionaryWords') as { name: string }[];
          const glossaryWord = _.find(terms, (term) => {
            return term.name.toLowerCase() === text;
          });
          const offset = $glossaryItem.offset();
          const width = $glossaryItem.width();
          const height = $glossaryItem.height();
          if (glossaryWord && offset && width && height) {
            cb(glossaryWord, offset.left, offset.top, width, height);
          }
        }
        return false;
      });
    });
  }

  // Show warning toast for price options with min-max rules and warnings
  public static showWarningToastIfNotAnimated(
    $warningToast: any,
    errorMessage: string,
    toastToCss: any,
    toastAfterCss: any,
  ): void {
    if (!$warningToast.is(':animated') && $warningToast.css('opacity') === '0') {
      $warningToast.htmlOrText(errorMessage);
      $warningToast.animate(toastToCss, 'slow');
      setTimeout(() => {
        $warningToast.animate(toastAfterCss, 'fast');
      }, 5000);
    }
    if (gcn.screenReaderIsActive) {
      gcn.showNativeToast(errorMessage);
    }
  }

  public static getReceiptBase64Images(location: GcnLocation): GcnLocation.ReceiptBase64ImageData {
    const receiptBase64Images: GcnLocation.ReceiptBase64ImageData = {};
    if (location.hasArr('receiptHeaderImage')) {
      const base64ImageData = gcn.getImageFromCache(location.get('receiptHeaderImage')![0].url);
      if (base64ImageData) {
        receiptBase64Images.receiptHeaderBase64ImageData = base64ImageData;
      }
    }
    if (location.hasArr('receiptFooterImage')) {
      const base64ImageData = gcn.getImageFromCache(location.get('receiptFooterImage')![0].url);
      if (base64ImageData) {
        receiptBase64Images.receiptFooterBase64ImageData = base64ImageData;
      }
    }
    return receiptBase64Images;
  }
}
