import classNames from 'classnames';
import * as React from 'react';

export function Skeleton({ className, ...props }: React.ComponentProps<'div'>): JSX.Element {
  return (
    <div
      className={classNames('tw-animate-pulse tw-bg-gray-300', className)}
      {...props}
    />
  );
}
