import _ from 'underscore';

import { Strings } from '@biteinc/common';
import { StringHelper } from '@biteinc/core-react';

import { localizeStr, str } from '~/app/js/localization/localization';

import { BackboneEvents } from '../backbone-events';
import GcnHelper from '../gcn_helper';
import GcnPrintJob from '../gcn_print_job';
import { GCNOrder } from '../models/gcn_order';
import { GCNAlertView } from './gcn_alert_view';
import { GCNOrderCellView } from './gcn_order_cell_view';
import { GCNView } from './gcn_view';

const CRITICAL_PERIOD = 'refund-flow';

export const GCNAdminView = GCNView.extend({
  className: 'refunder-view',
  template: _.template(
    // prettier-ignore
    '<div class="header refunder"></div>' +
    '<div class="search-controls">' +
      '<input class="form-control" id="search-text-box" autocomplete="off"/>' +
      '<div id="search-button" class="search-button"></div>' +
    '</div>' +
    '<div class="body">' +
      '<div class="text"></div>' +
      '<div class="receipt-image"></div>' +
    '</div>' +
    '<div class="delay-message"></div>',
  ),

  initialize(options, ...args) {
    GCNView.prototype.initialize.apply(this, [options, ...args]);

    this._accessCode = options.accessCode;
    this._selectedOrder = null;
    this._orders = [];
    this._cellViewByOrderId = {};

    const E = BackboneEvents.GCNMenuAppView;
    this.listenTo(gcn, E.RefundDidFail, this._refundDidFail);
    this.listenTo(gcn, E.RefundDidStartPrintingReceipt, this._refundDidStartPrintingReceipt);
    this.listenTo(gcn, E.RefundDidFailToPrintReceipt, this._refundDidFailToPrintReceipt);
    this.listenTo(gcn, E.RefundDidFinish, this._refundDidFinish);
  },

  overlayWasClicked() {
    const alertText = localizeStr(Strings.CONFIRM_QUIT, [], function (string) {
      return string.split('\n').join('<br />');
    });
    const confirmView = new GCNAlertView({
      text: alertText,
      okCallback() {
        gcn.goHome();
      },
      cancelCallback() {
        gcn.menuView.dismissModalPopup();
      },
    });
    gcn.menuView.showModalPopup(confirmView);
  },

  _refundDidFail(eventTarget, error) {
    if (error.domain === 'CardEaseMobile' && error.code === 5044) {
      this._selectedOrder.markRefunded(this._selectedOrder.currentRefund);
      this._finishRefundFlowWithError();
    } else {
      this._finishRefundFlowWithError(`Error performing refund: ${error.message}`);
    }
  },

  _refundDidStartPrintingReceipt() {
    this._selectedOrder.markRefunded(this._selectedOrder.currentRefund);
    this.$delayMessage.css('opacity', '1.0');
    gcn.menuView.showSpinner(localizeStr(Strings.PRINTING_RECEIPT));
  },

  _refundDidFailToPrintReceipt() {
    this._finishRefundFlowWithError(str(Strings.PRINT_RECEIPT_ERROR));
  },

  _refundDidFinish() {
    this._finishRefundFlowWithError();
  },

  _renderOrders() {
    const self = this;

    this.$body.html('');

    if (!this._orders.length) {
      this.$body.htmlOrText(localizeStr(Strings.NO_ORDERS_MATCH));
      return;
    }

    this._cellViewByOrderId = {};
    _.each(this._orders, (order) => {
      const orderCellView = new GCNOrderCellView({ model: order });
      self._cellViewByOrderId[order.id] = orderCellView;
      self.$body.append(orderCellView.render().$el);

      self.listenTo(
        orderCellView,
        BackboneEvents.GCNOrderCellView.ReceiptButtonWasTapped,
        self._receiptButtonWasTapped,
      );
      self.listenTo(
        orderCellView,
        BackboneEvents.GCNOrderCellView.RefundButtonWasTapped,
        self._refundButtonWasTapped,
      );
      self.listenTo(
        orderCellView,
        BackboneEvents.GCNOrderCellView.CancelOrderButtonWasTapped,
        self._cancelOrderWasTapped,
      );
    });
  },

  _receiptButtonWasTapped(orderViewCell) {
    const order = orderViewCell.model;
    orderViewCell.setError(null);

    gcn.menuView.showSpinner(localizeStr(Strings.PRINTING_RECEIPT));

    const receiptBase64Images = GcnHelper.getReceiptBase64Images(gcn.location);

    const printPayload = {
      printJobs: [
        GcnPrintJob.receiptPrintJob(gcn.location, gcn.menu, order, {
          printedFromAdmin: true,
          ...receiptBase64Images,
        }),
      ],
    };

    gcn.requestToPrintReceipt(printPayload, (response) => {
      gcn.menuView.dismissSpinner();
      if (response.error) {
        orderViewCell.setError(str(Strings.PRINT_RECEIPT_ERROR));
      }
    });
  },

  _cancelOrderWasTapped(orderViewCell) {
    const order = orderViewCell.model;
    this._selectedOrder = order;
    this.blur();
    gcn.menuView.showSpinner('Cancelling Order...');
    gcn.notifyUserDidEnterCriticalPeriod(CRITICAL_PERIOD);
    gcn.maitred.cancelOrder(order.id, this._accessCode, (err, data) => {
      if (err) {
        const message = err.message || "Couldn't cancel your order. Please try again.";
        this._cellViewByOrderId[this._selectedOrder.id].setError(
          `Error requesting cancellation: ${message}`,
        );
        gcn.notifyUserDidLeaveCriticalPeriod(CRITICAL_PERIOD);
        gcn.menuView.dismissSpinner();
        return;
      }

      order.set(data.order);

      gcn.notifyUserDidLeaveCriticalPeriod(CRITICAL_PERIOD);
      gcn.menuView.dismissSpinner();

      gcn.menuView.showSimpleAlert('Please remember to refund any transactions.');
    });
  },

  _refundButtonWasTapped(orderViewCell, transaction, refundAmount) {
    const order = orderViewCell.model;
    this._selectedOrder = order;
    const self = this;
    this.blur();
    gcn.menuView.showSpinner('Processing Refund...');
    const accessCode = self._accessCode;

    gcn.notifyUserDidEnterCriticalPeriod(CRITICAL_PERIOD);

    function refundCallback(err, data) {
      if (err) {
        const message = err.message || "Couldn't refund your order. Please try again.";
        self._finishRefundFlowWithError(`Error requesting refund: ${message}`);
        return;
      }

      order.set(data.order);
      gcn.menuView.showSpinner(localizeStr(Strings.PRINTING_RECEIPT));

      const receiptBase64Images = GcnHelper.getReceiptBase64Images(gcn.location);

      const printPayload = {
        printJobs: [
          GcnPrintJob.receiptPrintJob(gcn.location, gcn.menu, order, {
            printedFromAdmin: true,
            extraFooterLines: ['Customer Copy'],
            ...receiptBase64Images,
          }),
          GcnPrintJob.receiptPrintJob(gcn.location, gcn.menu, order, {
            printedFromAdmin: true,
            extraFooterLines: ['Merchant Copy'],
            ...receiptBase64Images,
          }),
        ],
      };
      gcn.requestToPrintReceipt(printPayload, (response) => {
        gcn.menuView.dismissSpinner();
        if (response.error) {
          orderViewCell.setError(str(Strings.PRINT_RECEIPT_ERROR));
        }
      });

      self._finishRefundFlowWithError(null);
    }

    if (gcn.location.useOrdersApiV2()) {
      const unrefundedAmount = order.getUnrefundedAmountForTransaction(transaction);
      const expectedRemainingAmount = unrefundedAmount;
      const clientId = StringHelper.newMongoId();
      gcn.maitred.refundRequest(
        transaction.id,
        refundAmount,
        expectedRemainingAmount,
        clientId,
        accessCode,
        refundCallback,
      );
    } else {
      gcn.maitred.refundTransaction(transaction, refundAmount, accessCode, refundCallback);
    }
  },

  _updateSearchButtonState() {
    const searchTerm = this.$searchTextBox.val().trim();
    this.$searchButton.toggleClass('disabled', searchTerm.length === 0);
  },

  _doSearch() {
    this.blur();
    this._orders = [];

    const self = this;
    const searchTerm = this.$searchTextBox.val().trim();
    if (!searchTerm.length) {
      return;
    }

    gcn.menuView.showSpinner('Searching...');
    gcn.maitred.searchForOrders(searchTerm, this._accessCode, (err, data) => {
      gcn.menuView.dismissSpinner();
      if (err) {
        self.$body.htmlOrText(localizeStr(Strings.NO_ORDERS_MATCH));
      } else {
        self._orders = _.map(data.gazeboOrders, (orderJSON) => {
          return new GCNOrder(orderJSON);
        });
        self._renderOrders();
      }
    });
  },

  _finishRefundFlowWithError(error) {
    if (error) {
      this._cellViewByOrderId[this._selectedOrder.id].setError(error);
    }

    gcn.notifyUserDidLeaveCriticalPeriod(CRITICAL_PERIOD);
    gcn.menuView.dismissSpinner();
  },

  blur() {
    this.$body.toggleClass('scroll', true);
    this.$searchTextBox[0].blur();
    this._updateSearchButtonState();
    gcn.notifyUserDidFocusOutOfTextField();
  },

  destroy(...args) {
    GCNView.prototype.destroy.apply(this, args);
    this.blur();
  },

  render() {
    const self = this;
    this.$el.html(this.template());
    this.$body = this.$('.body');

    this.$searchTextBox = this.$('#search-text-box');
    this.$searchButton = this.$('#search-button');
    this.$delayMessage = this.$('.delay-message');

    // Setup search handlers.
    self._updateSearchButtonState();
    this.$searchTextBox.keyup((e) => {
      // Handle the enter key.
      self._updateSearchButtonState();
      if (e.keyCode === 13) {
        self._doSearch();
      }
      return false;
    });
    this.$searchTextBox.on('blur', () => {
      self.blur();
    });
    this.$searchTextBox.on('focus', () => {
      self.$body.toggleClass('scroll', false);
    });
    this.$searchButton.onButtonTapOrHold('adminSearch', () => {
      self._doSearch();
      return false;
    });

    this.$('.header.refunder').htmlOrText(localizeStr(Strings.REFUNDER_TITLE));
    this.$searchTextBox.attr('placeholder', localizeStr(Strings.REFUNDER_SEARCH_PLACEHOLDER));
    this.$searchButton.htmlOrText(localizeStr(Strings.SEARCH));
    this.$body.find('.text').htmlOrText(localizeStr(Strings.REFUNDER_INSTRUCTIONS));
    this.$delayMessage.htmlOrText(localizeStr(Strings.REFUNDER_DELAY_MESSAGE));

    return this;
  },
});
