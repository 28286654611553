import _ from 'underscore';

import { LocalizationHelper, StringHelper } from '@biteinc/core-react';
import {
  CardEntryMethod,
  CardSchemeId,
  Currency,
  Gateway,
  TransactionResult,
  TransactionState,
  TransactionType,
  TransactionUserAction,
} from '@biteinc/enums';

import { GcnPaymentHelper } from '../../../helpers';
import { str } from '../localization/localization';
import { GCNModel } from './gcn_model';

export const GCNTransaction = GCNModel.extend({
  getLastFour() {
    if (Gateway.None === this.get('gateway')) {
      return '1234';
    }
    return this.get('last4') || '';
  },

  getCardEntryMethodString() {
    return GcnPaymentHelper.localizableStringFromCardEntryMethod(this.get('cardEntryMethod'));
  },

  getRefundableAmount() {
    if (this.hasArr('tenders')) {
      return _.reduce(
        this.get('tenders'),
        (total, tender) => {
          return total + tender.amount;
        },
        0,
      );
    }
    // If amountAuthorized is undefined, then most likely we have a bug with the integration.
    // If it's actually 0... how could a transaction be successful and be authorized for $0?
    return (this.get('amountAuthorized') || 0) > 0
      ? this.get('amountAuthorized')
      : this.get('amount');
  },

  getNonDiscountTenders() {
    let cardSchemeName = this.hasStr('customCardSchemeName')
      ? this.get('customCardSchemeName')
      : null;
    if (this.hasArr('tenders')) {
      const nonDiscountTenders = [];
      _.each(this.get('tenders'), (tender) => {
        if (!tender.isDiscount) {
          nonDiscountTenders.push({
            amount: tender.amount,
            cardSchemeName:
              cardSchemeName ||
              str(LocalizationHelper.localizeEnum.CardSchemeId(tender.cardSchemeId)),
          });
        }
      });
      return nonDiscountTenders;
    }

    if (Gateway.None === this.get('gateway')) {
      cardSchemeName = 'DemoPayCard';
    }

    return [
      {
        amount: this.get('amount'),
        cardSchemeName:
          cardSchemeName ||
          str(LocalizationHelper.localizeEnum.CardSchemeId(this.get('cardSchemeId'))),
      },
    ];
  },

  isSale() {
    return this.get('type') === TransactionType.Sale;
  },

  isRefund() {
    return this.get('type') === TransactionType.Refund;
  },

  // TODO: this is copied from transaction.js
  // Use a common helper for this
  isSuccessful() {
    if (TransactionResult.Approved !== this.get('result')) {
      return false;
    }

    const stateValues = [TransactionState.Committed];
    if (GcnPaymentHelper.gatewayUsesAuthAndCapture(this.get('gateway'))) {
      stateValues.push(TransactionState.Uncommitted);
    }
    if (!_.contains(stateValues, this.get('state'))) {
      return false;
    }

    if (
      _.contains(
        [TransactionUserAction.Cancelled, TransactionUserAction.Abandoned],
        this.get('userAction'),
      )
    ) {
      return false;
    }
    return true;
  },

  hasKnownCardEntryMethod() {
    return this.has('cardEntryMethod') && this.get('cardEntryMethod') !== CardEntryMethod.Unknown;
  },
});

GCNTransaction.getSuccessfulSaleTransactionsWithoutRefunds = (
  successfulTransactions, // needs to include sale and refund transactions
) => {
  const refundedTransactionIdMap = {};
  successfulTransactions.forEach((transaction) => {
    if (!transaction.isRefund()) {
      return;
    }
    const refundedTransactionId = transaction.get('originalTransactionId');
    refundedTransactionIdMap[refundedTransactionId] = true;
  });

  return successfulTransactions.filter((transaction) => {
    if (!transaction.isSale()) {
      return false;
    }
    return !refundedTransactionIdMap[transaction.id];
  });
};

/**
 * @param {number} amount
 * @param {string} clientOrderId
 * @param {PaymentsApiVersion} paymentsApiVersion
 * @param {string} orgId
 * @param {string} locationId
 * @param {string | undefined} kioskId
 * @returns
 */
GCNTransaction.demoPayTransactionWithAmount = (
  amount,
  clientOrderId,
  paymentsApiVersion,
  orgId,
  locationId,
  kioskId,
) => {
  const maskPan = '123456******9876';
  const now = Date.now();
  const clientId = StringHelper.newMongoId();
  const authCode = `${Math.floor(Math.random() * 899999) + 100000}`;

  return {
    amount,
    authCode,
    clientId,
    createdAt: now,
    finishedAt: now,
    gateway: Gateway.None,
    gatewaySoftwareVersion: 'DEMO_PAY',
    last4: maskPan.slice(-4),
    locationId,
    maskPan,
    orgId,
    cardSchemeId: CardSchemeId.MasterCard,
    result: TransactionResult.Approved,
    startedAt: now,
    state: TransactionState.Committed,
    transactionTime: now,
    type: TransactionType.Sale,
    updatedAt: now,
    paymentsApiVersion,
    kioskId,
    userAction: TransactionUserAction.None,
    amountAuthorized: amount,
    currencyCode: Currency.USD,
    clientOrderId,
  };
};
