import $ from 'jquery';
import _ from 'underscore';

import { Strings } from '@biteinc/common';

import { BackboneEvents } from '~/app/js/backbone-events';

import GcnHtml from '../gcn_html';
import { localizeStr } from '../localization/localization';
import ScreenReaderHelper from '../screen_reader_helper';
import { GCNQuantitySelectionView } from './gcn_quantity_selection_view';
import { GCNView } from './gcn_view';

// There are three states that this button can be in.
// 1 - Deselected - WHich represents having 0 of the addon.
// 2 - Selected - Which represents having 1 of the addon.
// 3 - Quantity Set to N - Which represents having N of the addon, where N can be 1.
export const GCNAddonButtonView = GCNView.extend({
  // This class name does not conform to the usual ...-view format for backwards compatibility
  // with the pre-class implementation.
  className: 'addon-item font-body',

  initialize(options, ...args) {
    GCNView.prototype.initialize.apply(this, [options, ...args]);

    this.model = options.model;
    this._displayName = options.displayName;
    this._addonPrice = options.addonPrice;
    this._relativeAddonPrice = 'relativeAddonPrice' in options ? options.relativeAddonPrice : null;
    this._maxSelectable = options.maxSelectable;
    this._hideIndividualPrices = options.hideIndividualPrices;
    this._onTapCallback = options.onTapCallback;
    this._onIncrementCallback = options.onIncrementCallback;
    this._onDecrementCallback = options.onDecrementCallback;
    this.isDisabled = options.model.is86d();
    this.isRecommended = options.isRecommended;
  },

  setSelected(selected) {
    this.$el.toggleClass('selected', selected);
    this.$contentEl?.attr(
      'aria-label',
      ScreenReaderHelper.prepareAriaLabel(`${this.$details.text()}${selected ? ', selected' : ''}`),
    );
    if (!selected) {
      if (this._quantitySelectorView) {
        this._quantitySelectorView.setValue(0);
        this._quantitySelectorView.$el.hide();
        this.$imageEl.show();
      }
      if (this.$modOptionsDisplay) {
        this.$modOptionsDisplay.hide();
        this.trigger(BackboneEvents.GCNAddonSetPickerView.AddonOptionCodeSelectionUnset);

        if (this.$optionButtonById) {
          this.$el.toggleClass('with-display-options', false);
        }
      }
    }
  },

  setQuantity(quantity) {
    if (this.isDisabled) {
      return;
    }
    this.$el.toggleClass('selected', true);

    // Swap in the quantity selector.
    this.$imageEl.hide();
    this._quantitySelectorView.$el.show();
    this._quantitySelectorView.setValue(quantity);
  },

  getAddon() {
    return this.model;
  },

  renderOptions(priceOptions, currentSelectedOptionId) {
    if (this.isDisabled) {
      return;
    }

    if (!this.$modOptionsDisplay) {
      this.$modOptionsDisplay = $(`<div class="display-options"></div>`);
    } else {
      this.$modOptionsDisplay.html('');
      this.$modOptionsDisplay.show();
    }

    this.$optionButtonById = {};

    let priceOptionWasAlreadySelected = false;
    let defaultPriceOption = null;
    priceOptions.forEach((priceOption) => {
      let name = priceOption.displayNameHtml();
      if (name && priceOption.get('price')) {
        name += ` ($${GcnHtml.stringFromPrice(priceOption.get('price'))})`;
      } else if (priceOption.get('price')) {
        name = `$${GcnHtml.stringFromPrice(priceOption.get('price'))}`;
      } else if (!name) {
        name = this._displayName;
      }
      const button = $(`<button>${name}</button>`);

      // Assumes only one price option can be selected at a time.
      if (currentSelectedOptionId === priceOption.id) {
        button.toggleClass('selected', true);
        priceOptionWasAlreadySelected = true;
      }

      button.onButtonTapOrHold(
        'abvNested',
        (event) => {
          // Prevent the tap from bubbling up to the addon button element
          event.stopPropagation();
          const currentlySelected = button.hasClass('selected');

          // Toggle the selection state for any selected options
          Object.entries(this.$optionButtonById).forEach(([id]) => {
            this.$optionButtonById[id].toggleClass('selected', false);
          });

          if (!currentlySelected) {
            button.toggleClass('selected');
          }

          this.trigger(
            BackboneEvents.GCNAddonSetPickerView.AddonOptionSelectionComplete,
            priceOption,
            this.model,
          );
        },
        { allowDoubleTap: true },
      );

      if (priceOption.isDefault()) {
        defaultPriceOption = priceOption;
      }

      this.$modOptionsDisplay.append(button);
      this.$optionButtonById[priceOption.id] = button;
    });

    if (!priceOptionWasAlreadySelected && defaultPriceOption) {
      this.$optionButtonById[defaultPriceOption.id].toggleClass('selected', true);

      this.trigger(
        BackboneEvents.GCNAddonSetPickerView.AddonOptionSelectionComplete,
        defaultPriceOption,
        this.model,
      );
    }

    this.$el.append(this.$modOptionsDisplay);
    this.$el.toggleClass('with-display-options', true);
  },

  renderCodes(addonSet, currentSelectedAddonId) {
    if (this.isDisabled) {
      return;
    }

    this.$optionButtonByAddonId = {};

    if (!this.$modOptionsDisplay) {
      this.$modOptionsDisplay = $(`<div class="display-options"></div>`);
    } else {
      this.$modOptionsDisplay.html('');
      this.$modOptionsDisplay.show();
    }

    const oneAddonHasNonZeroPrice = addonSet.items.some((addon) => {
      return addonSet.addonPriceInSet(addon) !== 0;
    });
    addonSet.items.forEach((addon) => {
      const addonName = addonSet.addonNameInSet(addon);
      const priceHtml = oneAddonHasNonZeroPrice
        ? GcnHtml.htmlFromPrice(addonSet.addonPriceInSet(addon), { className: 'braces' })
        : '';
      const button = $(`<button>${addonName} ${priceHtml}</button>`);

      this.$optionButtonByAddonId[addon.id] = button;

      // Assumes only one modifier code can be selected at a time.
      if (currentSelectedAddonId === addon.id) {
        button.toggleClass('selected', true);
        button.html('✓ ' + button.html());
      } else if (
        addonSet.addonIsSelectedByDefault(addon.id) &&
        !currentSelectedAddonId &&
        !addon.is86d()
      ) {
        button.toggleClass('selected', true);
        button.html('✓ ' + button.html());

        setTimeout(() => {
          this.trigger(
            BackboneEvents.GCNAddonSetPickerView.AddonOptionCodeSelectionComplete,
            addonSet,
            addon,
            this.model,
            true,
          );
        }, 50);
      }

      button.onButtonTapOrHold(
        'abvNested',
        (event) => {
          // Prevent the tap from bubbling up to the addon button element
          event.stopPropagation();
          const currentlySelected = button.hasClass('selected');

          // Toggle the selection state for any selected options
          Object.entries(this.$optionButtonByAddonId).forEach(([addonId]) => {
            const currentButton = this.$optionButtonByAddonId[addonId];
            currentButton.toggleClass('selected', false);
            currentButton.html(currentButton.html().replace('✓ ', ''));
          });

          const weAreSelecting = !currentlySelected;
          if (weAreSelecting) {
            button.toggleClass('selected');
            button.html('✓ ' + button.html());
          }

          this.trigger(
            BackboneEvents.GCNAddonSetPickerView.AddonOptionCodeSelectionComplete,
            addonSet,
            addon,
            this.model,
            weAreSelecting,
          );
        },
        { allowDoubleTap: true },
      );
      this.$modOptionsDisplay.append(button);
    });
    this.$el.append($('<div class="line-break"></div>'));
    this.$el.append(this.$modOptionsDisplay);
    this.$el.toggleClass('with-display-options', true);
  },

  render() {
    this.$el.html('');

    this.$contentEl = $('<div class="content"></div>');
    this.$el.append(this.$contentEl);
    this.$imageEl = $('<div class="image" aria-hidden="true"></div>');
    const images = this.model.get('images');
    if (images && images.length) {
      gcn.requestImageByUrl(images[images.length - 1].url, (err, imgPath) => {
        this.$imageEl.css('background-image', `url(${imgPath})`);
      });
    } else {
      this.$imageEl.addClass('no-image');
    }
    this.$contentEl.append(this.$imageEl);
    if (this.isDisabled) {
      this.$imageEl.addClass('disabled');
    }

    // Optional quantity selector.
    const quantitySelectorOptions = {
      hasExternalController: true,
      upperLimit: this._maxSelectable,
      menuItemName: this._displayName,
      lowerLimit: 0,
    };

    this._quantitySelectorView = new GCNQuantitySelectionView(quantitySelectorOptions);
    const $quantitySelector = this._quantitySelectorView.render().$el;
    this.listenTo(
      this._quantitySelectorView,
      BackboneEvents.GCNQuantitySelectionView.IncrementPressed,
      () => {
        this._onIncrementCallback(this);
      },
    );
    this.listenTo(
      this._quantitySelectorView,
      BackboneEvents.GCNQuantitySelectionView.DecrementPressed,
      () => {
        this._onDecrementCallback(this);
      },
    );
    this.$contentEl.append($quantitySelector);
    $quantitySelector.hide();

    if (this.isDisabled) {
      this.$el.addClass('disabled');
    }
    const name = this._displayName;

    const $addonHeader = $('<div class="addon-header"></div>');

    let modCaloriesLabel = '';
    if (gcn.menu.settings.get('showNutritionInfo')) {
      const modCalorieRange = this.getAddon().getCalorieRange();
      const calorieSuffix = gcn.menu.settings.get('calorieSuffix') || 'Cals';
      if (modCalorieRange?.min) {
        modCaloriesLabel = ` (${GcnHtml.stringFromCalorieRange(modCalorieRange)} ${calorieSuffix})`;
      }
    }

    $addonHeader.append(
      // prettier-ignore
      '<div class="left-col">' +
        `<div class="name">${GcnHtml.htmlFromString(name)}${modCaloriesLabel}</div>` +
      '</div>',
    );
    if (!this._hideIndividualPrices && !this.isDisabled) {
      if (this._relativeAddonPrice !== null) {
        if (this._relativeAddonPrice !== 0) {
          const priceHtml = GcnHtml.htmlFromPrice(this._relativeAddonPrice, {
            prefix: this._relativeAddonPrice > 0 ? '+' : '',
            className: 'braces',
          });
          $addonHeader.append(` ${priceHtml}`);
        }
      } else if (this._addonPrice !== 0) {
        $addonHeader.append(` ${GcnHtml.htmlFromPrice(this._addonPrice, { className: 'braces' })}`);
      }
    }

    this.$details = $('<div class="addon-details" aria-hidden="true"></div>');
    this.$details.append($addonHeader);
    const descriptionHtml = this.model.displayDescriptionHtml();
    if (descriptionHtml.length) {
      this.$details.append($(`<span class="description">${descriptionHtml}</span>`));
    }
    this.$contentEl.append(this.$details);

    if (this.isDisabled) {
      this.$el.append(
        $(`<div class="out-of-stock-message">${localizeStr(Strings.OUT_OF_STOCK)}</div>`),
      );
    }

    if (this.model.badges().length) {
      const $badges = $('<div class="badges tw-space-y-0.5"></div>');
      $addonHeader.find('.left-col').append($badges);
      _.each(this.model.badges(), (badge) => {
        const $badge = $(`<div class="badge tw-flex tw-items-center tw-space-x-1"></div>`);
        const $image = $('<img class="badge-image tw-w-5 tw-h-5" />');
        $badge.append($image);
        const $text = $('<span class="badge-text tw-text-sm"></span>');
        $text.text(badge.displayNameHtml(true));
        $badge.append($text);
        $badges.append($badge);
        const url = badge.get('icons')[0].url;
        gcn.requestImageByUrl(url, (err, imgPath) => {
          $image.attr('src', imgPath);
        });
      });
    }

    this.$el.attr('id', GCNAddonButtonView.IdPrefix + this.model.id);
    this.$el.toggleClass(`addon-${this.model.getSlugName()}`, true);

    if (!this.isDisabled && !!this.isRecommended) {
      this.$el.toggleClass('promoted', !this.isDisabled);
      this.$el.prepend(`<div class="header">${localizeStr(Strings.PROMOTED_MODIFIER)}</div>`);
    }

    // Let our creator handle it.
    if (!this.isDisabled) {
      this.$contentEl.onTapInScrollableAreaWithCalibration('mbv', () => {
        this._onTapCallback(this);
      });
    }

    this._quantitySelectorView.$el.attr('aria-hidden', true);
    this.$contentEl.attr('aria-label', ScreenReaderHelper.prepareAriaLabel(this.$details.text()));
    return this;
  },
});
GCNAddonButtonView.IdPrefix = 'id-';
