import classNames from 'classnames';
import * as React from 'react';
import { useButton } from 'react-aria';
import { MdAdd } from 'react-icons/md';

import type { GcnMenuItem } from '../../../types/gcn_menu_item';
import { CartUtils } from '../cart.utils';

type RecommendedItemProps = {
  item: GcnMenuItem;
  index: number;
};

export function RecommendedItem({ index, item }: RecommendedItemProps): JSX.Element {
  const image = CartUtils.useRecommendedItemImagePath(item);
  const placeholderClass = gcn.menu.placeholderImageClass(item);
  const ref = React.useRef<HTMLDivElement>(null);
  const { buttonProps, isPressed } = useButton(
    {
      onPress: () => {
        CartUtils.addRecommendedItemToOrder(item, index);
      },
      elementType: 'div',
    },
    ref,
  );

  return (
    <div
      className="cart-view__collapsible-view__recommendations__items__item"
      key={item.id}
      ref={ref}
      data-pressed={isPressed ? '' : undefined}
      {...buttonProps}
    >
      <img
        className={classNames(
          'cart-view__collapsible-view__recommendations__items__item__picture',
          {
            [placeholderClass]: image.status === 'error',
            'loading-skeleton': image.status === 'fetching',
          },
        )}
        src={image.data}
        alt={item.displayName()}
      />
      <div className="cart-view__collapsible-view__recommendations__items__item__details">
        <div className="cart-view__collapsible-view__recommendations__items__item__details__name-price">
          <div className="cart-view__collapsible-view__recommendations__items__item__details__name-price__name">
            {item.displayName()}
          </div>

          <div className="cart-view__collapsible-view__recommendations__items__item__details__name-price__price">
            {CartUtils.formatPrice(item.attributes.priceOptions[0].price)}
          </div>
        </div>

        <span className="cart-view__collapsible-view__recommendations__items__item__details__add">
          <MdAdd className="tw-h-8 tw-w-8" />
        </span>
      </div>
    </div>
  );
}
