import async from 'async';
import $ from 'jquery';
import _ from 'underscore';

import { Log, Strings } from '@biteinc/common';
import {
  GroupOrderingNameType,
  LocationRecommendationsLevel,
  MenuItemSaleUnit,
  MenuItemSaleUnitHelper,
} from '@biteinc/enums';

import { BackboneEvents } from '~/app/js/backbone-events';
import { localizeStr } from '~/app/js/localization/localization';

import { RecommendationDisplayLocationDescription } from '../../../types/recommendation';
import GcnAddonHelper from '../gcn_addon_helper';
import GcnHelper from '../gcn_helper';
import GcnHtml from '../gcn_html';
import GcnRecoTracker from '../gcn_reco_tracker';
import { GCNRouterHelper } from '../gcn_router_helper';
import { GCNOrderedItem } from '../models/gcn_ordered_item';
import ScreenReaderHelper from '../screen_reader_helper';
import Analytics from '../utils/analytics';
import { asCallback } from '../utils/promises';
import { GCNAlertView } from './gcn_alert_view';
import { GCNGroupOrderPromptView } from './gcn_group_order_prompt_view';
import { GCNGroupRecipientGuestNameModalView } from './gcn_group_recipient_guest_name_modal_view';
import { GCNMenuItemCustomizeView } from './gcn_menu_item_customize_view';
import { GCNMenuItemDetailsView } from './gcn_menu_item_details_view';
import { GCNQuantitySelectionView } from './gcn_quantity_selection_view';
import { GCNRecommendationsMiniView } from './gcn_recommendations_mini_view';
import { GCNView } from './gcn_view';

// Controlling view for ordering a single menu item.
export const GCNMenuItemOrderView = GCNView.extend({
  className: 'item-order-view',
  template: _.template(
    // prettier-ignore
    '<div class="top-nav">' +
      '<div class="title"></div>' +
    '</div>' +
    '<div class="ordering-guests"></div>' +
    '<div class="contents"></div>' +
    '<div class="overflow-shadow"></div>' +
    '<div class="more-indicator"></div>' +
    '<div class="warning-toast"></div>' +
    '<div class="recos"></div>' +
    '<div class="bottom-nav">' +
      '<div class="price-label"></div>' +
      '<div class="buttons-right">' +
      '<div role="button" class="button done" role="button"></div>' +
      '<div role="button" class="button checkout" role="button"></div>' +
      '<div role="button" class="button cancel"></div>' +
      '</div>' +
    '</div>',
  ),

  initialize(options, ...args) {
    GCNView.prototype.initialize.apply(this, [options, ...args]);

    const self = this;

    this._options = options || {};

    this.addRefreshOnLanguageChangeSubscription();

    // See if this item was ordered before.
    const historyItem = gcn.guestManager.getOrderedItemWithId(this._options.item.id);
    if (historyItem && gcn.menu.settings.get('preselectPreviouslyOrderedMods')) {
      this._origModel = historyItem;
      this.model = historyItem.createCopy();
      if (this._options.upsellScreen) {
        this.model.setUpsellScreen(this._options.upsellScreen);
      }
      this.fromHistoryItem = true;
    }

    if (!this.model) {
      this.model = new GCNOrderedItem(
        {},
        {
          item: this._options.item,
          section: this._options.section,
          upsellScreen: this._options.upsellScreen,
        },
      );
      this._origModel = new GCNOrderedItem(
        {},
        {
          item: this._options.item,
          section: this._options.section,
        },
      );
      if (!this._origModel.orderedPO) {
        this._origModelNeedsPriceOption = true;
      }
    }

    if (this._options.extras.weight) {
      // we set the weight and quantity of `1` if this menu item order view has weight
      this.model.setWeightAndQuantityOnSelectedPriceOption(this._options.extras.weight, 1);
    }

    if (this._options.extras.barcode) {
      // we set the barcode if barcode is received via `extras`
      this.model.setBarcode(this._options.extras.barcode);
    }

    this._fetchAndRenderRecommendations();

    this.cancelButtonText = this._options.cancelButtonText;

    this.listenTo(this.model, 'change', this.__modelDidChange);

    this._customizeView = new GCNMenuItemCustomizeView({
      model: this.model,
      setDoneDisabled: (disabled) => {
        this.setDoneAndCheckoutButtonsDisabled(disabled);
      },
      isFromHistoryItem: this.fromHistoryItem,
    });

    this._detailsView = new GCNMenuItemDetailsView({
      menuItem: this.model.item,
      orderedItem: this.model,
      customizeView: this._customizeView,
    });

    this.listenTo(
      this._customizeView,
      BackboneEvents.GCNMenuItemCustomizeView.ComboButtonTapped,
      ($comboBuilderAddonSetPickersContainer, skipScroll) => {
        if (skipScroll) {
          return;
        }
        GcnAddonHelper.scrollToContentEl($comboBuilderAddonSetPickersContainer, this.__$contents);
      },
    );

    this.listenTo(
      this._customizeView,
      BackboneEvents.GCNMenuItemCustomizeView.AddonSetSelectionComplete,
      ($subView, callback) => {
        GcnAddonHelper.scrollToContentEl($subView, this.__$contents, callback);
      },
    );

    // GCNMenuItemCustomizeView has the ability to toggle to done button state
    // This is the last sanity check to ensure at an order level we are in the correct state
    // i.e a valid number of items have been added to the order
    this.listenTo(
      this._customizeView,
      BackboneEvents.GCNMenuItemCustomizeView.AddonOrPriceOptionChanged,
      () => {
        this._checkIsOrderValid();
      },
    );

    this.listenTo(
      this._customizeView,
      BackboneEvents.GCNMenuItemCustomizeView.SpecialRequestTextChanged,
      () => {
        this._checkIsOrderValid();
      },
    );

    this.listenTo(
      this._customizeView,
      BackboneEvents.GCNAddonSetPickerView.AddonErrorMessageShown,
      (errorMessage) => {
        if (!errorMessage?.length) {
          return;
        }
        const $warningToast = this.$el.find('.warning-toast');
        GcnHelper.showWarningToastIfNotAnimated(
          $warningToast,
          errorMessage,
          { opacity: 0.9, bottom: '10%' },
          { opacity: 0, bottom: '0' },
        );
      },
    );

    this.listenTo(
      this._customizeView,
      BackboneEvents.GCNMenuItemCustomizeView.ContentsExpanded,
      () => {
        self._showMoreIndicatorIfContentHidden();
      },
    );

    this.listenTo(
      this._customizeView,
      BackboneEvents.GCNMenuItemCustomizeView.SelectedRecosChanged,
      () => {
        self._updateTotalPrice();
      },
    );

    if (this.model.item.isWeighable() && !gcn.location.isDeliKioskDemo()) {
      Log.info('START listening to scale');
      this._readingScale = true;
      gcn.startReadingScale();
      this.listenTo(gcn, BackboneEvents.GCNMenuAppView.ScaleDidReadData, (scaleData) => {
        self._detailsView.handleScaleData(scaleData);
      });
    }
  },

  destroy(...args) {
    GCNView.prototype.destroy.apply(this, args);

    this._detailsView.destroy();
    this._customizeView.destroy();
    if (this._readingScale) {
      Log.info('STOP listening to scale');
      gcn.stopReadingScale();
      this.stopListening(gcn);
    }
  },

  __canProceed() {
    if (this.__$doneButton.hasClass('disabled')) {
      return false;
    }
    return true;
  },

  __modelDidChange() {
    this._updateTotalPrice();
    this._checkIsOrderValid();
  },

  __itemIsCustomized() {
    // If there are 2+ price options then the origModel wouldn't have one at
    // all at init time. Give it the current one so that we don't show an
    // alert about different price options. But if there are addons then the
    // orig model wouldn't have them and the models wouldn't be equal.
    if (this._origModelNeedsPriceOption && this.model.orderedPO) {
      this._origModel.setPriceOption(this.model.orderedPO.po);
    }
    return !this.model.isEqualToOrderedItem(this._origModel);
  },

  overlayWasClicked() {
    const menuItemId = this.model.id;
    const menuItemName = this.model.displayName();

    if (this.__itemIsCustomized()) {
      const alertText = localizeStr(
        this.isEditView ? Strings.CONFIRM_DISCARD_CHANGES : Strings.CONFIRM_DISCARD,
        [],
        function (string) {
          return string.split('\n').join('<br />');
        },
      );
      const confirmView = new GCNAlertView({
        text: alertText,
        okText: localizeStr(Strings.YES),
        okCallback() {
          gcn.orderManager.eventRepo.trackMenuItemAbandon(menuItemId, menuItemName);
          Analytics.trackEvent({
            eventName: Analytics.EventName.MenuItemAbandoned,
            eventData: {
              itemName: menuItemName,
            },
          });
          gcn.menuView.dismissModalPopup();
          gcn.menuView.dismissStablePopup();
        },
        cancelCallback() {
          gcn.menuView.dismissModalPopup();
        },
      });
      gcn.menuView.showModalPopup(confirmView);
    } else {
      gcn.orderManager.eventRepo.trackMenuItemAbandon(menuItemId, menuItemName);
      Analytics.trackEvent({
        eventName: Analytics.EventName.MenuItemAbandoned,
        eventData: {
          itemName: menuItemName,
        },
      });
      gcn.menuView.dismissStablePopup();
    }

    GCNRouterHelper.navToMenu();

    this.trigger(BackboneEvents.GCNMenuItemOrderView.OverlayPressed, this);
  },

  setMaxImageSize(width, height) {
    this._detailsView?.setMaxImageSize(width, height);
  },

  _checkIsOrderValid(options) {
    const upperLimit = (options || {}).upperLimit || 99;

    this.setDoneAndCheckoutButtonsDisabled(false);

    // Check quantity limit if using item quantity input
    if (this._quantitySelectionView) {
      const val = this._quantitySelectionView.getValue();
      if (!val || val < 1 || val > upperLimit) {
        this._$priceLabel.html(`${localizeStr(Strings.TOTAL)}: -`);
        this.setDoneAndCheckoutButtonsDisabled(true);
        return;
      }
    }

    if (this._customizeView.isSpecialRequestProfane()) {
      this.setDoneAndCheckoutButtonsDisabled(true);
      this._scrollToBottom();
      return;
    }

    this._customizeView.updateDoneButtonState();
  },

  _updateTotalPrice() {
    const totalPrice = this._getTotalPrice();

    this._$priceLabel.html(`${localizeStr(Strings.TOTAL)}: ${GcnHtml.htmlFromPrice(totalPrice)}`);

    if (this.model.item.getSaleUnit()) {
      this.setDoneAndCheckoutButtonsDisabled(this._getTotalPrice() === 0);
    }
  },

  _getQuantity() {
    // Hijack the quantity selector to send weights instead.
    if (gcn.location.isDeliKioskDemo() && this.model.item.isWeighable()) {
      return 1;
    }
    return this._quantitySelectionView ? this._quantitySelectionView.getValue() : 1;
  },

  _getTotalPrice() {
    return this.model.getRecalculatedTotal(this._getQuantity(), this._detailsView?.getWeight());
  },

  __finalizeItem() {
    if (!this.__canProceed()) {
      const firstInvalidAddonId = this._customizeView.getFirstInvalidAddonId();
      if (firstInvalidAddonId) {
        const firstInvalidAddonEle = document.getElementById(firstInvalidAddonId);
        if (firstInvalidAddonEle) {
          firstInvalidAddonEle.scrollIntoView({ behavior: 'smooth' });
        }
      }
      return false;
    }

    if (this._customizeView.groupRecipientView) {
      if (!this._customizeView.groupRecipientView.validate()) {
        document.getElementById('group-recipient-view')?.scrollIntoView({ behavior: 'smooth' });
        return false;
      }
    }

    if (this._exiting) {
      return false;
    }

    this._exiting = true;

    const specialRequest = this._customizeView.getSpecialRequest();
    if ((specialRequest || '').length) {
      this.model.set('specialRequest', specialRequest);
    }

    // since this is a new ordered item, the only case we would have a recipient is if
    // the user is in group ordering mode and set the first guest recipient
    // in the async waterfall below using GCNGroupRecipientGuestNameModalView
    const guest = this.model.getRecipient() || this._customizeView.getGroupAssignee();
    if (guest) {
      this.model.setRecipient(guest);
    }

    const quantity = this._getQuantity();
    this.model.setQuantityOnSelectedPriceOption(quantity);

    // TODO: Bring back animation, or remove it:
    // this._finalizeView.animateOutOrderedItem().
    gcn.orderManager.addToOrder(this.model, quantity, {
      recommendationDisplayLocationDescription:
        this._options.extras.recommendationDisplayLocationDescription,
    });

    // Add all selected recommendations.
    const { orderedItems, recommendationDisplayLocationDescription } =
      this._customizeView.getOrderedRecommendations();
    _.each(orderedItems, (orderedItem) => {
      gcn.orderManager.addToOrder(orderedItem, orderedItem.orderedPO.get('quantity'), {
        recommendationDisplayLocationDescription,
      });
    });

    if (this._recosMiniView) {
      _.each(this._recosMiniView.getOrderedItems(), (orderedItem) => {
        // this is a new addition -> and recommendation is only of one item
        const recommendedItemQuantity = 1;
        gcn.orderManager.addToOrder(orderedItem, recommendedItemQuantity, {
          recommendationDisplayLocationDescription:
            RecommendationDisplayLocationDescription.MENU_ITEM_MINI_RECOS,
        });
      });
    }

    gcn.menuView.dismissStablePopup();

    GCNRouterHelper.navToMenu();

    this.trigger(BackboneEvents.GCNMenuItemOrderView.DonePressed, this);
    return true;
  },

  __renderContents() {
    // Full bleed to top in regular order view.
    this.__$topNav.remove();

    this.__$contents.append(this._detailsView.render().el);
    this.__$contents.append(this._customizeView.render().el);

    // We shorten text to add based on body size.
    if ($('body').width() <= 768) {
      this.setCheckoutButtonText(localizeStr(Strings.CHECKOUT));
      this.setDoneButtonText(localizeStr(Strings.ADD));
    } else {
      this.setCheckoutButtonText(localizeStr(Strings.QUICK_CHECKOUT));
      this.setDoneButtonText(localizeStr(Strings.ADD_TO_ORDER));
    }
  },

  _resetOverflowShadow() {
    if (this.__$contents.canVerticalScroll()) {
      this._$overflowShadow.show();
    } else {
      this._$overflowShadow.hide();
    }
  },

  __actualContentHeight() {
    return this._customizeView.$el.height() + this._detailsView.$el.height();
  },

  _showMoreIndicatorIfContentHidden() {
    const self = this;

    if (this.moreIndicatorShowing) {
      return;
    }

    // check if all content fits inside view already
    const availableHeight =
      this.__$contents.height() - this.__$topNav.height() - this.__$bottomNav.height();

    const actualContentHeight = this.__actualContentHeight();

    if (actualContentHeight <= availableHeight) {
      return;
    }

    this.moreIndicatorShowing = true;

    const $moreIndicator = $(
      '<div class="scroll-indicator">' +
        '<div class="more-text"></div>' +
        '<div class="arrow-icon">&#x2304;</div>' +
        '</div>',
    );
    $moreIndicator.insertAfter(this.__$contents);
    $moreIndicator.find('.more-text').htmlOrText(localizeStr(Strings.MORE));
    setTimeout(() => {
      // Center it.
      let left = (self.__$contents.outerWidth() - $moreIndicator.width()) / 2;
      // TODO: Remove this adjustment when #1000 is resolved.
      left -= 14;
      $moreIndicator.css('left', `${left}px`);
      $moreIndicator.css('bottom', self.$('.bottom-nav').outerHeight());
    }, 1);
    $moreIndicator.hide();
    $moreIndicator.fadeIn(200);

    $moreIndicator.onButtonTapOrHold('miovMore', () => {
      Analytics.track(Analytics.EventName.MenuItemViewMoreIndicatorTapped);
      self._scrollDownBy(self.__$contents, 160);
    });

    this.__$contents.scroll(() => {
      $moreIndicator.fadeOut(300);
      self.moreIndicatorShowing = false;
    });
  },

  _scrollDownBy($element, pixels) {
    $element.animate(
      {
        scrollTop: $element.scrollTop() + pixels,
      },
      800,
    );
  },

  _scrollToBottom() {
    this.__$contents.animate(
      {
        scrollTop: this.__$contents[0].scrollHeight,
      },
      800,
    );
  },

  _willShowMiniRecos() {
    return (
      gcn.menu.settings.get('recommendationsLevel') === LocationRecommendationsLevel.Active &&
      !gcn.screenReaderIsActive
    );
  },

  _fetchAndRenderRecommendations() {
    if (gcn.menu.settings.get('recommendationsLevel') !== LocationRecommendationsLevel.Active) {
      return;
    }

    asCallback(
      GcnRecoTracker.getItemRecommendations(gcn.maitred, this.model),
      (err, recommendations) => {
        if (err) {
          Log.error('error loading recos');
          return;
        }

        const recoItems = gcn.menu.getMenuItemsFromRecommendations(recommendations, {
          displayRecosLimit: 2,
        });
        this._renderMiniRecos(recoItems);
      },
    );
  },

  _renderMiniRecos(recoItems) {
    const self = this;
    if (!recoItems.length) {
      return;
    }

    this.__$contents.addClass('show-mini-recos');

    this._$miniRecos.show();
    this._recosMiniView = new GCNRecommendationsMiniView({
      model: this.model.item,
      upsellScreen: 'GCNMenuItemOrderView',
      recos: recoItems,
      usePopup: true,
    });
    this._$miniRecos.append(this._recosMiniView.render().$el);
    this.listenTo(
      this._recosMiniView,
      BackboneEvents.GCNRecommendationsView.SelectedItemsChanged,
      () => {
        self._updateTotalPrice();
      },
    );
    setTimeout(() => {
      self._$miniRecos.css('bottom', `${self.__$bottomNav.height() + 10}px`);
    }, 1);
  },

  _shouldShowQuantitySelector() {
    if (this.model.item.isWeighable()) {
      // Weighable items not in deli mode should now show the selector since a scale is involved.
      // Deli mode uses the quantity selector as UI for determining weight.
      return gcn.location.isDeliKioskDemo();
    }
    if (gcn.menu.settings.get('enableMultipleQuantityItems')) {
      // allow editing the number when `enableMultipleQuantityItems` is set
      return true;
    }
    return !this.isEditView;
  },

  setCancelButtonText(text) {
    this.__$cancelButton.htmlOrText(text);
    this.__$cancelButton.attr('aria-label', ScreenReaderHelper.prepareAriaLabel(text));
  },

  setCheckoutButtonText(text) {
    this.__$checkoutButton.htmlOrText(text);
    this.__$checkoutButton.attr('aria-label', ScreenReaderHelper.prepareAriaLabel(text));
  },

  setDoneButtonText(text) {
    this.__$doneButton.htmlOrText(text);
    this.__$doneButton.attr('aria-label', ScreenReaderHelper.prepareAriaLabel(text));
  },

  setDoneAndCheckoutButtonsDisabled(disabled) {
    this.__$doneButton.toggleClass('disabled', disabled);
    this.__$checkoutButton.toggleClass('disabled', disabled);
  },

  startDoneFlow() {
    if (gcn.orderManager.shouldPromptGroupOrderingMode()) {
      async.waterfall([
        (cb) => {
          const groupOrderingPromptView = new GCNGroupOrderPromptView({
            onDone: () => {
              gcn.menuView.dismissPopup();

              cb(null);
            },
          });

          gcn.menuView.showPopup(groupOrderingPromptView, { maxWidth: '80%' }, 'group-ordering');
        },
        (cb) => {
          // user rejected group ordering mode
          if (!gcn.orderManager.getGroupOrderingModeActive()) {
            cb();

            return;
          }

          if (gcn.menu.settings.get('groupOrderingNameType') === GroupOrderingNameType.Name) {
            const nameModalView = new GCNGroupRecipientGuestNameModalView({
              onDone: (guestName) => {
                this.model.set('recipientName', guestName);

                gcn.menuView.dismissPopup();
                cb();
              },
            });

            gcn.menuView.showPopup(nameModalView, { maxWidth: '80%' }, 'group-ordering');

            return;
          }

          cb();
        },
        (cb) => {
          this.__finalizeItem();
          cb(null);
        },
      ]);

      return;
    }

    if (
      gcn.orderManager.getGroupOrderingModeActive() &&
      !gcn.orderManager.getGroupOrderingModePrompted() &&
      gcn.menu.settings.get('groupOrderingNameType') === GroupOrderingNameType.Name
    ) {
      gcn.orderManager.setGroupOrderingModePrompted();
      const nameModalView = new GCNGroupRecipientGuestNameModalView({
        onDone: (guestName) => {
          this.model.set('recipientName', guestName);

          gcn.menuView.dismissPopup();

          this.__finalizeItem();
        },
      });

      gcn.menuView.showPopup(nameModalView, { maxWidth: '80%' }, 'group-ordering');

      return;
    }

    this.__finalizeItem();
  },

  render() {
    const self = this;

    this.$el.html(this.template());

    this.__$topNav = this.$el.find('.top-nav');
    this.__$contents = this.$el.find('.contents');
    this.__$bottomNav = this.$el.find('.bottom-nav');
    this.__$doneButton = this.$el.find('.bottom-nav .button.done');
    this.__$checkoutButton = this.$el.find('.bottom-nav .button.checkout');
    this.__$cancelButton = this.$el.find('.bottom-nav .button.cancel');
    this._$priceLabel = this.$el.find('.bottom-nav .price-label');
    this._$overflowShadow = this.$('.overflow-shadow');
    this._$miniRecos = this.$('.recos');

    if (gcn.menu.settings.get('groupOrderingMode') === 'group') {
      if (gcn.menu.settings.get('groupOrderingNameType') === 'group') {
        this._$orderingGuests = this.$el.find('.ordering-guests');
      }
    }

    if (this._shouldShowQuantitySelector()) {
      const userCanSetWeight = gcn.location.isDeliKioskDemo() && this.model.item.isWeighable();
      const saleUnit = this.model.item.getSaleUnit();
      let multiplier = 1;
      if (saleUnit) {
        multiplier = saleUnit === MenuItemSaleUnit.LB ? 0.25 : 1;
      }
      const options = {
        menuItemName: this.model.displayName(),
        showLabel: true,
      };

      if (window.isFlash) {
        options.hasInputQuantity = true;
      }
      // TODO: what to do when there is a displaySuffix
      if (userCanSetWeight) {
        options.displayMultiplier = multiplier;
        options.displaySuffix = ` ${MenuItemSaleUnitHelper.notation(saleUnit)}`;
        options.upperLimit = 99;
      }

      if (this.isEditView) {
        // Recommendations are opened in an instance of GCNMenuItemEditView and
        // they won't have a price option selected yet
        options.currentQuantity = this.model.orderedPO?.get('quantity') || 1;
      }
      this._quantitySelectionView = new GCNQuantitySelectionView(options);
      this.listenTo(
        this._quantitySelectionView,
        BackboneEvents.GCNQuantitySelectionView.QuantityChanged,
        () => {
          if (userCanSetWeight) {
            const value = self._quantitySelectionView.getValue() * multiplier;
            self._detailsView?.handleScaleData({
              weight: value,
              unit: saleUnit,
            });
          } else {
            self._updateTotalPrice();
            self._checkIsOrderValid(options);
          }
        },
      );
      this.$('.buttons-right').before(this._quantitySelectionView.render().$el);
    }

    this.$el.toggleClass('weighable-item', this.model.item.isWeighable());

    this._$miniRecos.hide();

    this.__renderContents();
    this._updateTotalPrice();

    this.__$doneButton.onButtonTapOrHold('menuItemOrderViewDoneBtn', this.startDoneFlow.bind(this));

    if (gcn.menu.settings.get('quickCheckoutEnabled')) {
      this.__$checkoutButton.onButtonTapOrHold('miov_quick_checkout_btn', () => {
        Analytics.trackEvent({
          eventName: Analytics.EventName.QuickCheckout,
          eventData: {
            source: 'menu_item_order_view',
          },
        });
        if (this.__finalizeItem()) {
          gcn.startCheckoutSequence();
        }
      });
    } else {
      this.__$checkoutButton.remove();
    }

    if (this.cancelButtonText) {
      this.setCancelButtonText(this.cancelButtonText);
      this.__$cancelButton.onButtonTapOrHold('miovCancel', () => {
        const menuItemName = this.model.displayName();
        Analytics.trackEvent({
          eventName: Analytics.EventName.MenuItemAbandoned,
          eventData: {
            itemName: menuItemName,
          },
        });
        self.trigger(BackboneEvents.GCNMenuItemOrderView.CancelPressed, self);
      });
    } else {
      this.__$cancelButton.remove();
    }

    setTimeout(() => {
      self._$overflowShadow.css('bottom', self.__$bottomNav.outerHeight());
      self._resetOverflowShadow();
    }, 1);

    this.__$contents.scroll(() => {
      if (
        self.__$contents.scrollTop() + self.__$contents.innerHeight() >=
        self.__$contents[0].scrollHeight
      ) {
        self._$overflowShadow.fadeOut(200);
      } else if (!self._$overflowShadow.is(':visible')) {
        self._$overflowShadow.fadeIn(200);
      }
    });

    this.moreIndicatorShowing = false;
    setTimeout(() => {
      self._showMoreIndicatorIfContentHidden();
    }, 1);

    if (!this.isEditView && this.model.item.isWeighable() && !gcn.location.isDeliKioskDemo()) {
      this.setDoneAndCheckoutButtonsDisabled(true);
    }

    return this;
  },
});
