import html2canvas from 'html2canvas';
import ReactGA from 'react-ga4';

import { Log } from '@biteinc/common';
import { StringHelper } from '@biteinc/core-react';
import type { OrderChannel } from '@biteinc/enums';
import { BiteLogType, Environment, FlashBridgeMessage } from '@biteinc/enums';

import type {
  BridgeCallback,
  BridgeImageResponse,
  BridgeMessageHandler,
  BridgeResponse,
} from './gcn_bridge_interface';
import GcnFlashMaitredClient from './gcn_flash_maitred_client';
import type GcnMaitredClient from './gcn_maitred_client';

export default class GcnFlashBridge {
  private flashMaitredClient: GcnFlashMaitredClient;

  private bridgeMessageHandler: BridgeMessageHandler;

  constructor(
    private maitred: GcnMaitredClient,
    apiHost: string,
    orgIdStr: string,
    orderChannel: OrderChannel,
    locationIdStr: string,
  ) {
    this.flashMaitredClient = new GcnFlashMaitredClient(
      apiHost,
      orgIdStr,
      orderChannel,
      locationIdStr,
    );

    // disable ReactGA when in test env
    if (window.env !== Environment.TEST && window.isFlash) {
      const googleAnalyticsApiKey = gcn.location.get('googleAnalyticsApiKey');
      if (googleAnalyticsApiKey) {
        ReactGA.initialize(googleAnalyticsApiKey);
      }
    }
  }

  // The garcon bridge expects a function called init and not initialize.
  init(bridgeMessageHandler: BridgeMessageHandler): void {
    this.bridgeMessageHandler = bridgeMessageHandler;
  }

  sendLog(data: any, type: BiteLogType = BiteLogType.Device): void {
    const logJson = {
      ...data,
      type,
      clientId: StringHelper.newMongoId(),
      clientName: 'flash',
      createdAt: Date.now(),
    };
    this.maitred.sendLog(logJson);
  }

  send(data: any, callback: BridgeCallback): void {
    Log.debug('bridge send', data);
    switch (data.event) {
      case FlashBridgeMessage.IMAGE: {
        setTimeout(() => {
          const response: BridgeImageResponse = data.imageUrl;
          callback(response);
        }, 1);
        break;
      }
      case FlashBridgeMessage.MAITRED_REQUEST: {
        Log.debug(FlashBridgeMessage.MAITRED_REQUEST, data);
        const request = data.request;
        this.flashMaitredClient.makeApiRequest(
          request.apiService,
          request.method,
          request.path,
          request.body,
          request.timeout,
          callback,
        );
        break;
      }
      case FlashBridgeMessage.GO_HOME: {
        window.location.reload();
        break;
      }
      case FlashBridgeMessage.DEVICE_GAZEBO_LOG: {
        Log.debug(FlashBridgeMessage.DEVICE_GAZEBO_LOG, data);
        if (data.takeScreenshot) {
          html2canvas(document.body)
            .then((canvas) => {
              data.screenshotData = canvas
                .toDataURL('image/jpeg', 0.8)
                // native clients don't include data content types in their base64 data,
                // so strip it out for consistency
                .replace('data:image/jpeg;base64,', '');
              this.sendLog(data);
            })
            .catch(() => {
              this.sendLog(data);
            });
        } else {
          this.sendLog(data);
        }
        break;
      }
      case FlashBridgeMessage.TRACK_EVENT: {
        if (gcn.location.get('googleAnalyticsApiKey') && window.isFlash) {
          const googleAnalyticsApiKey = gcn.location.get('googleAnalyticsApiKey');
          if (!ReactGA.isInitialized && googleAnalyticsApiKey) {
            ReactGA.initialize(googleAnalyticsApiKey);
          }
          ReactGA.event(data.eventName, data.eventData);
        }
        break;
      }
      case FlashBridgeMessage.USER_DID_END_PAYMENT_PROCESS:
      case FlashBridgeMessage.USER_DID_TAP_CANCEL_PAYMENT:
      case FlashBridgeMessage.UPDATED_SESSION_DATA:
        // TODO: do we need to do anything here
        break;
      case FlashBridgeMessage.READY:
      case FlashBridgeMessage.SEND_ORDER:
      case FlashBridgeMessage.ORDER_RECOVERY_IS_READY:
      case FlashBridgeMessage.USER_DID_FOCUS_ON_TEXT_FIELD:
      case FlashBridgeMessage.USER_DID_FOCUS_OUT_OF_TEXT_FIELD:
      case FlashBridgeMessage.USER_DID_ENTER_CRITICAL_PERIOD:
      case FlashBridgeMessage.USER_DID_LEAVE_CRITICAL_PERIOD:
      case FlashBridgeMessage.USER_DID_REQUEST_TO_PRINT_RECEIPT:
        // Do nothing for these events
        break;
      default:
        Log.error('missing bridge event', data);
    }
  }

  async sendAsync(data: any): Promise<BridgeResponse> {
    return new Promise((resolve) => {
      this.send(data, (response) => {
        resolve(response);
      });
    });
  }
}
