module ScreenReaderHelper {
  export function prepareAriaLabel(name: string): string {
    return removeSpecialCharactersButKeepCertainPunctuation(name)
      .replace(/w\//gi, 'with')
      .toLowerCase();
  }

  function removeSpecialCharactersButKeepCertainPunctuation(str: string): string {
    return str.replace(/[^\w\s.,:;!?$/]/g, '');
  }
}

export default ScreenReaderHelper;
