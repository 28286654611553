/**
 * This is a stop-gap solution to sharing the cart state between Cart and GcnMenuAppView. It was
 * designed to be as uninvasive as possible, so it can be easily stripped out once we have a global
 * redux store. At that point, GcnMenuAppView to read directly from the store.
 */
module CartCoordinator {
  export interface Delegate {
    openCart(): void;
    closeCart(): void;
    isCartOpen(): boolean;
    getCartSize(): number;
  }

  export interface Observer {
    cartOpened(): void;
    cartClosed(): void;
    cartUpdated(itemCount: number): void;
  }

  let cartDelegate: Delegate | undefined;
  let cartObserver: Observer | undefined;

  export function setCartDelegate(delegate?: Delegate): void {
    cartDelegate = delegate;
  }

  export function setCartObserver(observer?: Observer): void {
    cartObserver = observer;
  }

  export function cartOpened(): void {
    cartObserver?.cartOpened();
  }

  export function cartClosed(): void {
    cartObserver?.cartClosed();
  }

  export function cartUpdated(itemCount: number): void {
    cartObserver?.cartUpdated(itemCount);
  }

  export function openCart(): void {
    cartDelegate?.openCart();
  }

  export function closeCart(): void {
    cartDelegate?.closeCart();
  }

  export function isCartOpen(): boolean {
    return cartDelegate?.isCartOpen() ?? false;
  }

  export function getCartSize(): number {
    return cartDelegate?.getCartSize() ?? 0;
  }
}

export default CartCoordinator;
