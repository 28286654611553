import * as Sentry from '@sentry/browser';
import async from 'async';

import type { GcnMenuItem } from '~/types/gcn_menu_item';

import type { GcnOrderedItem } from '../../types/gcn_ordered_item';
import type {
  GuestIdentifiers,
  Recommendation,
  RecommendationEngine,
} from '../../types/recommendation';
import { RecommendationDisplayLocationDescription } from '../../types/recommendation';

// store state in the tracker
const recommendationResultIdByDisplayLocationDescription: Record<
  RecommendationDisplayLocationDescription,
  string | null
> = {
  [RecommendationDisplayLocationDescription.MENU_ITEM_CUSTOMIZE_VIEW]: null,
  [RecommendationDisplayLocationDescription.MENU_SECTION_MENU_ITEM_HIGHLIGHT]: null,
  [RecommendationDisplayLocationDescription.PRE_CHECKOUT_POPUP]: null,
  [RecommendationDisplayLocationDescription.MENU_ITEM_CONFIRMATION_STEP]: null,
  [RecommendationDisplayLocationDescription.MENU_ITEM_MINI_RECOS]: null,
  [RecommendationDisplayLocationDescription.CART_VIEW]: null,
};

let recommendationByItemIdByRecommendationResultId: Record<
  string,
  Record<string, Recommendation>
> = {};

let modRecommendationsByItemIdByModGroupId: Record<string, Record<string, Recommendation[]>> = {};

// a history containing all recommendation result ids
// for example: we can get multiple recommendations for an item
let isFetchedByRecommendationResultId: Record<string, boolean> = {};

let sessionVariationId: string | null = null;

module GcnRecoTracker {
  function canUseRecoTracker(): boolean {
    if (window.isFlash && gcn.location.shouldShowClosedScreen()) {
      // This closed screen does not apply to non-flash channels
      return false;
    }

    if (!gcn.orderManager.getFulfillmentMethod()) {
      // Fulfillment method is required to fetch recommendations, but we don't have one yet.
      return false;
    }

    return true;
  }

  export function clearRecos(): void {
    // reset the recos in the tracker for a new session
    // let other places clear their own recos, like in views
    recommendationByItemIdByRecommendationResultId = {};
    // reset references
    Object.values(RecommendationDisplayLocationDescription).forEach(
      (displayLocationDescription) => {
        recommendationResultIdByDisplayLocationDescription[displayLocationDescription] = null;
      },
    );

    modRecommendationsByItemIdByModGroupId = {};

    isFetchedByRecommendationResultId = {};

    sessionVariationId = null;
  }

  function setRecos(
    recommendationResultId: string,
    recommendations: Recommendation[],
    displayLocationDescriptions: RecommendationDisplayLocationDescription[],
  ): void {
    recommendationByItemIdByRecommendationResultId[recommendationResultId] = {};
    // set recommendations so we can access them
    recommendations.forEach((recommendation) => {
      recommendationByItemIdByRecommendationResultId[recommendationResultId][
        recommendation.itemId
      ] = recommendation;
    });

    displayLocationDescriptions.forEach((displayLocationDescription) => {
      recommendationResultIdByDisplayLocationDescription[displayLocationDescription] =
        recommendationResultId;
    });

    isFetchedByRecommendationResultId[recommendationResultId] = true;
  }

  function getRecommendationResultId(
    displayLocationDescription: RecommendationDisplayLocationDescription,
  ): string | null {
    const recommendationResultId =
      recommendationResultIdByDisplayLocationDescription[displayLocationDescription];
    if (!recommendationResultId) {
      Sentry.captureMessage('No recommendation result id found', {
        extra: { displayLocationDescription },
      });
    }
    return recommendationResultId;
  }

  function getRecoByItemId(itemId: string, recommendationResultId: string): Recommendation | null {
    const recommendation =
      recommendationByItemIdByRecommendationResultId[recommendationResultId][itemId];

    if (!recommendation) {
      const itemInMenu = gcn.menu.getMenuItemWithId(itemId);
      const recommendationResultItemIds = Object.keys(
        recommendationByItemIdByRecommendationResultId,
      );
      const recommendations =
        recommendationByItemIdByRecommendationResultId[recommendationResultId];
      Sentry.captureMessage('No recommendation found', {
        extra: {
          itemId,
          recommendationResultId,
          itemInMenu: !!itemInMenu,
          recommendationResultItemIds,
          recommendations,
        },
      });
    }
    return recommendation;
  }

  export function getRecoVariationId(): string | null {
    return sessionVariationId;
  }

  export function modGroupRecosFetched(itemId: string, modGroupId: string): boolean {
    return !!modRecommendationsByItemIdByModGroupId[itemId]?.[modGroupId];
  }

  export function getModGroupRecos(
    itemId: string,
    modGroupId: string,
  ): Recommendation[] | undefined {
    return modRecommendationsByItemIdByModGroupId[itemId]?.[modGroupId];
  }

  function buildGuestIdentifiers(): GuestIdentifiers {
    const guestId = gcn.guestManager.getGuestId();
    const guestPhoneNumber = gcn.orderManager.getGuestPhoneNumber();

    return {
      ...(guestId && { guestId }),
      ...(guestPhoneNumber && { guestPhoneNumber }),
    };
  }

  // load the recommendations and return them to the view
  export async function getFirstLoadRecommendations(
    recommendationEngine: RecommendationEngine,
  ): Promise<Recommendation[]> {
    if (!canUseRecoTracker() || !gcn.location.shouldMakeRecommendationFirstLoadCall()) {
      return [];
    }
    try {
      const orderedItems = gcn.orderManager.getOrderedItems() || [];
      const { recommendationResultId, recommendations, recommendationVariationId, guestId } =
        await recommendationEngine.fetchFirstLoadRecommendations(
          gcn.menu.structure.id,
          buildGuestIdentifiers(),
          orderedItems,
        );

      if (guestId && guestId !== gcn.guestManager.getGuestId()) {
        // We found an existing guest using identifiers
        // Update the guest manager with the correct ID
        gcn.guestManager.setGuestId(guestId);
      }

      const itemRecosDisplayLocationDescriptions = [
        RecommendationDisplayLocationDescription.MENU_SECTION_MENU_ITEM_HIGHLIGHT,
      ];

      sessionVariationId = recommendationVariationId;

      setRecos(recommendationResultId, recommendations, itemRecosDisplayLocationDescriptions);

      return recommendations;
    } catch {
      return [];
    }
  }

  // load the recommendations and return them to the view
  export async function getItemRecommendations(
    recommendationEngine: RecommendationEngine,
    selectedOrderedItem: GcnOrderedItem,
  ): Promise<Recommendation[]> {
    if (!canUseRecoTracker()) {
      return [];
    }
    try {
      const orderedItems = gcn.orderManager.getOrderedItems() || [];
      const { recommendationResultId, recommendations, recommendationVariationId } =
        await recommendationEngine.fetchItemRecommendations(
          buildGuestIdentifiers(),
          selectedOrderedItem,
          orderedItems,
          sessionVariationId!,
        );

      if (recommendationVariationId && !sessionVariationId) {
        sessionVariationId = recommendationVariationId;
      }

      const itemRecosDisplayLocationDescriptions = [
        RecommendationDisplayLocationDescription.MENU_ITEM_CUSTOMIZE_VIEW,
        RecommendationDisplayLocationDescription.MENU_ITEM_CONFIRMATION_STEP,
        RecommendationDisplayLocationDescription.MENU_ITEM_MINI_RECOS,
      ];
      setRecos(recommendationResultId, recommendations, itemRecosDisplayLocationDescriptions);

      return recommendations;
    } catch {
      return [];
    }
  }

  export async function getModifierGroupRecommendations(
    recommendationEngine: RecommendationEngine,
    selectedOrderedItem: GcnMenuItem,
    modGroupId: string,
    virtualSubGroupId: string,
  ): Promise<Recommendation[]> {
    if (!canUseRecoTracker()) {
      return [];
    }
    try {
      const { recommendationResultId, recommendations, recommendationVariationId } =
        await recommendationEngine.fetchModGroupRecommendations(
          gcn.menu.structure.id,
          selectedOrderedItem,
          modGroupId,
          virtualSubGroupId,
        );

      if (recommendationVariationId && !sessionVariationId) {
        sessionVariationId = recommendationVariationId;
      }

      const itemRecosDisplayLocationDescriptions = [
        RecommendationDisplayLocationDescription.MENU_ITEM_CUSTOMIZE_VIEW,
      ];
      setRecos(recommendationResultId, recommendations, itemRecosDisplayLocationDescriptions);

      return recommendations;
    } catch {
      return [];
    }
  }

  export async function getBatchedRecommendations(
    recommendationEngine: RecommendationEngine,
    selectedOrderedItem: GcnOrderedItem,
    modGroupIds: string[],
    virtualSubGroupIds: (string | undefined)[],
  ): Promise<{
    itemRecommendations: Recommendation[];
    modGroupRecommendationsById: Record<string, Recommendation[]>;
  }> {
    if (!canUseRecoTracker()) {
      return {
        itemRecommendations: [],
        modGroupRecommendationsById: {},
      };
    }
    try {
      const orderedItems = gcn.orderManager.getOrderedItems() || [];
      const {
        recommendationResultId,
        itemRecommendations,
        modGroupRecommendationsById,
        recommendationVariationId,
      } = await recommendationEngine.fetchBatchedRecommendations(
        sessionVariationId!,
        buildGuestIdentifiers(),
        selectedOrderedItem,
        orderedItems,
        modGroupIds,
        virtualSubGroupIds,
      );

      if (recommendationVariationId && !sessionVariationId) {
        sessionVariationId = recommendationVariationId;
      }

      const itemRecosDisplayLocationDescriptions = [
        RecommendationDisplayLocationDescription.MENU_ITEM_CUSTOMIZE_VIEW,
      ];

      setRecos(recommendationResultId, itemRecommendations, itemRecosDisplayLocationDescriptions);

      const itemId = selectedOrderedItem.item.id;
      Object.keys(modGroupRecommendationsById).forEach((modGroupId) => {
        const modRecommendations = modGroupRecommendationsById[modGroupId];

        if (!modRecommendationsByItemIdByModGroupId[itemId]) {
          modRecommendationsByItemIdByModGroupId[itemId] = {};
        }

        modRecommendationsByItemIdByModGroupId[itemId][modGroupId] = [
          ...(modRecommendationsByItemIdByModGroupId[itemId][modGroupId] || []),
          ...modRecommendations,
        ];
      });

      return { itemRecommendations, modGroupRecommendationsById };
    } catch (err) {
      throw err;
    }
  }

  export async function getPreCheckoutRecommendations(
    recommendationEngine: RecommendationEngine,
  ): Promise<Recommendation[]> {
    if (!canUseRecoTracker()) {
      return [];
    }
    try {
      const cartOrderedItems = gcn.orderManager.getOrderedItems() || [];
      const { recommendationResultId, recommendations, recommendationVariationId } =
        await recommendationEngine.fetchPreCheckoutRecommendations(
          buildGuestIdentifiers(),
          cartOrderedItems,
          sessionVariationId!,
        );

      if (recommendationVariationId && !sessionVariationId) {
        sessionVariationId = recommendationVariationId;
      }

      const itemRecosDisplayLocationDescriptions = [
        RecommendationDisplayLocationDescription.PRE_CHECKOUT_POPUP,
      ];
      setRecos(recommendationResultId, recommendations, itemRecosDisplayLocationDescriptions);

      return recommendations;
    } catch {
      return [];
    }
  }

  export async function getSideCartRecommendations(
    recommendationEngine: RecommendationEngine,
  ): Promise<Recommendation[]> {
    if (!canUseRecoTracker()) {
      return [];
    }
    try {
      const cartOrderedItems = gcn.orderManager.getOrderedItems() || [];
      const { recommendationResultId, recommendations, recommendationVariationId } =
        await recommendationEngine.fetchSideCartRecommendations(
          buildGuestIdentifiers(),
          cartOrderedItems,
          sessionVariationId!,
        );

      if (recommendationVariationId && !sessionVariationId) {
        sessionVariationId = recommendationVariationId;
      }
      const itemRecosDisplayLocationDescriptions = [
        RecommendationDisplayLocationDescription.CART_VIEW,
      ];
      setRecos(recommendationResultId, recommendations, itemRecosDisplayLocationDescriptions);

      return recommendations;
    } catch {
      return [];
    }
  }

  export function trackDisplayRecommendations(
    recommendationEngine: RecommendationEngine,
    displayLocationDescription: RecommendationDisplayLocationDescription,
    itemIds: string[],
    displayContext?: Record<string, any>,
  ): void {
    if (!canUseRecoTracker()) {
      return;
    }

    (async () => {
      const recommendationResultId = getRecommendationResultId(displayLocationDescription);
      if (!recommendationResultId) {
        return;
      }
      const recos = itemIds
        .map((itemId) => {
          return getRecoByItemId(itemId, recommendationResultId);
        })
        .filter((reco): reco is NonNullable<typeof reco> => !!reco);
      await recommendationEngine.sendRecommendationEventDisplayRecommendations(
        displayLocationDescription,
        recos,
        recommendationResultId,
        displayContext,
      );
    })().catch(() => {});
  }

  export function trackGuestAddToCart(
    recommendationEngine: RecommendationEngine,
    displayLocationDescription: RecommendationDisplayLocationDescription,
    selectedOrderedItem: GcnOrderedItem,
  ): void {
    if (!canUseRecoTracker()) {
      return;
    }

    (async () => {
      const selectedItemId = selectedOrderedItem.item.id;
      const itemSubtotal = selectedOrderedItem.getTotal();
      const recommendationResultId = getRecommendationResultId(displayLocationDescription);
      if (!recommendationResultId) {
        return;
      }
      const reco = getRecoByItemId(selectedItemId, recommendationResultId);
      if (!reco) {
        return;
      }
      await recommendationEngine.sendRecommendationEventGuestAddToCart({
        recommendationResultId,
        selectedRecommendation: reco,
        itemId: selectedOrderedItem.item.id,
        subTotal: itemSubtotal,
        quantity: selectedOrderedItem.orderedPO.get('quantity'),
        posId: selectedOrderedItem.item.attributes.posId,
      });
    })().catch(() => {});
  }

  export function createOrderReferences(
    recommendationEngine: RecommendationEngine,
    orderId: string,
  ): void {
    if (!canUseRecoTracker()) {
      return;
    }

    (async () => {
      const recommendationResultIds = Object.keys(isFetchedByRecommendationResultId);
      await async.each(recommendationResultIds, async (recommendationResultId) => {
        if (!recommendationResultId) {
          return;
        }
        await recommendationEngine.sendRecommendationEventOrderReference(
          recommendationResultId,
          orderId,
        );
      });
    })().catch(() => {});
  }
}

export default GcnRecoTracker;
