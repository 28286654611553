import classNames from 'classnames';
import * as React from 'react';

import { LocalizationHelper } from '@biteinc/core-react';
import { Button } from '@biteinc/ui';

import { useLocalize } from '~/app/js/localization/localization';

import { GcnPaymentHelper } from '../../../helpers';
import { FreedomPayEcommUtils } from './freedom-pay-ecomm-form.utils';

export function SavedCard({
  savedCard,
  onSelectCard,
  selectedCard,
}: {
  savedCard: FreedomPayEcommUtils.SavedCard;
  onSelectCard: (card: FreedomPayEcommUtils.SavedCard) => void;
  selectedCard: FreedomPayEcommUtils.SavedCard | undefined;
}): JSX.Element {
  const localizeStr = useLocalize();
  const expirationStatus = FreedomPayEcommUtils.getCardExpirationStatus(savedCard);
  const notice = FreedomPayEcommUtils.getCardExpirationNotice(expirationStatus);
  const cardType = localizeStr(
    LocalizationHelper.localizeEnum.CardSchemeId(savedCard.cardSchemeId),
  );

  const isExpired = expirationStatus === 'expired';

  return (
    <Button
      className="tw-flex tw-items-center tw-justify-between tw-gap-4 tw-p-2 disabled:tw-opacity-50"
      id={`card-${savedCard._id}`}
      disabled={isExpired}
      onClick={() => {
        onSelectCard(savedCard);
      }}
    >
      <div className="tw-flex tw-items-center tw-gap-4">
        <img
          src={GcnPaymentHelper.getCardSchemeImage(savedCard.cardSchemeId)}
          alt={cardType.toString()}
          className="tw-block tw-h-8 tw-w-8"
        />
        <div className="tw-flex tw-flex-col tw-text-sm">
          {savedCard.nickname ? <p className="tw-flex tw-font-bold">{savedCard.nickname}</p> : null}
          <p className="tw-flex tw-gap-1">
            <span>{cardType}</span> <span>{savedCard.last4}</span>
            <span>
              exp. {savedCard.expirationMonth.toString().padStart(2, '0')}/
              {savedCard.expirationYear}
            </span>
          </p>
          {expirationStatus !== 'valid' && (
            <p
              className={classNames({
                'tw-text-red-500': expirationStatus === 'expired',
                'tw-text-yellow-500': expirationStatus === 'expiring',
              })}
            >
              {notice}
            </p>
          )}
        </div>
      </div>

      <input
        type="radio"
        checked={selectedCard?._id === savedCard._id}
        onChange={() => onSelectCard(savedCard)}
      />
    </Button>
  );
}
