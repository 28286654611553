import _ from 'underscore';

import { Strings } from '@biteinc/common';

import { localizeStr } from '~/app/js/localization/localization';

import { GCNRewardView } from './gcn_reward_view';
import { GCNView } from './gcn_view';

// Control pane that displays loyalty account details, and allows interaction with rewards.
export const GcnLoyaltyPaneView = GCNView.extend({
  className: 'loyalty-pane-view',

  _template: _.template(
    // prettier-ignore
    '<div class="header font-body">' +
      '<div class="rewards-title"><%= authedGuestText %></div>' +
    '</div>' +
    '<div class="estimated-points-container"></div>' +
    '<div class="reward-list"></div>',
  ),

  render() {
    const orderHasCoupon = !!gcn.orderManager.getCouponCode();
    const rewards = gcn.loyaltyManager.getRewards();
    const guestName = gcn.loyaltyManager.getAuthedGuestFriendlyName();
    const { hideStatusAndPoints } = gcn.location.getLoyaltyIntegration();
    let authedGuestText;
    if (orderHasCoupon && !gcn.loyaltyManager.areCouponsAndLoyaltyEarningCompatible()) {
      authedGuestText = localizeStr(Strings.LOYALTY_COUPON_BURNING_INCOMPATIBLE);
    } else if (rewards.length) {
      authedGuestText = localizeStr(Strings.LOYALTY_AVAILABLE_REWARDS_LABEL);
    } else {
      authedGuestText = localizeStr(Strings.LOYALTY_NO_REWARDS_LABEL);
    }
    if (guestName) {
      authedGuestText += localizeStr(Strings.LOYALTY_REWARDS_FOR) + ` ${guestName}!`;
    }

    this.$el.html(this._template({ authedGuestText }));

    const status = gcn.loyaltyManager.getStatus();
    if (status && !hideStatusAndPoints) {
      this.$('.header').append(`<div class="progress">${status}</div>`);
    }

    const estimatedPointsEarned = gcn.loyaltyManager.getEstimatedPointsEarned();
    if (estimatedPointsEarned > 0 && !hideStatusAndPoints) {
      this.$('.estimated-points-container').append(
        `<div class="estimated-points-info">${localizeStr(
          Strings.LOYALTY_EARNED_POINT_ESTIMATE,
        )}</div>`,
        `<div class="estimated-points-value">${localizeStr(
          Strings.LOYALTY_EARNED_POINT_ESTIMATE_METRIC,
          [estimatedPointsEarned],
        )}</div>`,
      );
    }

    const $rewardOptions = this.$('.reward-list');
    const displayableRewards = rewards.filter((reward) => {
      return !reward.get('shouldBeHidden');
    });
    if (displayableRewards.length) {
      const appliedRewards = gcn.orderManager.getAppliedRewards();
      const appliedRewardByMapKey = appliedRewards.reduce((rewardById, reward) => {
        rewardById[reward.appliedRewardMapKey()] = reward;
        return rewardById;
      }, {});

      const canRedeemMultipleRewards = gcn.loyaltyManager.canRedeemMultipleRewards();
      const canRedeemRedemptionAndRewards =
        !!gcn.location.getLoyaltyIntegration().allowRedemptionCodeWithReward;
      const appliedRewardContainsRedemptionCode = appliedRewards.find(
        (reward) => reward.get('i9nType') === 'redemption_code',
      );

      const rewardViews = [];
      displayableRewards.forEach((reward) => {
        // Find the latest reward, which would be a newer one on the order.
        const rewardToUse = gcn.orderManager.findAppliedReward(reward) || reward;
        const rewardView = new GCNRewardView({ model: rewardToUse });
        const isClaimed = !!appliedRewardByMapKey[rewardToUse.appliedRewardMapKey()];
        rewardView.setClaimed(isClaimed);
        if (rewardToUse.get('notCurrentlyRedeemable')) {
          // If the reward is not valid, disable it but still show it.
          rewardView.setDisabled();
        }

        // For simplicity, we currently do not allow selecting other rewards if there is a
        // single applied reward. It needs to be removed first before others can be interacted
        // with.
        if (orderHasCoupon && !gcn.loyaltyManager.areCouponsAndLoyaltyEarningCompatible()) {
          rewardView.setDisabled();
        }

        if (!isClaimed) {
          if (
            !canRedeemMultipleRewards &&
            appliedRewards.length === 1 &&
            !appliedRewardContainsRedemptionCode
          ) {
            // only disable reward if the only applied reward is not a redemption_code
            rewardView.setDisabled();
          }

          if (
            gcn.loyaltyManager.hasRewardsAndRedemptionCode() &&
            appliedRewards.length === 2 &&
            canRedeemRedemptionAndRewards &&
            !canRedeemMultipleRewards
          ) {
            // this is a very specific use-case where we disable any unClaimed rewards in an order
            // where we allow redemption code & reward but not multiple rewards after two
            // total rewards are allowed
            rewardView.setDisabled();
          }
        }
        const $rewardViewEl = rewardView.render().$el;
        if (isClaimed) {
          // Ensure that any claimed rewards are displayed at the top.
          rewardViews.unshift($rewardViewEl);
        } else {
          rewardViews.push($rewardViewEl);
        }
      });
      $rewardOptions.append(rewardViews);
    } else {
      $rewardOptions.htmlOrText(localizeStr(Strings.LOYALTY_NO_REWARDS_TEXT));
      $rewardOptions.toggleClass('no-rewards', true);
    }

    return this;
  },
});
