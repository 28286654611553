import $ from 'jquery';
import _ from 'underscore';

import { Log } from '@biteinc/common';

import { FSF_VIEW_ENTER_EVENT, FSF_VIEW_EXIT_EVENT } from '../../../helpers/custom_events';
import { GCNView } from './gcn_view';

const transitionEndEvents = 'transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd';

export const GCNFullScreenFlowView = GCNView.extend({
  className: 'full-screen-flow-view',

  initialize(options, ...args) {
    GCNView.prototype.initialize.apply(this, [options, ...args]);

    document.dispatchEvent(FSF_VIEW_ENTER_EVENT);
    this.options = options;

    this.steps = [];
    this.yPosition = 0;
  },

  destroy(...args) {
    GCNView.prototype.destroy.apply(this, args);
    document.dispatchEvent(FSF_VIEW_EXIT_EVENT);

    this._notifyLeaveStep(_.last(this.steps));
    _.each(this.steps, (step) => {
      step.destroy();
    });
    this.steps = [];
  },

  _notifyEnterStep(step) {
    if (step && step.onEnterStep) {
      step.onEnterStep();
    }
  },

  _notifyLeaveStep(step) {
    if (step && step.onLeaveStep) {
      step.onLeaveStep();
    }
  },

  _yPositionForStep(index) {
    const view = this.steps[index];
    if (view) {
      return view.$el.offset().top;
    }
    Log.error(`No view at index ${index}`);
    return 0;
  },

  _getBackgroundForStep(stepNumber) {
    const images = gcn.menu.getFullScreenBackgroundImages();
    if (images) {
      return images[stepNumber % images.length];
    }
    return null;
  },

  _setBackground(image) {
    if (image?.url) {
      gcn.requestImageByUrl(image.url, (err, imgPath) => {
        this.$el.css('background-image', `url(${imgPath})`);
      });
    }
  },

  _generateFirstStep() {
    Log.debug('GCNFullScreenFlowView::_generateFirstStep');
  },

  _updateCurrentStepStatus() {
    this.steps.forEach((step) => {
      step.setIsCurrent(false);
    });
    const currentStep = _.last(this.steps);
    currentStep?.setIsCurrent(true);
    return currentStep;
  },

  nextStep() {
    // New step has already been pushed, so handle the last two.
    this._notifyLeaveStep(this.steps[this.steps.length - 2]);
    this._notifyEnterStep(_.last(this.steps));

    const newBackground = this._getBackgroundForStep(this.steps.length - 1);
    this._setBackground(newBackground);

    this.yPosition -= this._yPositionForStep(this.steps.length - 1);
    this.$contents.css('transform', `translateY(${this.yPosition}px)`);
    this.$contents.bind(transitionEndEvents, () => {
      const currentStep = this._updateCurrentStepStatus();
      if (currentStep?.onShowTransitionEnd) {
        currentStep.onShowTransitionEnd();
      }
      this.$contents.unbind(transitionEndEvents);
    });
  },

  prevStep() {
    this._notifyLeaveStep(_.last(this.steps));
    this.steps.pop();
    this._notifyEnterStep(_.last(this.steps));

    const newBackground = this._getBackgroundForStep(this.steps.length - 1);
    this._setBackground(newBackground);

    this.yPosition -= this._yPositionForStep(this.steps.length - 1);
    this.$contents.bind(transitionEndEvents, () => {
      this._updateCurrentStepStatus();

      this.$contents.children().last().remove();
      this.$contents.unbind(transitionEndEvents);
    });
    this.$contents.css('transform', `translateY(${this.yPosition}px)`);
  },

  render() {
    this.$el.html('');

    this.$el.append($('<div class="contents"></div>'));
    this.$contents = this.$('.contents');

    _.each(this.steps, (step) => {
      this.$contents.append(step.render().$el);
    });
    const newBackground = this._getBackgroundForStep(0);
    this._setBackground(newBackground);

    // Animate in the first pane.
    if (this.steps.length) {
      this._notifyEnterStep(_.last(this.steps));
      const bodyHeight = window.innerHeight || $('body')[0].clientHeight;
      this.$contents.css('transform', `translateY(${bodyHeight}px)`);
      this.$contents.bind(transitionEndEvents, () => {
        const currentStep = this._updateCurrentStepStatus();
        if (currentStep?.onShowTransitionEnd) {
          currentStep.onShowTransitionEnd();
        }
        this.$contents.unbind(transitionEndEvents);
      });
    }
    setTimeout(() => {
      this.$contents.css('transform', 'translateY(0px)');
    }, 1);

    return this;
  },
});
