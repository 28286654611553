import $ from 'jquery';

import { Strings } from '@biteinc/common';

import { localizeStr } from '~/app/js/localization/localization';

import { GCNPriceOptionPickerView } from '../gcn_price_option_picker_view';
import { GCNCustomizeFlowStepView } from './gcn_customize_flow_step_view';
import { GCNFullScreenFlowStepView } from './gcn_full_screen_flow_step_view';

export const GCNPriceOptionStepView = GCNCustomizeFlowStepView.extend({
  className: 'customize-flow-step-view price-option',
  stepType: GCNFullScreenFlowStepView.StepType.PriceOption,

  confirmStep() {
    this.parent.orderedItem.setPriceOption(this.selectedPriceOption);
    return true;
  },

  render(...args) {
    GCNCustomizeFlowStepView.prototype.render.apply(this, args);

    this.setNextDisabled(true);

    this.$stepBody.append(
      // prettier-ignore
      `<div class="header-container">` +
        `<div class="header bg-color-spot-1" role="heading" aria-level="1">${localizeStr(Strings.CHOOSE_OPTION)}</div>` +
      `</div>`,
    );
    const $priceOptions = $('<div class="price-options"></div>');
    this.$stepBody.append($priceOptions);

    const priceOptionPickerView = new GCNPriceOptionPickerView({
      priceOptions: this.parent.orderedItem.item.priceOptions,
      priceOptionChangedCallback: (selectedPriceOption) => {
        this.selectedPriceOption = selectedPriceOption;
        this.setNextDisabled(false);
      },
    });
    $priceOptions.append(priceOptionPickerView.render().$el);

    if (this.parent.orderedItem.orderedPO) {
      priceOptionPickerView.selectPriceOption(this.parent.orderedItem.orderedPO.id);
    }

    return this;
  },
});
