import type { LocalizableString } from '@biteinc/common';
import { ErrorCode, Strings } from '@biteinc/common';
import {
  CardEntryMethod,
  CardSchemeId,
  CardSchemeIdHelper,
  Gateway,
  IntegrationSystem,
} from '@biteinc/enums';

import { str } from '~/app/js/localization/localization';

module GcnPaymentHelper {
  // keep in sync with PaymentHelper.gatewayUsesAuthAndCapture
  export function gatewayUsesAuthAndCapture(gateway: Gateway): boolean {
    switch (gateway) {
      case Gateway.CardConnect: // TODO - this can be changed to false once everyone flows through the back end
      case Gateway.FreedomPayKiosk: // TODO: this isn't actually true... is it?
      case Gateway.Heartland:
      case Gateway.Worldpay: // TODO - this can be changed to false once everyone flows through the back end
        return true;
      case Gateway.Adyen:
      case Gateway.Archer:
      case Gateway.Atrium:
      case Gateway.Cbord:
      case Gateway.ChoptLoyalty:
      case Gateway.Como:
      case Gateway.CreditCall:
      case Gateway.DatatransOloDeprecated:
      case Gateway.DatatransPinataDeprecated:
      case Gateway.Eigen:
      case Gateway.EigenStoredValue:
      case Gateway.Elavon:
      case Gateway.FreedomPayFlash:
      case Gateway.Givex:
      case Gateway.LevelUp:
      case Gateway.None:
      case Gateway.OLOGift:
      case Gateway.OloPayKioskOrderingApi:
      case Gateway.OloPayKioskPaymentApi:
      case Gateway.PaytronixGift:
      case Gateway.PinataPay:
      case Gateway.PinataPayNative:
      case Gateway.PinataStoredValue:
      case Gateway.Square:
      case Gateway.Stripe:
      case Gateway.StripeTerminal:
      case Gateway.TCC:
      case Gateway.Valutec:
      case Gateway.VerifonePointCloud:
      case Gateway.VerifonePointSca:
        return false;
    }
  }

  export function systemSupportsTransactionCancellation(
    system: IntegrationSystem.KioskPayment,
  ): boolean {
    if (!window.hasPaymentTerminal) {
      return true;
    }

    switch (system) {
      case IntegrationSystem.SquareReaderDeprecated:
      case IntegrationSystem.OloPayKioskOrderingApi:
      case IntegrationSystem.OloPayKioskPaymentApi:
      case IntegrationSystem.StripeTerminal:
      case IntegrationSystem.PinataPay:
        return true;
      case IntegrationSystem.Adyen:
      case IntegrationSystem.CardConnect:
      case IntegrationSystem.Eigen:
      case IntegrationSystem.Elavon:
      case IntegrationSystem.FreedomPayKiosk:
      case IntegrationSystem.Heartland:
      case IntegrationSystem.PinataPayNative:
      case IntegrationSystem.VerifonePointCloud:
      case IntegrationSystem.VerifonePointSca:
      case IntegrationSystem.Worldpay:
        return false;
    }
  }

  // keep in sync with PaymentHelper.gatewaySupportsPartialRefunds
  export function gatewaySupportsPartialRefunds(gateway: Gateway): boolean {
    switch (gateway) {
      case Gateway.Adyen:
      case Gateway.CardConnect: // If the payment is fully captured
      case Gateway.CreditCall:
      case Gateway.Eigen:
      case Gateway.EigenStoredValue:
      case Gateway.Elavon:
      case Gateway.FreedomPayFlash:
      case Gateway.FreedomPayKiosk:
      case Gateway.Heartland:
      case Gateway.None:
      case Gateway.PinataPay:
      case Gateway.PinataPayNative:
      case Gateway.PinataStoredValue:
      case Gateway.Square:
      case Gateway.Stripe:
      case Gateway.StripeTerminal:
      case Gateway.VerifonePointCloud:
      case Gateway.Worldpay:
        return true;
      case Gateway.Archer:
      case Gateway.Atrium: // TODO: ??
      case Gateway.Cbord:
      case Gateway.ChoptLoyalty:
      case Gateway.Como:
      case Gateway.DatatransOloDeprecated:
      case Gateway.DatatransPinataDeprecated:
      case Gateway.Givex:
      case Gateway.LevelUp:
      case Gateway.OLOGift:
      case Gateway.OloPayKioskOrderingApi:
      case Gateway.OloPayKioskPaymentApi:
      case Gateway.PaytronixGift: // TODO: ??
      case Gateway.TCC:
      case Gateway.Valutec:
      case Gateway.VerifonePointSca: // TODO(sca): verify
        return false;
    }
  }

  export type TerminalType = 'square' | 'ingenico' | 'verifone' | 'bbpos';
  export function terminalTypeForSystem(system: IntegrationSystem.KioskPayment): TerminalType {
    switch (system) {
      case IntegrationSystem.SquareReaderDeprecated:
        throw new Error('SquareReader is deprecated');
      case IntegrationSystem.CardConnect:
      case IntegrationSystem.Elavon:
      case IntegrationSystem.FreedomPayKiosk:
      case IntegrationSystem.Heartland:
      case IntegrationSystem.PinataPay:
      case IntegrationSystem.PinataPayNative:
      case IntegrationSystem.Worldpay:
        return 'ingenico';
      case IntegrationSystem.VerifonePointCloud:
      case IntegrationSystem.VerifonePointSca:
      case IntegrationSystem.OloPayKioskOrderingApi:
      case IntegrationSystem.OloPayKioskPaymentApi:
      case IntegrationSystem.Eigen: // TODO can also be ingenico
      case IntegrationSystem.Adyen: // Could be more options - but not right now
        return 'verifone';
      case IntegrationSystem.StripeTerminal:
        return 'bbpos';
    }
  }

  export function localizableStringFromCardEntryMethod(
    cardEntryMethod: CardEntryMethod,
  ): LocalizableString {
    switch (cardEntryMethod) {
      case CardEntryMethod.Unknown:
        return Strings.CARD_ENTRY_UNKNOWN;
      case CardEntryMethod.Swipe:
        return Strings.CARD_ENTRY_SWIPE;
      case CardEntryMethod.Chip:
        return Strings.CARD_ENTRY_CHIP;
      case CardEntryMethod.Contactless:
        return Strings.CARD_ENTRY_CONTACTLESS;
      case CardEntryMethod.ManuallyEntered:
        return Strings.CARD_ENTRY_MANUALLY_ENTERED;
      case CardEntryMethod.Scan:
        return Strings.CARD_ENTRY_SCAN;
      case CardEntryMethod.ApplePay:
        return Strings.CARD_ENTRY_APPLE_PAY;
      case CardEntryMethod.GooglePay:
        return Strings.CARD_ENTRY_GOOGLE_PAY;
      case CardEntryMethod.ProvidedExternally:
        return Strings.CARD_ENTRY_PROVIDED_EXTERNALLY;
      case CardEntryMethod.CardOnFile:
        return Strings.CARD_ENTRY_CARD_ON_FILE;
    }
    // @ts-ignore this code could be reached if gcn doesn't yet know about a new CardEntryMethod
    return Strings.CARD_ENTRY_UNKNOWN;
  }

  export function getCardSchemeImage(cardSchemeId: CardSchemeId): string {
    const baseUrl = 'https://assets.getbite.com/images-default/credit-card-icons/';
    switch (cardSchemeId) {
      case CardSchemeId.AmEx:
        return `${baseUrl}card-brand-amex.svg`;
      case CardSchemeId.ChinaUnionPay:
        return `${baseUrl}card-brand-china-union-pay.svg`;
      case CardSchemeId.Diners:
        return `${baseUrl}card-brand-diners-club.svg`;
      case CardSchemeId.Discover:
        return `${baseUrl}card-brand-discover.svg`;
      case CardSchemeId.MasterCard:
        return `${baseUrl}card-brand-master-card.svg`;
      case CardSchemeId.JCB:
        return `${baseUrl}card-brand-jcb.svg`;
      case CardSchemeId.Visa:
        return `${baseUrl}card-brand-visa.svg`;
    }
    return `${baseUrl}card-brand-empty.svg`;
  }

  export function getKioskPaymentErrorMessage(
    errorCode: ErrorCode,
    entryMode: CardEntryMethod,
    cardSchemeId: CardSchemeId,
  ): string {
    switch (errorCode) {
      case ErrorCode.PaymentTerminalNotFound:
        return str(Strings.ERROR_KIOSK_PAYMENT_DEVICE_NOT_FOUND);
      case ErrorCode.PaymentTerminalOffline:
        return str(Strings.ERROR_KIOSK_PAYMENT_DEVICE_OFFLINE);
      case ErrorCode.PaymentCardSchemeIdNotAccepted:
        return str(Strings.ERROR_CARD_SCHEME_NOT_ACCEPTED).replace(
          '{0}',
          // We should be using a translation function but the values we care about here don't get
          // translated.
          // eslint-disable-next-line no-restricted-syntax
          CardSchemeIdHelper.name(cardSchemeId),
        );
    }
    if (entryMode === CardEntryMethod.Contactless) {
      return str(Strings.ERROR_KIOSK_CARD_DECLINED_TAP);
    }
    return str(Strings.ERROR_KIOSK_CARD_DECLINED);
  }

  export function canWeRetryKioskPayment(errorCode: Number): boolean {
    switch (errorCode) {
      // If we know our payment device is either not found or offline there isn't a point of retrying
      // as the second attempt will fail
      case ErrorCode.PaymentTerminalNotFound:
      case ErrorCode.PaymentTerminalOffline:
        return false;

      default:
        return true;
    }
  }
}

export default GcnPaymentHelper;
