/**  these events serve as a way to communicate between backbone and react
 *   example
 *      within a backbone view:
 *      document.dispatchEvent(CHECKOUT_VIEW_ENTER_EVENT);
 *
 *      within a react component:
 *      React.useEffect(() => {
 *
 *        function listener() {
 *         // do something
 *        }
 *         document.addEventListener(CHECKOUT_VIEW_ENTER, listener);
 *
 *       return () => {
 *         document.removeEventListener(CHECKOUT_VIEW_ENTER, listener);
 *      },[])
 */
export const PAGE_NAV_VIEW_SHOW = 'page_nav_view_show';
export const PAGE_NAV_VIEW_HIDE = 'page_nav_view_hide';
export const PAGE_NAV_VIEW_SHOW_EVENT = new Event(PAGE_NAV_VIEW_SHOW);
export const PAGE_NAV_VIEW_HIDE_EVENT = new Event(PAGE_NAV_VIEW_HIDE);

export const CHECKOUT_VIEW_ENTER = 'checkout_view_enter';
export const CHECKOUT_VIEW_EXIT = 'checkout_view_exit';
export const CHECKOUT_VIEW_ENTER_EVENT = new Event(CHECKOUT_VIEW_ENTER);
export const CHECKOUT_VIEW_EXIT_EVENT = new Event(CHECKOUT_VIEW_EXIT);

export const FSF_VIEW_ENTER = 'fsf_view_enter';
export const FSF_VIEW_EXIT = 'fsf_view_exit';
export const FSF_VIEW_ENTER_EVENT = new Event(FSF_VIEW_ENTER);
export const FSF_VIEW_EXIT_EVENT = new Event(FSF_VIEW_EXIT);

export const POPUP_VIEW_ENTER = 'popup_view_enter';
export const POPUP_VIEW_EXIT = 'popup_view_exit';

export const LOYALTY_VIEW_EXIT = 'loyalty_view_exit';
export const LOYALTY_VIEW_EXIT_EVENT = new Event(LOYALTY_VIEW_EXIT);
