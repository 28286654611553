// cspell:ignore accesstoken activeorder activeorders

import async from 'async';

import { Log } from '@biteinc/common';
import { StringHelper } from '@biteinc/core-react';
import {
  BiteLogLrsTableTrackerEvent,
  BiteLogType,
  FlashBridgeMessage,
  FulfillmentMethod,
  IntegrationSystem,
} from '@biteinc/enums';
import { PromiseHelper, Time } from '@biteinc/helpers';

import type { GcnOrder } from '~/types/gcn_order';

import type GcnLocation from '../models/gcn_location';
import type { I9nClient } from './i9n_client';

enum OrderType {
  OnPremises = 'ON_PREMISES',
  ToGo = 'TO_GO',
}

type GenerateTokenResponse = {
  status: number;
  returnCode: number;
  token: {
    name: string;
    token: string;
    creation_data: string;
    last_user: string | null;
    ip_address: string | null;
  };
};

type ActivateOrderResponse = {
  status: number;
  returnCode: number;
  activeorder: {
    uuid: string;
    created: string;
    orderType: OrderType;
    locationName: string;
    state: string;
    stateChanged: string;
    name: string;
    paged: boolean;
    elapsedTime: number;
  };
};

export class LrsTableTrackerClient implements I9nClient {
  private gatewayIpAddress: string | null = null;

  private gatewayAccessToken: string | null = null;

  constructor(public readonly i9nData: GcnLocation.PartialLrsTableTrackerI9n) {
    if (i9nData.gatewayIpAddress) {
      this.gatewayIpAddress = i9nData.gatewayIpAddress;
      Log.info('LRS TT | Constructing with Gateway IP Address');
    }
    if (i9nData.gatewayAccessToken) {
      this.gatewayAccessToken = i9nData.gatewayAccessToken;
      Log.info('LRS TT | Constructing with Gateway Access Token');
    }
  }

  private async validateIpAddress(): Promise<boolean> {
    if (!this.gatewayIpAddress) {
      // A null IP address is not valid.
      Log.info('LRS TT | Gateway IP Address is null');
      return false;
    }

    try {
      // Check if the IP address is valid.
      await this.performHttpRequest({
        method: 'GET',
        path: '/api/v2',
      });
      Log.info('LRS TT | Gateway IP Address is valid');
      return true;
    } catch (err) {
      Log.info(`LRS TT | Gateway IP Address is invalid: ${this.gatewayIpAddress}`);
      this.sendLog({
        status: BiteLogLrsTableTrackerEvent.GetIpAddressError,
        message: `Gateway IP address is invalid: ${this.gatewayIpAddress}`,
        error: this.serializeError(err),
      });
      this.gatewayIpAddress = null;

      return false;
    }
  }

  private async getGatewayIpAddress(): Promise<string | null> {
    return new Promise<string | null>((resolve, reject) => {
      try {
        gcn.bridge.send<{ success: boolean; response: string }>(
          {
            event: FlashBridgeMessage.GET_LRS_TABLE_TRACKER_GATEWAY_IP_ADDRESS,
          },
          ({ success, response }) => {
            if (success) {
              resolve(response);
            } else {
              reject(response);
            }
          },
        );
      } catch (e) {
        reject(e);
      }
    });
  }

  private async findIpAddress(): Promise<void> {
    if (await this.validateIpAddress()) {
      // We already have a valid IP address.
      this.sendLog({
        status: BiteLogLrsTableTrackerEvent.GetIpAddressSuccess,
        message: `Using existing IP address: ${this.gatewayIpAddress}`,
      });

      return;
    }

    try {
      const phiGatewayIpAddress = await this.getGatewayIpAddress();
      if (!phiGatewayIpAddress) {
        this.sendLog({
          status: BiteLogLrsTableTrackerEvent.GetIpAddressError,
          message: 'Gateway IP address could not be found',
        });
        return;
      }
      this.gatewayIpAddress = phiGatewayIpAddress;
    } catch (err) {
      this.sendLog({
        status: BiteLogLrsTableTrackerEvent.GetIpAddressError,
        message: 'Gateway IP address could not be found',
        error: this.serializeError(err),
      });

      throw err;
    }

    Log.info(`LRS TT | Gateway IP Address was found: ${this.gatewayIpAddress}`);
    this.sendLog({
      status: BiteLogLrsTableTrackerEvent.GetIpAddressSuccess,
      message: `Found IP address: ${this.gatewayIpAddress}`,
    });

    // We found an IP address. Save it to the backend for future use.
    await gcn.maitred.updateFrontEndI9nDataRequest(this.i9nData._id, {
      system: IntegrationSystem.LrsTableTracker,
      gatewayIpAddress: this.gatewayIpAddress,
    });
  }

  private async validateAccessToken(): Promise<boolean> {
    if (!this.gatewayAccessToken) {
      // A null access token is not valid.
      Log.info('LRS TT | Gateway Access Token is null');
      return false;
    }

    try {
      // Check if the access token is valid.
      await this.performHttpRequest({
        method: 'GET',
        path: '/api/v2/accesstoken',
        secured: true,
      });
      Log.info('LRS TT | Gateway Access Token is valid');

      return true;
    } catch (err) {
      this.gatewayAccessToken = null;

      Log.info('LRS TT | Gateway Access Token is invalid');
      this.sendLog({
        status: BiteLogLrsTableTrackerEvent.GetAccessTokenError,
        message: 'Gateway access token is invalid',
        error: this.serializeError(err),
      });

      return false;
    }
  }

  private async generateAccessToken(): Promise<void> {
    if (await this.validateAccessToken()) {
      // We already have a valid access token.
      this.sendLog({
        status: BiteLogLrsTableTrackerEvent.GetAccessTokenSuccess,
        message: `Using existing access token`,
      });

      return;
    }

    let finished = false;

    await async.doUntil(
      async (callback) => {
        try {
          const generateAccessTokenResponse = await this.performHttpRequest<GenerateTokenResponse>({
            method: 'GET',
            path: '/api/v2/accesstoken/generate',
          });

          this.gatewayAccessToken = generateAccessTokenResponse.token.token;
          finished = true;

          // Upload the access token to the backend to be shared across kiosks.
          await gcn.maitred.updateFrontEndI9nDataRequest(this.i9nData._id, {
            system: IntegrationSystem.LrsTableTracker,
            gatewayAccessToken: this.gatewayAccessToken,
          });
        } catch (err) {
          switch (err.status) {
            case 403:
              Log.warn('LRS TT | Gateway Access Token was not generated; trying again in 1 minute');
              this.sendLog({
                status: BiteLogLrsTableTrackerEvent.GetAccessTokenWaiting,
                message: 'Could not generate access token. Please click the button on the gateway.',
                error: this.serializeError(err),
              });
              break;
            default:
              Log.error('LRS TT | Gateway Access Token was not generated; could not reach gateway');
              this.sendLog({
                status: BiteLogLrsTableTrackerEvent.GetAccessTokenError,
                message: 'Could not generate access token. Please check connection to gateway.',
                error: this.serializeError(err),
              });

              // If we can't reach the gateway, then we can't generate the access token.
              finished = true;
              break;
          }
        }

        if (!finished) {
          // The window for generating the access token is 2 minutes, so waiting a minute between
          // attempts should be sufficient.
          await PromiseHelper.sleep(Time.MINUTE);

          // Check the backend to see if another kiosk was able to generate the access token.

          // Perform this check after the first attempt since otherwise the access token that we
          // fetch will likely be the one that we had at the very start, if any.

          const { gatewayAccessToken } = await gcn.maitred.getFrontEndI9nDataRequest(
            this.i9nData._id,
          );

          if (gatewayAccessToken) {
            this.gatewayAccessToken = gatewayAccessToken;
            if (await this.validateAccessToken()) {
              Log.info('LRS TT | Gateway Access Token was fetched from server');
              finished = true;
            } else {
              // If the access token from the gateway is invalid, then generate a new one.
              this.gatewayAccessToken = null;
            }
          }
        }

        callback();
      },
      async (callback) => {
        callback(null, finished);
      },
    );

    if (this.gatewayAccessToken) {
      Log.info('LRS TT | Gateway Access Token was generated');
      this.sendLog({
        status: BiteLogLrsTableTrackerEvent.GetAccessTokenSuccess,
        message: `Got access token`,
      });
    }
  }

  async configure(): Promise<void> {
    await this.findIpAddress();
    if (!this.gatewayIpAddress) {
      return;
    }

    await this.generateAccessToken();
  }

  private parseOrderType(fulfillmentMethod: FulfillmentMethod): OrderType {
    switch (fulfillmentMethod) {
      case FulfillmentMethod.KIOSK_TO_GO:
        return OrderType.ToGo;
      case FulfillmentMethod.KIOSK_DINE_IN:
      case FulfillmentMethod.KIOSK_OUTPOST:
        return OrderType.OnPremises;
      default:
        return OrderType.OnPremises;
    }
  }

  async sendOrder(order: GcnOrder): Promise<void> {
    // The table number is actually the tracker number.
    // We need it to send the order to the LRS gateway.
    if (!order.attributes.tableNumber) {
      Log.warn(`LRS TT | Order does not have a tracker number; cannot activate: ${order.id}`);
      this.sendLog({
        status: BiteLogLrsTableTrackerEvent.ActivateOrderSkipped,
        message: `Order does not have a tracker number: ${order.id}`,
      });

      return;
    }

    if (!this.gatewayIpAddress) {
      Log.error('LRS TT | Gateway IP address not set; cannot activate order');
      this.sendLog({
        status: BiteLogLrsTableTrackerEvent.ActivateOrderError,
        message: 'Gateway IP address not set',
      });

      return;
    }
    if (!this.gatewayAccessToken) {
      Log.error('LRS TT | Gateway access token not set; cannot activate order');
      this.sendLog({
        status: BiteLogLrsTableTrackerEvent.ActivateOrderError,
        message: 'Gateway access token not set',
      });

      return;
    }

    try {
      const response = await this.performHttpRequest<ActivateOrderResponse>({
        method: 'POST',
        path: '/api/v2/activeorders',
        secured: true,
        data: {
          name: order.attributes.tableNumber,
          orderType: this.parseOrderType(order.attributes.fulfillmentMethod),
        },
      });

      this.sendLog({
        status: BiteLogLrsTableTrackerEvent.ActivateOrderSuccess,
        message: `Order activated: ${response.activeorder.uuid}`,
      });
    } catch (err) {
      switch (err.status) {
        case 401:
          // Invalid access token
          this.gatewayAccessToken = null;

          Log.error('LRS TT | Order Access Token is invalid');
          this.sendLog({
            status: BiteLogLrsTableTrackerEvent.ActivateOrderError,
            message: 'Gateway access token is invalid',
            error: this.serializeError(err),
          });
          break;
        default:
          Log.error('LRS TT | Order could not be activated');
          this.sendLog({
            status: BiteLogLrsTableTrackerEvent.ActivateOrderError,
            message: 'Order could not be activated',
            error: this.serializeError(err),
          });
          break;
      }
    }
  }

  private async performHttpRequest<ResponseData>({
    method,
    path,
    secured = false,
    data,
  }: {
    method: string;
    path: string;
    secured?: boolean;
    data?: Record<string, any>;
  }): Promise<ResponseData> {
    if (!this.gatewayIpAddress) {
      throw new Error('Gateway IP address is not set');
    }
    if (secured && !this.gatewayAccessToken) {
      throw new Error('Gateway access token is not set');
    }

    const url = `http://${this.gatewayIpAddress}:8000${path}`;

    const reqAt = Date.now();
    Log.info(`LRS TT | ${method} ${url}${data ? ` ${JSON.stringify(data)}` : ''}`);

    return new Promise<ResponseData>((resolve, reject) => {
      $.ajax(url, {
        method,
        headers: {
          ...(secured && { Authorization: `Bearer ${this.gatewayAccessToken}` }),
        },
        ...(data && { data: JSON.stringify(data) }),
        contentType: 'application/json',
        dataType: 'json',
        timeout: 15 * Time.SECOND,
        success: (responseData) => {
          this.logHttpResponse({ method, url, reqAt, isError: false, responseData });
          resolve(responseData);
        },
        error: (jqXhr) => {
          this.logHttpResponse({
            method,
            url,
            reqAt,
            isError: true,
            responseData: jqXhr.responseJSON,
          });
          reject(jqXhr);
        },
      });
    });
  }

  private logHttpResponse({
    method,
    url,
    reqAt,
    isError,
    responseData,
  }: {
    method: string;
    url: string;
    reqAt: number;
    isError: boolean;
    responseData?: Record<string, any>;
  }): void {
    const resAt = Date.now();
    const duration = resAt - reqAt;
    let output = `LRS TT | ${method} ${url} ${duration}ms E:${isError}`;
    if (responseData) {
      output += ` ${JSON.stringify(responseData)}`;
    }
    Log.info(output);
  }

  private getClientName(): string {
    if (window.isKioskPreview) {
      return 'kiosk-preview';
    }
    if (gcn.kiosk) {
      return gcn.kiosk.get('name');
    }

    return 'unknown';
  }

  private sendLog(logData: {
    status: BiteLogLrsTableTrackerEvent;
    message: string;
    error?: string;
  }): void {
    const kiosk = gcn.kiosk;
    const order = gcn.orderManager.getOrder();

    gcn.maitred.sendLog({
      type: BiteLogType.LrsTableTracker,
      clientId: StringHelper.newMongoId(),
      clientName: this.getClientName(),
      createdAt: Date.now(),
      ...(kiosk && { kioskId: kiosk.get('_id') }),
      ...(order && { orderId: order.id }),
      ...logData,
    });
  }

  private serializeError(errorOfAnyType: any): string {
    if (typeof errorOfAnyType === 'object') {
      if (errorOfAnyType instanceof Error) {
        return errorOfAnyType.message;
      }

      return JSON.stringify(errorOfAnyType);
    }

    return `${errorOfAnyType}`;
  }
}
