/* eslint-disable prefer-arrow-callback */
import * as React from 'react';
import _ from 'underscore';

import { Log, PaymentCode, Strings } from '@biteinc/common';
import { BiteLogFreedomPayEvent, BiteLogType, CardEntryMethod } from '@biteinc/enums';

import { localizeStr } from '~/app/js/localization/localization';

import type { Fetcher } from '../../../app/js/utils/use-fetcher';
import { useFetcher } from '../../../app/js/utils/use-fetcher';
import { GcnCustomerAccountHelper } from '../../../helpers';
import { useStore } from '../../../stores';
import type { EcommPaymentFormProps } from '../ecomm-payment-form';

export module FreedomPayEcommUtils {
  export type SavedCard = {
    _id: string;
    last4: string;
    expirationMonth: number;
    expirationYear: number;
    cardSchemeId: number;
    gatewayToken: string;
    postalCode: string;
    nickname?: string;
    nameOnCard?: string;
  };

  type ApplePayPaymentContact = {
    emailAddress?: string;
    familyName?: string;
    givenName?: string;
    phoneNumber?: string;
    phoneticFamilyName?: string;
    phoneticGivenName?: string;
    addressLines?: string[];
    locality?: string;
    subLocality?: string;
    administrativeArea?: string;
    subAdministrativeArea?: string;
    postalCode?: string;
    country?: string;
    countryCode?: string;
  };

  type FreedomPayEventAttribute = {
    Key: string;
    Value: string | ApplePayPaymentContact;
  };

  type FreedomPayEventError = {
    data: null;
    emittedBy: 'ApplePay' | 'RequestPaymentKey';
    errorType: number;
    message: string;
    messageCode: string;
  };

  export type FreedomPayEventData = {
    height?: number;
    paymentType?: string;
    attributes?: FreedomPayEventAttribute[];
    emittedBy?: 'CardNumber' | 'ExpirationDate' | 'SecurityCode' | 'PostalCode';
    isValid?: boolean;
    message: string | undefined;
    type?: number;
    paymentKeys?: string[];
    errors: FreedomPayEventError[];
  };

  export type FreedomPayEvent = {
    isTrusted: boolean;
    bubbles: boolean;
    cancelBubble: boolean;
    cancelable: boolean;
    composed: boolean;
    currentTarget: Window;
    data: {
      data: FreedomPayEventData;
      messageCode: undefined | string;
      type: 1 | 2 | 3 | 4;
    };
    defaultPrevented: boolean;
    eventPhase: number;
    lastEventId: string;
    origin: string;
    path: Window[];
    ports: string[];
    returnValue: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    source: any;
    srcElement: Window;
    target: Window;
    timeStamp: number;
    type: string;
    userActivation: null | boolean;
  };

  type CardEntryStatus = 'valid' | 'invalid' | 'unknown';

  type CardEntry = {
    name: string;
    status: CardEntryStatus;
  };

  type FreedomPayEcommFormState = {
    onSelectCard: (card: SavedCard | null) => void;
    onToggleSaveOnSale: () => void;
    saveOnSale: boolean;
    savedCards: Fetcher<SavedCard[]>;
    selectedCard: SavedCard | undefined;
    hasCustomer: boolean;
    iFrames: {
      googleWallet: Fetcher<
        IframeData & {
          type: 'google';
        }
      > & {
        state: {
          height: number;
          errors: string[];
        };
      };
      appleWallet: Fetcher<
        IframeData & {
          type: 'apple';
        }
      > & {
        state: {
          height: number;
          errors: string[];
        };
      };
      paymentForm: Fetcher<IframeData> & {
        state: {
          height: number;
          errors: string[];
          cardEntry: CardEntry;
          updateCard: (card: CardEntry) => void;
        };
      };
      savedCard: Fetcher<IframeData> & {
        state: {
          height: number;
          errors: string[];
        };
      };
    };
  };

  type SubmitPaymentConfig = {
    data: FreedomPayEcommUtils.FreedomPayEventData;
    cardEntryMethod: CardEntryMethod;
    nameOnCard: string;
    card?: FreedomPayEcommUtils.SavedCard;
    sessionKey?: string;
  };

  export type IframeData = {
    iframe: string;
    sessionKey: string;
    workflowId: string;
  };

  type CardExpirationStatus = 'expired' | 'expiring' | 'valid';

  const IFRAME_CARD_ID = 'hpc--card-frame';
  const IFRAME_CARD_ON_FILE = 'hpc--cardonfile-frame';
  const IFRAME_APPLE_PAY = `hpc--applepay-frame`;
  const IFRAME_GOOGLE_PAY = `hpc--googlepay-frame`;

  const FREEDOM_PAY_CODES = {
    ERROR: 1,
    IFRAME_HEIGHT: 2,
    SUBMIT: 3,
    CARD_FIELD_VALIDATED: 4,
  };

  export function getCardExpirationNotice(status: CardExpirationStatus): string | null {
    switch (status) {
      case 'valid':
        return null;
      case 'expiring':
        return localizeStr(Strings.CARD_EXPIRING);
      case 'expired':
        return localizeStr(Strings.CARD_EXPIRED);
    }
  }

  export function getCardExpirationStatus(card: SavedCard): CardExpirationStatus {
    const date = new Date();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const expirationYear = card.expirationYear + 2000;
    const expirationMonth = card.expirationMonth;
    if (expirationYear < year) {
      return 'expired';
    }
    if (expirationYear === year) {
      if (expirationMonth === month) {
        return 'expiring';
      }
      if (expirationMonth < month) {
        return 'expired';
      }
    }
    return 'valid';
  }

  export async function getSavedCardIframe(): Promise<IframeData> {
    return new Promise((resolve, reject) => {
      gcn.maitred.fetchCreditCardEntryIframe({ type: 'token' }, (err, data) => {
        if (err) {
          Log.error(err);
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  }

  function handleIframeMessage(iframeId: string, handler: (event: MessageEvent) => void) {
    return (event: MessageEvent) => {
      const iframe = document.getElementById(iframeId) as HTMLIFrameElement;
      if (iframe && event.source === iframe.contentWindow) {
        handler(event);
      }
      /**
       * In the case of apple pay, the event source is the window, not the iframe.
       */
      if (iframe && event.origin === window.location.origin) {
        handler(event);
      }
    };
  }

  export async function getCustomerSavedCards(): Promise<SavedCard[]> {
    return new Promise((resolve, reject) => {
      gcn.maitred.getCustomerResource('payment-methods', null, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data.paymentMethods);
        }
      });
    });
  }

  export async function getPaymentFormIframe(): Promise<IframeData> {
    return new Promise<IframeData>((resolve, reject) => {
      gcn.maitred.fetchCreditCardEntryIframe(null, (err, data) => {
        if (err) {
          gcn.bridge.sendLog(
            {
              status: BiteLogFreedomPayEvent.IframeInitError,
              systemCode: PaymentCode.PaymentIframeError,
              message: err.message,
            },
            BiteLogType.FreedomPay,
          );
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  }

  export async function getWalletIframe<T extends 'google' | 'apple'>(options: {
    type: T;
    total: number;
  }): Promise<
    IframeData & {
      type: T;
    }
  > {
    return new Promise((resolve, reject) => {
      gcn.maitred.fetchCreditCardEntryIframe(options, (err, data) => {
        if (err) {
          gcn.bridge.sendLog(
            {
              status: BiteLogFreedomPayEvent.IframeInitError,
              systemCode: PaymentCode.PaymentIframeError,
              message: err.message,
            },
            BiteLogType.FreedomPay,
          );
          reject(err);
        } else {
          resolve({
            ...data,
            type: options.type,
          });
        }
      });
    });
  }

  function calculateCardNameFromAttributes(attributes: FreedomPayEventAttribute[]): string | null {
    // Google Pay Logic
    const name = attributes.find((attribute) => {
      return attribute.Key === 'Name';
    });

    if (name && typeof name?.Value === 'string') {
      return name.Value;
    }
    // Apple Pay
    const billingContact = attributes.find((attribute) => {
      return attribute.Key === 'billingContact';
    });
    if (!billingContact || !billingContact.Value) {
      return null;
    }

    if (typeof billingContact.Value === 'string') {
      try {
        const info = JSON.parse(billingContact.Value) as {
          givenName: string;
          familyName: string;
        };

        return `${info.givenName} ${info.familyName}`;
      } catch {
        return null;
      }
    }
    if (!billingContact.Value.givenName || !billingContact.Value.familyName) {
      return null;
    }

    return `${billingContact.Value.givenName} ${billingContact.Value.familyName}`;
  }

  export function useSavedCards(hasCustomer: boolean): Fetcher<SavedCard[]> {
    const fetcher = useFetcher(getCustomerSavedCards);
    React.useEffect(() => {
      if (hasCustomer) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        fetcher.get();
      }
    }, [hasCustomer]);

    return fetcher;
  }

  export function useAppleWalletIframe(props: {
    subTotal: number;
    submitPayment: (config: SubmitPaymentConfig) => void;
  }): Fetcher<
    IframeData & {
      type: 'apple';
    }
  > & { state: { height: number; errors: string[] } } {
    const [height, setHeight] = React.useState(144);
    const [formErrors, setFormErrors] = React.useState<string[]>([]);
    const fetcher = useFetcher(async () => {
      if (
        window.FreedomPay?.Apm?.ApplePay?.isSupported() &&
        window.FreedomPay?.Apm?.ApplePay?.canMakePayments()
      ) {
        window.FreedomPay.Apm.ApplePay.initialize({
          requiredBillingContactFields: ['email'],
          requiredShippingContactFields: [],
        });
        return getWalletIframe({
          type: 'apple',
          total: props.subTotal,
        });
      }
      throw new Error('Apple wallet unavailable');
    });

    React.useEffect(() => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      fetcher.get();
    }, []);

    React.useEffect(
      function handleApplePayIframe() {
        const handleMessage = handleIframeMessage(IFRAME_APPLE_PAY, (event) => {
          const message = event.data;
          const data = event.data.data as FreedomPayEventData;

          switch (message.type) {
            case FREEDOM_PAY_CODES.ERROR: {
              const errors = data.errors ?? [];
              errors.forEach((error) => {
                gcn.bridge.sendLog(
                  {
                    status: BiteLogFreedomPayEvent.TokenizationError,
                    systemCode: PaymentCode.PaymentIframeError,
                    message: error.message,
                  },
                  BiteLogType.FreedomPay,
                );
              });
              const formattedErrors = _.flatten(
                errors.map((error) => {
                  if (error.message === 'Browser is not supported for ApplePay.') {
                    return [
                      'Please use an Apple device with a modern version of Safari.',
                      'The device must have Touch ID or Face ID and must be logged into an Apple account that has ApplePay enabled.',
                    ];
                  }

                  return error.message;
                }),
              );
              setFormErrors(formattedErrors);
              break;
            }
            case FREEDOM_PAY_CODES.IFRAME_HEIGHT: {
              setHeight(data.height!);
              break;
            }
            case FREEDOM_PAY_CODES.SUBMIT: {
              props.submitPayment({
                data,
                cardEntryMethod: CardEntryMethod.ApplePay,
                nameOnCard: calculateCardNameFromAttributes(data.attributes!) || '',
                sessionKey: fetcher.data?.sessionKey,
              });

              break;
            }
          }
        });

        window.addEventListener('message', handleMessage);
        return () => {
          window.removeEventListener('message', handleMessage);
        };
      },
      [fetcher.data?.sessionKey, props],
    );

    return {
      ...fetcher,
      state: { height, errors: formErrors },
    };
  }

  export function useGoogleWalletIframe(props: {
    subTotal: number;
    submitPayment: (config: SubmitPaymentConfig) => void;
  }): Fetcher<
    IframeData & {
      type: 'google';
    }
  > & { state: { height: number; errors: string[] } } {
    const [height, setHeight] = React.useState(144);
    const [formErrors, setFormErrors] = React.useState<string[]>([]);
    const fetcher = useFetcher(async () => {
      /**
       * Google Pay is not supported on safari
       */
      if (!window.FreedomPay?.Apm?.ApplePay?.isSupported() && window.PaymentRequest) {
        // Check if Payment Request API exists in browser
        return getWalletIframe({
          type: 'google',
          total: props.subTotal,
        });
      }
      throw new Error('Google wallet unavailable');
    });

    React.useEffect(() => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      fetcher.get();
    }, []);

    React.useEffect(
      function handleGooglePayIframe() {
        const handleMessage = handleIframeMessage(IFRAME_GOOGLE_PAY, (event) => {
          const message = event.data;
          const data = event.data.data as FreedomPayEventData;

          switch (message.type) {
            case FREEDOM_PAY_CODES.ERROR: {
              const errors = data.errors ?? [];
              errors.forEach((error) => {
                gcn.bridge.sendLog(
                  {
                    status: BiteLogFreedomPayEvent.TokenizationError,
                    systemCode: PaymentCode.PaymentIframeError,
                    message: error.message,
                  },
                  BiteLogType.FreedomPay,
                );
              });
              const formattedErrors = errors.map((error) => {
                return error.message;
              });
              setFormErrors(formattedErrors);
              break;
            }
            case FREEDOM_PAY_CODES.IFRAME_HEIGHT: {
              setHeight(data.height!);
              break;
            }
            case FREEDOM_PAY_CODES.SUBMIT: {
              props.submitPayment({
                data,
                cardEntryMethod: CardEntryMethod.GooglePay,
                nameOnCard: calculateCardNameFromAttributes(data.attributes!) || '',
                sessionKey: fetcher.data?.sessionKey,
              });
              break;
            }
          }
        });
        window.addEventListener('message', handleMessage);
        return () => {
          window.removeEventListener('message', handleMessage);
        };
      },
      [fetcher.data?.sessionKey, props],
    );

    return {
      ...fetcher,
      state: { height, errors: formErrors },
    };
  }

  export function usePaymentFormIframe(
    submitPayment: (config: SubmitPaymentConfig) => void,
  ): Fetcher<IframeData> & {
    state: {
      height: number;
      errors: string[];
      cardEntry: CardEntry;
      updateCard: (cardEntry: CardEntry) => void;
    };
  } {
    const fetcher = useFetcher(getPaymentFormIframe);
    const [height, setHeight] = React.useState(400);
    const [formErrors, setFormErrors] = React.useState<string[]>([]);
    const [cardEntry, setCardEntry] = React.useState<CardEntry>({
      name: '',
      status: 'unknown',
    });

    function updateCard(card: CardEntry): void {
      setCardEntry(card);
    }

    React.useEffect(() => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      fetcher.get();
    }, []);

    React.useEffect(
      function handlePaymentFormIframe() {
        const handleMessage = handleIframeMessage(IFRAME_CARD_ID, (e) => {
          const message = e.data;
          const data = message.data as FreedomPayEventData;

          const cardName = cardEntry.name;
          const card = {
            name: cardName ?? '',
            status: (cardName ? 'valid' : 'invalid') as CardEntryStatus,
          };

          switch (message.type) {
            case FREEDOM_PAY_CODES.ERROR: {
              const errors = data.errors ?? [];
              errors.forEach((error) => {
                gcn.bridge.sendLog(
                  {
                    status: BiteLogFreedomPayEvent.TokenizationError,
                    systemCode: PaymentCode.PaymentIframeError,
                    message: error.message,
                  },
                  BiteLogType.FreedomPay,
                );
              });
              const formattedErrors = errors.map((error) => {
                return error.message;
              });
              setFormErrors(formattedErrors);
              break;
            }
            case FREEDOM_PAY_CODES.IFRAME_HEIGHT: {
              setHeight(data.height!);
              break;
            }
            case FREEDOM_PAY_CODES.SUBMIT: {
              if (card.status === 'valid') {
                submitPayment({
                  data,
                  cardEntryMethod: CardEntryMethod.ManuallyEntered,
                  nameOnCard: card.name,
                  sessionKey: fetcher.data?.sessionKey,
                });
              } else {
                updateCard(card);
              }
              break;
            }
          }
        });

        window.addEventListener('message', handleMessage);
        return () => {
          window.removeEventListener('message', handleMessage);
        };
      },
      [submitPayment, fetcher.data?.sessionKey, cardEntry.name],
    );

    return {
      ...fetcher,
      state: {
        height,
        errors: formErrors,
        cardEntry,
        updateCard,
      },
    };
  }

  export function useSavedCardIframe(props: {
    selectedSavedCard: SavedCard | undefined;
    submitPayment: (config: SubmitPaymentConfig) => void;
  }): Fetcher<IframeData> & {
    state: {
      height: number;
      errors: string[];
    };
  } {
    const [height, setHeight] = React.useState(144);
    const [formErrors, setFormErrors] = React.useState<string[]>([]);
    const fetcher = useFetcher(getSavedCardIframe);
    React.useEffect(() => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      fetcher.get();
    }, []);

    React.useEffect(
      function handleSavedCardIframe() {
        const handleMessage = handleIframeMessage(IFRAME_CARD_ON_FILE, (event) => {
          const message = event.data;
          const data = event.data.data as FreedomPayEventData;

          switch (message.type) {
            case FREEDOM_PAY_CODES.ERROR: {
              const errors = data.errors ?? [];
              errors.forEach((error) => {
                gcn.bridge.sendLog(
                  {
                    status: BiteLogFreedomPayEvent.TokenizationError,
                    systemCode: PaymentCode.PaymentIframeError,
                    message: error.message,
                  },
                  BiteLogType.FreedomPay,
                );
              });
              const formattedErrors = errors.map((error) => {
                return error.message;
              });
              setFormErrors(formattedErrors);
              break;
            }
            case FREEDOM_PAY_CODES.IFRAME_HEIGHT: {
              setHeight(data.height!);
              break;
            }
            case FREEDOM_PAY_CODES.SUBMIT: {
              props.submitPayment({
                data,
                cardEntryMethod: CardEntryMethod.CardOnFile,
                nameOnCard: props.selectedSavedCard?.nameOnCard || '',
                card: props.selectedSavedCard,
                sessionKey: fetcher.data?.sessionKey,
              });

              break;
            }
          }
        });

        window.addEventListener('message', handleMessage);

        return () => {
          window.removeEventListener('message', handleMessage);
        };
      },
      [fetcher.data?.sessionKey, props],
    );

    return {
      ...fetcher,
      state: {
        height,
        errors: formErrors,
      },
    };
  }

  export function useFreedomPayState(props: EcommPaymentFormProps): FreedomPayEcommFormState {
    const onEcommPaymentUpdated = useStore((state) => state.checkout.onEcommPaymentUpdated);
    function submitPayment(config: SubmitPaymentConfig): void {
      onEcommPaymentUpdated({
        value: '',
        isValid: true,
      });
      gcn.orderManager.setEcommPaymentMethod({
        ...config.data,
        nameOnCard: config.nameOnCard,
        sessionKey: config.sessionKey,
        cardEntryMethod: config.cardEntryMethod,
        cardOnFile: config.card,
        saveOnSale,
      });
      if (config.cardEntryMethod === CardEntryMethod.ApplePay) {
        window?.top?.document?.dispatchEvent(new CustomEvent('ApplePayFinalize', { detail: true }));
      }
      gcn.menuView.showSpinner(localizeStr(Strings.CONFIRMING_YOUR_CARD));

      props.onSubmit();
    }

    const hasCustomer =
      GcnCustomerAccountHelper.customerAccountsAreEnabled() &&
      Boolean(gcn.orderManager.getCustomer());

    const savedCards = FreedomPayEcommUtils.useSavedCards(hasCustomer);
    const appleWalletIframe = FreedomPayEcommUtils.useAppleWalletIframe({
      subTotal: props.checkoutState.order.subTotal,
      submitPayment,
    });
    const googleWalletIframe = FreedomPayEcommUtils.useGoogleWalletIframe({
      subTotal: props.checkoutState.order.subTotal,
      submitPayment,
    });
    const paymentFormIframe = FreedomPayEcommUtils.usePaymentFormIframe(submitPayment);
    const [selectedSavedCard, setSelectedSavedCard] = React.useState<
      FreedomPayEcommUtils.SavedCard | undefined
    >(undefined);
    const savedCardIframe = FreedomPayEcommUtils.useSavedCardIframe({
      selectedSavedCard,
      submitPayment,
    });
    const [saveOnSale, setSaveOnSale] = React.useState(false);

    return {
      hasCustomer,
      selectedCard: selectedSavedCard,
      onSelectCard: (card: SavedCard) => {
        setSelectedSavedCard(card);
      },
      onToggleSaveOnSale: () => {
        setSaveOnSale((prev) => !prev);
      },
      saveOnSale,
      savedCards,
      iFrames: {
        appleWallet: appleWalletIframe,
        googleWallet: googleWalletIframe,
        paymentForm: paymentFormIframe,
        savedCard: savedCardIframe,
      },
    };
  }
}
