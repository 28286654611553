import _ from 'underscore';

import { Strings } from '@biteinc/common';
import { MenuItemDisplayStyle, MenuSectionPriceDisplay } from '@biteinc/enums';

import { BackboneEvents } from '~/app/js/backbone-events';
import { localizeStr } from '~/app/js/localization/localization';
import { GcnUtils } from '~/helpers';

import { GCNOrderedItem } from '../models/gcn_ordered_item';
import Analytics from '../utils/analytics';
import { GCNMenuItemCellView } from './gcn_menu_item_cell_view';
import { GCNMenuItemCustomizeView } from './gcn_menu_item_customize_view';
import { GCNMenuItemEditView } from './gcn_menu_item_edit_view';
import { GCNView } from './gcn_view';

export const GCNRecommendationsView = GCNView.extend({
  className: 'recommendations',
  template: _.template(
    // prettier-ignore
    '<div class="header font-body">' +
      '<span class="contents"></span>' +
    '</div>' +
    '<div class="reco-container">' +
      '<div class="carousel-h-outer">' +
        '<div class="carousel-h-inner"></div>' +
      '</div>' +
    '</div>' +
    '<div class="reco-details">' +
      '<div class="customize-view"></div>' +
      '<div class="controls">' +
        '<div class="total"></div>' +
        '<div role="button" class="button done"><%= doneStr %></div>' +
        '<div role="button" class="button cancel"><%= cancelStr %></div>' +
      '</div>' +
    '</div>',
  ),

  initialize(options, ...args) {
    GCNView.prototype.initialize.apply(this, [options, ...args]);

    this._options = options;
    this._recos = options.recos;

    // Create map for convenience of adding and updating items. This map will
    // contain the associated view, model and completed orderedItem for a
    // given item ID.
    this._recoIdToDetails = {};
    _.each(this._recos, (reco) => {
      this._recoIdToDetails[reco.id] = {
        model: reco,
      };
    });
  },

  _setSelectedRecoViewForId(idToSelect) {
    _.each(this._recoIdToDetails, (details, id) => {
      let notSelected = true;
      // Pass in undefined for idToSelect to mark everything as unselected.
      if (!idToSelect || id === idToSelect) {
        notSelected = false;
      }
      details.itemView.$el.toggleClass('not-selected', notSelected);
    });
  },

  _showRecommendationDetails(itemView) {
    const self = this;
    const recoItem = itemView.model;
    const { orderedItem, added } = this._recoIdToDetails[recoItem.id];

    if (this._options.usePopup && !gcn.screenReaderIsActive) {
      const editView = new GCNMenuItemEditView({
        model: orderedItem,
        recoEditor: true,
        isNested: true,
        cancelButtonText: added ? localizeStr(Strings.REMOVE) : null,
      });

      gcn.menuView.showPopup(editView, undefined, 'recommended-item');

      // Do we need to clean up each by stopping the listen?
      this.listenTo(editView, BackboneEvents.GCNMenuItemOrderView.DonePressed, () => {
        self._updateRecommendationState(self._currentCustomItem, true);
        self.trigger(BackboneEvents.GCNRecommendationsView.ProceedToNextStep);
        gcn.menuView.dismissPopup();
      });
      this.listenTo(editView, BackboneEvents.GCNMenuItemOrderView.CancelPressed, () => {
        gcn.menuView.dismissPopup();
        self._updateRecommendationState(self._currentCustomItem, false);
      });
      this.listenTo(editView, BackboneEvents.GCNMenuItemOrderView.OverlayPressed, () => {
        gcn.menuView.dismissPopup();
        self._hideShownItem();
      });
    } else {
      // Inline
      this._customizeRecoView = new GCNMenuItemCustomizeView({
        model: orderedItem,
        isNested: true,
        setDoneDisabled(disabled) {
          self.$('.controls .button.done').toggleClass('disabled', disabled);
        },
      });
      this.$('.customize-view').html(this._customizeRecoView.render().$el);
      this._$recoDetails.hide();
      this._$recoDetails.slideDown();
      this._setSelectedRecoViewForId(recoItem.id);
    }

    this._currentCustomItem = recoItem;
  },

  _updateRecommendationState(recoItem, added) {
    const details = this._recoIdToDetails[recoItem.id];
    details.added = added;
    details.itemView.$el.toggleClass('selected', added);
    details.itemView.$el.attr('aria-description', `${added ? '- added to cart' : ''}`);
    details.itemView.setAddedVisualState(added);

    if (gcn.screenReaderIsActive) {
      gcn.showNativeToast(
        `${recoItem.displayName()} - ${added ? 'added to cart' : 'removed from cart'}`,
      );
    }

    if (added) {
      Analytics.trackEvent({
        eventName: Analytics.EventName.RecommendationMenuItemAdded,
        eventData: {
          itemName: details.model.displayName(),
        },
      });
    } else {
      Analytics.trackEvent({
        eventName: Analytics.EventName.RecommendationMenuItemRemoved,
        eventData: {
          itemName: details.model.displayName(),
        },
      });
    }
    this.trigger(BackboneEvents.GCNRecommendationsView.SelectedItemsChanged);
    this._hideShownItem();
  },

  _hideShownItem() {
    if (this._$recoDetails) {
      this._$recoDetails.slideUp();
    }
    this._setSelectedRecoViewForId();
    this._currentCustomItem = null;
  },

  __getHeaderText() {
    return this._options.headerText || localizeStr(Strings.GOES_WELL_WITH);
  },

  __didTapOnRecommendedItemView(itemView) {
    const model = itemView.model;
    const details = this._recoIdToDetails[model.id];

    // Simpler logic for when the screen reader is active to make removal of a reco easier.
    if (gcn.screenReaderIsActive) {
      if (details.added) {
        this._updateRecommendationState(model, false);
      } else if (model.hasOnePriceOptionNoAddons()) {
        this._updateRecommendationState(model, true);
      } else {
        this._showRecommendationDetails(itemView);
      }
      return;
    }

    if (this._currentCustomItem) {
      this._hideShownItem();
      return;
    }

    if (model.hasOnePriceOptionNoAddons()) {
      if (details.added) {
        this._updateRecommendationState(model, false);
      } else {
        this._updateRecommendationState(model, true);
      }
    } else if (this._currentCustomItem === model) {
      this._updateRecommendationState(model, false);
    } else {
      this._showRecommendationDetails(itemView);
    }
  },

  __addRecoAndViewToDetails(reco, itemView, position) {
    const orderedItem = new GCNOrderedItem(null, {
      item: reco,
      upsellScreen: GcnUtils.recommendationTrackingLabel(
        this._options.upsellScreen,
        position,
        reco,
      ),
    });
    this._recoIdToDetails[reco.id].orderedItem = orderedItem;
    this._recoIdToDetails[reco.id].itemView = itemView;
  },

  getOrderedItems() {
    const orderedItems = [];
    _.each(this._recoIdToDetails, (details) => {
      if (details.added) {
        orderedItems.push(details.orderedItem);
      }
    });
    return orderedItems;
  },

  render() {
    const self = this;
    if (!this._recos.length) {
      return this;
    }

    this.$el.html(
      this.template({
        doneStr: localizeStr(Strings.DONE),
        cancelStr: localizeStr(Strings.CANCEL),
      }),
    );

    this.$('.header').htmlOrText(this.__getHeaderText());

    const $innerCarousel = this.$('.carousel-h-inner');
    this._$recoDetails = this.$('.reco-details');

    _.each(this._recos, (reco, index) => {
      const itemView = new GCNMenuItemCellView({
        displayStyle: MenuItemDisplayStyle.NameAndImage,
        model: reco,
        sectionId: null,
        priceDisplay: MenuSectionPriceDisplay.Default,
        hideCalories: gcn.menu.shouldDeemphasizeCalories(),
        hideDescription: true,
        useFallbackImage: true,
        showFalseButton: true,
        onTapCallback() {
          self.__didTapOnRecommendedItemView(itemView);
        },
      });
      $innerCarousel.append(itemView.render().$el);

      self.__addRecoAndViewToDetails(reco, itemView, index);
    });
    this.$('.reco-details').hide();

    this.$('.controls .button.done').onButtonTapOrHold('recoDone', () => {
      if (!self.$('.controls .button.done').hasClass('disabled')) {
        self._updateRecommendationState(self._currentCustomItem, true);
      }
    });
    this.$('.controls .button.cancel').onButtonTapOrHold('recoCancel', () => {
      self._updateRecommendationState(self._currentCustomItem, false);
    });

    return this;
  },
});
