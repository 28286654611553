import _ from 'underscore';

import { Strings } from '@biteinc/common';
import { FlashBridgeMessage } from '@biteinc/enums';

import { localizeStr } from '~/app/js/localization/localization';
import GcnGiftCardHelper from '~/helpers/gcn_gift_card_helper';

import { useStore } from '../../../stores';
import Errors from '../utils/errors';
import { GCNView } from './gcn_view';
import {
  getCssClassForCardReader,
  getNativeStoredValueInstructionText,
  getSecondaryCssClassForCardReader,
  terminalBasedIntegrationUsesApiPayments,
} from './stored_value_card_reader_helper';

export const GCNGiftCardTerminalView = GCNView.extend({
  className: 'gift-card-terminal-view',

  template: _.template(
    // prettier-ignore
    '<div class="body">' +
      '<div class="title"></div>' +
      '<div class="images-container">' +
        '<div class="image secondary"></div>' +
        '<div class="image primary"></div>' +
      '</div>' +
      '<div class="subtitle"></div>' +
    '</div>',
  ),

  initialize(options, ...args) {
    GCNView.prototype.initialize.apply(this, [options, ...args]);

    this.grandTotal = options.grandTotal;
    this._callback = options.callback;
    this._readGiftCard();
  },

  destroy(...args) {
    GCNView.prototype.destroy.apply(this, args);

    if (!this._obtainedTransaction && gcn.bridge) {
      gcn.bridge.send({ event: 'cancelReadGiftCard' });
    }
  },

  async _readGiftCard() {
    if (!gcn.bridge) {
      return;
    }

    const currentOrder = gcn.orderManager.getOrder();
    const storedValuePartialI9n = gcn.location.getStoredValueIntegration();

    if (terminalBasedIntegrationUsesApiPayments(storedValuePartialI9n.system)) {
      try {
        const amount = gcn.orderManager.getGrandTotal();
        gcn.menuView.showSpinner(localizeStr(Strings.PROCESSING_PAYMENT));

        let data;
        try {
          data = await gcn.maitred.giftCardCaptureInQueue(null, amount);
        } finally {
          gcn.menuView.dismissSpinner();
        }

        gcn.orderManager.setOrderFromJSON(data.order);
        if (gcn.location.useOrdersApiV2()) {
          gcn.orderManager.setTransactionsFromJson(data.successfulTransactions);
        } else {
          gcn.orderManager.setTransactionsFromJson([data.transaction]);
        }
        useStore.getState().checkout.onStoredValueUpdated();
      } catch (err) {
        this._showError(Errors.stringFromErrorCode(err.code), this._callback);
        return;
      } finally {
        this._obtainedTransaction = true;
      }

      this._callback();

      return;
    }

    // Does this callback get GCd once the view is destroyed?
    const payload = {
      event: FlashBridgeMessage.READ_GIFT_CARD,
      amountInCents: this.grandTotal,
      order: currentOrder.attributes,
    };

    const authData = await gcn.bridge.sendAsync(payload);
    // authData could be pre-transaction or post-transaction depending on the integration

    // If the transaction fails by either getting cancelled or being abandoned, the native side
    // will send an empty authData
    // If we get here, then it means the user has abandoned the transaction. Go home, just
    // like we do with normal CC transactions
    if (_.isEmpty(authData)) {
      gcn.goHome();
      return;
    }

    const cardNumber = authData.cardNumber;

    if (storedValuePartialI9n.requiresPin) {
      const callback = () => {
        gcn.menuView.dismissModalPopup();
        this._callback();
      };
      gcn.menuView.dismissModalPopup();
      GcnGiftCardHelper.showGiftCardViewWithPrefilledCardNumber(cardNumber, callback);
      return;
    }

    gcn.orderManager.addStoredValueCard(cardNumber, authData.cardEntryMethod);

    try {
      await GcnGiftCardHelper.captureMaximumAmount(authData);
    } catch (err) {
      this._showError(Errors.stringFromErrorCode(err.code), this._callback);
      return;
    } finally {
      this._obtainedTransaction = true;
    }

    this._callback();
  },

  _showError(message, callback) {
    // We can't use the built-in timeout feature with GCNAlertView because we need a cancel
    // callback without showing a cancel button
    const errorTimeout = setTimeout(() => {
      gcn.menuView.dismissModalPopup();
      callback();
    }, 15 * 1000);

    gcn.menuView.showSimpleAlert(message, () => {
      clearTimeout(errorTimeout);
      callback();
    });
  },

  render() {
    this.$el.html(this.template());

    const storedValueSystem = gcn.location.get('giftCardI9n');
    this._$title = this.$('.title');
    this._$subtitle = this.$('.subtitle');
    this._$image = this.$('.image.primary');
    this._$imageSecondary = this.$('.image.secondary');

    this._$title.htmlOrText(localizeStr(Strings.GIFT_CARD_TITLE));
    this._$subtitle.htmlOrText(getNativeStoredValueInstructionText(storedValueSystem));
    this._$image.addClass(getCssClassForCardReader(storedValueSystem));

    const secondaryCssClass = getSecondaryCssClassForCardReader(storedValueSystem);
    if (secondaryCssClass) {
      this._$imageSecondary.addClass(secondaryCssClass);
      let imageOpacity = 1;
      this.cardAnimationInterval = setInterval(() => {
        imageOpacity = 1 - imageOpacity;
        this._$imageSecondary.css('opacity', imageOpacity);
      }, 2400);
    } else {
      this._$imageSecondary.remove();
    }

    return this;
  },
});
