import { IonItem, IonLabel } from '@ionic/react';
import React from 'react';

type OrderDetailsAddressProps = {
  address: string;
  header: string;
};

export function OrderDetailsAddress(props: OrderDetailsAddressProps): JSX.Element {
  return (
    <IonItem
      lines="none"
      className="fulfillment-address"
    >
      <IonLabel
        position="stacked"
        className="fulfillment-address-header"
      >
        {props.header}
      </IonLabel>
      <div className="fulfillment-address-body">{props.address}</div>
    </IonItem>
  );
}
