import type { FulfillmentMethod, MenuCoverPromoLinkType } from '@biteinc/enums';

// <GroupName><Event><?Status>
export enum AnalyticsEventName {
  AdminViewPrintReceiptTapped = 'adminViewPrintReceiptTapped',
  CartOpened = 'cartOpened',
  CartClosed = 'cartClosed',
  CartCleared = 'cartCleared',
  CartFulfillmentMethodEditTapped = 'cartFulfillmentMethodEditTapped',
  CartFulfillmentMethodToggleTapped = 'cartFulfillmentMethodToggleTapped',
  CartMenuItemAdded = 'cartMenuItemAdded',
  CartMenuItemEditComplete = 'cartMenuItemEditComplete',
  CartMenuItemEditStart = 'cartMenuItemEditStart',
  CartMenuItemRemoved = 'cartMenuItemRemoved',
  CheckoutCancel = 'checkoutCancel',
  CheckoutComplete = 'checkoutComplete',
  CheckoutCompletePrintReceiptTapped = 'checkoutCompletePrintReceiptTapped',
  CheckoutCompleteStartNewOrderTapped = 'checkoutCompleteStartNewOrderTapped',
  CheckoutStart = 'checkoutStart',
  CompCardApply = 'compCardApply',
  CompCardApplyError = 'compCardApplyError',
  CompCardRemove = 'compCardRemove',
  CompCardRemoveError = 'compCardRemoveError',
  CouponSearch = 'couponSearch',
  CoverPromoClicked = 'coverPromoClicked',
  CreditCardPaymentComplete = 'creditCardPaymentComplete',
  CreditCardPaymentStart = 'creditCardPaymentStart',
  CreditCartPaymentCancel = 'creditCartPaymentCancel',
  CustomerNoTip = 'customerNoTip',
  CustomerTipPresetPercentageSelected = 'customerTipPresetPercentageSelected',
  CustomerTipPresetValueSelected = 'customerTipPresetValueSelected',
  CustomerTipCustomAmountSelected = 'customerTipCustomAmountSelected',
  DelphiOptOut = 'delphiOptOut',
  EmailReceiptRequested = 'emailReceiptRequested',
  EmailSkipped = 'emailSkipped',
  EmailSubmitted = 'emailSubmitted',
  ExitMenuTapped = 'exitMenuTapped',
  FulfillmentAtCheckoutBackOut = 'fulfillmentAtCheckoutBackOut',
  FulfillmentAtCheckoutComplete = 'fulfillmentAtCheckoutComplete',
  FulfillmentAtCheckoutStart = 'fulfillmentAtCheckoutStart',
  FulfillmentAtOpeningBackOut = 'fulfillmentAtOpeningBackOut',
  FulfillmentAtOpeningComplete = 'fulfillmentAtOpeningComplete',
  FulfillmentAtOpeningStart = 'fulfillmentAtOpeningStart',
  FulfillmentAtOpeningDiningOptionShown = 'fulfillmentAtOpeningDiningOptionShown',
  FulfillmentMethodSelected = 'fulfillmentMethodSelected',
  FulfillmentMethodSelectionBackOut = 'fulfillmentMethodSelectionBackOut',
  FulfillmentMethodSelectionRevised = 'fulfillmentMethodSelectionRevised',
  FutureOrderTimeBackOut = 'futureOrderTimeBackOut',
  FutureOrderTimeSelectedAsap = 'futureOrderTimeSelectedAsap',
  FutureOrderTimeSelectedNextBusinessDay = 'futureOrderTimeSelectedNextBusinessDay',
  FutureOrderTimeSelectedSlot = 'futureOrderTimeSelectedSlot',
  FutureOrderTimeSelectedToday = 'futureOrderTimeSelectedToday',
  GuestSurveyValueSubmitted = 'guestSurveyValueSubmitted',
  LoyaltyAuthError = 'loyaltyAuthError',
  LoyaltyAuthStart = 'loyaltyAuthStart',
  LoyaltyAuthSuccess = 'loyaltyAuthSuccess',
  LoyaltyRedeemError = 'loyaltyRedeemError',
  LoyaltyRedeemStart = 'loyaltyRedeemStart',
  LoyaltyRedeemSuccess = 'loyaltyRedeemSuccess',
  LoyaltySimpleSignupComplete = 'loyaltySimpleSignupComplete',
  MenuAbandoned = 'menuAbandoned',
  MenuItemAbandoned = 'menuItemAbandoned',
  MenuItemQuantityUpdated = 'menuItemQuantityUpdated',
  MenuItemScanned = 'menuItemScanned',
  MenuItemSelected = 'menuItemSelected',
  MenuItemStoryShow = 'menuItemStoryShow',
  MenuItemView = 'menuItemView',
  MenuItemViewMoreIndicatorTapped = 'menuItemViewMoreIndicatorTapped',
  MenuPageViewSubPageSelected = 'menuPageViewSubPageSelected',
  ModAdded = 'modAdded',
  ModRemoved = 'modRemoved',
  OrderFailedToGet = 'orderFailedToGet',
  OrderSendComplete = 'orderSendComplete',
  OrderSendError = 'orderSendError',
  orderSendErrorOutOfSync = 'orderSendErrorOutOfSync',
  OrderSendErrorDoneButtonTapped = 'orderSendErrorDoneButtonTapped',
  OrderSendStart = 'orderSendStart',
  OutpostSelected = 'outpostSelected',
  PageNavExitToCoverTapped = 'pageNavExitToCoverTapped',
  PageNavPageSelected = 'pageNavPageSelected',
  PageNavSectionSelected = 'pageNavSectionSelected',
  PaymentMethodBackOut = 'paymentMethodBackOut',
  PaymentMethodSelected = 'paymentMethodSelected',
  PaymentFailed = 'paymentFailed',
  PhoneNumberBackOut = 'phoneNumberBackOut',
  PhoneNumberSkipped = 'phoneNumberSkipped',
  PhoneNumberSmsPromoSubmitted = 'phoneNumberSmsPromoSubmitted',
  PhoneNumberSubmitted = 'phoneNumberSubmitted',
  QuickCheckout = 'quickCheckout',
  QuickNavSectionSelected = 'quickNavSectionSelected',
  RecommendationMenuItemAdded = 'recommendationMenuItemAdded',
  RecommendationMenuItemRemoved = 'recommendationMenuItemRemoved',
  RefundTransactionError = 'refundTransactionError',
  RefundTransactionStart = 'refundTransactionStart',
  RefundTransactionSuccess = 'refundTransactionSuccess',
  RewardApply = 'rewardApply',
  RewardRemove = 'rewardRemove',
  ScrollingNavPageSelected = 'scrollingNavPageSelected',
  ScrollingNavSectionSelected = 'scrollingNavSectionSelected',
  SessionComplete = 'sessionComplete',
  SessionStart = 'sessionStart',
  SimpleCoverDismissed = 'simpleCoverDismissed',
  SpecialRequestSubmitted = 'specialRequestSubmitted',
  StoredValueDismissed = 'storedValueDismissed',
  StoredValueInquire = 'storedValueInquire',
  StoredValueInquireError = 'storedValueInquireError',
  StoredValueNoBalance = 'storedValueNoBalance',
  StoredValueSufficientBalance = 'storedValueSufficientBalance',
  TableNumberBackOut = 'tableNumberBackOut',
  TableNumberSubmitted = 'tableNumberSubmitted',
  TouchVsClick = 'touchVsClick',
  UpsellInterstitialRecoDismissed = 'upsellInterstitialRecoDismissed',
  UpsellComboShown = 'upsellComboShown',
  UpsellComboDismissed = 'upsellComboDismissed',
  UpsellComboAccepted = 'upsellComboAccepted',
  UpsellModifierShown = 'upsellModifierShown',
  UpsellModifierDismissed = 'upsellModifierDismissed',
  UpsellModifierAccepted = 'upsellModifierAccepted',
  VehicleDescriptionBackOut = 'vehicleDescriptionBackOut',
  VehicleDescriptionSubmitted = 'vehicleDescriptionSubmitted',
}

type AnalyticsErrorEventName =
  | AnalyticsEventName.LoyaltyAuthError
  | AnalyticsEventName.LoyaltyRedeemError
  | AnalyticsEventName.StoredValueInquireError
  | AnalyticsEventName.OrderSendError
  | AnalyticsEventName.RefundTransactionError;

export type AnalyticsEventNameWithoutData = Exclude<
  AnalyticsEventName,
  | AnalyticsErrorEventName
  | AnalyticsEventName.CartMenuItemAdded
  | AnalyticsEventName.CartMenuItemEditStart
  | AnalyticsEventName.CartMenuItemEditComplete
  | AnalyticsEventName.CartMenuItemRemoved
  | AnalyticsEventName.CheckoutComplete
  | AnalyticsEventName.CheckoutStart
  | AnalyticsEventName.CouponSearch
  | AnalyticsEventName.CoverPromoClicked
  | AnalyticsEventName.CustomerNoTip
  | AnalyticsEventName.CustomerTipPresetPercentageSelected
  | AnalyticsEventName.CustomerTipPresetValueSelected
  | AnalyticsEventName.CustomerTipCustomAmountSelected
  | AnalyticsEventName.FulfillmentAtCheckoutComplete
  | AnalyticsEventName.FulfillmentAtOpeningComplete
  | AnalyticsEventName.FulfillmentAtOpeningDiningOptionShown
  | AnalyticsEventName.FulfillmentMethodSelected
  | AnalyticsEventName.FutureOrderTimeSelectedSlot
  | AnalyticsEventName.GuestSurveyValueSubmitted
  | AnalyticsEventName.MenuAbandoned
  | AnalyticsEventName.MenuItemAbandoned
  | AnalyticsEventName.MenuItemQuantityUpdated
  | AnalyticsEventName.MenuItemScanned
  | AnalyticsEventName.MenuItemSelected
  | AnalyticsEventName.MenuItemView
  | AnalyticsEventName.MenuPageViewSubPageSelected
  | AnalyticsEventName.ModAdded
  | AnalyticsEventName.ModRemoved
  | AnalyticsEventName.OutpostSelected
  | AnalyticsEventName.PageNavPageSelected
  | AnalyticsEventName.PageNavSectionSelected
  | AnalyticsEventName.PaymentMethodSelected
  | AnalyticsEventName.QuickCheckout
  | AnalyticsEventName.QuickNavSectionSelected
  | AnalyticsEventName.RecommendationMenuItemAdded
  | AnalyticsEventName.RecommendationMenuItemRemoved
  | AnalyticsEventName.ScrollingNavPageSelected
  | AnalyticsEventName.ScrollingNavSectionSelected
  | AnalyticsEventName.SessionComplete
  | AnalyticsEventName.RefundTransactionError
  | AnalyticsEventName.TouchVsClick
>;

export type AnalyticsEvent = Readonly<
  | {
      eventName: AnalyticsEventNameWithoutData;
      eventData?: never;
    }
  | {
      eventName: AnalyticsErrorEventName;
      eventData: {
        message: string;
      };
    }
  | {
      eventName: AnalyticsEventName.CartMenuItemAdded;
      eventData: {
        itemName: string;
        itemQuantity: number;
      };
    }
  | {
      eventName:
        | AnalyticsEventName.CartMenuItemEditStart
        | AnalyticsEventName.CartMenuItemEditComplete;
      eventData: {
        itemId: string;
        itemPosId?: string;
        itemName: string;
      };
    }
  | {
      eventName: AnalyticsEventName.CartMenuItemRemoved;
      eventData: {
        itemName: string;
        reason?: string;
      };
    }
  | {
      eventName: AnalyticsEventName.CheckoutComplete | AnalyticsEventName.CheckoutStart;
      eventData: {
        cartSize: number;
        cartSubTotal: number;
      };
    }
  | {
      eventName: AnalyticsEventName.CouponSearch;
      eventData: {
        couponCode: string;
      };
    }
  | {
      eventName: AnalyticsEventName.CoverPromoClicked;
      eventData: {
        linkType: MenuCoverPromoLinkType;
        linkTarget: string;
      };
    }
  | {
      eventName: AnalyticsEventName.CustomerNoTip;
      eventData: {
        wasForced: boolean;
      };
    }
  | {
      eventName: AnalyticsEventName.CustomerTipPresetPercentageSelected;
      eventData: {
        percent: string;
        amount: number;
        wasForced: boolean;
      };
    }
  | {
      eventName: AnalyticsEventName.CustomerTipPresetValueSelected;
      eventData: {
        amount: number;
        wasForced: boolean;
      };
    }
  | {
      eventName: AnalyticsEventName.CustomerTipCustomAmountSelected;
      eventData: {
        amount: number;
        wasForced: boolean;
      };
    }
  | {
      eventName:
        | AnalyticsEventName.FulfillmentAtCheckoutComplete
        | AnalyticsEventName.FulfillmentAtOpeningComplete;
      eventData: {
        stepCount: number;
      };
    }
  | {
      eventName: AnalyticsEventName.FulfillmentAtOpeningDiningOptionShown;
      eventData: {
        duration: number;
      };
    }
  | {
      eventName: AnalyticsEventName.FulfillmentMethodSelected;
      eventData: {
        fulfillmentMethod: string;
      };
    }
  | {
      eventName: AnalyticsEventName.FutureOrderTimeSelectedSlot;
      eventData: {
        value: string;
      };
    }
  | {
      eventName: AnalyticsEventName.GuestSurveyValueSubmitted;
      eventData: {
        value: number;
      };
    }
  | {
      eventName: AnalyticsEventName.MenuAbandoned;
      eventData: {
        reason: string;
      };
    }
  | {
      eventName:
        | AnalyticsEventName.MenuItemAbandoned
        | AnalyticsEventName.MenuItemSelected
        | AnalyticsEventName.MenuItemView;
      eventData: {
        itemName: string;
      };
    }
  | {
      eventName: AnalyticsEventName.MenuItemScanned;
      eventData: {
        itemName: string;
        isWeighted: boolean;
      };
    }
  | {
      eventName: AnalyticsEventName.MenuItemQuantityUpdated;
      eventData: {
        itemName: string;
        quantity: number;
      };
    }
  | {
      eventName: AnalyticsEventName.MenuPageViewSubPageSelected;
      eventData: {
        subPageName: string;
      };
    }
  | {
      eventName: AnalyticsEventName.ModAdded | AnalyticsEventName.ModRemoved;
      eventData: {
        itemName: string;
      };
    }
  | {
      eventName: AnalyticsEventName.OutpostSelected;
      eventData: {
        outpostName: string;
      };
    }
  | {
      eventName: AnalyticsEventName.PageNavPageSelected;
      eventData: {
        pageName: string;
      };
    }
  | {
      eventName: AnalyticsEventName.PageNavSectionSelected;
      eventData: {
        sectionName: string;
      };
    }
  | {
      eventName: AnalyticsEventName.PaymentMethodSelected;
      eventData: {
        paymentMethod: 'card' | 'cashier';
      };
    }
  | {
      eventName: AnalyticsEventName.QuickCheckout;
      eventData: {
        source: string;
      };
    }
  | {
      eventName: AnalyticsEventName.QuickNavSectionSelected;
      eventData: {
        sectionName: string;
      };
    }
  | {
      eventName:
        | AnalyticsEventName.RecommendationMenuItemAdded
        | AnalyticsEventName.RecommendationMenuItemRemoved;
      eventData: {
        itemName: string;
      };
    }
  | {
      eventName: AnalyticsEventName.ScrollingNavPageSelected;
      eventData: {
        pageName: string;
      };
    }
  | {
      eventName: AnalyticsEventName.ScrollingNavSectionSelected;
      eventData: {
        sectionName: string;
      };
    }
  | {
      eventName: AnalyticsEventName.SessionComplete;
      eventData: {
        duration: number;
      };
    }
  | {
      eventName: AnalyticsEventName.TouchVsClick;
      eventData: {
        // New prefixes here might affect the regex in gcn_extensions.js#onButtonTapOrHold()
        // and how the eventData is built in gcn_menu_app_view.js#goHome().
        [
          buttonName: `${
            | 'clickCount'
            | 'touchStartCount'
            | 'touchEndCount'
            | 'touchHoldCount'}.${string}`
        ]: number;
      };
    }
  | {
      eventName: AnalyticsEventName.CartFulfillmentMethodToggleTapped;
      eventData: {
        fulfillmentMethod: FulfillmentMethod;
      };
    }
>;
