/* eslint-disable import/no-mutable-exports */
let biteBeep = (): void => {};

if (typeof AudioContext !== 'undefined') {
  const audio = new AudioContext(); // browsers limit the number of concurrent audio contexts, so you better re-use'em
  biteBeep = (): void => {
    const vol = 1;
    const freq = 220;
    const duration = 100;

    const v = audio.createOscillator();
    const u = audio.createGain();
    v.connect(u);
    v.frequency.value = freq;
    v.type = 'square';
    u.connect(audio.destination);
    u.gain.value = vol * 0.01;
    v.start(audio.currentTime);
    v.stop(audio.currentTime + duration * 0.001);
  };
}

export { biteBeep };

if (['dev', 'test'].includes(window.env)) {
  window.onerror = (e, a, b, c) => {
    // eslint-disable-next-line no-console
    console.error('UNCAUGHT ERROR', e, a, b, c);
    biteBeep();
  };
}
