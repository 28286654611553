import _ from 'underscore';

import GcnHelper from '../gcn_helper';
import { GCNModel } from './gcn_model';

export const GCNOrderedPriceOption = GCNModel.extend({
  initialize(json, options, ...args) {
    if (options && options.priceOption) {
      this.po = options.priceOption;

      if (_.keys(json).length) {
        GCNModel.prototype.initialize.apply(this, [json, options, ...args]);
      } else {
        // Default to price option values.
        this.set({
          _id: this.po.id,
          name: this.po.displayName(),
          price: this.po.get('price'),
          unitPrice: this.po.get('price'),
        });
        if (this.po.has('i9nId')) {
          this.set('i9nId', this.po.get('i9nId'));
        }
        if (this.po.has('posId')) {
          this.set('posId', this.po.get('posId'));
        }
        const barcode = this.po.get('barcode') || this.po.get('posBarcode');
        if (barcode) {
          this.set('barcode', barcode);
        }
        if (this.po.get('saleUnit')) {
          // We don't care if it's 0
          this.set('saleUnit', this.po.get('saleUnit'));
          this.set('unitPrice', this.po.get('price'));
          this.setQuantity(1);
        }
      }
    }
  },

  createCopy() {
    const copy = new GCNOrderedPriceOption(GcnHelper.deepClone(this.attributes));
    copy.po = this.po;
    return copy;
  },

  setWeightAndQuantity(weight, quantity) {
    this.set('quantity', quantity);

    if (this.get('saleUnit')) {
      // only set weight if `saleUnit` is set for either `menuItem`
      this.set('weight', weight);
      this.set('price', Math.round(this.get('unitPrice') * weight * quantity));
    } else {
      this.set('price', Math.round(this.get('unitPrice') * quantity));
    }
  },

  setQuantity(quantity) {
    this.set('quantity', quantity);
    const weight = this.get('weight') || 1;
    this.set('price', Math.round(this.get('unitPrice') * quantity * weight));
  },

  getPrice() {
    return this.get('price');
  },

  toJSON(selectionStructByAddonSetId) {
    const addonSetsJson = this._jsonFromSetSelectionStructById(
      selectionStructByAddonSetId,
      this.po,
    );

    const json = GcnHelper.deepClone(this.attributes);
    json.addonSets = addonSetsJson;
    return json;
  },

  _jsonFromSetSelectionStructById(setSelectionStructById, po) {
    const addonSetsJSON = [];
    _.each(po.get('addonSetIds'), (addonSetId) => {
      const setSelectionStruct = setSelectionStructById[addonSetId];
      if (!setSelectionStruct) {
        return;
      }

      const { model: addonSet, selections, deselections } = setSelectionStruct;
      const addonSetJSON = this._jsonFromAddonSet(addonSet, selections, deselections);
      if (addonSetJSON) {
        addonSetsJSON.push(addonSetJSON);
      }
    });
    return addonSetsJSON;
  },

  _jsonFromAddonSet(addonSet, selections, deselections) {
    const selectedItems = [];
    _.each(selections, (addonStruct) => {
      // It is possible to have multiple of the same item. Add JSON once per instance.
      // when 'enableMultipleQuantityItems' is set then we want to traverse once
      const addonStructQuantity = gcn.menu.settings.get('enableMultipleQuantityItems')
        ? 1
        : addonStruct.quantity;
      _.times(addonStructQuantity, (quantityIndex) => {
        selectedItems.push(this._jsonFromAddon(addonStruct, addonSet, quantityIndex));
      });
    });
    const deselectedItems = _.map(deselections, (addonStruct) => {
      return this._jsonFromAddon(addonStruct, addonSet);
    });

    if (!selectedItems.length && !deselectedItems.length) {
      return null;
    }

    const json = {
      _id: addonSet.id,
      name: addonSet.displayName(),
    };
    if (addonSet.has('i9nId')) {
      json.i9nId = addonSet.get('i9nId').toString();
      json.posId = addonSet.get('posId').toString();
    }
    if (addonSet.has('posFields')) {
      json.posFields = addonSet.get('posFields');
    }
    if (addonSet.has('parentModGroupId')) {
      json.parentModGroupId = addonSet.get('parentModGroupId');
    }
    if (selectedItems.length) {
      json.items = selectedItems;
    }
    if (deselectedItems.length) {
      json.deselectedItems = deselectedItems;
    }
    return json;
  },

  _jsonFromAddon(addonStruct, addonSet, quantityIndex = -1) {
    // quantityIndex defaults to -1 since we would want to apply priceOverrides for all the Addons
    // when priceOverrides is populated.
    const {
      model: addon,
      selections,
      priceOverrides,
      upsellScreen,
      selectedPriceOption,
    } = addonStruct;

    let unitPrice = addonSet.addonPriceInSet(addon, selectedPriceOption);
    if (priceOverrides?.length && quantityIndex >= 0) {
      unitPrice = (
        addonStruct.priceOverrides.find(({ addonQuantityCap }) => {
          return addonQuantityCap <= quantityIndex;
        }) || _.last(addonStruct.priceOverrides)
      ).price;
    }

    const priceOption = selectedPriceOption || addon.priceOptions[0];
    const subAddonSetsJson = this._jsonFromSetSelectionStructById(selections, priceOption);
    const quantity = gcn.menu.settings.get('enableMultipleQuantityItems')
      ? addonStruct.quantity ?? 1
      : 1;
    const price = unitPrice * quantity;

    const json = {
      _id: addon.id,
      name: addonSet.addonNameInSet(addon, selectedPriceOption),
      priceOption: {
        _id: priceOption.id,
        price,
        unitPrice,
        quantity,
      },
      ...(upsellScreen && { upsellScreen }),
    };
    if (addon.has('i9nId')) {
      json.i9nId = addon.get('i9nId').toString();
      json.posId = addon.get('posId').toString();
    }
    if (addon.has('posName')) {
      json.posName = addon.get('posName');
    }
    if (priceOption.has('i9nId')) {
      json.priceOption.i9nId = priceOption.get('i9nId');
    }
    if (priceOption.has('posId')) {
      json.priceOption.posId = priceOption.get('posId');
    }
    if (priceOption.has('barcode')) {
      json.priceOption.barcode = priceOption.get('barcode');
    }
    if (subAddonSetsJson.length) {
      json.priceOption.addonSets = subAddonSetsJson;
    }
    if (addon.has('posFields')) {
      json.posFields = addon.get('posFields');
    }
    return json;
  },
});
