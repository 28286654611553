import _ from 'underscore';

import { GCNView } from './gcn_view';

export const GCNDictionaryCardView = GCNView.extend({
  className: 'dictionary-card-view font-body',
  template: _.template(
    // prettier-ignore
    '<div class="image"></div>' +
      '<div class="text">' +
      '<div class="name font-title"><%= word.name %></div>' +
      '<div class="desc-short"><%= word.simpleDescription %></div>' +
      '<div class="desc-long"><%= word.detailedDescription %></div>' +
    '</div>',
  ),

  updateModel(model) {
    this.model = model;
  },

  render() {
    this.$el.html(this.template({ word: this.model }));
    const $image = this.$('.image');
    if (_.size(this.model.images)) {
      const image = this.model.images[0];
      gcn.requestImageByUrlAndWidth(image.url, image.width, (err, imgPath) => {
        $image.css('background-image', `url(${imgPath})`);
      });
    } else {
      $image.hide();
    }

    return this;
  },
});
