import _ from 'underscore';

import type { FulfillmentMethod } from '@biteinc/enums';
import {
  RecommendationEventName,
  RecommendationEventSource,
  TriggerEventName,
} from '@biteinc/enums';

import { ConfigUtils } from '~/helpers';
import type { GcnMenuItem } from '~/types/gcn_menu_item';
import type { GcnOrderedItem } from '~/types/gcn_ordered_item';
import type {
  CartItem,
  GuestIdentifiers,
  ItemAndModRecommendationBody,
  ModifierRecommendationBody,
  Recommendation,
  RecommendationDisplayLocationDescription,
  RecommendationEvent,
  RecommendationEventBody,
  RecommendationEventDisplayRecommendationsBody,
  RecommendationEventGuestAddToCartBody,
  RecommendationEventGuestAddToCartParams,
  RecommendationEventOrderReferenceBody,
  SelectedItem,
  SessionRecommendationBody,
} from '~/types/recommendation';

import pkg from '../../../package.json';
import type { RecommendationEventPayload } from './gcn_maitred_client';

export function getModGroupRecommendationsPayload(
  menuStructureId: string,
  selectedOrderedItem: GcnMenuItem,
  modGroupId: string,
  virtualSubGroupId?: string,
): ModifierRecommendationBody {
  const fulfillmentMethod = gcn.orderManager.getFulfillmentMethod();
  const selectedItemAttributes = selectedOrderedItem.attributes;
  const selectedItem: SelectedItem = {
    id: selectedItemAttributes._id,
    // Prefer POS name if it exists
    name: selectedItemAttributes.posName || selectedItemAttributes.name!,
    modifiers: [],
    metadata: [],
  };
  const selectedBadges = ConfigUtils.sessionRecommendationMenuFilters();

  return {
    triggerEventName: TriggerEventName.ViewModGroup,
    fulfillmentMethod,
    menuStructureId,
    selectedItem,
    modGroupId,
    ...(virtualSubGroupId && { virtualSubGroupId }),
    ...selectedBadges,
  };
}

export function getModGroupPluralRecommendationsPayload(
  guestIdentifiers: GuestIdentifiers,
  selectedOrderedItem: GcnOrderedItem,
  cartOrderedItems: GcnOrderedItem[],
  modGroupIds: string[],
  virtualSubGroupIds: (string | undefined)[],
  recommendationVariationId?: string,
): ItemAndModRecommendationBody {
  const menuStructureId = gcn.menu.structure.id;
  const fulfillmentMethod = gcn.orderManager.getFulfillmentMethod()!;
  const selectedItemAttributes = selectedOrderedItem.item.attributes;
  const selectedPriceOption = selectedOrderedItem.orderedPO;

  const selectedItem: SelectedItem = {
    id: selectedItemAttributes._id,
    name: selectedItemAttributes.posName || selectedItemAttributes.name!,
    ...(selectedOrderedItem.section && { sectionId: selectedOrderedItem.section.id }),
    // TODO add modifier selections
    modifiers: [],
    metadata: [],
    ...(_.isNumber(selectedPriceOption?.attributes.price) && {
      price: selectedPriceOption.attributes.price,
    }),
  };

  const cartItems = getCartItems(cartOrderedItems);

  const selectedBadges = ConfigUtils.sessionRecommendationMenuFilters();

  return {
    triggerEventName: TriggerEventName.ViewItem,
    ...guestIdentifiers,
    fulfillmentMethod,
    menuStructureId,
    selectedItem,
    modGroups: modGroupIds.map((modGroupId, idx) => ({
      id: modGroupId,
      ...(virtualSubGroupIds[idx] && { virtualSubGroupId: virtualSubGroupIds[idx] }),
    })),
    ...selectedBadges,
    recommendationVariationId,
    cartItems,
    ...(recommendationVariationId && { recommendationVariationId }),
  };
}

function getBaseRecommendationEvent(
  recommendationResultId: string,
): Omit<RecommendationEvent<RecommendationEventBody>, 'body' | 'name'> {
  return {
    eventSource: RecommendationEventSource.Gcn,
    eventSourceVersion: `${window.env}-biteinc-gcn.${pkg.version}`,
    eventAt: Date.now(),
    recommendationResultId,
  };
}

export function getRecommendationEventGuestAddToCartPayload(
  params: RecommendationEventGuestAddToCartParams,
): RecommendationEventPayload<RecommendationEventGuestAddToCartBody> {
  const { selectedRecommendation, posId, subTotal, quantity } = params;
  return {
    event: {
      name: RecommendationEventName.GuestAddToCart,
      ...getBaseRecommendationEvent(params.recommendationResultId),
      body: {
        selectedRecommendation,
        clientItemReference: {
          biteId: params.itemId,
          posId,
          subTotal,
          quantity,
        },
      },
    },
  };
}

export function getRecommendationEventOrderReferencePayload(
  recommendationResultId: string,
  orderId: string,
): RecommendationEventPayload<RecommendationEventOrderReferenceBody> {
  return {
    event: {
      name: RecommendationEventName.OrderReference,
      ...getBaseRecommendationEvent(recommendationResultId),
      body: {
        orderReference: {
          biteId: orderId,
        },
      },
    },
  };
}

export function getRecommendationEventDisplayRecommendationsPayload(
  displayLocationDescription: RecommendationDisplayLocationDescription,
  recommendations: Recommendation[],
  recommendationResultId: string,
  displayContext?: Record<string, any>,
): RecommendationEventPayload<RecommendationEventDisplayRecommendationsBody> {
  return {
    event: {
      name: RecommendationEventName.DisplayRecommendations,
      ...getBaseRecommendationEvent(recommendationResultId),
      body: {
        displayLocationDescription,
        displayedRecommendations: recommendations,
        ...(displayContext && { displayContext }),
      },
    },
  };
}

function getCartItems(cartOrderedItems: GcnOrderedItem[]): CartItem[] {
  return cartOrderedItems.map((orderedItem) => {
    const orderedItemAttributes = orderedItem.item.attributes;
    return {
      id: orderedItemAttributes._id,
      // Prefer POS name if it exists
      name: orderedItemAttributes.posName || orderedItemAttributes.name!,
      price: orderedItem.orderedPO.attributes.price,
      ...(orderedItem.section && { sectionId: orderedItem.section.id }),
      // TODO add modifier selections
      modifiers: [],
      metadata: [],
    };
  });
}

export function getItemRecommendationsPayload(
  guestIdentifiers: GuestIdentifiers,
  selectedOrderedItem: GcnOrderedItem,
  cartOrderedItems: GcnOrderedItem[],
  recommendationVariationId?: string,
): SessionRecommendationBody {
  const menuStructureId = gcn.menu.structure.id;
  const fulfillmentMethod = gcn.orderManager.getFulfillmentMethod() as FulfillmentMethod;
  const selectedItemAttributes = selectedOrderedItem.item.attributes;
  const selectedPriceOption = selectedOrderedItem.orderedPO;

  const cartItems = getCartItems(cartOrderedItems);
  const selectedBadges = ConfigUtils.sessionRecommendationMenuFilters();

  return {
    triggerEventName: TriggerEventName.ViewItem,
    menuStructureId,
    ...guestIdentifiers,
    fulfillmentMethod,
    selectedItem: {
      id: selectedItemAttributes._id,
      // Prefer POS name if it exists
      name: selectedItemAttributes.posName || selectedItemAttributes.name!,
      ...(selectedOrderedItem.section && { sectionId: selectedOrderedItem.section.id }),
      // TODO add modifier selections
      modifiers: [],
      metadata: [],
      ...(_.isNumber(selectedPriceOption?.attributes.price) && {
        price: selectedPriceOption.attributes.price,
      }),
    },
    cartItems,
    ...selectedBadges,
    ...(recommendationVariationId && { recommendationVariationId }),
  };
}

export function getPreCheckoutRecommendationsPayload(
  guestIdentifiers: GuestIdentifiers,
  cartOrderedItems: GcnOrderedItem[],
  recommendationVariationId?: string,
): SessionRecommendationBody {
  const menuStructureId = gcn.menu.structure.id;
  const fulfillmentMethod = gcn.orderManager.getFulfillmentMethod() as FulfillmentMethod;
  const cartItems = getCartItems(cartOrderedItems);
  const selectedBadges = ConfigUtils.sessionRecommendationMenuFilters();
  return {
    triggerEventName: TriggerEventName.PreCheckout,
    menuStructureId,
    ...guestIdentifiers,
    fulfillmentMethod,
    cartItems,
    ...(recommendationVariationId && { recommendationVariationId }),
    ...selectedBadges,
  };
}

export function getSideCartViewRecommendationsPayload(
  guestIdentifiers: GuestIdentifiers,
  cartOrderedItems: GcnOrderedItem[],
  recommendationVariationId?: string,
): SessionRecommendationBody {
  const menuStructureId = gcn.menu.structure.id;
  const fulfillmentMethod = gcn.orderManager.getFulfillmentMethod() as FulfillmentMethod;
  const cartItems = getCartItems(cartOrderedItems);
  const selectedBadges = ConfigUtils.sessionRecommendationMenuFilters();

  return {
    triggerEventName: TriggerEventName.CartView,
    menuStructureId,
    ...guestIdentifiers,
    fulfillmentMethod,
    cartItems,
    ...(recommendationVariationId && { recommendationVariationId }),
    ...selectedBadges,
  };
}

export function getFirstLoadRecommendationsPayload(
  menuStructureId: string,
  guestIdentifiers: GuestIdentifiers,
  cartOrderedItems: GcnOrderedItem[],
): SessionRecommendationBody {
  const fulfillmentMethod = gcn.orderManager.getFulfillmentMethod();
  // there could be items in the cart if a user reloads the page
  const cartItems = getCartItems(cartOrderedItems);
  const selectedBadges = ConfigUtils.sessionRecommendationMenuFilters();

  return {
    triggerEventName: TriggerEventName.FirstLoad,
    menuStructureId,
    ...guestIdentifiers,
    fulfillmentMethod,
    cartItems,
    ...selectedBadges,
  };
}
