import _ from 'underscore';

import { GCNModel } from './gcn_model';

export const GCNMenuPage = GCNModel.extend({
  initialize() {
    this.pages = _.map(this.get('pages'), (pageJson) => {
      return new GCNMenuPage(pageJson);
    });
  },

  hasItemWithId(itemId) {
    if (this.pages.length) {
      return _.any(this.pages, (page) => {
        return page.hasItemWithId(itemId);
      });
    }
    return _.any(this.sections, (section) => {
      return section.hasItemWithId(itemId);
    });
  },

  hasMenuPageImages() {
    // Vendors store the page images.
    return _.any(this.pages, (page) => {
      if (!page.vendor) {
        return false;
      }
      return page.vendor.hasArr('logoImage');
    });
  },

  getNavigationImageUrlIfExists() {
    if (this.hasArr('pageNavigationImages')) {
      return this.get('pageNavigationImages')[0].url;
    }
    return this.getMenuPageImageUrl();
  },

  getMenuPageImageUrl() {
    if (this.vendor && this.vendor.hasArr('logoImage')) {
      return this.vendor.get('logoImage')[0].url;
    }
    return null;
  },

  getMenuPageThatContainsSectionWithId(menuSectionId) {
    if (this.pages.length) {
      for (let i = 0; i < this.pages.length; i++) {
        const menuPage = this.pages[i].getMenuPageThatContainsSectionWithId(menuSectionId);
        if (menuPage) {
          return menuPage;
        }
      }
      return null;
    }

    const section = _.find(this.sections, (menuSection) => {
      return !menuSection.isPromoSection() && menuSection.id === menuSectionId;
    });
    return section ? this : null;
  },

  getMenuSectionThatContainsItemId(itemId) {
    if (this.pages.length) {
      for (let i = 0; i < this.pages.length; i++) {
        const section = this.pages[i].getMenuSectionThatContainsItemId(itemId);
        if (section) {
          return section;
        }
      }
      return null;
    }

    return _.find(this.sections, (menuSection) => {
      return !menuSection.isPromoSection() && menuSection.hasItemWithId(itemId);
    });
  },

  setVendorFromMenu(vendors) {
    const vendorId = this.get('associatedVendorId');
    // Note: Might find nothing, especially if it's the top-level structure.
    this.vendor = _.find(vendors, (vendor) => {
      return vendor.id === vendorId;
    });

    // Recurse on all sub-pages.
    _.each(this.pages, (page) => {
      page.setVendorFromMenu(vendors);
    });
  },
});
