export default class TrackingStepCounter {
  private stepCount = 0;

  constructor(private startBlock: { (): void }) {}

  getStepCount(): number {
    return this.stepCount;
  }

  addStep(): void {
    this.stepCount++;
    if (this.stepCount > 1) {
      return;
    }

    this.startBlock();
  }
}
