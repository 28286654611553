import Handlebars from 'handlebars';
import * as React from 'react';

import { StringHelper } from '@biteinc/core-react';
import type { FulfillmentMethod } from '@biteinc/enums';

import { GcnFulfillmentHelper } from '~/helpers';
import { useLocation } from '~/stores';

import { OrderDetailsUtils } from './order-details.utils';

type FulfillmentInstructionsProps = {
  fulfillmentMethod: FulfillmentMethod;
  orderNumber?: string;
  guestName?: string;
  outpostName?: string;
};

export function FulfillmentInstructions(props: FulfillmentInstructionsProps): JSX.Element {
  const location = useLocation();
  const order = gcn.orderManager.getOrder();

  if (!order) {
    return <></>;
  }

  const diningOption = location.getDiningOptionForFulfillmentMethodWithoutLocalizedName(
    props.fulfillmentMethod,
  );
  const fulfillmentInfo = GcnFulfillmentHelper.getFulfillmentInfo(
    gcn.location,
    order,
    props.orderNumber!,
    diningOption,
  );
  const confirmationTemplate = diningOption?.successScreenFulfillmentTemplate;
  const { fullName, firstName, lastName } = StringHelper.getFirstAndLastNameFromFullName(
    props.guestName,
  );

  return (
    <>
      {confirmationTemplate ? (
        <div className="fulfillment-instructions-text">
          {Handlebars.compile(confirmationTemplate)({
            GUEST_NAME: fullName,
            GUEST_FIRST_NAME: firstName,
            GUEST_LAST_NAME: lastName,
            ORDER_NUMBER: props.orderNumber,
            OUTPOST_NAME: props.outpostName,
          })}
        </div>
      ) : (
        fulfillmentInfo.fulfillmentInstructions.map((val) => <div key={val}>{val}</div>)
      )}
      {fulfillmentInfo.customerIdentifierValue &&
      OrderDetailsUtils.shouldShowCustomerIdentifierInFulfillmentInstructions(
        fulfillmentInfo.customerIdentifier,
      ) ? (
        <div className="fulfillment">
          <div className="title">{fulfillmentInfo.customerIdentifierLabel}</div>
          <div className="number">{fulfillmentInfo.customerIdentifierValue}</div>
        </div>
      ) : null}
    </>
  );
}
