enum MobileAppMessageType {
  OpenCart = 'open-cart',
  CloseCart = 'close-cart',
  GetCartSize = 'get-cart-size',
  IsCartOpen = 'is-cart-open',
  OnNavigateUp = 'on-navigate-up',

  BridgeReady = 'bridge-ready',
  CartOpened = 'cart-opened',
  CartClosed = 'cart-closed',
  CartUpdated = 'cart-updated',
  OrderClosed = 'order-closed',
}

export default MobileAppMessageType;
