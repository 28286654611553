import { IonButton, IonCol, IonIcon, IonItem, IonLabel, IonRow } from '@ionic/react';
import { createOutline, heart, trashBinOutline } from 'ionicons/icons';
import * as React from 'react';

import { Log, Strings } from '@biteinc/common';
import type { Order, OrderedMod, OrderedPriceOption, UIOrderedItem } from '@biteinc/core-react';

import { useLocalize } from '~/app/js/localization/localization';

import GcnFlashMaitredClient from '../app/js/gcn_flash_maitred_client';
import GcnHtml from '../app/js/gcn_html';
import { AuthService } from '../services';
import { useLocation, useStore } from '../stores';

interface OrderedItemProps {
  index: number;
  orderedItem: UIOrderedItem;
  showEditDeleteButtons?: true;
  showFavoriteButton?: true;
  order?: Order;
}

export function OrderedItemComponent(props: OrderedItemProps): JSX.Element {
  const selectedModNames = getModsFromPriceOption(props.orderedItem.priceOption).map(
    ({ mod }) => mod.name,
  );

  return (
    <IonItem className="ordered-item">
      <IonLabel>
        <IonRow className="item-row">
          <IonCol size="12">
            <IonRow className="item-row">
              <IonCol>
                <p className="name">
                  <span className="quantity">{props.orderedItem.priceOption.quantity}× </span>
                  {props.orderedItem.name}
                </p>
              </IonCol>
              <IonCol size="auto">
                <p className="price">{`$${GcnHtml.stringFromPrice(props.orderedItem.total)}`}</p>
              </IonCol>
            </IonRow>
          </IonCol>
          <IonCol size={props.showEditDeleteButtons ? '12' : '10'}>
            {props.orderedItem.priceOption.name ? (
              <p className="modifier">{props.orderedItem.priceOption.name}</p>
            ) : null}
            {selectedModNames.length ? (
              <p className="modifier">{selectedModNames.join(', ')}</p>
            ) : null}
            {props.orderedItem.specialRequest ? (
              <p className="special-request">{props.orderedItem.specialRequest}</p>
            ) : null}
          </IonCol>
          <FavoriteButton {...props} />
          <EditDeleteButtons {...props} />
        </IonRow>
      </IonLabel>
    </IonItem>
  );
}

function EditDeleteButtons(props: {
  showEditDeleteButtons?: boolean;

  orderedItem: UIOrderedItem;
}): JSX.Element | null {
  const editItem = useStore((state) => state.cart.editOrderedItem);
  const removeItem = useStore((state) => state.cart.removeOrderedItem);

  if (!props.showEditDeleteButtons) {
    return null;
  }
  return (
    <IonCol size="12">
      <IonRow className="item-row">
        <IonCol>
          <IonButton
            className="edit-item-button simple-icon-button"
            fill="clear"
            size="small"
            onClick={() => {
              // eslint-disable-next-line no-underscore-dangle
              editItem(props.orderedItem._uid);
            }}
          >
            <IonIcon icon={createOutline} />
          </IonButton>
          <IonButton
            className="remove-item-button simple-icon-button"
            fill="clear"
            size="small"
            onClick={() => {
              // eslint-disable-next-line no-underscore-dangle
              removeItem(props.orderedItem._uid);
            }}
          >
            <IonIcon icon={trashBinOutline} />
          </IonButton>
        </IonCol>
      </IonRow>
    </IonCol>
  );
}

function FavoriteButton(props: {
  showFavoriteButton?: true;
  order?: Order;
  index: number;
  orderedItem: UIOrderedItem;
}): JSX.Element | null {
  const str = useLocalize();
  const [isFavorite, setIsFavorite] = React.useState(false);
  const location = useLocation();

  if (!props.showFavoriteButton) {
    return null;
  }

  function saveToFavorites(): void {
    if (!GcnFlashMaitredClient.getCustomerToken()) {
      AuthService.promptSignup(location, {
        order: props.order!,
        favoriteItemIndex: props.index,
      });
      return;
    }
    const payload = {
      locationId: location._id,
      orderedItem: props.orderedItem,
    };
    gcn.maitred.postCustomerResource('favorites', payload, (err /* data */) => {
      if (err) {
        Log.error('Err', err);
      } else {
        setIsFavorite(true);
      }
    });
  }

  const btnText = isFavorite
    ? str(Strings.ADDED_TO_FAVORITES_WEB)
    : str(Strings.ADD_TO_FAVORITES_WEB);
  const iconSlot = btnText ? 'end' : 'icon-only';
  return (
    <IonCol
      size="2"
      className="favorite-button-container"
    >
      <IonButton
        className={`favorite-button simple-icon-button ${isFavorite ? 'selected' : ''}`}
        fill="clear"
        size="small"
        disabled={isFavorite}
        {...(btnText && { expand: 'block' })}
        onClick={() => {
          saveToFavorites();
        }}
      >
        {btnText || undefined}
        <IonIcon
          slot={iconSlot}
          icon={heart}
        />
      </IonButton>
    </IonCol>
  );
}

function getModsFromPriceOption(priceOption: OrderedPriceOption): { mod: OrderedMod }[] {
  const mods: { mod: OrderedMod }[] = [];
  priceOption.addonSets?.forEach((addonSet) => {
    addonSet.items?.forEach((item) => {
      mods.push({ mod: item });
      mods.push(...getModsFromPriceOption(item.priceOption));
    });
  });
  return mods;
}
