import _ from 'underscore';

import { Log } from '@biteinc/common';

/*
  flash only router
  uses the history API https://developer.mozilla.org/en-US/docs/Web/API/History_API
  doesn't use state, just hashes because if we were to first load a page with
  #item-5e5d31753569920017cc64cb-section-5e5d31753569920017cc64cd
  we wouldn't have state
  */
export const GCNRouterHelper = {
  canUseRouter() {
    return _.every([
      window.isFlash,

      // history api is not supported by old browsers
      // https://caniuse.com/#feat=history
      window.history,
      window.history.pushState,
      window.history.replaceState,
    ]);
  },

  navToMenu() {
    if (!this.canUseRouter()) {
      return;
    }

    window.history.pushState({}, null, '#menu');
  },

  simulateNavToMenu() {
    if (gcn.checkoutFlowView) {
      gcn.checkoutFlowView.exitCheckoutFlow();
    }

    // use the internal ones to bypass inserting more history
    gcn.menuView.hideAllPopups();
  },

  navToCheckout() {
    if (!this.canUseRouter()) {
      return;
    }

    window.history.pushState({}, null, '#checkout');
  },

  navToOrderConfirmation() {
    if (!this.canUseRouter()) {
      return;
    }

    window.history.pushState({}, null, '#confirmation');
  },

  navToItem(itemId, sectionId) {
    if (!this.canUseRouter()) {
      return;
    }

    const locationHash = `#item-${itemId}-section-${sectionId}`;
    window.history.pushState({}, null, locationHash);
  },

  simulateNavToItem(sectionId, item) {
    // simulate tapping the menu item cell view
    gcn.notifyUserDidTapMenuItem(sectionId, item);
  },

  initialize() {
    if (!this.canUseRouter()) {
      return;
    }

    // coming from url with hash
    const locationHash = window.location.hash;
    Log.info('initial window.location.hash', locationHash);

    // always start with #menu so we can nav back to it
    window.history.replaceState(
      {
        view: 'menu',
      },
      null,
      '#menu',
    );

    this.simulateNav(locationHash, true);
  },

  simulateNav(locationHash, firstLoad) {
    const itemUrlRegex = /#item-([a-zA-Z0-9]+)-section-([a-zA-Z0-9]+)/;
    switch (true) {
      // #item-5e5d31753569920017cc64cb-section-5e5d31753569920017cc64cd
      case itemUrlRegex.test(locationHash):
        // commented out due to BITE-2377.
        // Menu item detail view was being called before opening sequence was finished
        // so no fulfillment method was set
        // reimplement after it's easy to check the opening sequence state BITE-2504

        // var itemId = locationHash.match(itemUrlRegex)[1];
        // var item = gcn.menu.getMenuItemWithId(itemId);
        // var sectionId = locationHash.match(itemUrlRegex)[2];
        // var section = gcn.menu.getMenuSectionWithId(sectionId);

        // if (!item || !section) {
        //   break;
        // }

        // // update history
        // if (firstLoad) {
        //   this.navToItem(itemId, sectionId);
        // }
        // this.simulateNavToItem(sectionId, item);
        break;
      // #checkout
      case /#checkout/.test(locationHash):
        // let default behavior handle this case
        break;
      // #menu
      case /#menu/.test(locationHash):
        if (firstLoad) {
          break;
        }
        this.simulateNavToMenu();
        break;
    }
  },

  listenForRouting() {
    if (!this.canUseRouter()) {
      return;
    }

    const self = this;
    // listen when a user clicks back or forward
    window.onpopstate = function onPopState(/* event */) {
      // window.location.hash is the page the user expects to nav to
      self.simulateNav(window.location.hash, false);
    };
  },
};
