import { motion } from 'framer-motion';
import * as React from 'react';

import { Strings } from '@biteinc/common';

import { useLocalize } from '~/app/js/localization/localization';
import ScreenReaderHelper from '~/app/js/screen_reader_helper';
import { ReactAriaButton } from '~/components/button';

import GcnHelper from '../../../app/js/gcn_helper';
import { FeatureState, useStore } from '../../../stores';
import { CartUtils } from '../cart.utils';
import { LanguagePopover } from '../language-popover';
import { AccessibilityIcon } from './accessibility-icon';
import { BottomBarUtils } from './cart-bottom-bar.utils';
import { MagnifyingIcon } from './magnifying-icon';

export function CartBottomBar(): JSX.Element {
  const str = useLocalize();
  const order = useStore((state) => state.cart);
  const config = useStore((state) => state.config);

  const inCheckoutView = CartUtils.useInCheckoutView();
  const inNavigationView = CartUtils.useInNavigationView();
  const inFSF = CartUtils.useInFSF();
  const popupInView = CartUtils.usePopupInView();

  const shouldHideBottomBar = BottomBarUtils.useShouldHideBottomBar({
    inCheckoutView,
    inNavigationView,
    inFSF,
    order,
    config,
  });

  const shouldHideTotals = inCheckoutView || inNavigationView || order.orderedItems.length === 0;
  const checkoutDisabled =
    order.orderedItems.length === 0 || inFSF || popupInView || !order.fulfillmentMethod;
  const orderTotalText = `${CartUtils.formatPrice(order.subTotal) ?? '0.00'}`;

  function checkout(): void {
    gcn.startCheckoutSequence();
  }

  React.useEffect(() => {
    document.documentElement.style.setProperty(
      '--bottom-bar-space',
      shouldHideBottomBar ? '24px' : '130px',
    );
  }, [shouldHideBottomBar]);

  return (
    <motion.div
      className="cart-view__bottom-bar"
      variants={BottomBarUtils.bottomBarVariants}
      initial="hidden"
      animate={shouldHideBottomBar ? 'hidden' : 'visible'}
      transition={{
        bounce: 0,
      }}
      aria-hidden={shouldHideBottomBar}
    >
      <div className="cart-view__bottom-bar__buttons">
        {config.zoomState !== FeatureState.HIDDEN && (
          <ReactAriaButton
            className="tw-bg-transparent tw-p-1.5"
            aria-label={ScreenReaderHelper.prepareAriaLabel(
              config.zoomState === FeatureState.ENABLED ? 'Zoom out' : 'Zoom in',
            )}
            onPress={() => {
              if (config.zoomState === FeatureState.ENABLED) {
                config.disableZoom();
              } else {
                config.enableZoom();
              }
            }}
            data-active={config.zoomState === FeatureState.ENABLED}
          >
            <MagnifyingIcon
              zoomed={config.zoomState === FeatureState.ENABLED}
              className="tw-w-9 tw-h-9"
            />
          </ReactAriaButton>
        )}
        {config.reducedHeightState !== FeatureState.HIDDEN && (
          <ReactAriaButton
            aria-label={ScreenReaderHelper.prepareAriaLabel(
              config.reducedHeightState === FeatureState.ENABLED ? 'Full height' : 'Reduced height',
            )}
            onPress={() => {
              if (config.reducedHeightState === FeatureState.ENABLED) {
                config.disableReducedHeight();
              } else {
                config.enableReducedHeight();
              }
            }}
            className="tw-bg-transparent tw-p-1.5"
            data-active={config.reducedHeightState === FeatureState.ENABLED}
          >
            <AccessibilityIcon className="tw-w-9 tw-h-9" />
          </ReactAriaButton>
        )}
        <LanguagePopover />
      </div>

      <motion.div
        className="cart-view__bottom-bar__checkout-total"
        variants={BottomBarUtils.totalsVariants}
        initial="hidden"
        animate={shouldHideTotals ? 'hidden' : 'visible'}
        transition={{
          bounce: 0,
        }}
        aria-hidden={shouldHideTotals}
      >
        <span
          className="cart-view__bottom-bar__checkout-total__total"
          role="heading"
          aria-level={1}
          aria-label={ScreenReaderHelper.prepareAriaLabel(`${orderTotalText}`)}
          aria-hidden={checkoutDisabled}
        >
          {str(Strings.TOTAL)}: {orderTotalText}
        </span>
        <ReactAriaButton
          useOnPress
          onPress={checkout}
          className={
            'tw-rounded-md tw-py-3 tw-px-7 tw-whitespace-nowrap tw-w-max tw-bg-[var(--ok-green)] disabled:tw-opacity-50 cart-view__bottom-bar__checkout-total__checkout tw-text-2xl'
          }
          aria-label={ScreenReaderHelper.prepareAriaLabel(
            `${str(Strings.CHECKOUT)} Subtotal $${GcnHelper.stringFromTotal(
              gcn.orderManager.getSubTotal(),
            )}`,
          )}
          aria-hidden={checkoutDisabled}
          disabled={checkoutDisabled}
        >
          {str(Strings.CHECKOUT)}
        </ReactAriaButton>
      </motion.div>
    </motion.div>
  );
}
