// cspell:ignore ptpv
import _ from 'underscore';

import { Strings } from '@biteinc/common';

import { localizeStr } from '~/app/js/localization/localization';

import Analytics from '../utils/analytics';
import { GCNView } from './gcn_view';

export const GCNEbtModePickerView = GCNView.extend({
  className: 'order-destination-view',

  _template: _.template(
    // prettier-ignore
    '<div class="payment-destination">' +
      '<div class="back-button"></div>' +
      '<div class="header" tabIndex="-1"></div>' +
      '<div class="body">' +
        '<div class="button-container">' +
          '<div class="button pay-at-kiosk">' +
            '<div class="image"></div>' +
            '<div class="label"></div>' +
          '</div>' +
          '<div class="button pay-at-cashier">' +
            '<div class="image"></div>' +
            '<div class="label"></div>' +
          '</div>' +
        '</div>' +
      '</div>' +
    '</div>',
  ),

  initialize(options, ...args) {
    GCNView.prototype.initialize.apply(this, args);

    this._callback = options.callback;
    this.addRefreshOnLanguageChangeSubscription();
  },

  _renderBackButton() {
    this.$('.back-button').htmlOrText(localizeStr(Strings.BACK));
    this.$('.back-button').onButtonTapOrHold('ptpvBack', () => {
      Analytics.track(Analytics.EventName.PaymentMethodBackOut);
      this._callback(null);
    });
  },

  render() {
    this.$el.html(this._template());

    this._renderBackButton();

    // Set up handlers for buttons.
    const $cashBenefitButton = this.$('.button.pay-at-kiosk');
    $cashBenefitButton.onButtonTapOrHold('ptpvCashBenefit', () => {
      Analytics.trackEvent({
        eventName: Analytics.EventName.PaymentMethodSelected,
        eventData: {
          paymentMethod: 'ebt-cash-benefit',
        },
      });
      this._callback('cash-benefit');
    });
    $cashBenefitButton.htmlOrText(localizeStr(Strings.USE_EBT_CASH_BENEFIT));

    const $foodStampButton = this.$('.button.pay-at-cashier');
    $foodStampButton.onButtonTapOrHold('ptpvFoodStamp', () => {
      Analytics.trackEvent({
        eventName: Analytics.EventName.PaymentMethodSelected,
        eventData: {
          paymentMethod: 'ebt-food-stamp',
        },
      });
      this._callback('food-stamp');
    });
    $foodStampButton.htmlOrText(localizeStr(Strings.USE_EBT_FOOD_STAMP));

    // Localize.
    this.$('.header').htmlOrText(localizeStr(Strings.HOW_WOULD_YOU_LIKE_TO_PAY));

    this.$('.header').requestFocusAfterDelay();

    return this;
  },
});
