import _ from 'underscore';

import { Strings } from '@biteinc/common';
import { OrderPaymentDestination } from '@biteinc/enums';

import { BackboneEvents } from '~/app/js/backbone-events';
import { localizeStr } from '~/app/js/localization/localization';

import Analytics from '../utils/analytics';
import { GCNView } from './gcn_view';

export const GCNPaymentTypePickerView = GCNView.extend({
  className: 'order-destination-view',

  _template: _.template(
    // prettier-ignore
    '<div class="payment-destination">' +
      '<div class="back-button"></div>' +
      '<div class="header" tabIndex="-1"></div>' +
      '<div class="body">' +
        '<div class="button-container">' +
          '<div class="button pay-at-kiosk">' +
            '<div class="image"></div>' +
            '<div class="label"></div>' +
          '</div>' +
          '<div class="button pay-at-cashier">' +
            '<div class="image"></div>' +
            '<div class="label"></div>' +
          '</div>' +
        '</div>' +
      '</div>' +
    '</div>',
  ),

  initialize(...args) {
    GCNView.prototype.initialize.apply(this, args);

    this.addRefreshOnLanguageChangeSubscription();
  },

  _renderBackButton() {
    this.$('.back-button').htmlOrText(localizeStr(Strings.BACK));
    this.$('.back-button').onButtonTapOrHold('pypvBack', () => {
      Analytics.track(Analytics.EventName.PaymentMethodBackOut);
      this.trigger(BackboneEvents.GCNPaymentTypePickerView.BackedOut);
    });
  },

  render() {
    this.$el.html(this._template());

    this._renderBackButton();

    // Set up handlers for buttons.
    const $payAtKioskButton = this.$('.button.pay-at-kiosk');
    $payAtKioskButton.onButtonTapOrHold('ptpvCredit', () => {
      Analytics.trackEvent({
        eventName: Analytics.EventName.PaymentMethodSelected,
        eventData: {
          paymentMethod: 'card',
        },
      });
      gcn.orderManager.setPaymentDestination(OrderPaymentDestination.CreditCard);
      this.trigger(BackboneEvents.GCNPaymentTypePickerView.DidPickPaymentType);
    });
    $payAtKioskButton.htmlOrText(localizeStr(Strings.DEBIT_CREDIT_CARD));

    const $payAtCashierButton = this.$('.button.pay-at-cashier');
    $payAtCashierButton.onButtonTapOrHold('ptpvCash', () => {
      Analytics.trackEvent({
        eventName: Analytics.EventName.PaymentMethodSelected,
        eventData: {
          paymentMethod: 'cashier',
        },
      });
      gcn.orderManager.setPaymentDestination(OrderPaymentDestination.Cashier);
      this.trigger(BackboneEvents.GCNPaymentTypePickerView.DidPickPaymentType);
    });
    $payAtCashierButton.htmlOrText(localizeStr(Strings.CASH));

    // Localize.
    this.$('.header').htmlOrText(localizeStr(Strings.HOW_WOULD_YOU_LIKE_TO_PAY));

    this.$('.header').requestFocusAfterDelay();

    return this;
  },
});
