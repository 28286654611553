import type { Store } from '../../stores';
import type { EcommI9nConfig, KioskLocation } from '../../types';

export interface EcommPaymentFormProps {
  checkoutState: Store['checkout'];
  ecommI9nConfig: EcommI9nConfig;
  orderTotalsComponent: React.ReactNode;
  onSubmit(): void;
  requiresEcommPayment: boolean;
  isReadyToSubmit: boolean;
  location: KioskLocation;
}

export const paymentRequestButtonContainer = 'payment-request-button-container';

export const cardElement = 'card-element';
