import type { Variants } from 'framer-motion';
import { motion } from 'framer-motion';
import * as React from 'react';

import { Strings } from '@biteinc/common';
import { KioskOrderingMode } from '@biteinc/enums';

import { str } from '~/app/js/localization/localization';
import Analytics from '~/app/js/utils/analytics';
import { AnalyticsEventName } from '~/types/analytics_event';

import { useSettings, useStore } from '../../stores';
import { CartBottomBar } from './cart-bottom-bar';
import { CartCollapsibleView } from './cart-collapsible-view';
import { CartTopBar } from './cart-top-bar';
import { CartUtils } from './cart.utils';

const cartTopVariants: Variants = {
  visible: {
    opacity: 1,
    y: 0,
  },
  hidden: {
    opacity: 0,
    y: '100%',
  },
};

function CartV2Component(): JSX.Element {
  const order = useStore((state) => state.cart);
  const [showCartInfo, setShowCartInfo] = React.useState(false);
  const settings = useSettings();
  const config = useStore((state) => state.config);
  // By default, if the screen reader is active, the cart should always be open.
  const forceOpen =
    settings.kioskOrderingMode === KioskOrderingMode.ScannerOnly || config.screenReaderIsActive;
  const cartIsOpen = forceOpen || showCartInfo;
  const paneRef = React.useRef<HTMLDivElement>(null);
  const inNavigationView = CartUtils.useInNavigationView();

  const inFSF = CartUtils.useInFSF();
  const popupInView = CartUtils.usePopupInView();

  /**
   * Toggles the cart's detailed view.
   * If a screen reader is active, or if the location is in scanner only mode  the function will be non-operational
   * to ensure accessibility.
   */
  const toggle = (): void => {
    if (!forceOpen) {
      setShowCartInfo((show) => !show);
    }
  };

  const shouldHideTopBar = inNavigationView || inFSF || order.orderedItems.length === 0;
  const shouldHideTopBarInAda = shouldHideTopBar || popupInView;

  React.useEffect(() => {
    if (order.orderedItems.length === 0 && showCartInfo) {
      setShowCartInfo(false);
    }
  }, [order, showCartInfo]);

  React.useEffect(() => {
    if (popupInView && showCartInfo) {
      setShowCartInfo(false);
    }
  }, [popupInView, showCartInfo]);

  React.useEffect(() => {
    Analytics.track(showCartInfo ? AnalyticsEventName.CartOpened : AnalyticsEventName.CartClosed);
  }, [showCartInfo]);

  // if a user clicks outside of the cart, close it
  React.useEffect(() => {
    const handleClick = (event: MouseEvent): void => {
      if (!paneRef.current?.contains(event.target as Node)) {
        setShowCartInfo(false);
      }
    };

    // handle when the gcn embed div classnames change and check if the name includes 'with-popup'
    // if it does, then we know the popup is open and we should close the cart

    window.addEventListener('mousedown', handleClick);
    window.addEventListener('pointerdown', handleClick);

    return (): void => {
      window.removeEventListener('mousedown', handleClick);
      window.removeEventListener('pointerdown', handleClick);
    };
  }, []);

  return (
    <div
      id="cart-v2"
      ref={paneRef}
    >
      {order.orderedItems.length > 0 && (
        <div>
          <span
            className="sr-only"
            aria-level={1}
            role="heading"
            aria-hidden={shouldHideTopBarInAda}
          >
            {str(Strings.CART)}
          </span>

          <span
            className="sr-only"
            aria-live="polite"
            aria-hidden={shouldHideTopBarInAda}
          >
            {order.orderedItems.length} items in cart.
          </span>
        </div>
      )}

      <motion.div
        className="cart-container"
        variants={cartTopVariants}
        initial="hidden"
        animate={shouldHideTopBar ? 'hidden' : 'visible'}
        transition={{
          bounce: 0,
        }}
        aria-hidden={shouldHideTopBarInAda}
      >
        <CartTopBar
          onToggle={toggle}
          showCartInfo={cartIsOpen}
        />
        <CartCollapsibleView showCartInfo={cartIsOpen} />
      </motion.div>

      <CartBottomBar />
    </div>
  );
}

function CartWrapper(): JSX.Element {
  return (
    <CartUtils.PopupProvider>
      <CartUtils.FSFProvider>
        <CartUtils.CheckoutViewProvider>
          <CartUtils.NavigationViewProvider>
            <CartV2Component />
          </CartUtils.NavigationViewProvider>
        </CartUtils.CheckoutViewProvider>
      </CartUtils.FSFProvider>
    </CartUtils.PopupProvider>
  );
}

export const CartV2 = CartWrapper;
