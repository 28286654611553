import $ from 'jquery';
import _ from 'underscore';

import { GCNView } from './gcn_view';

export const GCNLayeredImageView = GCNView.extend({
  className: 'layered-image-view',
  template: _.template(
    // prettier-ignore
    '<div class="base-layer"></div>' +
    '<div class="top-layers"></div>',
  ),

  // baseImage - A single "Image" type object. (see schemas for definition)
  // layers - An array of "Image" type object.
  initialize(options, ...args) {
    GCNView.prototype.initialize.apply(this, [options, ...args]);

    this.baseImage = options.baseImage;
    // It is up to the user of this class to track which index relate to
    // which layer of the view.
    this.layers = options.layers;
  },

  _layerClass(index) {
    return `layer-${index}`;
  },

  _getImageElementAtIndex(index) {
    return this.$el.find(`.${this._layerClass(index)}`);
  },

  showImageAtIndex(index) {
    this._getImageElementAtIndex(index).show();
  },

  hideImageAtIndex(index) {
    this._getImageElementAtIndex(index).hide();
  },

  hideAllTopImages() {
    this.$el.find('.layer.top').hide();
  },

  render() {
    const self = this;

    // Create base and load image.
    this.$el.html(this.template());
    this.$baseLayer = $('<div class="layer base"></div>');
    gcn.requestImageByUrl(this.baseImage.url, (err, imgPath) => {
      if (!err) {
        self.$baseLayer.css('background-image', `url(${imgPath})`);
      }
    });
    this.$('.base-layer').append(this.$baseLayer);

    _.each(this.layers, (layer, i) => {
      // Create and add the image with a specific layer class so they can be
      // found later.
      const $layer = $('<div class="layer top"></div>');
      $layer.addClass(self._layerClass(i));

      gcn.requestImageByUrl(layer.url, (err, imgPath) => {
        if (!err) {
          $layer.css('background-image', `url(${imgPath})`);
        }
      });
      self.$('.top-layers').append($layer);
    });

    return this;
  },
});
