import _ from 'underscore';

import { RecommendationDisplayLocationDescription } from '../../../types/recommendation';
import { setImage } from '../gcn_app_view';
import GcnHtml from '../gcn_html';
import GcnRecoTracker from '../gcn_reco_tracker';
import Analytics from '../utils/analytics';
import { GCNRecommendationsView } from './gcn_recommendations_view';
import { GCNView } from './gcn_view';

export const GCNItemMiniView = GCNView.extend({
  className: 'item-mini-view',
  template: _.template(
    // prettier-ignore
    '<div class="image">' +
      '<div class="check"></div>' +
    '</div>' +
    '<div class="name"><%= name %></div>',
  ),

  setAddedVisualState(added) {
    this.$el.toggleClass('added', added);
  },

  render() {
    this.$el.html(
      this.template({
        name: this.model.displayName(),
      }),
    );

    if (this.model.hasOnePriceOptionNoAddons()) {
      this.$el.append(GcnHtml.htmlFromPriceOption(this.model.priceOptions[0]));
    }

    const $image = this.$('.image');
    const itemImages = this.model.get('images');
    if (itemImages?.length) {
      const image = itemImages[itemImages.length - 1];
      setImage(image, $image);
    } else {
      // Use fallback.
      const placeholderClass = gcn.menu.placeholderImageClass(this.model);
      $image.addClass(placeholderClass);
      $image.addClass('item-image');
    }

    return this;
  },
});

export const GCNRecommendationsMiniView = GCNRecommendationsView.extend({
  className: 'recommendations-mini',
  template: _.template(
    // prettier-ignore
    '<div class="header bg-color-spot-1 color-spot-1"></div>' +
    '<div class="items font-body"></div>',
  ),

  render() {
    if (!this._recos.length) {
      return this;
    }

    GcnRecoTracker.trackDisplayRecommendations(
      gcn.maitred,
      RecommendationDisplayLocationDescription.MENU_ITEM_MINI_RECOS,
      this._recos.map((item) => {
        return item.id;
      }),
    );

    this.$el.html(this.template());

    this.$('.header').htmlOrText(this.__getHeaderText());

    const $container = this.$('.items');
    _.each(this._recos, (reco, index) => {
      const itemView = new GCNItemMiniView({
        model: reco,
      });
      $container.append(itemView.render().$el);

      itemView.$el.onButtonTapOrHold('reco_mini_button', () => {
        Analytics.trackEvent({
          eventName: Analytics.EventName.RecommendationMenuItemAdded,
          eventData: {
            itemName: reco.displayName(),
          },
        });
        this.__didTapOnRecommendedItemView(itemView, reco.isAuto);
      });

      this.__addRecoAndViewToDetails(reco, itemView, index);
    });

    return this;
  },
});
