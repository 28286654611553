import { IonButton, IonItem, IonList } from '@ionic/react';
import React from 'react';

import { Log, Strings } from '@biteinc/common';

import { useLocalize } from '~/app/js/localization/localization';
import { GCNCouponView } from '~/app/js/views/gcn_coupon_view';
import { useLocation } from '~/stores';

interface CouponProps {
  couponApplied: boolean;
  orderIsPartiallyPaid: boolean;
}

export function Coupon(props: CouponProps): React.ReactElement {
  const location = useLocation();
  const str = useLocalize();
  function addCoupon(): void {
    // remove coupon if it exists
    if (gcn.orderManager.getCouponCode()) {
      gcn.orderManager.removeCouponCode(() => {
        gcn.menuView.dismissSpinner();
      });
      return;
    }

    const couponView = new GCNCouponView({
      successCallback: () => {
        Log.info('Successfully applied coupon. Got a new order to validate.');
        gcn.menuView.dismissStablePopup();
      },
    });
    gcn.menuView.showStablePopup(couponView, 'coupon-view');
  }

  function areCouponsAndLoyaltyEarningCompatible(): boolean {
    const allPosCompatible = location
      .getPosI9nSchemas()
      .every((schema) => schema.couponsAndLoyaltyEarningCompatible);

    if (!allPosCompatible) {
      return false;
    }

    return !!location.getLoyaltyI9nSchema()?.couponsAndLoyaltyEarningCompatible;
  }

  // If the order is partially paid for we can't apply a new coupon
  const disableButton = props.orderIsPartiallyPaid;

  const label = props.couponApplied ? str(Strings.REMOVE_COUPON) : str(Strings.USE_COUPON);

  return (
    <IonList
      lines="none"
      className="coupon"
    >
      {location.hasCouponProvider() || areCouponsAndLoyaltyEarningCompatible() ? (
        <IonItem>
          <IonButton
            className="get-coupons-button"
            expand="block"
            fill="outline"
            size="default"
            disabled={disableButton}
            onClick={() => {
              addCoupon();
            }}
          >
            {label}
          </IonButton>
        </IonItem>
      ) : null}
    </IonList>
  );
}
