import React from 'react';

import { ModalService } from '@biteinc/core-react';

import { MenuFilterPicker } from '../components';

module MenuFilterService {
  export function showMenuFilters(): void {
    const hide = ModalService.showCustomModal({
      cssClass: 'menu-filters-modal',
      children: (
        <MenuFilterPicker
          close={() => {
            hide();
          }}
        />
      ),
    });
  }
}

export default MenuFilterService;
