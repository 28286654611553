import { KioskOrderingMode } from '@biteinc/enums';

import type { GcnMenuPage } from '~/types/gcn_menu';

import { BackboneEvents } from '../backbone-events';
import ScreenReaderHelper from '../screen_reader_helper';
import Analytics from '../utils/analytics';
import GcnView from './gcn_view_ts';

export class GCNSideNavView extends GcnView {
  className = 'side-nav-view';

  SECTION_CLASS_PREFIX = 'section-';
  SELECTED_BUTTON_CLASS = 'selected border-color-spot-1 color-spot-1';

  private topLevelMenuPageId?: string;

  private menuPageId?: string;

  private firstPageWasSet?: boolean;

  constructor() {
    super();

    this.listenTo(gcn, BackboneEvents.GCNMenuAppView.MenuDidUpdate, this.render);
  }

  isNavButtonSelected(): boolean {
    return (
      this.$el.find(`.button-touch-area.${this.SELECTED_BUTTON_CLASS.split(' ').join('.')}`)
        .length > 0
    );
  }

  private isSinglePageMode(): boolean {
    return gcn.menu.structure.pages.length === 1;
  }

  private setSelectedNavButton(className: string): void {
    const $otherButtons = this.$('.button-touch-area');
    $otherButtons.removeClass(this.SELECTED_BUTTON_CLASS);

    const $buttonToSelect = this.$(`.button-touch-area.${className}`);
    $buttonToSelect.addClass(this.SELECTED_BUTTON_CLASS);
  }

  private scrollToSelectedPageButton(): void {
    // Calculate the final scroll position.
    const $selectedButton = this.$el.find('div.selected');
    if (!$selectedButton.length) {
      return;
    }
    this.animateToButton($selectedButton);
  }

  private animateToButton(selectedButton: any): void {
    const distanceFromTop = selectedButton.position().top + this.$el.scrollTop();
    if (this.isSinglePageMode()) {
      this.$el.stop().animate(
        {
          scrollY: distanceFromTop,
        },
        600,
      );
    } else {
      const $sideNavEl = this.$el.find('div.side-nav-view');
      const sideNavInViewRange = $sideNavEl.position().top + ($sideNavEl.height() || 0);

      // if distanceFromTop is already in view do nothing
      if (distanceFromTop > sideNavInViewRange) {
        this.$el.stop();
        $sideNavEl.animate(
          {
            scrollTop: distanceFromTop,
          },
          600,
        );
      }
    }
  }

  private findMenuPage(menuPageId: string): GcnMenuPage | null | undefined {
    if (!menuPageId) {
      return null;
    }
    return gcn.menu.structure.pages.find((page) => {
      return gcn.menu.menuPageContainsPageWithId(page, menuPageId);
    });
  }

  setMenuPage(menuPage: GcnMenuPage): void {
    // Figure out which top-level page was selected.
    this.menuPageId = menuPage.id;
    // eslint-disable-next-line no-param-reassign
    menuPage = this.findMenuPage(this.menuPageId)!;
    this.topLevelMenuPageId = (menuPage || {}).id || this.menuPageId;

    this.render();

    this.scrollToSelectedPageButton();
  }

  setSelectedSectionWithId(sectionId: string, scrollButtonIntoView: boolean): void {
    if (this.isSinglePageMode()) {
      this.setSelectedNavButton(this.SECTION_CLASS_PREFIX + sectionId);
      if (scrollButtonIntoView) {
        this.scrollToSelectedPageButton();
      }
    }
  }

  private createNavButton(): JQuery<HTMLElement> {
    return $(
      // prettier-ignore
      '<div class="button-touch-area">' +
        '<div class="button-actual" aria-hidden="true"> </div>' +
      '</div>',
    );
  }

  private template(): string {
    return `
    <div class="side-nav-view">
      <div class="side-nav-view-content">
      </div>
    </div>`;
  }

  setSideNavHeightLevel(): void {
    const cartHasOrders = !!gcn.orderManager.getOrderedItems().length;
    const isBottomBarShown = !!gcn.location.hasBottomBarButtons();
    const $sideNavViewEle = this.$el.find('.side-nav-view');
    const cartForcedOpenMode =
      gcn.menu.settings.get('kioskOrderingMode') === KioskOrderingMode.ScannerOnly ||
      gcn.screenReaderIsActive;

    if (cartForcedOpenMode && cartHasOrders) {
      const topNavOffset = document.querySelector('.top-nav-view')?.clientHeight || 0;
      const recosOffset =
        document.querySelector('.cart-view__collapsible-view__recommendations')?.clientHeight || 0;
      // If the cart is forced open dynamically resize the side nav to fit the cart container and bottom bar
      // Use the padding + margin of the side nav container and items to properly display the last item to match the first element

      // Take the height of the entire screen and then subtract
      // * Dynamic cart top bar height
      // * Dynamic cart bottom bar height
      // * Height of the cart container, includes ordered items and recommended items
      // * Replicate top padding from the side nav on the bottom
      setTimeout(() => {
        const cartContainerHeight = document.querySelector(
          '.cart-view__collapsible-view__container',
        )?.clientHeight;
        const cartItemContainerHeight = document.querySelector(
          '.cart-view__collapsible-view__orders__item-container',
        )?.clientHeight;

        $sideNavViewEle.css(
          'height',
          `calc(100% - (var(--cart-top-bar-height) + (var(--bottom-bar-height) + ${Math.floor(
            cartContainerHeight && cartItemContainerHeight
              ? cartContainerHeight / cartItemContainerHeight
              : gcn.orderManager.getOrderedItems().length,
          )} * var(--side-nav-scrolling-spacer)) + ${
            topNavOffset + recosOffset
          }px + ${$sideNavViewEle.css('padding-top')}`,
        );
      }, 500);
    } else if (cartForcedOpenMode && !cartHasOrders) {
      $sideNavViewEle.css('height', '');
    } else if (cartHasOrders && !$sideNavViewEle.hasClass('has-cart')) {
      $sideNavViewEle.addClass('has-cart');
      $sideNavViewEle.removeClass('has-bottom-bar');
    } else if (isBottomBarShown && !$sideNavViewEle.hasClass('has-bottom-bar')) {
      $sideNavViewEle.addClass('has-bottom-bar');
      $sideNavViewEle.removeClass('has-cart');
    } else if (!isBottomBarShown && !cartHasOrders) {
      $sideNavViewEle.removeClass('has-cart');
      $sideNavViewEle.removeClass('has-bottom-bar');
    }
  }

  render(): this {
    this.$el.html(this.template());
    const menuStructure = gcn.menu.structure;

    this.setSideNavHeightLevel();

    if (this.isSinglePageMode()) {
      // Section navigation.
      menuStructure.pages[0].sections.forEach((section) => {
        if (section.attributes.hideFromMenu) {
          return;
        }
        const $button = this.createNavButton();
        $button.addClass(this.SECTION_CLASS_PREFIX + section.id);
        $button.addClass(this.SECTION_CLASS_PREFIX + section.getSlugName());
        const $buttonActual = $button.find('.button-actual');

        const menuSectionImages = section.get('pageNavigationImages') ?? [];
        if (menuSectionImages.length) {
          // using the first page navigation picture assuming there are more
          const sectionImage = menuSectionImages[0].url;
          gcn.requestImageByUrl(sectionImage, (err, imgPath) => {
            $buttonActual
              .parent()
              .prepend(`<img src="${imgPath}" class="side-nav-button-image" role="presentation"/>`);
          });
        }
        $buttonActual.text(section.displayName());

        // The first page needs to be set once the menu is loaded.
        if (!this.firstPageWasSet) {
          this.firstPageWasSet = true;
          this.setSelectedNavButton(this.SECTION_CLASS_PREFIX + section.id);
        }

        $button.onTapInScrollableAreaWithCalibration('snvSection', () => {
          Analytics.trackEvent({
            eventName: Analytics.EventName.ScrollingNavSectionSelected,
            eventData: {
              sectionName: section.displayName(),
            },
          });
          this.trigger(BackboneEvents.GCNScrollingNavView.DidSelectSectionId, section.id);
          this.setSelectedNavButton(this.SECTION_CLASS_PREFIX + section.id);
          this.scrollToSelectedPageButton();

          return false;
        });

        this.$el.find('.side-nav-view-content').append($button);
      });
    } else {
      // WHY NOT CLASS ?!? Page navigation.
      menuStructure.pages?.forEach((page) => {
        // Make one button per ID and Name.
        const $button = this.createNavButton();
        $button.addClass(`page-${page.getSlugName()}`);
        const $buttonActual = $button.find('.button-actual');

        // in multi-paged menu we use the `pageNavigationImages` to populate the
        // image in side-nav section
        const pageImages = page.get('pageNavigationImages');
        if (pageImages?.length) {
          // using the first page navigation picture assuming there are some
          const pageImage = pageImages[0].url;
          gcn.requestImageByUrl(pageImage, (err, imgPath) => {
            if (!err) {
              $buttonActual
                .parent()
                .prepend(
                  `<img src="${imgPath}" class="side-nav-button-image" role="presentation" />`,
                );
            }
          });
          $buttonActual.text(page.get('name'));
        } else {
          const url = page.getMenuPageImageUrl();
          if (url) {
            $buttonActual.addClass('has-image');
            gcn.requestImageByUrl(url, (err, imgPath) => {
              if (!err) {
                $buttonActual.css('background-image', `url(${imgPath})`);
              }
            });
          } else {
            $buttonActual.text(page.get('name'));
          }
        }

        if (this.topLevelMenuPageId === page.id) {
          $button.addClass(this.SELECTED_BUTTON_CLASS);
          this.setSelectedNavButton(this.SECTION_CLASS_PREFIX + page.id);
          $button.attr('role', 'heading');
          $button.attr('aria-level', '1');
        }
        $button.attr('aria-label', ScreenReaderHelper.prepareAriaLabel($buttonActual.text()));

        $button.onTapInScrollableAreaWithCalibration('snvSubPage', () => {
          Analytics.trackEvent({
            eventName: Analytics.EventName.ScrollingNavPageSelected,
            eventData: {
              pageName: page.get('name'),
            },
          });

          this.trigger(BackboneEvents.GCNScrollingNavView.DidSelectMenuPageId, page.id);
          if (gcn.screenReaderIsActive) {
            // There's an issue with JAWS where it doesn't reload the menu
            // structure when a new menu category is selected. To fix this, we
            // can ask it to re-focus.
            // @ts-ignore
            this.$el.parent().find('.section-header').first().requestFocusAfterDelay();
          }
          this.scrollToSelectedPageButton();
          return false;
        });

        this.$el.find('.side-nav-view-content').append($button);
      });
    }

    if (!this.isNavButtonSelected()) {
      // always select the first `.side-nav-view-content > .button-touch-area` child
      // IFF no button is selected
      this.$el
        .find('.side-nav-view-content > .button-touch-area:first-child')
        .addClass(this.SELECTED_BUTTON_CLASS);
    }

    return this;
  }
}
