import React, { Component } from 'react';

import { Log, PaymentCode, Strings } from '@biteinc/common';
import type { elements as StripeElements, StripePaymentRequest } from '@biteinc/core-react';
import { BiteLogStripeEvent, BiteLogType } from '@biteinc/enums';

import { localizeStr, str } from '~/app/js/localization/localization';
import { GCNAlertView } from '~/app/js/views/gcn_alert_view';

import type { EcommPaymentFormProps } from '../ecomm-payment-form';
import { cardElement, paymentRequestButtonContainer } from '../ecomm-payment-form';
import SubmitOrderButton from '../submit-order-button';

type StripeEcommFormState = {
  stripeErrorMessage: string;
};

export class StripeEcommForm extends Component<EcommPaymentFormProps, StripeEcommFormState> {
  private stripeCard: StripeElements.Element | undefined;

  private stripeElements: StripeElements.Elements | undefined;

  private paymentRequest: StripePaymentRequest | undefined;

  private paymentRequestButton: StripeElements.Element | undefined;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private cardElement: React.RefObject<any>;

  constructor(props: EcommPaymentFormProps) {
    super(props);

    this.cardElement = React.createRef();
    this.stripeElements = undefined;
    this.stripeCard = undefined;
    this.paymentRequest = undefined;
    this.paymentRequestButton = undefined;
    this.state = {
      stripeErrorMessage: '',
    };
  }

  private mountCardElement(): void {
    this.stripeCard?.mount(`#${cardElement}`);
    if (this.props.requiresEcommPayment) {
      this.createStripePaymentRequest();
    }
  }

  componentDidMount(): void {
    // If component did mount, we should expect that window.stripe exists.
    // We create the stripe cc field elements, but do not mount here
    // as the section may not exist yet
    this.stripeElements = window.stripe.elements();
    const style = {
      base: {
        color: '#32325d',
      },
    };
    this.stripeCard = this.stripeElements?.create('card', { style });
    // Add the on change listener
    // on valid data, dispatch action to update checkout state
    this.stripeCard?.on('change', (event) => {
      if (event?.error) {
        this.setState({ stripeErrorMessage: event.error.message || '' });
      } else {
        this.setState({ stripeErrorMessage: '' });
      }
      if (this.props.checkoutState.ecommPayment.isValid !== event?.complete) {
        this.props.checkoutState.onEcommPaymentUpdated({
          value: '',
          isValid: event?.complete || false,
        });
      }
    });
    this.stripeCard?.on('focus', () => {
      // Workaround to make sure cc input is always visible on focus
      // If the top of the cc input is at the middle of the screen or below, scroll up on focus
      if (
        this.cardElement &&
        this.cardElement.current.getBoundingClientRect().top >= window.screen.height / 2
      ) {
        this.cardElement.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    });
    if (this.props.checkoutState.order.wasValidated) {
      this.mountCardElement();
    }
  }

  /**
   * Only likely to occur if we are logged in and validate immediately
   */
  componentDidUpdate(prevProps: EcommPaymentFormProps): void {
    // Order may not be validated when component mounts, so we
    // mount card element on update when order is validated.
    if (
      this.props.checkoutState.order.wasValidated &&
      !prevProps.checkoutState.order.wasValidated
    ) {
      this.mountCardElement();
    }
  }

  createStripePaymentRequest(): void {
    // If order is validated, then total should exist.
    if (!this.props.checkoutState.order.wasValidated) {
      throw new Error('Order not validated');
    }

    this.paymentRequest = window.stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: str(Strings.TOTAL),
        amount: this.props.checkoutState.order.total,
      },
      requestPayerEmail: true,
    });

    // Google and Apple Pay Logic
    this.paymentRequestButton = this.stripeElements?.create('paymentRequestButton', {
      paymentRequest: this.paymentRequest,
    });

    // Check the availability of the Payment Request API first.
    this.paymentRequest
      ?.canMakePayment()
      .then((result) => {
        if (result) {
          this.paymentRequestButton?.mount(`#${paymentRequestButtonContainer}`);
        }
      })
      .catch((err) => {
        // We probably want to do something here
        Log.error(err);
      });

    this.paymentRequest?.on('paymentmethod', (event) => {
      Log.info('paymentMethodEvent', event);
      gcn.orderManager.setEcommPaymentMethod({
        ...event.paymentMethod,
      });
      event.complete('success');
      this.props.onSubmit();
    });

    this.paymentRequest?.on('cancel', () => {
      gcn.bridge.sendLog(
        {
          status: BiteLogStripeEvent.TokenizationError,
          systemCode: PaymentCode.PaymentCancelled,
          message: 'User cancelled payment request.',
        },
        BiteLogType.Stripe,
      );
    });
  }

  // Actually creates the payment and proceeds through the flow
  // When we hit submit
  createStripePaymentMethod(): void {
    gcn.menuView.showSpinner(localizeStr(Strings.CONFIRMING_YOUR_CARD));
    const guestEmail = gcn.orderManager.getGuestEmail();
    window.stripe
      .createPaymentMethod({
        type: 'card',
        card: this.stripeCard,
        billing_details: {
          email: guestEmail,
        },
      })
      .then((result) => {
        if (result.error) {
          if (result.error.code === 'email_invalid') {
            Log.error('Invalid Email', result.error);
            const alertText = 'Invalid Email';
            const alertView = new GCNAlertView({
              text: alertText,
              okCallback: () => {
                gcn.menuView.dismissModalPopup();
              },
            });
            gcn.menuView.showModalPopup(alertView);
          } else {
            gcn.bridge.sendLog(
              {
                status: BiteLogStripeEvent.TokenizationError,
                systemCode: PaymentCode.PaymentTokenError,
                message: result.error.message,
              },
              BiteLogType.Stripe,
            );
          }
          gcn.menuView.dismissSpinner();
        } else {
          gcn.orderManager.setEcommPaymentMethod({
            ...result.paymentMethod,
          });
          this.props.onSubmit();
        }
      })
      .catch((error) => {
        Log.error(error);
        gcn.bridge.sendLog(
          {
            status: BiteLogStripeEvent.TokenizationError,
            systemCode: PaymentCode.PaymentTokenError,
            message: error.message,
          },
          BiteLogType.Stripe,
        );
      });
  }

  displayStripeErrors(): React.ReactNode | undefined {
    if (this.state.stripeErrorMessage.length > 0) {
      return <div className="checkout-form-error">{this.state.stripeErrorMessage}</div>;
    }
  }

  render(): React.ReactNode {
    return (
      <div className="stripe-form-container">
        <div id={paymentRequestButtonContainer} />
        {this.props.checkoutState.order.wasValidated && this.props.requiresEcommPayment && (
          <div
            id={cardElement}
            ref={this.cardElement}
            className="checkout-form stripe"
          />
        )}
        {this.displayStripeErrors()}
        {this.props.orderTotalsComponent}
        <SubmitOrderButton
          enabled={!!this.props.checkoutState.order.wasValidated}
          onClick={() => {
            if (this.props.isReadyToSubmit) {
              if (this.props.requiresEcommPayment) {
                gcn.menuView.showSpinner(localizeStr(Strings.CONFIRMING_YOUR_CARD));
                this.createStripePaymentMethod();
              } else {
                this.props.onSubmit();
              }
            } else {
              this.props.checkoutState.onInvalidSubmitOrderAttempt();
            }
          }}
        />
      </div>
    );
  }
}
