import type { StateCreator } from 'zustand';

import { FlashBridgeMessage, LanguageCode, MenuFilterCategory } from '@biteinc/enums';

import GcnAdaInstructionsView from '~/app/js/views/gcn_ada_instructions_view';
import { LocationUtils } from '~/helpers';

import type { BridgeState } from './bridge.store';

export enum FeatureState {
  HIDDEN = 'hidden',
  ENABLED = 'enabled',
  DISABLED = 'disabled',
}

type ConfigState = {
  zoomState: FeatureState;
  reducedHeightState: FeatureState;
  initialScale: string;
  language: LanguageCode;
  screenReaderIsActive: boolean;
  menuFilters: Record<MenuFilterCategory, string[]>;
};

type MenuFilter = {
  id: string;
  category: MenuFilterCategory;
};

type ConfigActions = {
  enableReducedHeight: () => void;
  disableReducedHeight: () => void;
  enableZoom: () => void;
  disableZoom: () => void;
  setScreenReaderIsActive: (screenReaderIsActive: boolean) => void;
  addMenuFilter: (filter: MenuFilter) => void;
  removeMenuFilter: (filter: MenuFilter) => void;
  clearMenuFilters: () => void;
  setLanguage: (language: LanguageCode) => void;
  reset: () => void;
  update: () => void;
};

export type ConfigStore = {
  config: ConfigState & ConfigActions;
} & {
  bridge?: Partial<BridgeState>;
};

const configStoreInitialState: ConfigState = {
  zoomState: FeatureState.HIDDEN,
  reducedHeightState: FeatureState.HIDDEN,
  // support browser where visualViewport is not defined
  initialScale: window.visualViewport?.scale.toFixed(3) || '1.000',
  language: LanguageCode.EN_US,
  screenReaderIsActive: false,
  menuFilters: {
    [MenuFilterCategory.Restrictive]: [],
    [MenuFilterCategory.Dietary]: [],
    [MenuFilterCategory.Other]: [],
  },
};

function disabledOrHiddenFeatureState(featureIsAllowed: boolean): FeatureState {
  return featureIsAllowed ? FeatureState.DISABLED : FeatureState.HIDDEN;
}

function featureStatePostUpdate(
  featureIsAllowed: boolean,
  currentFeatureState: FeatureState,
): FeatureState {
  if (!featureIsAllowed) {
    return FeatureState.HIDDEN;
  }

  if (currentFeatureState === FeatureState.HIDDEN) {
    return FeatureState.DISABLED;
  }
  return currentFeatureState;
}

function disableZoom(config: ConfigState): void {
  if (config.zoomState !== FeatureState.ENABLED) {
    return;
  }

  // Bridge will not be defined in demo kiosk-preview
  gcn.bridge?.send({
    event: FlashBridgeMessage.DISABLE_ZOOM,
  });

  const { initialScale } = config;
  setDocumentMetaViewportData(initialScale, initialScale, false);
}

function disableReducedHeight(config: ConfigState): void {
  if (config.reducedHeightState !== FeatureState.ENABLED) {
    return;
  }

  // Bridge will not be defined in demo kiosk-preview
  gcn.menuView.$el.toggleClass('reduced-height', false);
  gcn.bridge?.send({
    event: FlashBridgeMessage.DISABLE_REDUCED_VIEWPORT_HEIGHT,
  });
}

export const initConfigStore: StateCreator<ConfigStore> = (set, get) => ({
  config: {
    ...configStoreInitialState,
    enableReducedHeight: () => {
      const { screenReaderIsActive } = get().config;
      if (screenReaderIsActive) {
        return;
      }

      // Bridge will not be defined in demo kiosk-preview
      gcn.menuView.$el.toggleClass('reduced-height', true);
      gcn.bridge?.send({
        event: FlashBridgeMessage.ENABLE_REDUCED_VIEWPORT_HEIGHT,
      });
      set((state) => ({
        config: { ...state.config, reducedHeightState: FeatureState.ENABLED },
      }));
    },
    disableReducedHeight: () => {
      disableReducedHeight(get().config);
      const settings = get().bridge!.menu!.settings;

      set((state) => ({
        config: {
          ...state.config,
          reducedHeightState: disabledOrHiddenFeatureState(
            LocationUtils.allowsReducedHeight(settings, state.config.screenReaderIsActive),
          ),
        },
      }));
    },
    enableZoom: () => {
      const { screenReaderIsActive } = get().config;
      if (screenReaderIsActive) {
        return;
      }

      setDocumentMetaViewportData('1.0', '2.0', true);
      setTimeout(() => {
        gcn.menuView.dismissAdaPopup();
      }, 5000);
      gcn.menuView.showAdaPopup(new GcnAdaInstructionsView());

      // Bridge will not be defined in demo kiosk-preview
      gcn.bridge?.send({
        event: FlashBridgeMessage.ENABLE_ZOOM,
      });

      set((state) => ({
        config: {
          ...state.config,
          zoomState: FeatureState.ENABLED,
          // Update the initial scale here as it could have changed since
          // configStoreInitialState was initialized
          // support browsers where visualViewport is not defined
          initialScale: window.visualViewport?.scale.toFixed(3) || '1.000',
        },
      }));
    },
    disableZoom: () => {
      const settings = get().bridge!.menu!.settings;
      disableZoom(get().config);

      set((state) => ({
        config: {
          ...state.config,
          zoomState: disabledOrHiddenFeatureState(
            LocationUtils.allowsZoomFeature(settings, state.config.screenReaderIsActive),
          ),
        },
      }));
    },
    setScreenReaderIsActive: (screenReaderIsActive) => {
      const { config, bridge } = get();
      disableReducedHeight(config);
      disableZoom(config);

      const settings = bridge!.menu!.settings;
      set((state) => ({
        config: {
          ...state.config,
          screenReaderIsActive,
          zoomState: disabledOrHiddenFeatureState(
            LocationUtils.allowsZoomFeature(settings, screenReaderIsActive),
          ),
          reducedHeightState: disabledOrHiddenFeatureState(
            LocationUtils.allowsReducedHeight(settings, screenReaderIsActive),
          ),
        },
      }));
    },
    reset: () => {
      const { config, bridge } = get();
      const settings = bridge!.menu!.settings;

      // If ADA features are enabled, disable them
      disableReducedHeight(config);
      disableZoom(config);

      set((state) => {
        const updatedConfig = { ...state.config, ...configStoreInitialState };
        return {
          config: {
            ...updatedConfig,
            zoomState: disabledOrHiddenFeatureState(
              LocationUtils.allowsZoomFeature(settings, updatedConfig.screenReaderIsActive),
            ),
            reducedHeightState: disabledOrHiddenFeatureState(
              LocationUtils.allowsReducedHeight(settings, updatedConfig.screenReaderIsActive),
            ),
          },
        };
      });
    },
    update: () => {
      const settings = get().bridge!.menu!.settings;

      set((state) => ({
        config: {
          ...state.config,
          zoomState: featureStatePostUpdate(
            LocationUtils.allowsZoomFeature(settings, state.config.screenReaderIsActive),
            state.config.zoomState,
          ),
          reducedHeightState: featureStatePostUpdate(
            LocationUtils.allowsReducedHeight(settings, state.config.screenReaderIsActive),
            state.config.reducedHeightState,
          ),
        },
      }));
    },
    addMenuFilter: (filter) => {
      set((state) => ({
        config: {
          ...state.config,
          menuFilters: {
            ...state.config.menuFilters,
            [filter.category]: state.config.menuFilters[filter.category].concat(filter.id),
          },
        },
      }));
    },
    removeMenuFilter: (filter) => {
      set((state) => ({
        config: {
          ...state.config,
          menuFilters: {
            ...state.config.menuFilters,
            [filter.category]: state.config.menuFilters[filter.category].filter(
              (id) => filter.id !== id,
            ),
          },
        },
      }));
    },
    clearMenuFilters: () => {
      set((state) => ({
        config: {
          ...state.config,
          menuFilters: configStoreInitialState.menuFilters,
        },
      }));
    },
    setLanguage: (language) => {
      set((state) => ({
        config: {
          ...state.config,
          language,
        },
      }));
    },
  },
});

function setDocumentMetaViewportData(
  minScale: string,
  maxScale: string,
  userScalable: boolean,
): void {
  document
    .querySelector('meta[name=viewport]')!
    .setAttribute(
      'content',
      `width=768, minimum-scale=${minScale}, maximum-scale=${maxScale}, user-scalable=${
        userScalable ? 'yes' : 'no'
      }`,
    );
}
