import { Log } from '@biteinc/common';
import { OrderClientEventName } from '@biteinc/enums';

type OrderClientEvent = {
  name: OrderClientEventName;
  createdAt: number;
  data?: object;
};

export default class OrderEventRepo {
  private readonly events: OrderClientEvent[] = [];

  getEvents(): OrderClientEvent[] {
    return [...this.events];
  }

  log(): void {
    this.events.forEach((event) => {
      Log.info('e', event.name, event.createdAt, event.data || '');
    });
  }

  hasEvent(name: OrderClientEventName): boolean {
    return !!this.events.find((event) => {
      return name === event.name;
    });
  }

  track(name: OrderClientEventName, data?: Record<string, any>): void {
    // Handle the case where we have an error that cannot be deconstructed, ex. TypeErrors
    // These errors are identified by 0 length keys
    let dataToAdd;
    if (data && data instanceof Error && !Object.keys(data).length) {
      const stringifiedMessage = `${data.toString()} ${
        data.stack
          ? `
        ${data.stack}`
          : ''
      }`;
      dataToAdd = {
        ...(data.message && { message: data.message }),
        ...(stringifiedMessage.length && { message: stringifiedMessage }),
      };

      if (!Object.keys(dataToAdd)) {
        this.addEvent(name);
        return;
      }
    }

    this.addEvent(name, dataToAdd || data);
  }

  trackCheckoutStart(orderSize: number): void {
    this.addEvent(OrderClientEventName.CheckoutStart, { orderSize });
  }

  trackMenuItemAbandon(menuItemId: string, menuItemName: string): void {
    this.addEvent(OrderClientEventName.MenuItemAbandon, { menuItemId, menuItemName });
  }

  trackMenuItemAddToCart(
    menuItemId: string,
    menuItemName: string,
    upsellScreen?: string,
    reason?: 'auto-add-promo',
  ): void {
    this.addEvent(OrderClientEventName.MenuItemAddToCart, {
      menuItemId,
      menuItemName,
      ...(upsellScreen && { upsellScreen }),
      ...(reason && { reason }),
    });
  }

  trackMenuItemScan(menuItemId: string, menuItemName: string): void {
    this.addEvent(OrderClientEventName.MenuItemScan, { menuItemId, menuItemName });
  }

  trackScannerMessageReceived(message?: string): void {
    this.addEvent(OrderClientEventName.DataScanAttempted, { message });
  }

  trackMenuItemView(menuItemId: string, menuItemName: string, usesFullScreenFlow: boolean): void {
    this.addEvent(OrderClientEventName.MenuItemView, {
      menuItemId,
      menuItemName,
      usesFullScreenFlow,
    });
  }

  trackMenuItemRemoveFromCart(
    menuItemId: string,
    menuItemName: string,
    reason?: 'auto-add-promo' | 'outdated',
  ): void {
    this.addEvent(OrderClientEventName.MenuItemRemoveFromCart, {
      menuItemId,
      menuItemName,
      ...(reason && { reason }),
    });
  }

  trackSessionAbandon(
    reason:
      | 'kiosk-transaction-abandoned'
      | 'menu-inactivity-with-prompt'
      | 'menu-inactivity-without-prompt'
      | 'order-summary-inactivity',
  ): void {
    this.addEvent(OrderClientEventName.SessionAbandon, { reason });
  }

  private addEvent(name: OrderClientEventName, data?: Record<string, any>): void {
    Log.info('ADDING EVENT', name);
    this.events.push({
      name,
      createdAt: Date.now(),
      ...(data && { data }),
    });
  }
}
