import _ from 'underscore';

import { Strings } from '@biteinc/common';

import { localizeStr } from '~/app/js/localization/localization';

import { GCNLoyaltyProgramHeaderView } from './gcn_loyalty_program_header_view';
import { GCNView } from './gcn_view';

export const GCNSimpleLoyaltyQrCodeSignupView = GCNView.extend({
  className: `simple-loyalty-qrcode-signup-view`,

  template: _.template(
    `<div class="body">
      <div class="title"></div>
      <div class="subtitle one"></div>
      <div class="subtitle two"></div>
      <div class="image"></div>
    </div>`,
  ),

  initialize(options, ...args) {
    GCNView.prototype.initialize.apply(this, [options, ...args]);
    this.hasScrim = !!gcn.menu.settings.get('loyaltyAccountScrimImage').length;
  },

  render() {
    this.$el.html(this.template());
    if (!this.hasScrim) {
      this.$el.prepend(
        new GCNLoyaltyProgramHeaderView({
          isLoyaltySignUp: true,
        }).render().$el,
      );
    } else {
      const scrimImage = gcn.menu.settings.get('loyaltyAccountScrimImage')[0].url;
      this.$el.prepend(
        `<div class="loyalty-program-header-view">
          <div class="header bg-color-spot-1">
            <img alt="loyalty-account-scrim" class="w-full" src="${scrimImage}"/>
          </div>
        </div>`,
      );
    }

    this._$title = this.$('.title');
    this._$title.htmlOrText(localizeStr(Strings.SIGN_UP));
    this._$subtitleOne = this.$('.subtitle.one');
    this._$subtitleOne.htmlOrText(localizeStr(Strings.LOYALTY_INTRO_COULD_NOT_FIND_ACCOUNT));
    this._$subtitleTwo = this.$('.subtitle.two');
    this._$subtitleTwo.htmlOrText(localizeStr(Strings.LOYALTY_INTRO_QR_SIGN_UP));
    this._$image = this.$('.image');
    const qrCodeImageUrl = gcn.location.get('loyaltySignUpQrCodeImage')[0].url;
    this._$image.html(`<img alt="QR Sign Up Code" class="m-auto" src="${qrCodeImageUrl}"/>`);

    return this;
  },
});
