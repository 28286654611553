import $ from 'jquery';
import _ from 'underscore';

import { Log, Strings } from '@biteinc/common';
import {
  ComboUpsellType,
  DeprecatedRecommendationSource,
  MenuCoverPromoLinkType,
  MenuItemCategory,
  MenuItemDisplayStyle,
  MenuSectionPriceDisplay,
  RecommendationsFirstLoadVisibility,
  RecommendationSource,
} from '@biteinc/enums';

import { localizeStr } from '~/app/js/localization/localization';
import { GcnUtils } from '~/helpers';
import { AnalyticsEventName } from '~/types/analytics_event';

import { RecommendationDisplayLocationDescription } from '../../../types/recommendation';
import { BackboneEvents } from '../backbone-events';
import { setImage } from '../gcn_app_view';
import GcnHtml from '../gcn_html';
import GcnRecoTracker from '../gcn_reco_tracker';
import { GCNRouterHelper } from '../gcn_router_helper';
import { GCNOrderedItem } from '../models/gcn_ordered_item';
import ScreenReaderHelper from '../screen_reader_helper';
import Analytics from '../utils/analytics';
import GcnComboUpsellView from './gcn_combo_upsell_view';
import { GCNQuantitySelectionView } from './gcn_quantity_selection_view';
import { GCNView } from './gcn_view';

export const GCNMenuItemCellView = GCNView.extend({
  className: 'item-cell-view touchable font-body shadow-z-1',
  template: _.template(
    // prettier-ignore
    '<div class="card" aria-hidden="true">' +
      '<div class="header bg-color-spot-1"></div>' +
      '<div class="image"></div>' +
      '<div class="title-line">' +
        '<span class="title">' +
          '<div class="badges"></div>' +
          '<span class="item-name"><%= name %></span>' +
        '</span>' +
      '</div>' +
      '<div class="description"><%= description %></div>' +
      '<div class="price-options row">' +
        '<div class="price-options-list"></div>' +
        '<div class="web-badges">' +
          '<div class="badges"></div>' +
          '<div class="calories"></div>' +
        '</div>' +
      '</div>' +
      '<div class="buttons-right">' +
      '<div role="button" class="button add" role="button"></div>' +
      '</div>' +
    '</div>',
  ),
  // The extra space after description is there to force the "more..." to be
  // counted as a separate word

  initialize(opts, ...args) {
    GCNView.prototype.initialize.apply(this, [opts, ...args]);

    const options = opts || {};
    if (!_.has(options, 'showBadges')) {
      options.showBadges = true;
    }
    this.showBadges = options.showBadges;
    this.priceDisplay = options.priceDisplay;
    this.sectionId = options.sectionId;
    this.displayStyle = options.displayStyle || MenuItemDisplayStyle.NameAndDescription;
    this.showImage =
      this.model.hasImages() &&
      (this.displayStyle === MenuItemDisplayStyle.NameAndImage ||
        this.displayStyle === MenuItemDisplayStyle.Wide ||
        this.displayStyle === MenuItemDisplayStyle.NameDescriptionAndImage);
    this.maxHeight = options.maxHeight;
    this.onTapCallback = options.onTapCallback;
    this.isDisabled = this.model.is86d() || this.model.get('hiddenState');
    this.isAlcohol = this.model.get('category') === MenuItemCategory.AlcoholicBeverage;
    this.orderedItem = new GCNOrderedItem(null, { item: this.model });
    /**
     * False for Flash/Web - always show with new ui
     */
    if (!window.isFlash) {
      this.hideDescription =
        !this.model.displayDescriptionHtml().length ||
        !!options.hideDescription ||
        (!this.model.has('spiritId') && this.displayStyle === MenuItemDisplayStyle.NameAndImage);
    }
    this.useFallbackImage = options.useFallbackImage;
    this.showFalseButton = options.showFalseButton;
    this.hideCalories = options.hideCalories;
    this.hasItemAddButtons =
      !this.isDisabled &&
      !this.showFalseButton &&
      !!this.orderedItem.orderedPO &&
      gcn.menu.settings.get('addMenuItemFromMenuView');

    if (this.hasItemAddButtons) {
      _.each(this.orderedItem.getAddonSets(), (addonSet) => {
        // eslint-disable-next-line no-bitwise
        this.hasItemAddButtons &=
          addonSet.isSelectionStructValid(
            {
              model: addonSet,
              selections: {},
            },
            false,
            !!addonSet.get('useAsComboBuilder'),
            false,
          ) || gcn.menu.addonSetHasSufficientSelections(addonSet);
      });
    }
  },

  setAddedVisualState(added) {
    const buttonText = added ? `✓ ${localizeStr(Strings.ADDED)}` : localizeStr(Strings.ADD);
    this.$falseButton.htmlOrText(buttonText);
  },

  _onTap(upsellScreen) {
    const comboUpsell = this.model.get('comboUpsell');
    if (
      comboUpsell?.type === ComboUpsellType.MenuItem &&
      gcn.menu.settings.get('comboUpsell') &&
      gcn.orderManager.comboUpsellCountWithinCap()
    ) {
      const upsellComboItem = gcn.menu.getMenuItemWithId(comboUpsell.comboItemId);

      if (upsellComboItem) {
        const sectionId =
          gcn.menu.getMenuSectionThatContainsItemId(upsellComboItem.id)?.id || this.sectionId;
        gcn.orderManager.incrementComboUpsellPromptCount();
        Analytics.track(AnalyticsEventName.UpsellComboShown);
        const upsellComboView = new GcnComboUpsellView({
          model: upsellComboItem,
          sectionId,
          upsellScreen,
          recommendationDisplayLocationDescription: this._recommendationDisplayLocationDescription,
          onTapCallback: this.onTapCallback,

          originalModel: this.model,
          originalSectionId: this.sectionId,
        });
        gcn.menuView.showStablePopup(upsellComboView);

        return;
      }

      // fall through to the normal item tap
      Log.error(`upsell item ${comboUpsell.comboItemId} not found`);
    }

    if (this.onTapCallback) {
      this.onTapCallback(this.sectionId, this.model.id, this.model.priceOptions[0].id);
    } else {
      GCNRouterHelper.navToItem(this.model.id, this.sectionId);
      gcn.notifyUserDidTapMenuItem(
        this.sectionId,
        this.model,
        upsellScreen,
        this._recommendationDisplayLocationDescription,
      );
    }
  },

  _getQuantity() {
    // Hijack the quantity selector to send weights instead.
    if (gcn.location.isDeliKioskDemo() && this.model.item.isWeighable()) {
      return 1;
    }
    return this._quantitySelectionView ? this._quantitySelectionView.getValue() : 1;
  },

  render() {
    /**
     * In flash we don't append to the name calories
     */
    let name = this.model.displayNameHtml();
    const caloriesHtml = this.hideCalories ? undefined : this.model.getCaloriesHtml();

    if (!window.isFlash && caloriesHtml) {
      name += `<br />${caloriesHtml}`;
    }

    this.$el.html(
      this.template({
        name,
        description: this.model.displayDescriptionHtml(),
      }),
    );

    this.$el.addClass(`item-${this.model.id}`);
    if (this.model.posId().length) {
      this.$el.addClass(`pos-${this.model.posId()}`);
    }

    if (this.displayStyle === MenuItemDisplayStyle.Wide) {
      this.$el.toggleClass('wide', true);
    }
    const recoItemId = this.model.id;
    let recoType;
    let showRecommendation = false;

    if (gcn.guestManager.hasRecoItemId(recoItemId)) {
      const isInPromoSection =
        this.sectionId && gcn.menu.getMenuSectionWithId(this.sectionId).isPromoSection();

      let label = localizeStr(Strings.RECOMMENDED_FOR_YOU);
      recoType = 'recommended';
      const recoSource = gcn.guestManager.recoSourceForRecoItemId(recoItemId);

      const recommendationVisibility = isInPromoSection
        ? gcn.menu.settings.get('recommendationsFirstLoadVisibility')
        : gcn.menu.settings.get('recommendationsInMenuVisibility');

      switch (recoSource) {
        case RecommendationSource.OrderHistory:
        case DeprecatedRecommendationSource.OrderHistory:
          showRecommendation =
            recommendationVisibility !== RecommendationsFirstLoadVisibility.Hidden;
          label = localizeStr(Strings.YOU_PREVIOUSLY_ORDERED);
          recoType = 'past-ordered';
          break;
        case RecommendationSource.FavoriteItem:
        case DeprecatedRecommendationSource.FavoriteItem:
          showRecommendation =
            recommendationVisibility !== RecommendationsFirstLoadVisibility.Hidden;
          label = localizeStr(Strings.FAVORITE);
          recoType = 'favorite';
          break;
        default:
          showRecommendation =
            recommendationVisibility ===
            RecommendationsFirstLoadVisibility.PredictionsAndOrderHistory;
          break;
      }

      if (showRecommendation) {
        this.$el.addClass('promoted');
        this.$('.header').htmlOrText(label);

        if (this.sectionId) {
          // we're implicitly in a section view
          this._recommendationDisplayLocationDescription =
            RecommendationDisplayLocationDescription.MENU_SECTION_MENU_ITEM_HIGHLIGHT;
          GcnRecoTracker.trackDisplayRecommendations(
            gcn.maitred,
            this._recommendationDisplayLocationDescription,
            [recoItemId],
            {
              menuSectionId: this.sectionId,
              isPromoSection: isInPromoSection,
            },
          );
        }
      }
    }

    let upsellScreen;
    if (recoType && this.sectionId && showRecommendation) {
      const section = gcn.menu.getMenuSectionWithId(this.sectionId);
      const position = _.findIndex(section.items, (item) => {
        return item.id === this.model.id;
      });
      const screen = section.isPromoSection() ? 'promo-section' : 'menu-section';
      upsellScreen = GcnUtils.recommendationTrackingLabel(screen, position, this.model, recoType);
    } else if (
      this.sectionId &&
      gcn.activatedMenuCoverPromo?.linkType === MenuCoverPromoLinkType.MenuSection &&
      this.sectionId === gcn.activatedMenuCoverPromo?.linkTarget
    ) {
      upsellScreen = 'menu-cover-promo-menu-section';
    } else if (
      this.sectionId &&
      gcn.activatedMenuCoverPromo?.linkType === MenuCoverPromoLinkType.MenuPage &&
      gcn.menuView.getMenuPageId() === gcn.activatedMenuCoverPromo?.linkTarget
    ) {
      upsellScreen = 'menu-cover-promo-menu-page';
    }

    this.$description = this.$('.description');
    this.$image = this.$('.image');
    this.$titleLine = this.$('.title-line');
    this.$priceOptions = this.$('.price-options .price-options-list');
    this.$addButton = this.$('.button.add');

    if (this.hasItemAddButtons && !showRecommendation) {
      // initialize quantity selector view
      this._quantitySelectionView = new GCNQuantitySelectionView({
        menuItemName: this.model.displayName(),
        hasExternalController: true,
      });

      this.listenTo(gcn.orderManager, BackboneEvents.GCNOrderManager.OrderDidChange, () => {
        // If previously not added to the cart, and now added, update the button text, and onClick handler
        const orderedItem = gcn.orderManager.getOrderedItemWithIdWithSelections(
          this.orderedItem.id,
          this.orderedItem.getSelectionsInStringForm(),
        );
        if (orderedItem) {
          this.orderedItem = orderedItem;
          this._quantitySelectionView.$el.css('visibility', 'hidden');
          this.$addButton.htmlOrText(`${localizeStr(Strings.ADDED)}`);
          this.$addButton.toggleClass('disabled', true);
        } else if (this.$addButton.hasClass('disabled')) {
          // Item has been removed from cart, revert to default state
          this.$addButton.htmlOrText(localizeStr(Strings.ADD));
          this._quantitySelectionView.setValue(1);
          this._quantitySelectionView.$el.css('visibility', 'visible');

          if (this._getQuantity() > 0) {
            this.$addButton.toggleClass('disabled', false);
          }
        }
      });

      this.$addButton.htmlOrText(localizeStr(Strings.ADD));
      this.$addButton.onTapInScrollableAreaWithCalibration('micev_add', (e) => {
        // BITE-8537: Stop propagation to prevent the parent from handling the event when Enable Multiple Quantity items is not enabled
        e.stopPropagation();
        if (!this.$addButton.hasClass('disabled')) {
          const quantityToAdd = this._getQuantity();
          this.orderedItem.setPriceOption(this.orderedItem.orderedPO.po);
          this.orderedItem.setQuantityOnSelectedPriceOption(quantityToAdd);
          gcn.orderManager.addToOrder(this.orderedItem, quantityToAdd);
        }
      });

      this.listenTo(
        this._quantitySelectionView,
        BackboneEvents.GCNQuantitySelectionView.IncrementPressed,
        () => {
          if (this.$addButton.hasClass('disabled')) {
            if (this.$addButton.html() !== localizeStr(Strings.ADDED)) {
              this._quantitySelectionView.setValue(this._getQuantity() + 1);
              this.$addButton.toggleClass('disabled', false);
            } else {
              const updatedItem = this.orderedItem;
              updatedItem.setQuantityOnSelectedPriceOption(this._getQuantity() + 1);
              gcn.orderManager.replaceInOrder(this.orderedItem, updatedItem);
            }
          } else {
            this._quantitySelectionView.setValue(this._getQuantity() + 1);
          }
        },
      );
      this.listenTo(
        this._quantitySelectionView,
        BackboneEvents.GCNQuantitySelectionView.DecrementPressed,
        () => {
          if (this.$addButton.hasClass('disabled')) {
            if (this._getQuantity() - 1 === 0) {
              gcn.orderManager.removeFromOrderV2(this.orderedItem._uid);
            } else {
              const updatedItem = this.orderedItem;
              updatedItem.setQuantityOnSelectedPriceOption(this._getQuantity() - 1);
              gcn.orderManager.replaceInOrder(this.orderedItem, updatedItem);
            }
          } else {
            this._quantitySelectionView.setValue(this._getQuantity() - 1);
          }
        },
      );

      this.$('.card').addClass('has-quantity-selector');
      this.$('.buttons-right').prepend(this._quantitySelectionView.render().$el);
    } else {
      this.$('.buttons-right').remove();
    }

    /**
     * Badges are bottom right for web ui
     */
    if (window.isFlash) {
      this.$badges = this.$('.web-badges .badges');
      this.$caloriesContainer = this.$('.web-badges .calories');
      if (caloriesHtml) {
        this.$caloriesContainer.append(caloriesHtml);
      }
    } else {
      this.$badges = this.$('.title-line .title .badges');
    }

    if (this.showBadges) {
      const badges = this.model.badges();
      _.each(badges, (badge) => {
        const $span = $('<span class="badge"></span>');
        this.$badges.append($span);
        const url = badge.get('icons')[0].url;
        gcn.requestImageByUrl(url, (err, imgPath) => {
          $span.css('background-image', `url(${imgPath})`);
        });
      });
      if (!badges.length && window.isFlash) {
        this.$caloriesContainer.addClass('align-left');
      }
    }

    if (this.showImage || this.useFallbackImage) {
      this.$el.addClass('show-image');
      if (this.model.hasArr('images')) {
        const images = this.model.get('images');
        setImage(images[images.length - 1], this.$image);

        if (this.isDisabled) {
          this.$image.addClass('disabled');
        }
      } else {
        // Use fallback.
        const placeholderClass = gcn.menu.placeholderImageClass(this.model);
        this.$image.addClass(placeholderClass);
        this.$image.addClass('item-image');
      }
      if (this.hideDescription) {
        this.$description.css('display', 'none');
      }
    } else {
      this.$image.css('height', '0px');
      this.$card = this.$('.card');
      this.$card.addClass('no-image');
    }

    const maxAlcoholicItemCountPerOrder = gcn.location.get('maxAlcoholicItemCountPerOrder');
    const maxSpecialItemCountPerOrder = gcn.location.get('maxSpecialItemCountPerOrder');
    const itemIsAlcoholic = this.model.get('category') === MenuItemCategory.AlcoholicBeverage;
    const itemIsSpecialOffer = this.model.get('category') === MenuItemCategory.SpecialOffer;

    if (maxAlcoholicItemCountPerOrder && itemIsAlcoholic) {
      this.$('.card').addClass('is-alcohol');
      this.$('.card').append(
        `<div class="max-alcohol-in-cart-message hidden">${localizeStr(Strings.MAX_ALCOHOLIC_ITEM_LIMIT_REACHED, [maxAlcoholicItemCountPerOrder])}</div>`,
      );
    }
    if (maxSpecialItemCountPerOrder && itemIsSpecialOffer) {
      this.$('.card').append(
        `<div class="max-special-offer-in-cart-message hidden">${localizeStr(Strings.MAX_SPECIAL_ITEM_LIMIT_REACHED, [maxSpecialItemCountPerOrder])}</div>`,
      );
    }

    if (
      (itemIsAlcoholic || itemIsSpecialOffer) &&
      (maxSpecialItemCountPerOrder || maxAlcoholicItemCountPerOrder)
    ) {
      this.listenTo(gcn.orderManager, BackboneEvents.GCNOrderManager.OrderDidChange, () => {
        if (itemIsAlcoholic && gcn.orderManager.isAlcoholicItemLimitReached()) {
          // disable menu item
          this.$el.removeClass('touchable');
          this.$el.toggleClass('disabled', true);
          this.$('.card .max-alcohol-in-cart-message').removeClass('hidden');
        } else if (itemIsAlcoholic && maxAlcoholicItemCountPerOrder) {
          this.$el.addClass('touchable');
          this.$el.toggleClass('disabled', false);
          this.$('.card .max-alcohol-in-cart-message').addClass('hidden');
        }

        if (itemIsSpecialOffer && gcn.orderManager.isSpecialOfferItemLimitReached()) {
          // disable menu item
          this.$el.removeClass('touchable');
          this.$el.toggleClass('disabled', true);
          this.$('.card .max-special-offer-in-cart-message').removeClass('hidden');
        } else if (itemIsSpecialOffer && maxSpecialItemCountPerOrder) {
          this.$el.addClass('touchable');
          this.$el.toggleClass('disabled', false);
          this.$('.card .max-special-offer-in-cart-message').addClass('hidden');
        }
      });
    }

    if (this.isDisabled || !this.model.canBeOpened()) {
      this.$el.removeClass('touchable');

      if (this.isDisabled) {
        this.$el.toggleClass('disabled', true);
        this.$('.card').append(
          `<div class="out-of-stock-message">${localizeStr(Strings.OUT_OF_STOCK)}</div>`,
        );
      }
    } else {
      this.$el.onTapInScrollableAreaWithCalibration('micv', () => {
        Analytics.trackEvent({
          eventName: Analytics.EventName.MenuItemSelected,
          eventData: {
            itemName: this.model.displayName(),
          },
        });
        this._onTap(upsellScreen);
      });

      if (!this.model.displayDescriptionHtml().length) {
        // Force the click magnet to appear.
        this.$description.text(' ');
      }
    }

    const firstPO = this.model.priceOptions[0];
    const price = firstPO.getPrice();

    if (this.priceDisplay === MenuSectionPriceDisplay.HidePriceOptions) {
      // do not show any price
      this.$priceOptions.remove();
      this.$priceOptions = null;
    } else if (this.model.priceOptions.length === 1) {
      /**
       * Web UI
       */
      if (price !== 0 && !window.isFlash) {
        this.$titleLine.append(GcnHtml.htmlFromPriceOption(firstPO));
      }
      /**
       * @description
       * For flash web we want to display the price at the bottom
       * not in line with the name
       */
      if (price !== 0 && window.isFlash) {
        const priceHtml = GcnHtml.htmlFromPriceOption(firstPO);
        this.$priceOptions.append(`<span class="price-option">${priceHtml}</span>`);
      } else {
        this.$priceOptions.remove();
        this.$priceOptions = null;
      }
    } else {
      const samePrice = _.all(this.model.priceOptions, (po) => {
        return po.getPrice() === price;
      });
      /**
       * Web UI has different logic
       */
      if (!window.isFlash && samePrice && price !== 0) {
        this.$titleLine.append(GcnHtml.htmlFromPriceOption(firstPO));
      }

      _.each(this.model.priceOptions, (priceOption) => {
        let priceHtml = priceOption.displayNameHtml();
        /**
         * @description
         * For flash web we want to show the price next to the option
         */
        if ((priceOption.getPrice() && !samePrice) || window.isFlash) {
          priceHtml += ` ${GcnHtml.htmlFromPriceOption(priceOption)}`;
        }
        this.$priceOptions.append(`<span class="price-option">${priceHtml}</span>`);
      });
    }

    if (this.showFalseButton) {
      this.$falseButton = $('<div class="false-button"></div>');
      this.$falseButton.htmlOrText(localizeStr(Strings.ADD));
      if (window.isFlash) {
        const priceOptionsContainer = this.$('.price-options');
        priceOptionsContainer.append('<div class="false-button-container"></div>');
        const falseButtonContainer = this.$('.false-button-container');
        falseButtonContainer.append(this.$falseButton);
      } else {
        this.$falseButton.insertBefore(this.$titleLine);
      }
      if (this.$priceOptions) {
        this.$priceOptions.remove();
      }
    }
    if (this.isDisabled) {
      const priceOptionsContainer = this.$('.price-options');
      priceOptionsContainer.remove();
    }

    // Fast touch feedback.
    if (!window.isGarcon) {
      // Disable this Tizen since it destroys scrolling perf.
      // Maybe other platforms won't have that problem.
      this.$('.card').fastTouchFeedback();
      this.$el.fastTouchFeedback();
    }

    const caloriesText = this.model.getCaloriesAriaLabel();
    const optionalCaloriesDisplay = caloriesText ? `, ${caloriesText}` : '';
    const optionalPriceDisplay = price ? `, $${GcnHtml.stringFromPrice(price)}` : '';
    const optionalFalseButton = this.showFalseButton
      ? `, ${localizeStr(Strings.ADD).toLocaleLowerCase()}`
      : '';
    this.$el.attr(
      'aria-label',
      `${ScreenReaderHelper.prepareAriaLabel(
        this.model.displayNameWithoutCalories(),
      )}${optionalCaloriesDisplay}${optionalPriceDisplay}${optionalFalseButton}`,
    );

    return this;
  },
});
