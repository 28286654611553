import _ from 'underscore';

import { Strings, Validators } from '@biteinc/common';

import { str } from '../localization/localization';
import { GCNView } from './gcn_view';

type Options = {
  onDone: (guestName: string) => void;
};

export const GCNGroupRecipientGuestNameModalView = GCNView.extend({
  className: 'group-recipient-guest-name-modal-view',

  template: _.template(`
    <div class="body">
      <div class="title"></div>
      <div class='guest-name-input-container'>
        <p class='guest-name-input-error'></p>
        <input class="guest-name-input" placeholder="Recipient Name"></input>
      </div>
      <div class="button-container">
        <button class="button button-primary button-large" data-action="group-ordering" data-value="done">
        </button>
      </div>
    </div>
  `),

  initialize(options: Options, ...args: any) {
    GCNView.prototype.initialize.apply(this, options ? [options, ...args] : args);

    if (options?.onDone) {
      this.onDone = options.onDone;
    }
  },

  shouldHideCloseLabel() {
    return true;
  },

  isValid() {
    const regex = new RegExp(Validators.simpleStringRegexString());
    if (!this.$input.val() || !regex.test(this.$input.val())) {
      this.$error.htmlOrText(str(Strings.GROUP_ORDERING_GUEST_NAME_INVALID_CHARACTERS));
      this.$error.show();
      return false;
    }

    const existingNames = gcn.orderManager.getDistinctGroupOrderGuestLabels();
    if (existingNames.includes(this.$input.val())) {
      this.$error.htmlOrText(str(Strings.GROUP_ORDERING_GUEST_NAME_TAKEN));
      this.$error.show();
      return false;
    }

    return true;
  },

  render() {
    this.$el.html(this.template());

    this.$title = this.$('.title');
    this.$input = this.$('.guest-name-input');
    this.$error = this.$('.guest-name-input-error');
    this.$button = this.$('.button-container button[data-value="done"]');

    this.$title.htmlOrText(str(Strings.GROUP_ORDERING_ITEM_FOR));
    this.$button.htmlOrText(str(Strings.GROUP_ORDERING_ADD_GUEST));

    this.$error.hide();

    this.$button.onButtonTapOrHold('grngn', () => {
      if (!this.isValid()) {
        return;
      }

      this.onDone(this.$input.val());
    });

    return this;
  },
});
