import { PrinterDestination } from '@biteinc/enums';

import type { PrinterSpecs } from './printer_specs';

module GcnHardwareManager {
  export type HardwareConfig = {
    printers: Record<PrinterDestination, PrinterSpecs>;
    paymentClient: boolean;
    scaleClient: boolean;
    scannerClient: boolean;
  };

  export function getPrinterSpecForDestination(
    printerDestination: PrinterDestination,
  ): PrinterSpecs | undefined {
    return window.hardwareConfig && window.hardwareConfig.printers[printerDestination];
  }

  export function hasReceiptPrinter(): boolean {
    if (window.isKioskPreview) {
      return true;
    }
    return !!window.hardwareConfig?.printers[PrinterDestination.Receipt];
  }
}

export default GcnHardwareManager;
