import _ from 'underscore';

import { StringHelper } from '@biteinc/core-react';
import type { MenuItemSaleUnit } from '@biteinc/enums';
import { MenuItemSaleUnitHelper, MenuPriceStyle } from '@biteinc/enums';

export default class GcnHtml {
  public static htmlFromString(str: string): string {
    return (
      (str || '')
        // The <> chars could have been escaped already by our backend.
        .replace(/(\\)?</g, '&lt;')
        .replace(/(\\)?>/g, '&gt;')
        .split('\n')
        .join('<br />')
    );
  }

  public static htmlFromPriceOption(
    priceOption: any,
    options: { className?: string } = {},
  ): string {
    return GcnHtml.htmlFromPrice(priceOption.getPrice(), {
      ...options,
      saleUnit: priceOption.get('saleUnit'),
    });
  }

  public static htmlFromPrice(
    priceInCents: number,
    options: {
      saleUnit?: MenuItemSaleUnit;
      className?: string;
      prefix?: string;
      suffix?: string;
    } = {},
  ): string {
    let priceStr = GcnHtml.stringFromPrice(Math.abs(priceInCents));
    const ariaPrice = `${priceStr}`;

    const decimalIndex = priceStr.indexOf('.');
    if (decimalIndex !== -1) {
      priceStr = `${priceStr.slice(0, decimalIndex)}<span class="cents">${priceStr.substring(
        decimalIndex,
      )}</span>`;
    }

    if (options.saleUnit && MenuItemSaleUnitHelper.notation(options.saleUnit)) {
      priceStr += `/${MenuItemSaleUnitHelper.notation(options.saleUnit)}`;
    }

    if (gcn.menu.settings.get('showCurrencySymbol')) {
      priceStr = `$${priceStr}`;
    }
    if (priceInCents < 0) {
      priceStr = `-${priceStr}`;
    }

    priceStr = `${options.prefix || ''}${priceStr}${options.suffix || ''}`;

    return `<span class="price${
      options.className ? ` ${options.className}` : ''
    }" role="text" aria-label="$${ariaPrice}">${priceStr}</span>`;
  }

  public static stringFromPrice(priceInCents: number): string {
    if (!_.isNumber(priceInCents)) {
      return priceInCents;
    }

    const ret = priceInCents / 100.0;

    const priceStyle: MenuPriceStyle = gcn.menu.settings.get('priceStyle');
    switch (priceStyle) {
      case MenuPriceStyle.Default:
        if (priceInCents % 100 !== 0) {
          return ret.toFixed(2);
        }
        break;
      case MenuPriceStyle.OneDigit:
        if (priceInCents % 100 !== 0 && priceInCents % 10 === 0) {
          return ret.toFixed(1);
        }
        if (priceInCents % 100 !== 0) {
          return ret.toFixed(2);
        }
        break;
      case MenuPriceStyle.TwoDigits:
        return ret.toFixed(2);
    }

    return ret.toString();
  }

  public static stringFromCalorieRange(range: { min: number; max?: number }): string {
    const maxCaloriesStr = range.max && range.max !== range.min ? `-${range.max}` : '';
    return `${range.min}${maxCaloriesStr}`;
  }

  public static glossaryTermsFromString(
    text: string,
    processedDictionaryWordIdSet: Record<string, true>,
  ): string {
    const terms = _.filter(gcn.menu.get('dictionaryWords'), (term) => {
      return !processedDictionaryWordIdSet[term._id];
    });
    if (!terms.length) {
      return text;
    }
    let lowerCaseText = text.toLowerCase().replace(/[\n]/gi, ' ');
    let processedText = text;

    _.each(terms, (term) => {
      const target = term.name.toLowerCase();
      const index = lowerCaseText.indexOf(target);
      if (index >= 0 && (index === 0 || lowerCaseText.charAt(index - 1) === ' ')) {
        processedDictionaryWordIdSet[term._id] = true;
        let endIndex = index + target.length;
        if (endIndex < lowerCaseText.length && lowerCaseText.charAt(endIndex) === 's') {
          endIndex++;
        }
        const span = `<span class="dict-word" term="${target}">`;
        processedText = StringHelper.insertTextAt(processedText, endIndex, '</span>');
        processedText = StringHelper.insertTextAt(processedText, index, span);
        // Also replace the lower case text so the next search works.
        lowerCaseText = StringHelper.insertTextAt(lowerCaseText, endIndex, '</span>');
        lowerCaseText = StringHelper.insertTextAt(lowerCaseText, index, span);
      }
    });
    return processedText;
  }
}
