import Backbone from 'backbone';

import { StringHelper } from '@biteinc/core-react';

import { useStore } from '~/stores';

export const GCNView = Backbone.View.extend({
  initialize(...args) {
    Backbone.View.prototype.initialize.apply(this, args);

    this._subscriptions = [];

    // Generate a unique id for each instance of a backbone view
    this._id = StringHelper.newMongoId();
  },

  destroy() {
    this.stopListening();

    this._subscriptions.forEach((unsub) => {
      unsub(); // unsubscribes
    });
    this._subscriptions = [];
  },

  addSubscription(subscription) {
    this._subscriptions.push(subscription);
  },

  addRefreshOnLanguageChangeSubscription() {
    this.addSubscription(
      useStore.subscribe((state, prevState) => {
        if (state.config.language !== prevState.config.language) {
          this.renderLanguageChange();
        }
      }),
    );
  },

  renderLanguageChange() {
    return this.render();
  },
});
