export default function getQueryParam(key: string): string | undefined {
  const searchParams = new URL(document.location.toString()).searchParams;

  if (!searchParams.has(key)) {
    return undefined;
  }

  const value = searchParams.get(key);

  // Convert null to undefined to satisfy TS
  return value || undefined;
}
