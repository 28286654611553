import type { DeliveryAddress } from '@biteinc/core-react';
import { TimeHelper } from '@biteinc/core-react';
import type { FulfillmentMethod } from '@biteinc/enums';
import { CheckinType, CustomerIdentifier, FulfillmentMethodHelper } from '@biteinc/enums';

import type { Store } from '~/stores';

export module OrderDetailsUtils {
  export type OrderDetailsProps = {
    onEdit: () => void;
  };
  export function calculateFutureTimeStr(props: {
    pickupAtIso?: string;
    fulfillmentMethod: FulfillmentMethod;
    orderedItemsLeadTime?: number;
  }): string {
    let futureTimeStr = TimeHelper.getFriendlyFutureTimeDescription(props.pickupAtIso);
    /**
     * Non-Delivery & NOT future order
     */
    if (
      !props.pickupAtIso &&
      !FulfillmentMethodHelper.isDelivery(props.fulfillmentMethod) &&
      props.orderedItemsLeadTime
    ) {
      futureTimeStr += ` (Est. ${TimeHelper.millisecondsToFriendlyDescription(
        props.orderedItemsLeadTime,
      )})`;
    }

    /**
     * Delivery & NOT future order
     * - Show Timestamp + time in seconds
     */
    if (
      !props.pickupAtIso &&
      FulfillmentMethodHelper.isDelivery(props.fulfillmentMethod) &&
      props.orderedItemsLeadTime
    ) {
      const estimatedDeliveryTime = TimeHelper.stringWithTimezone(
        Date.now() + props.orderedItemsLeadTime,
        gcn.location.get('timezone'),
      );
      futureTimeStr = TimeHelper.getFriendlyFutureTimeDescription(estimatedDeliveryTime);
      futureTimeStr += ` (Est. ${TimeHelper.millisecondsToFriendlyDescription(
        props.orderedItemsLeadTime,
      )})`;
    }
    return futureTimeStr;
  }

  export function shouldShowCustomerIdentifierInFulfillmentInstructions(
    customerIdentifier: CustomerIdentifier,
  ): boolean {
    switch (customerIdentifier) {
      case CustomerIdentifier.GuestName:
      case CustomerIdentifier.VehicleDescription:
      case CustomerIdentifier.Address:
      case CustomerIdentifier.PhoneNumber:
      case CustomerIdentifier.GuestEmail:
        return false;
      case CustomerIdentifier.TableNumber:
      case CustomerIdentifier.BiteOrderNumber:
      case CustomerIdentifier.PosOrderNumber:
        return true;
    }
  }

  export function getCheckinMessage(): string {
    if (gcn.location.get('checkinReceiptInstructions')) {
      return gcn.location.get('checkinReceiptInstructions') as string;
    }
    if (gcn.location.get('checkinType') === CheckinType.Code) {
      return `Please proceed to the ${gcn.location.get(
        'name',
      )} and enter the 4-digit code on the screen at the link below to collect your order.`;
    }
    return `Please proceed to the ${gcn.location.get(
      'name',
    )} and click the link below to collect your order.`;
  }

  export function getOrderDetails(order: Store['checkout']['order']): {
    checkinUrl?: string | null;
    wasValidated: boolean;
    orderIsClosed: boolean;
    fulfillmentMethod: FulfillmentMethod;
    pickupAtIso?: string;
    orderedItemsLeadTime?: number;
    deliveryAddress?: DeliveryAddress;
    deliveryInstructions?: string;
    orderNumber?: string;
    guestName?: string;
    outpost?: {
      name: string;
      roomNumber?: string;
    };
  } {
    return {
      checkinUrl: order.checkinUrl,
      wasValidated: !!order.wasValidated,
      orderIsClosed: !!order.wasValidated && !!order.isClosed,
      fulfillmentMethod: order.fulfillmentMethod!,
      pickupAtIso: order.pickupAtIso,
      orderedItemsLeadTime: order.orderedItemsLeadTime,
      deliveryAddress: order.deliveryAddress,
      deliveryInstructions: order.deliveryInstructions,
      ...(order.wasValidated && {
        orderNumber: order.orderNumber,
      }),
      guestName: order.guestName,
      outpost: order.outpost,
    };
  }
}
