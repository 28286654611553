export module LoyaltyUtils {
  export const validateEmail = (email?: string): boolean => {
    // validate email with regex
    const re = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    return re.test(email || '');
  };

  export const validatePhone = (phone: string): boolean => {
    const re = /^\d{10}$/;
    return re.test(phone);
  };

  export const validateCardEntry = (cardNumber: string, validLengths: number[]): boolean => {
    return !!cardNumber.length && validLengths.includes(cardNumber.length);
  };

  export enum LoyaltySignUpMethod {
    QR = 'qrCode',
    PHONE = 'phone',
  }
}
