import type { StateCreator } from 'zustand';

import type { Order } from '@biteinc/core-react';
import type { LoyaltyAuthEntryMethod } from '@biteinc/enums';

import type { GcnReward } from '../types/gcn_reward';
import type { CheckoutState } from './checkout.store';

type LoyaltyState = {
  hasFetchedRewards: boolean;
  rewards: GcnReward[];
  prefetchedRewards?: GcnReward[];
  appliedRewards: GcnReward[];
  appliedCompCards: GcnReward[];
  preSelectedRewards?: GcnReward[];
  status: string;
  couponApplied: boolean;
  authFriendlyName?: string;
  loyaltyAuthData?: {
    authToken?: string;
    authEntryMethod?: LoyaltyAuthEntryMethod;
    tokenData?: string;
    tokenDataEntryMethod?: LoyaltyAuthEntryMethod;
    redemptionCode?: string;
    redemptionCodeEntryMethod?: LoyaltyAuthEntryMethod;
    customerId?: string;
  };
};

type LoyaltyActions = {
  fetch: () => void;
  onCouponUpdated: () => void;
  onLoyaltyUpdated: (order: Order) => void;
  onLoyaltyBackedOut: () => void;
  onRewardsFetched: () => void;
  signOutGuest: () => void;
  reset: () => void;
};

export type LoyaltyStore = {
  loyalty: LoyaltyState & LoyaltyActions;
} & { checkout?: Partial<CheckoutState> };

const loyaltyStoreInitialState = {
  rewards: [],
  appliedRewards: [],
  appliedCompCards: [],
  status: '',
  couponApplied: false,
  hasFetchedRewards: false,
  loyaltyAuthData: undefined,
  authFriendlyName: undefined,
  preSelectedRewards: [],
};

export const initLoyaltyStore: StateCreator<LoyaltyStore> = (set) => ({
  loyalty: {
    ...loyaltyStoreInitialState,
    fetch: () => {
      set((state) => ({
        loyalty: {
          ...state.loyalty,
          status: gcn.loyaltyManager.getStatus()!,
          rewards: gcn.loyaltyManager.getRewards(),
          appliedRewards: gcn.orderManager.getAppliedRewards(),
          appliedCompCards: gcn.orderManager.getAppliedCompCards(),
          preSelectedRewards: [...(gcn.loyaltyManager.getPreSelectedRewards() || [])],
          couponApplied: !!gcn.orderManager.getCouponCode(),
          loyaltyAuthData: gcn.loyaltyManager.getLoyaltyAuthData()!,
          authFriendlyName: gcn.loyaltyManager.getAuthedGuestFriendlyName(),
        },
      }));
    },
    onCouponUpdated: () => {
      set((state) => ({
        checkout: {
          ...state.checkout,
          order: gcn.orderManager.getOrderForStore(),
          orderUpdatedAt: Date.now(),
        },
        loyalty: {
          ...state.loyalty,
          couponApplied: !!gcn.orderManager.getCouponCode(),
        },
      }));
    },
    onLoyaltyUpdated: (order: Order) => {
      gcn.orderManager.setOrderFromJSON(order);
      set((state) => ({
        checkout: {
          ...state.checkout,
          order: gcn.orderManager.getOrderForStore(),
          orderUpdatedAt: Date.now(),
        },
        loyalty: {
          ...state.loyalty,
          transaction: gcn.loyaltyManager.getLoyaltyTransaction(),
          appliedRewards: gcn.orderManager.getAppliedRewards(),
          appliedCompCards: gcn.orderManager.getAppliedCompCards(),
          status: gcn.loyaltyManager.getStatus()!,
        },
      }));
    },
    onLoyaltyBackedOut: () => {
      set((state) => ({
        loyalty: {
          ...state.loyalty,
          transaction: gcn.loyaltyManager.getLoyaltyTransaction(),
          appliedRewards: [],
          appliedCompCards: [],
          rewards: [],
          hasFetchedRewards: false,
        },
      }));
    },
    onRewardsFetched: () => {
      set((state) => ({
        loyalty: {
          ...state.loyalty,
          hasFetchedRewards: true,
          rewards: gcn.loyaltyManager.getRewards(),
          prefetchedRewards: gcn.loyaltyManager.getPrefetchedRewards(),
          status: gcn.loyaltyManager.getStatus()!,
          loyaltyAuthData: gcn.loyaltyManager.getLoyaltyAuthData()!,
          authFriendlyName: gcn.loyaltyManager.getAuthedGuestFriendlyName(),
        },
      }));
    },
    signOutGuest: () => {
      set((state) => ({
        loyalty: {
          ...state.loyalty,
          loyaltyAuthData: undefined,
          authFriendlyName: undefined,
          hasFetchedRewards: false,
          rewards: [],
          appliedRewards: [],
          appliedCompCards: [],
          preSelectedRewards: [],
        },
      }));
    },
    reset: () => {
      set((state) => ({
        loyalty: {
          ...state.loyalty,
          ...loyaltyStoreInitialState,
        },
      }));
    },
  },
});
