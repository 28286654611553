import Backbone from 'backbone';
import * as React from 'react';
import _ from 'underscore';

import { Strings } from '@biteinc/common';
import type { OrderedMod, OrderedPriceOption } from '@biteinc/core-react';
import { FulfillmentMethod } from '@biteinc/enums';

import { BackboneEvents } from '~/app/js/backbone-events';
import { localizeStr } from '~/app/js/localization/localization';
import { GCNOrderedItem } from '~/app/js/models/gcn_ordered_item';
import { GCNMenuItemEditView } from '~/app/js/views/gcn_menu_item_edit_view';
import { GcnUtils } from '~/helpers';

import GcnHtml from '../../app/js/gcn_html';
import GcnRecoTracker from '../../app/js/gcn_reco_tracker';
import type { Fetcher } from '../../app/js/utils/use-fetcher';
import { useFetcher } from '../../app/js/utils/use-fetcher';
import { useMeasure } from '../../app/js/utils/use-measure';
import {
  CHECKOUT_VIEW_ENTER,
  CHECKOUT_VIEW_EXIT,
  FSF_VIEW_ENTER,
  FSF_VIEW_EXIT,
  PAGE_NAV_VIEW_HIDE,
  PAGE_NAV_VIEW_SHOW,
  POPUP_VIEW_ENTER,
  POPUP_VIEW_EXIT,
} from '../../helpers/custom_events';
import type { Store, useLocation } from '../../stores';
import { useSettings } from '../../stores';
import type { UnvalidatedOrder } from '../../types';
import type { GcnMenuItem } from '../../types/gcn_menu_item';
import { RecommendationDisplayLocationDescription } from '../../types/recommendation';

export module CartUtils {
  export function formatPrice(price: number): string | null {
    if (price === 0) {
      return null;
    }
    return `$${GcnHtml.stringFromPrice(price)}`;
  }

  export function addRecommendedItemToOrder(item: GcnMenuItem, index: number): void {
    const orderedItem = new GCNOrderedItem(null, {
      item,
      upsellScreen: GcnUtils.recommendationTrackingLabel('side-cart', index, item),
    });
    // Simple item with no modifications
    if (item.hasOnePriceOptionNoAddons()) {
      gcn.orderManager.addToOrder(orderedItem, 1, {
        recommendationDisplayLocationDescription:
          RecommendationDisplayLocationDescription.CART_VIEW,
      });
    } else {
      // Item requires a popup to select options for it.
      const editView = new GCNMenuItemEditView({
        model: orderedItem,
        recoEditor: true,
        isNested: true,
        cancelButtonText: localizeStr(Strings.REMOVE),
      });

      gcn.menuView.showPopup(editView, undefined, 'recommended-item');

      // Backbone called explicitly as this.listenTo is not recognized
      Backbone.Events.listenTo(editView, BackboneEvents.GCNMenuItemOrderView.DonePressed, () => {
        gcn.menuView.dismissPopup();
        gcn.orderManager.addToOrder(editView.model, orderedItem.orderedPO.get('quantity'), {
          recommendationDisplayLocationDescription:
            RecommendationDisplayLocationDescription.CART_VIEW,
        });
      });
      Backbone.Events.listenTo(editView, BackboneEvents.GCNMenuItemOrderView.CancelPressed, () => {
        gcn.menuView.dismissPopup();
      });
      Backbone.Events.listenTo(editView, BackboneEvents.GCNMenuItemOrderView.OverlayPressed, () => {
        gcn.menuView.dismissPopup();
      });
    }
  }

  // BITE-7509 - the toggle feature can only be active when the location has simple dining options
  // simple dining options are defined as having only 2 options, dine in and to go
  export function hasSimpleDiningOptions(location: ReturnType<typeof useLocation>): boolean {
    return (
      location.diningOptions?.length === 2 &&
      location.diningOptions.some(
        (option) => option.fulfillmentMethod === FulfillmentMethod.KIOSK_DINE_IN,
      ) &&
      location.diningOptions.some(
        (option) => option.fulfillmentMethod === FulfillmentMethod.KIOSK_TO_GO,
      )
    );
  }

  export function useRecommendedItems(order: UnvalidatedOrder): Fetcher<GcnMenuItem[]> {
    const settings = useSettings();
    const fetcher = useFetcher(async () => {
      if (!settings.showUpsellInCart) {
        return [];
      }
      const displayRecosLimit = 2;

      return GcnRecoTracker.getSideCartRecommendations(gcn.maitred).then((recommendations) => {
        const recommendedMenuItems = gcn.menu.getMenuItemsFromRecommendations(recommendations, {
          displayRecosLimit,
          pickSimpleItemsOnly: !!gcn.screenReaderIsActive,
        });

        return recommendedMenuItems;
      });
    });
    const inFSF = CartUtils.useInFSF();
    const popupInView = CartUtils.usePopupInView();
    const lastOrder = React.useRef(order);

    const orderedItemsHaveChanged = React.useMemo(() => {
      return JSON.stringify(order.orderedItems) !== JSON.stringify(lastOrder.current.orderedItems);
    }, [order.orderedItems]);

    // we should only fetch if the cart is viewable and the orderedItems have changed and are greater than 0
    const shouldFetch =
      !inFSF && !popupInView && order.orderedItems.length > 0 && orderedItemsHaveChanged;

    React.useEffect(() => {
      lastOrder.current = order;
    }, [order]);

    React.useEffect(() => {
      if (shouldFetch) {
        // error cases are handled in the fetcher
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        fetcher.get();
      }
    }, [shouldFetch]);

    return fetcher;
  }

  export function getModsFromPriceOption(priceOption: OrderedPriceOption): OrderedMod[] {
    return _.flatten(
      priceOption.addonSets?.map((addonSet) => {
        return _.flatten(
          addonSet.items?.map((item) => {
            return [item, ...getModsFromPriceOption(item.priceOption)];
          }) ?? [],
        );
      }) ?? [],
    );
  }

  export function useRecommendedItemImagePath(item: GcnMenuItem): Fetcher<string> {
    const fetcher = useFetcher(() => GcnUtils.getImagePath(item.getImageUrlOrPlaceholder()));

    React.useEffect(() => {
      // error cases are handled in the fetcher
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      fetcher.get();
    }, [item]);

    return fetcher;
  }

  const NavigationViewContext = React.createContext<boolean>(false);

  export function NavigationViewProvider(props: { children: React.ReactNode }): JSX.Element {
    const settings = useSettings();
    const [inNavigationView, setInNavigationView] = React.useState(() => {
      return !!settings.showPageNavigation;
    });

    React.useEffect(() => {
      function inView(): void {
        setInNavigationView(true);
      }

      function outOfView(): void {
        setInNavigationView(false);
      }

      document.addEventListener(PAGE_NAV_VIEW_SHOW, inView);
      document.addEventListener(PAGE_NAV_VIEW_HIDE, outOfView);

      return () => {
        document.removeEventListener(PAGE_NAV_VIEW_SHOW, inView);
        document.removeEventListener(PAGE_NAV_VIEW_HIDE, outOfView);
      };
    }, []);

    return (
      <NavigationViewContext.Provider value={inNavigationView}>
        {props.children}
      </NavigationViewContext.Provider>
    );
  }

  export function useInNavigationView(): boolean {
    return React.useContext(NavigationViewContext);
  }

  const CheckoutViewContext = React.createContext<boolean>(false);

  export function CheckoutViewProvider(props: { children: React.ReactNode }): JSX.Element {
    const [inCheckoutView, setInCheckoutView] = React.useState(false);

    React.useEffect(() => {
      function inView(): void {
        setInCheckoutView(true);
      }

      function outOfView(): void {
        setInCheckoutView(false);
      }

      document.addEventListener(CHECKOUT_VIEW_ENTER, inView);
      document.addEventListener(CHECKOUT_VIEW_EXIT, outOfView);

      return () => {
        document.removeEventListener(CHECKOUT_VIEW_ENTER, inView);
        document.removeEventListener(CHECKOUT_VIEW_EXIT, outOfView);
      };
    }, []);

    return (
      <CheckoutViewContext.Provider value={inCheckoutView}>
        {props.children}
      </CheckoutViewContext.Provider>
    );
  }

  export function useInCheckoutView(): boolean {
    return React.useContext(CheckoutViewContext);
  }

  export const FSFContext = React.createContext<boolean>(false);

  export function FSFProvider(props: { children: React.ReactNode }): JSX.Element {
    const [inFSF, setInFSF] = React.useState(false);

    React.useEffect(() => {
      function inView(): void {
        setInFSF(true);
      }

      function outOfView(): void {
        setInFSF(false);
      }

      document.addEventListener(FSF_VIEW_ENTER, inView);
      document.addEventListener(FSF_VIEW_EXIT, outOfView);

      return () => {
        document.removeEventListener(FSF_VIEW_ENTER, inView);
        document.removeEventListener(FSF_VIEW_EXIT, outOfView);
      };
    }, []);

    return <FSFContext.Provider value={inFSF}>{props.children}</FSFContext.Provider>;
  }

  export function useInFSF(): boolean {
    return React.useContext(FSFContext);
  }

  const PopupContext = React.createContext<string[]>([]);

  export function PopupProvider(props: { children: React.ReactNode }): JSX.Element {
    const [popupStack, setPopupStack] = React.useState<string[]>([]);

    React.useEffect(() => {
      function pushPopup(event: CustomEvent): void {
        setPopupStack((stack) => [...stack, event.detail.id]);
      }

      function popPopup(event: CustomEvent): void {
        setPopupStack((stack) => stack.filter((id) => id !== event.detail.id));
      }

      document.addEventListener(POPUP_VIEW_ENTER, pushPopup);
      document.addEventListener(POPUP_VIEW_EXIT, popPopup);

      return () => {
        document.removeEventListener(POPUP_VIEW_ENTER, pushPopup);
        document.removeEventListener(POPUP_VIEW_EXIT, popPopup);
      };
    }, []);

    return <PopupContext.Provider value={popupStack}>{props.children}</PopupContext.Provider>;
  }

  export function usePopupInView(): boolean {
    const popupStack = React.useContext(PopupContext);
    return popupStack.length > 0;
  }

  export function useCartHeight({
    ref,
    order,
    recommendedMenuItems,
  }: {
    ref: React.RefObject<HTMLDivElement>;
    order: Store['cart'];
    recommendedMenuItems: ReturnType<typeof CartUtils.useRecommendedItems>;
  }): number {
    const collapsibleBounds = useMeasure(ref);

    // in older browsers, ResizeObserver is not properly displaying the height of the element
    // so we need to fallback to the calculated height
    const height = Math.max(
      collapsibleBounds.height, // chrome 70 -> 5
      calculateCartHeight({
        order,
        recommendedMenuItems,
      }),
    );

    return height;
  }

  function calculateCartHeight({
    order,
    recommendedMenuItems,
  }: {
    order: Store['cart'];
    recommendedMenuItems: ReturnType<typeof CartUtils.useRecommendedItems>;
  }): number {
    // since the height is sometimes calculated, we need to account for the maxHeight of the element 40vh == window.innerHeight * 0.4
    const maxHeight = window.innerHeight * 0.4;
    const itemHeight = 52;
    const padding = 40;
    const itemMargin = 10;
    const recommendedMenuItemsHeight = 156;
    const calculatedHeight =
      order.orderedItems.length * itemHeight +
      padding +
      itemMargin +
      (recommendedMenuItems?.data?.length ? recommendedMenuItemsHeight : 0);
    // the calculated height might be above the max height, so we need to cap it
    return Math.min(calculatedHeight, maxHeight);
  }
}
