import * as React from 'react';
import create from 'zustand';
import { devtools } from 'zustand/middleware';

import type { EnhancedLocation } from '~/helpers';
import { LocationUtils } from '~/helpers';
import type { KioskMenu, KioskMenuSettings } from '~/types';

import type { BridgeStore } from './bridge.store';
import { initBridgeStore } from './bridge.store';
import type { CartStore } from './cart.store';
import { initCartStore } from './cart.store';
import type { CheckoutStore } from './checkout.store';
import { initCheckoutStore } from './checkout.store';
import type { ConfigStore } from './config.store';
import { initConfigStore } from './config.store';
import type { LoyaltyStore } from './loyalty.store';
import { initLoyaltyStore } from './loyalty.store';

export * from './config.store';
export * from './bridge.store';

export type Store = CartStore & CheckoutStore & ConfigStore & BridgeStore & LoyaltyStore;

declare global {
  interface Window {
    // this is for kiosk-preview use, since it pre-populates the store
    __useStore: typeof useStore;
  }
}

export const useStore = create(
  devtools<Store>((set, get, x) => ({
    ...initLoyaltyStore(set, get, x),
    ...initCheckoutStore(set, get, x),
    ...initCartStore(set, get, x),
    ...initConfigStore(set, get, x),
    ...initBridgeStore(set, get, x),
  })),
);

// for kiosk-preview use
// eslint-disable-next-line no-underscore-dangle
window.__useStore = useStore;

// this is a helper function for our class components to use the store
// there aren't many of these left, but we should try to get rid of them
export function withStore<State>(
  selector: (store: Store) => State,
  shouldUpdate: (prev: State, next: State) => boolean = (prev, next) => prev === next,
) {
  return function Wrapped<Props>(Component: React.ComponentType<State & Props>) {
    return function WithStore(props: Omit<Props, keyof State>) {
      const state = useStore<State>(selector, shouldUpdate);
      return (
        <Component
          {...(state as any)}
          {...props}
        />
      );
    };
  };
}

export const useUsers = (): any => useStore((state) => state.bridge.users);
export const useLocation = (): EnhancedLocation => {
  const location = useStore((state) => state.bridge.location);
  const settings = useSettings();
  return LocationUtils.bind(location, settings);
};
export const useSettings = (): KioskMenuSettings => {
  return useStore((state) => state.bridge.menu.settings);
};
export const useMenu = (): KioskMenu => {
  return useStore((state) => state.bridge.menu);
};
export const useKiosk = (): any => useStore((state) => state.bridge.kiosk);

export const useHasFetchedRewards = (): LoyaltyStore['loyalty']['hasFetchedRewards'] =>
  useStore((state) => state.loyalty.hasFetchedRewards);
export const useLoyaltyAuthData = (): LoyaltyStore['loyalty']['loyaltyAuthData'] =>
  useStore((state) => state.loyalty.loyaltyAuthData);
export const usePreSelectedRewards = (): LoyaltyStore['loyalty']['preSelectedRewards'] =>
  useStore((state) => state.loyalty.preSelectedRewards);
export const useRewards = (): LoyaltyStore['loyalty']['preSelectedRewards'] =>
  useStore((state) => state.loyalty.rewards);
export const usePrefetchedRewards = (): LoyaltyStore['loyalty']['prefetchedRewards'] =>
  useStore((state) => state.loyalty.prefetchedRewards);
export const useAuthFriendlyName = (): LoyaltyStore['loyalty']['authFriendlyName'] =>
  useStore((state) => state.loyalty.authFriendlyName);
export const useLoyaltyStatus = (): LoyaltyStore['loyalty']['status'] =>
  useStore((state) => state.loyalty.status);
