import _ from 'underscore';

import { GCNLayeredImageView } from './gcn_layered_image_view';
import { GCNView } from './gcn_view';

export const GCNBuildYourOwnView = GCNView.extend({
  className: 'build-your-own-view',

  initialize(options, ...args) {
    GCNView.prototype.initialize.apply(this, [options, ...args]);

    // Create a map of addon IDs to images.
    const menuItem = options.model;
    this.addonSetImageIndexById = {};
    const layers = [];
    let index = 0;
    _.each(menuItem.priceOptions, (priceOption) => {
      _.each(priceOption.addonSets, (addonSet) => {
        _.each(addonSet.items, (item) => {
          if (item.hasArr('images')) {
            layers.push({
              url: item.get('images')[item.get('images').length - 1].url,
            });
            this.addonSetImageIndexById[item.id] = index;
            index++;
          }
        });
      });
    });

    // Set up the image layers from the item.
    this.layerView = new GCNLayeredImageView({
      baseImage: {
        url: menuItem.get('buildYourOwnBaseImage')[0].url,
      },
      layers,
    });
  },

  updateForSelectAddonRefs(selectedAddonRefs) {
    const self = this;
    this.layerView.hideAllTopImages();
    _.each(selectedAddonRefs, (addonRef) => {
      const index = self.addonSetImageIndexById[addonRef.addon.id];
      if (index >= 0) {
        self.layerView.showImageAtIndex(index);
      }
    });
  },

  render() {
    this.$el.html('');
    this.$el.append(this.layerView.render().$el);
    this.layerView.hideAllTopImages();
    return this;
  },
});
