import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom/client';
import _ from 'underscore';

import { BackboneEvents } from '~/app/js/backbone-events';

import { FulfillmentHeader } from '../../../components';
import ScreenReaderHelper from '../screen_reader_helper';
import Analytics from '../utils/analytics';
import { GCNView } from './gcn_view';

const sectionClassPrefix = 'section-';
const selectedButtonClasses = 'selected border-color-spot-1 color-spot-1';
export const GCNScrollingNavView = GCNView.extend({
  className: 'scrolling-nav-view font-title',
  template:
    // prettier-ignore
    '<div class="carousel-h-outer">' +
      '<div class="carousel-h-inner"></div>' +
    '</div>',
  _menuPageId: null,
  _topLevelMenuPageId: null,

  initialize(...args) {
    GCNView.prototype.initialize.apply(this, args);

    this.listenTo(gcn, BackboneEvents.GCNMenuAppView.MenuDidUpdate, this._menuDidUpdate);
    this.listenTo(
      gcn,
      BackboneEvents.GCNScrollingNavView.DidScrollMenuPage,
      this._scrollingMenuPage,
    );
  },

  destroy() {
    clearInterval(this._indicators?.left.timer);
    clearInterval(this._indicators?.right.timer);
  },

  _menuDidUpdate() {
    this.setMenuPage(gcn.menu.getMenuPageWithId(this._menuPageId) || gcn.menu.getFirstMenuPage());
  },

  _findMenuPage(menuPageId) {
    if (!menuPageId) {
      return null;
    }
    return _.find(gcn.menu.structure.pages, (page) => {
      return gcn.menu.menuPageContainsPageWithId(page, menuPageId);
    });
  },

  setMenuPage(menuPage, resetIndicator) {
    // Figure out which top-level page was selected.
    this._menuPageId = menuPage.id;
    // eslint-disable-next-line no-param-reassign
    menuPage = this._findMenuPage(this._menuPageId);
    this._topLevelMenuPageId = (menuPage || {}).id || this._menuPageId;

    if (resetIndicator) {
      this._moreIndicatorRemoved = false;
    }
    this.render();

    this._scrollToSelectedPageButton();
  },

  setSelectedSectionWithId(sectionId, scrollButtonIntoView) {
    if (this._isSinglePageMode()) {
      this._setSelectedNavButton(sectionClassPrefix + sectionId);
      if (scrollButtonIntoView) {
        this._scrollToSelectedPageButton();
      }
    }
  },

  _assertInnerCarousel() {
    // Sentry blacklists an error message on calls to 'scrollLeft' on null, so throw a custom error
    // if $inner is not found.

    if (!this._$inner) {
      throw new Error('carousel inner element not found');
    }
  },

  _animateToButton(selectedButton) {
    // If in single page, scroll to the currently selectedButton in the innerView
    // Otherwise, scroll to the top of the new page section
    if (this._isSinglePageMode()) {
      this._assertInnerCarousel();

      const distanceFromLeft = selectedButton.position().left + this._$inner.scrollLeft();
      const pos = distanceFromLeft - 96;
      this._$inner.stop().animate(
        {
          scrollLeft: pos,
        },
        600,
      );
    } else {
      // this seems to be an issue on tizen devices
      window.gcn.el?.firstChild?.scrollTo({ top: 0, behavior: 'smooth' });
    }
  },

  _scrollToSelectedPageButton() {
    // Calculate the final scroll position.
    // TODO(steve): Scroll the view so that the selected div is centered.
    const $selectedButton = this._$inner.find('div.selected');
    if (!$selectedButton.length) {
      return;
    }
    this._animateToButton($selectedButton);
  },

  _createNavButton() {
    return $(
      // prettier-ignore
      '<div class="button-touch-area">' +
        '<div class="button-actual" aria-hidden="true"> </div>' +
      '</div>',
    );
  },

  _setSelectedNavButton(className) {
    const $otherButtons = this.$('.button-touch-area .button-actual');
    $otherButtons.removeClass(selectedButtonClasses);

    const $buttonToSelect = this.$(`.button-touch-area.${className} .button-actual`);
    $buttonToSelect.addClass(selectedButtonClasses);
  },

  _isSinglePageMode() {
    return gcn.menu.structure.pages.length === 1;
  },

  _setupIndicatorAnimation($indicator, direction) {
    const animation = `bounce-${direction}`;
    function bounce() {
      $indicator.toggleClass('animated', true);
      $indicator.toggleClass(animation, true);
      $indicator.one(
        'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend',
        () => {
          $indicator.toggleClass('animated', false);
          $indicator.toggleClass(animation, false);
        },
      );
    }

    return setInterval(bounce, 6000);
  },

  // BITE-6242 - scroll indicators for scrolling nav on both sides of the carousel
  // previously, there was only one indicator on the right side
  _renderMoreContentIndicators() {
    if (!this._indicators) {
      const $left = $('<div class="more-indicator left">❮</div>');
      const $right = $('<div class="more-indicator right">❯</div>');
      this._indicators = {
        left: {
          $el: $left,
          timer: this._setupIndicatorAnimation($left, 'left'),
        },
        right: {
          $el: $right,
          timer: this._setupIndicatorAnimation($right, 'right'),
        },
      };

      this._$inner.on('scroll', () => {
        this._assertInnerCarousel();

        // Remove the indicator on scrolling to the start.
        if (this._$inner.scrollLeft() === 0) {
          $left.hide();
        }

        if (this._$inner.scrollLeft() > 0) {
          $left.show();
        }

        if (this._$inner.width() + this._$inner.scrollLeft() < this._$inner[0].scrollWidth) {
          $right.show();
        }
        // Remove the indicator on scrolling to the end.
        else if (
          this._$inner.width() + this._$inner.scrollLeft() >=
          // Fudge the number a bit because some platforms may be off by a few pixels.
          this._$inner[0].scrollWidth - 20
        ) {
          $right.hide();
        }
      });
    }
    const $left = this._indicators.left.$el;
    const $right = this._indicators.right.$el;

    // edge case: tapping these buttons can cause the carousel to scroll _almost_ to the end or start,
    // but not quite, so some items may be partly visible.
    // there is no reliable way to scroll while perfectly aligning the items, so we just scroll by the width of the carousel
    $left.onButtonTapOrHold('snvMoreLeft', () => {
      this._assertInnerCarousel();

      let nextScrollLeft = this._$inner.scrollLeft() - this._$inner.width();
      this._$inner.animate({ scrollLeft: nextScrollLeft }, 500);
    });

    $right.onButtonTapOrHold('snvMoreRight', () => {
      this._assertInnerCarousel();

      let nextScrollLeft = this._$inner.scrollLeft() + this._$inner.width();
      this._$inner.animate({ scrollLeft: nextScrollLeft }, 500);
    });

    $left.hide();
    $right.hide();

    this._assertInnerCarousel();

    // check if scrolling to the left is possible
    if (this._$inner.scrollLeft() > 0) {
      $left.show();
    }

    // check if scrolling to the right is possible
    if (this._$inner[0].scrollWidth - 1 >= this._$inner.width()) {
      $right.show();
    }

    // if they aren't already in the DOM, add them
    if (!$left.parent().length) {
      this._$inner.prepend($left);
    }
    if (!$right.parent().length) {
      this._$inner.append($right);
    }
  },

  _scrollingMenuPage(top) {
    if (window.isFlash && this.$fulfillmentHeader) {
      const height = this.$el[0].clientHeight;
      const isCollapsed = this.$fulfillmentHeader.hasClass('collapse');
      const containerHeight = isCollapsed
        ? 50
        : window.innerWidth < 600
          ? // this is the height of the fulfillment header when it's not collapsed
            140
          : height - 100;

      if (top > containerHeight && !isCollapsed) {
        this.$fulfillmentHeader.addClass('collapse');
      } else if (top <= containerHeight && isCollapsed) {
        this.$fulfillmentHeader.removeClass('collapse');
      }
    }
  },

  resetScrollPosition() {
    this._$inner?.scrollLeft(0);
  },

  render() {
    if (this._$inner) {
      this._$inner.html('');
    } else {
      this.$el.html(this.template);
      this._$inner = this.$('.carousel-h-inner');
      if (!gcn.isTouchDevice() || navigator.platform === 'Win32') {
        this._$inner.addClass('show-scroll-bar');
        this.$el.css('overflow-x', 'hidden');
      }
    }

    if (window.isFlash && !this.$fulfillmentHeader) {
      this.$el.addClass('has-fulfillment-header');
      this.$fulfillmentHeader = $('<div class="fulfillment-header"></div>');
      this.$el.prepend(this.$fulfillmentHeader);
      const root = ReactDOM.createRoot(this.$fulfillmentHeader[0]);
      root.render(React.createElement(FulfillmentHeader));
    }

    const menuStructure = gcn.menu.structure;
    if (menuStructure.hasMenuPageImages()) {
      this.$el.addClass('has-page-images');
    }

    // TODO: Can definitely share more code here if we abstract out the navigation type through a
    // proper MVC pattern.
    if (this._isSinglePageMode()) {
      // Section navigation.
      this._first = false;
      _.each(menuStructure.pages[0].sections, (section) => {
        if (section.attributes.hideFromMenu) {
          return;
        }
        const $button = this._createNavButton();
        $button.addClass(sectionClassPrefix + section.id);
        $button.addClass(sectionClassPrefix + section.getSlugName());
        const $buttonActual = $button.find('.button-actual');
        $buttonActual.text(section.displayName());

        // TODO: Select first section properly.
        if (!this._first) {
          this._first = true;
          $buttonActual.addClass(selectedButtonClasses);
        }

        $button.onTapInScrollableAreaWithCalibration('snvSection', () => {
          Analytics.trackEvent({
            eventName: Analytics.EventName.ScrollingNavSectionSelected,
            eventData: {
              sectionName: section.displayName(),
            },
          });
          this.trigger(BackboneEvents.GCNScrollingNavView.DidSelectSectionId, section.id);
          this._setSelectedNavButton(sectionClassPrefix + section.id);
          this._scrollToSelectedPageButton();
          return false;
        });

        this._$inner.append($button);
      });
    } else {
      // Page navigation.
      _.each(menuStructure.pages, (page) => {
        // Make one button per ID and Name.
        const $button = this._createNavButton();
        $button.addClass(`page-${page.getSlugName()}`);
        const $buttonActual = $button.find('.button-actual');
        const url = page.getMenuPageImageUrl();
        if (url) {
          $buttonActual.addClass('has-image');
          gcn.requestImageByUrl(url, (err, imgPath) => {
            if (!err) {
              $buttonActual.css('background-image', `url(${imgPath})`);
            }
          });
        } else {
          $buttonActual.text(page.get('name'));
        }

        if (this._topLevelMenuPageId === page.id) {
          $buttonActual.addClass(selectedButtonClasses);
          $button.attr('role', 'heading');
          $button.attr('aria-level', '1');
        }
        $button.attr('aria-label', ScreenReaderHelper.prepareAriaLabel(page.get('name')));

        $button.onTapInScrollableAreaWithCalibration('snvSubPage', () => {
          Analytics.trackEvent({
            eventName: Analytics.EventName.ScrollingNavPageSelected,
            eventData: {
              pageName: page.get('name'),
            },
          });
          this.trigger(BackboneEvents.GCNScrollingNavView.DidSelectMenuPageId, page.id);
          if (gcn.screenReaderIsActive) {
            // There's an issue with JAWS where it doesn't reload the menu
            // structure when a new menu category is selected. To fix this, we
            // can ask it to re-focus.
            this.$el.parent().find('.section-header').first().requestFocusAfterDelay();
          }
          return false;
        });

        this._$inner.append($button);
      });
    }

    if (!gcn.screenReaderIsActive && gcn.menu.appearance.get('enableScrollingNavMoreButton')) {
      this._renderMoreContentIndicators();
    }

    return this;
  },
});
