import { IonButton, IonCol, IonItem, IonLabel, IonRow } from '@ionic/react';
import React, { Component } from 'react';

import { Strings } from '@biteinc/common';

import { str } from '~/app/js/localization/localization';

interface GuestSurveyProps {
  orderId: string;
}

type GuestRating = 0 | 1 | 2 | 3;

interface GuestSurveyState {
  hasSubmittedRating?: true;
  selectedRating?: GuestRating;
}

export default class GuestSurvey extends Component<GuestSurveyProps, GuestSurveyState> {
  constructor(props: GuestSurveyProps) {
    super(props);

    this.state = {};
  }

  setRating(rating: GuestRating): void {
    gcn.maitred.submitGuestSurveyWithId(this.props.orderId, rating, (err) => {
      if (!err) {
        this.setState({
          hasSubmittedRating: true,
          selectedRating: rating,
        });
      }
    });
  }

  render(): React.ReactNode {
    const ratingEmojis = ['🙁', '😐', '🙂', '😁'];
    return (
      <IonItem
        lines="none"
        className="guest-survey"
      >
        <IonLabel className="survey-info">
          <h3 className="title">{str(Strings.HOW_DID_WE_DO)}</h3>
          <IonRow className="survey-buttons">
            {ratingEmojis.map((emojiChar, i) => {
              return (
                <IonCol key={emojiChar}>
                  <IonButton
                    className="survey-button"
                    fill="outline"
                    size="default"
                    expand="block"
                    disabled={this.state.hasSubmittedRating && this.state.selectedRating !== i}
                    onClick={() => {
                      if (this.state.selectedRating !== i) {
                        this.setRating(i as GuestRating);
                      }
                    }}
                  >
                    {emojiChar}
                  </IonButton>
                </IonCol>
              );
            })}
          </IonRow>
          {this.state.hasSubmittedRating && (
            <h3 className="rating-submitted">Thank you for your feedback!</h3>
          )}
        </IonLabel>
      </IonItem>
    );
  }
}
