import * as React from 'react';
import ResizeObserver from 'resize-observer-polyfill';

export function useMeasure(ref: React.RefObject<HTMLElement>): {
  left: number;
  top: number;
  width: number;
  height: number;
} {
  const [bounds, set] = React.useState({
    left: 0,
    top: 0,
    width: 0,
    height: 0,
  });

  React.useEffect(() => {
    function measure(): void {
      if (ref.current) {
        const rect = ref.current.getBoundingClientRect();
        set(rect);
      }
    }
    measure();

    // use resize observer to measure when the ref changes and then set the new bounds
    const resizeObserver = new ResizeObserver(measure);
    if (ref.current) {
      resizeObserver.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        resizeObserver.unobserve(ref.current);
      }
    };
  }, []);

  return bounds;
}
