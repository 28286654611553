import { GCNAsterHallHeaderView } from './gcn_aster_hall_header_view';
import { GCNPaymentCompletedStepView } from './gcn_payment_completed_step_view';

export const GCNAsterHallCompletedStepView = GCNPaymentCompletedStepView.extend({
  // Append custom Aster Hall header.
  __renderHeader() {
    this.$stepBody.append(
      new GCNAsterHallHeaderView({
        title: 'Pick Up',
      }).render().$el,
    );
  },
});
