import { IonButton, IonCol, IonImg, IonRow } from '@ionic/react';
import * as React from 'react';
import _ from 'underscore';

import { LocalizationHelper, StringHelper } from '@biteinc/core-react';
import { FulfillmentMethodHelper, WeekdayHelper } from '@biteinc/enums';

import { useLocalize } from '~/app/js/localization/localization';
import type { KioskLocation } from '~/types';

import { GcnFulfillmentHelper, GcnPaymentHelper } from '../helpers';

interface StoreInfoProps {
  location: KioskLocation;
}

export function StoreInfo(props: StoreInfoProps): React.ReactElement {
  const openingHoursByFulfillmentMethod = props.location.openingHoursByFulfillmentMethod;
  const sortedHours = _.sortBy(openingHoursByFulfillmentMethod, ({ fulfillmentMethod }) => {
    switch (true) {
      case FulfillmentMethodHelper.isCurbside(fulfillmentMethod):
        return 5;
      case FulfillmentMethodHelper.isAnOutpost(fulfillmentMethod):
        return 4;
      case FulfillmentMethodHelper.isDelivery(fulfillmentMethod):
        return 3;
      case FulfillmentMethodHelper.isDineIn(fulfillmentMethod):
        return 2;
      default:
        return 1;
    }
  });

  const str = useLocalize();
  const { location } = props;
  const encodedURI = encodeURI(GcnFulfillmentHelper.addressToString(location.fullAddress, true));

  return (
    <IonRow className="store-info-container">
      <IonCol
        size="12"
        className="store-details"
      >
        <IonRow className="title-container">
          <h3 className="title store-name">{location.name}</h3>
        </IonRow>
        {location.phoneNumber && (
          <IonRow className="store-info store-phone-number">
            <IonCol className="label">Telephone</IonCol>
            <IonCol className="info">
              {StringHelper.toFormattedPhoneNumber(location.phoneNumber)}
            </IonCol>
          </IonRow>
        )}
        <IonRow className="store-info store-address">
          <IonCol className="label">Address</IonCol>
          <IonCol className="info">
            {GcnFulfillmentHelper.addressAsMultipleLines(location.fullAddress)}
          </IonCol>
        </IonRow>
        <IonRow className="store-info view-map">
          <IonCol className="button-container">
            <IonButton
              className="view-map-button"
              color="primary"
              fill="solid"
              expand="block"
              target="_blank"
              href={`https://www.google.com/maps/search/?api=1&query=${encodedURI}`}
            >
              View Map
            </IonButton>
          </IonCol>
        </IonRow>
      </IonCol>
      <IonCol
        size="12"
        className="store-hours"
      >
        <IonRow className="title-container">
          <h3 className="title">Hours of Operation</h3>
        </IonRow>
        <IonRow>
          {sortedHours.map((hours) => {
            return (
              <FulfillmentMethodHours
                hours={hours}
                key={hours.fulfillmentMethod}
              />
            );
          })}
        </IonRow>
      </IonCol>
      <IonCol
        size="12"
        className="store-accepted-payment-methods"
      >
        <IonRow className="payment-methods-container">
          <h3 className="title payment-method-icon-title">Accepted Cards</h3>
          {props.location.supportedCardSchemes?.map((cardSchemeId) => {
            return (
              <IonImg
                key={cardSchemeId}
                className="card-scheme-image"
                src={GcnPaymentHelper.getCardSchemeImage(cardSchemeId)}
                alt={str(LocalizationHelper.localizeEnum.CardSchemeId(cardSchemeId)).toString()}
              />
            );
          }) ?? null}
        </IonRow>
      </IonCol>
    </IonRow>
  );
}

const columnOptions = {
  sizeXs: '12',
  sizeSm: '12',
  sizeMd: '6',
  sizeLg: '6',
  className: 'fulfillment-method-hours',
};

type FulfillmentMethodHoursProps = {
  hours: KioskLocation['openingHoursByFulfillmentMethod'][number];
};

function FulfillmentMethodHours(props: FulfillmentMethodHoursProps): React.ReactElement {
  const str = useLocalize();
  const { fulfillmentMethod, ...daysOfTheWeek } = props.hours;
  return (
    <IonCol {...columnOptions}>
      <h4 className="fulfillment-method-name">
        {str(LocalizationHelper.localizeEnum.FulfillmentMethod(fulfillmentMethod))}
      </h4>
      {WeekdayHelper.allDaysStartingWithMonday().map((weekday) => {
        return (
          <IonRow
            className="day-schedule"
            key={weekday}
          >
            <IonCol
              className="label"
              size="3"
            >
              {`${StringHelper.toTitleCase(weekday)}:`}
            </IonCol>
            <IonCol className="info">
              {daysOfTheWeek[weekday]?.openingHoursString || 'closed'}
            </IonCol>
          </IonRow>
        );
      })}
    </IonCol>
  );
}
