import { IonIcon } from '@ionic/react';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { close, filterCircleOutline } from 'ionicons/icons';
import * as React from 'react';

import { Strings } from '@biteinc/common';

import { useLocalize } from '~/app/js/localization/localization';

import { useMenu, useStore } from '../stores';
import { ReactAriaButton } from './button';
import { MenuFilterPicker } from './menu-filter-picker';
import { Portal } from './portal';

export function MenuFilterHeader(): JSX.Element {
  const str = useLocalize();
  const menuFilters = useStore((store) => store.config.menuFilters);
  const clearMenuFilters = useStore((store) => store.config.clearMenuFilters);
  const menu = useMenu();

  const activeFilterBadges = menu.badges.filter((badge) => {
    return Object.values(menuFilters).some((category) => category.includes(badge._id));
  });
  const [open, setOpen] = React.useState(false);

  const isActive = activeFilterBadges.length > 0;

  return (
    <>
      <div
        className={classNames(
          'tw-flex tw-justify-between tw-items-center tw-w-full tw-px-4 tw-py-1 tw-space-x-2',
          {
            'tw-bg-[--color-primary]': isActive,
          },
        )}
      >
        <ReactAriaButton
          className={`menu-filters-button tw-flex tw-items-center tw-justify-center tw-flex-wrap tw-space-x-2 ${
            isActive ? 'active' : ''
          }`}
          onPress={() => {
            setOpen(true);
          }}
        >
          <div className="tw-flex max-sm:tw-flex-grow tw-space-x-2 tw-items-center">
            <IonIcon
              slot="start"
              className="tw-h-6 tw-w-6 tw-text-lg"
              icon={filterCircleOutline}
            />
            <span className="menu-filters-button-text -tw-mb-1.5">
              {str(Strings.MENU_FILTERS_NUTRITION_PREFERENCE)}
              {isActive ? ` (${activeFilterBadges.length})` : ''}
            </span>
          </div>
          {isActive ? (
            <div className="tw-space-x-2 tw-flex tw-items-center tw-justify-center max-sm:tw-flex-grow-[3]">
              {activeFilterBadges.map((badge) => (
                <img
                  alt={str(badge.name).toString()}
                  key={badge._id}
                  className="tw-w-7 tw-h-7 tw-p-1 tw-object-contain tw-bg-white tw-rounded-full"
                  src={badge.icons[0].url}
                />
              ))}
            </div>
          ) : null}
        </ReactAriaButton>

        <ReactAriaButton
          className="clear-filters-button"
          style={{ visibility: isActive ? 'visible' : 'hidden' }}
          onPress={() => {
            clearMenuFilters();
          }}
        >
          <IonIcon
            slot="icon-only"
            icon={close}
          />
        </ReactAriaButton>
      </div>
      <Portal>
        <AnimatePresence>
          {open && (
            <motion.div
              initial={{ opacity: 0, y: '100%' }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: '100%' }}
              transition={{ duration: 0.2 }}
              className="tw-h-[calc(100%_-_var(--bottom-bar-height))] tw-w-full tw-top-0 tw-left-0 tw-z-50 tw-flex tw-flex-col tw-absolute"
            >
              <div
                className="tw-h-full tw-absolute tw-w-full -tw-z-10"
                style={{
                  backgroundColor: 'rgba(0, 0, 0, 0.8)',
                }}
              />
              <MenuFilterPicker close={() => setOpen(false)} />
            </motion.div>
          )}
        </AnimatePresence>
      </Portal>
    </>
  );
}
