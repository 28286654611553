import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';

import { Strings } from '@biteinc/common';
import { BitePlatform } from '@biteinc/enums';

import { useSettings, useStore } from '~/stores';

import { useLocalize } from '../../../app/js/localization/localization';
import { CartUtils } from '../cart.utils';
import { OrderedItem } from './cart-ordered-item';
import { RecommendedItem } from './cart-recommended-item';

const collapsibleViewVariants = {
  open: (height: number) => ({
    height,
    overflow: 'hidden',
  }),
  closed: { height: '0px', overflow: 'hidden' },
};

type CartCollapsibleViewProps = {
  showCartInfo: boolean;
};

const IncludeUtensilsOptionView = (): JSX.Element | null => {
  const settings = useSettings();
  const str = useLocalize();
  const [includeUtensils, setIncludeUtensils] = useState(false);
  if (!settings.askToIncludeUtensilsOnKioskOrders) {
    return null;
  }
  const handleUtensilsStateChange = (): void => {
    setIncludeUtensils(!includeUtensils);
    gcn.orderManager.setIncludeUtensils(!includeUtensils);
  };

  return (
    <div className="tw-flex tw-flex-row tw-justify-end tw-border-t tw-border-t-slate-400 tw-py-3 tw-mt-4">
      <label
        className="tw-text-sm tw-mr-4 tw-ml-2 tw-text-gray-900"
        htmlFor="utensils-opt-in"
      >
        {str(Strings.INCLUDE_UTENSILS)}
      </label>
      <input
        id="utensils-opt-in"
        type="checkbox"
        checked={includeUtensils}
        name="utensils-opt-in"
        className="tw-w-6 tw-h-6 tw-text-blue-600 tw-bg-gray-100 tw-border-gray-300 tw-rounded tw-focus:ring-blue-500 tw-focus:ring-2"
        onChange={handleUtensilsStateChange}
      />
    </div>
  );
};

export function CartCollapsibleView({ showCartInfo }: CartCollapsibleViewProps): JSX.Element {
  const order = useStore((state) => state.cart);
  const loyalty = useStore((state) => state.loyalty);
  const str = useLocalize();
  const config = useStore((state) => state.config);
  const recommendedMenuItems = CartUtils.useRecommendedItems(order);
  const collapsibleRef = React.useRef<HTMLDivElement>(null);
  const height = CartUtils.useCartHeight({
    ref: collapsibleRef,
    order,
    recommendedMenuItems,
  });
  const hasPreAppliedRewards = loyalty.preSelectedRewards && loyalty.preSelectedRewards.length > 0;
  const platform = window.platform;

  return (
    <motion.div
      variants={collapsibleViewVariants}
      custom={height}
      animate={showCartInfo ? 'open' : 'closed'}
      transition={{
        bounce: 0,
      }}
      style={{
        maxHeight: platform === BitePlatform.KioskIos ? '40vh' : '25vh',
      }}
      className="cart-view__collapsible-view"
    >
      <div ref={collapsibleRef}>
        {hasPreAppliedRewards && (
          <div className="cart-view__collapsible-view__loyalty-disclaimer">
            {str(
              loyalty.preSelectedRewards!.length > 1
                ? Strings.DISCOUNTS_WILL_BE_APPLIED_AT_CHECKOUT
                : Strings.DISCOUNT_WILL_BE_APPLIED_AT_CHECKOUT,
            )}
          </div>
        )}
        <div
          className={`cart-view__collapsible-view__container ${
            hasPreAppliedRewards ? 'with-loyalty-disclaimer' : ''
          }`}
          style={{
            maxHeight: platform === BitePlatform.KioskIos ? '40vh' : '25vh',
          }}
          aria-hidden={!showCartInfo}
        >
          <div className="cart-view__collapsible-view__orders">
            <AnimatePresence initial={false}>
              {order.orderedItems.map((item) => (
                <OrderedItem
                  item={item}
                  // eslint-disable-next-line no-underscore-dangle
                  key={item._uid}
                />
              ))}
            </AnimatePresence>
          </div>
          {!config.screenReaderIsActive && (
            <>
              {recommendedMenuItems.status === 'fetching' ? (
                <div className="cart-view__collapsible-view__recommendations__items">
                  <div className="loading-skeleton cart-view__collapsible-view__recommendations__items__item cart-view__collapsible-view__recommendations__items__item--loading"></div>
                  <div className="loading-skeleton cart-view__collapsible-view__recommendations__items__item cart-view__collapsible-view__recommendations__items__item--loading"></div>
                </div>
              ) : null}
              {(recommendedMenuItems.status === 'success' ||
                recommendedMenuItems.status === 'refetching') &&
                recommendedMenuItems.data.length > 0 && (
                  <div className="cart-view__collapsible-view__recommendations">
                    <div className="cart-view__collapsible-view__recommendations__title">
                      {str(Strings.ALSO_CONSIDER)}
                    </div>
                    <div
                      style={{
                        opacity: recommendedMenuItems.status === 'refetching' ? 0.5 : 1,
                      }}
                      className="cart-view__collapsible-view__recommendations__items"
                    >
                      {recommendedMenuItems.data.map((item, index) => (
                        <RecommendedItem
                          item={item}
                          index={index}
                          key={item.id}
                        />
                      ))}
                    </div>
                  </div>
                )}
            </>
          )}
          <IncludeUtensilsOptionView />
        </div>
      </div>
    </motion.div>
  );
}
