import { useStore } from '~/stores';

export namespace ConfigUtils {
  export function sessionRecommendationMenuFilters(): {
    restrictionBadgeIds: string[];
    dietaryBadgeIds: string[];
    preferenceBadgeIds: string[];
  } {
    const menuFilters = useStore.getState().config.menuFilters;
    return {
      restrictionBadgeIds: menuFilters.restrictive,
      dietaryBadgeIds: menuFilters.dietary,
      preferenceBadgeIds: menuFilters.other,
    };
  }
}
