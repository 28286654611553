import _ from 'underscore';

import { Strings } from '@biteinc/common';
import { TimeHelper } from '@biteinc/core-react';

import { BackboneEvents } from '~/app/js/backbone-events';
import { localizeStr, str } from '~/app/js/localization/localization';

import { GcnPaymentHelper } from '../../../helpers';
import GcnHelper from '../gcn_helper';
import Analytics from '../utils/analytics';
import { GCNAlertView } from './gcn_alert_view';
import { GCNView } from './gcn_view';

export const GCNTransactionCellView = GCNView.extend({
  className: 'transaction-cell-view',
  template: _.template(
    // prettier-ignore
    '<div class="transaction-info">' +
      '<% details.forEach(function(detail) { %>' +
        '<span><%= detail %></span>' +
      '<% }); %>' +
    '</div>' +
    '<div class="refunds">' +
      '<% refunds.forEach(function(refund) { %>' +
        '<span><%= refund %></span>' +
      '<% }); %>' +
    '</div>' +
    '<% if (canBeRefunded) { %>' +
      '<div class="refund-controls">' +
        '<% if (canBePartiallyRefunded) { %>' +
          '<input value="<%= refundAmount %>" type="number" class="form-control refund-amount" placeholder="Refund Amount" autocomplete="off">' +
        '<% } %>' +
        '<div class="refund-button btn">REFUND</div>' +
      '</div>' +
    '<% } %>',
  ),
  _order: null,
  _$refundAmountInput: null,

  initialize(options, ...args) {
    GCNView.prototype.initialize.apply(this, [options, ...args]);

    this._order = options.order;
  },

  blur() {
    if (this._$refundAmountInput.length) {
      this._$refundAmountInput[0].blur();
    }
  },

  _refundButtonWasTapped() {
    let amount = this.model.getRefundableAmount();
    if (this._$refundAmountInput.length) {
      const amountStr = this._$refundAmountInput.val().trim();
      const validation = GcnHelper.priceFromString(amountStr);
      if (validation.error) {
        gcn.menuView.showSimpleAlert(localizeStr(Strings.PLEASE_ENTER_A_VALID_AMOUNT));
        Analytics.trackEvent({
          eventName: Analytics.EventName.RefundTransactionError,
          eventData: {
            message: str(Strings.PLEASE_ENTER_A_VALID_AMOUNT),
          },
        });
        return;
      }

      amount = validation.value;
    }
    Analytics.track(Analytics.EventName.RefundTransactionStart);

    const self = this;
    const alertText = localizeStr(
      Strings.CONFIRM_REFUND_AMOUNT,
      [`$${GcnHelper.stringFromTotal(amount)}`],
      function (string) {
        return string.split('\n').join('<br />');
      },
    );
    gcn.menuView.showModalPopup(
      new GCNAlertView({
        text: alertText,
        okCallback() {
          gcn.menuView.dismissModalPopup();
          self.trigger(BackboneEvents.GCNTransactionCellView.RefundButtonWasTapped, self, amount);
        },
        okText: localizeStr(Strings.YES),
        cancelCallback() {
          Analytics.track(Analytics.EventName.RefundTransactionSuccess);
          gcn.menuView.dismissModalPopup();
        },
        cancelText: localizeStr(Strings.NO),
      }),
    );
  },

  render() {
    const transaction = this.model;
    const order = this._order;

    const unrefundedAmount = order.getUnrefundedAmountForTransaction(transaction);
    const refunds = _.filter(order.get('refunds'), (refund) => {
      return refund.transactionId === transaction.id;
    });
    const tenders = transaction.getNonDiscountTenders();
    const paymentDetails = ['Payment:'];
    if (tenders.length === 1) {
      paymentDetails.push(tenders[0].cardSchemeName);
    }
    if (transaction.getLastFour()) {
      paymentDetails.push(transaction.getLastFour());
    }
    if (transaction.has('authCode')) {
      paymentDetails.push(transaction.get('authCode'));
    }
    if (transaction.hasKnownCardEntryMethod()) {
      paymentDetails.push(`(${str(transaction.getCardEntryMethodString())})`);
    }

    const refundLines = _.map(refunds, (refund) => {
      const refundTime = TimeHelper.format(refund.createdAt, 'MM/DD/YY h:mma');
      return `Refunded $${GcnHelper.stringFromTotal(refund.total)} - ${refundTime}`;
    });
    if (refundLines.length) {
      refundLines.push(`Remaining Balance: $${GcnHelper.stringFromTotal(unrefundedAmount)}`);
    }

    const amountDetails = [];
    if (tenders.length === 1) {
      amountDetails.push(`Amount: $${GcnHelper.stringFromTotal(tenders[0].amount)}`);
    } else {
      _.each(tenders, (tender) => {
        amountDetails.push(
          `${tender.cardSchemeName}: $${GcnHelper.stringFromTotal(tender.amount)}`,
        );
      });
    }

    this.$el.html(
      this.template({
        refundAmount: GcnHelper.stringFromTotal(unrefundedAmount),
        details: [
          paymentDetails.join(' '),
          amountDetails.join('<br />'),
          `Reference: ${transaction.get('clientId')}`,
        ],
        refunds: refundLines,
        canBeRefunded: order.canRefundTransaction(transaction),
        canBePartiallyRefunded: GcnPaymentHelper.gatewaySupportsPartialRefunds(
          transaction.get('gateway'),
        ),
      }),
    );

    this._$refundAmountInput = this.$('.refund-amount');
    this.$('.refund-button').click(this._refundButtonWasTapped.bind(this));

    return this;
  },
});
