import { GCNSimpleLoyaltyQrCodeSignupView } from './gcn_simple_loyalty_qrcode_signup_view';
import { GCNSimpleLoyaltySmsSignupView } from './gcn_simple_loyalty_sms_signup_view';
import { GCNView } from './gcn_view';

export const GCNSimpleLoyaltySignupView = GCNView.extend({
  className: 'simple-loyalty-signup-view',

  render() {
    // Loyalty Signup views is driven by `enableSimpleLoyaltySignup` and that is set
    // if location's setting has either `simpleSignupSmsTemplate` with `Twilio` for Phone Signup
    // or the location's setting contains `loyaltySignUpQrCodeImage` for QR Signup
    const locationHasQrSignup = gcn.loyaltyManager.hasLoyaltySignUpQrCodeImages();
    const locationHasSmsSignup = gcn.loyaltyManager.hasLoyaltySmsSignUp();

    // first preference is given to the phone number signup view
    if (locationHasSmsSignup) {
      this.$phoneNumberSignUpView = new GCNSimpleLoyaltySmsSignupView();
      this.$el.append(this.$phoneNumberSignUpView.render().$el);
      return this;
    }

    if (locationHasQrSignup) {
      this.$qrCodeSignUpView = new GCNSimpleLoyaltyQrCodeSignupView();
      this.$el.append(this.$qrCodeSignUpView.render().$el);
      return this;
    }

    return this;
  },
});
