import * as React from 'react';

export function AccessibilityIcon(props: React.ComponentProps<'svg'>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.0"
      viewBox="0 0 144 144"
      fill="currentColor"
      stroke="currentColor"
      {...props}
    >
      <path d="M103.2 2C99 4.6 96 9.6 96 14s3 9.4 7.2 12c6.4 3.9 14.6 1.6 18.8-5.2 7.1-11.7-7.1-25.9-18.8-18.8zM51.8 11.8C32.4 28 29.3 32 32.1 37.2c1.8 3.5 4 4.7 8.1 4.8 3.3 0 4.8-1 12-7.4l8.3-7.4 4.5 2.6c7.9 4.5 8.1 3.7-3.1 16.4-9.7 11.1-10.4 11.7-16.7 13.7-3.5 1.2-8.9 3.8-11.9 5.8l-5.4 3.6 5.6 5.7 5.7 5.8 4.8-3c9.5-5.9 22.9-4.9 32 2.3 11.8 9.3 13.9 26.6 4.8 39.1-1.1 1.5-.6 2.5 4.1 7.2 3 3 5.9 5.2 6.4 4.9.5-.3 2.6-3.2 4.7-6.5 6.9-10.5 8.6-25.2 4.5-36.9-.8-2.3-1.2-4.5-.9-5 .3-.5 2.8-.9 5.5-.9 5 0 5.1 0 4.5 2.8-.3 1.5-1 9.8-1.7 18.5-1.1 15.3-1.1 15.9.8 18.5 2.9 4 6.5 4.9 10.7 2.9 4.6-2.1 5-3.9 7.1-31.4 2.4-30.6 2.9-30-21.6-28.5-8.5.5-15.5.8-15.6.8-.1-.1 3.4-4.2 7.8-9.1 4.4-5 8.7-10.3 9.5-11.9 2-3.6 1.1-8.6-1.9-11.3-4.3-3.9-41.2-24.5-44.7-25-3-.4-4.2.2-8.2 3.5zM22.5 76.5c-8.3 14.1-8.6 30.3-.8 44.7 3.1 5.7 12.3 14.7 17.9 17.6 14.2 7.2 30.2 6.8 43.9-1.3l4.7-2.7-5.7-5.8-5.8-5.9-4.1 2.2c-5.7 3-17.2 3-23.7.1-5.8-2.7-11.6-8.5-14.3-14.3-2.9-6.5-2.9-18 .1-23.7l2.2-4.1-5.9-5.8-5.8-5.7-2.7 4.7z" />
    </svg>
  );
}
