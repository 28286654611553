import { IonItem, IonLabel, IonList } from '@ionic/react';
import * as React from 'react';

import { IntegrationSystem } from '@biteinc/enums';

import type { CheckoutState } from '~/stores/checkout.store';

import type { Store } from '../stores';
import { useLocation, useSettings } from '../stores';
import type { EcommI9nConfig, KioskMenuSettings } from '../types';
import { Coupon } from './coupon';
import { FreedomPayEcommForm } from './ecomm-payment/freedom-pay/freedom-pay-ecomm-form';
import { StripeEcommForm } from './ecomm-payment/stripe/stripe-ecomm-form';
import SubmitOrderButton from './ecomm-payment/submit-order-button';
import { Loyalty } from './loyalty';
import { OrderTotals } from './order-totals';
import StoredValuePanel from './stored-value-panel';
import { TipSelector } from './tip-selector';

type PaymentDetailsProps = {
  ecommI9nConfig: EcommI9nConfig;
  onSubmit(): void;
  checkoutState: Store['checkout'];
  loyaltyState: Store['loyalty'];
};

export function PaymentDetails(props: PaymentDetailsProps): JSX.Element {
  const location = useLocation();
  const { order, transactions } = props.checkoutState;
  const { wasValidated } = order;

  const tippableTotal = gcn.orderManager.getTippableTotal();
  const tipsEnabled = tippableTotal && !!location.tipsLevel;
  const orderIsPartiallyPaid = transactions.length > 0;

  return (
    <div className="payment-details">
      <IonList>
        <IonItem
          lines="none"
          className="payment-details-header"
        >
          <IonLabel className="title">
            <h3>Payment Details</h3>
          </IonLabel>
        </IonItem>
      </IonList>
      {wasValidated && tipsEnabled ? (
        <TipSelector
          tippableTotal={tippableTotal}
          selectedTipAmount={
            props.checkoutState.order.wasValidated ? props.checkoutState.order.tipTotal || 0 : 0
          }
          presetTips={location.presetTips}
        />
      ) : undefined}
      {wasValidated && (
        <Loyalty
          orderIsPartiallyPaid={orderIsPartiallyPaid}
          location={location}
          {...props.loyaltyState}
        />
      )}
      {wasValidated && (
        <Coupon
          orderIsPartiallyPaid={orderIsPartiallyPaid}
          couponApplied={props.loyaltyState.couponApplied}
        />
      )}
      {wasValidated && (
        <StoredValuePanel
          location={location}
          transactions={transactions}
          submitOrder={props.onSubmit}
        />
      )}
      {wasValidated && (
        <EcommPaymentForm
          ecommI9nConfig={props.ecommI9nConfig}
          onSubmit={props.onSubmit}
          checkoutState={props.checkoutState}
        />
      )}
    </div>
  );
}

function OrderDisclaimerAndTotals(params: {
  settings: KioskMenuSettings;
  order: CheckoutState['order'];
  transactions: CheckoutState['transactions'];
}): JSX.Element {
  const { settings, order, transactions } = params;
  return (
    <div className="tw-flex tw-flex-col tw-gap-2">
      {settings.checkoutDisclaimer ? (
        <p
          className="tw-text-lg tw-py-2 tw-border-t tw-border-b tw-border-[var(--color-light)]"
          style={{
            fontFamily: 'var(--ion-font-family)',
          }}
        >
          {settings.checkoutDisclaimer}
        </p>
      ) : null}

      <OrderTotals
        order={order}
        transactions={transactions}
      />
    </div>
  );
}

function EcommPaymentForm(props: {
  ecommI9nConfig: EcommI9nConfig;
  onSubmit(): void;
  checkoutState: Store['checkout'];
}): JSX.Element | null {
  const location = useLocation();
  const settings = useSettings();
  const outstandingAmount = gcn.orderManager.getGrandTotal();
  const requiresEcommPayment = outstandingAmount > 0;
  const isReadyToSubmitOrder = !requiresEcommPayment || props.checkoutState.ecommPayment.isValid;
  const orderTotalsComponent = (
    <OrderDisclaimerAndTotals
      settings={settings}
      order={props.checkoutState.order}
      transactions={props.checkoutState.transactions}
    />
  );
  switch (props.ecommI9nConfig.system) {
    case IntegrationSystem.FreedomPayFlash:
      if (!requiresEcommPayment) {
        return (
          <SubmitOrderButton
            enabled={!!props.checkoutState.order.wasValidated}
            onClick={() => {
              props.onSubmit();
            }}
          />
        );
      }

      return (
        <FreedomPayEcommForm
          ecommI9nConfig={props.ecommI9nConfig}
          onSubmit={props.onSubmit}
          orderTotalsComponent={orderTotalsComponent}
          checkoutState={props.checkoutState}
          isReadyToSubmit={isReadyToSubmitOrder}
          requiresEcommPayment={requiresEcommPayment}
          location={location}
        />
      );
    case IntegrationSystem.Stripe:
      return (
        <StripeEcommForm
          ecommI9nConfig={props.ecommI9nConfig}
          onSubmit={props.onSubmit}
          orderTotalsComponent={orderTotalsComponent}
          checkoutState={props.checkoutState}
          isReadyToSubmit={isReadyToSubmitOrder}
          requiresEcommPayment={requiresEcommPayment}
          location={location}
        />
      );
    case IntegrationSystem.DatatransOloDeprecated:
    case IntegrationSystem.DatatransPinataDeprecated:
      throw new Error(`Unsupported ecomm integration system: ${props.ecommI9nConfig.system}`);
  }
}
