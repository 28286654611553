import $ from 'jquery';
import _ from 'underscore';

import { Strings } from '@biteinc/common';
import { BitePlatform } from '@biteinc/enums';

import { BackboneEvents } from '~/app/js/backbone-events';
import { localizeStr } from '~/app/js/localization/localization';

import { POPUP_VIEW_ENTER, POPUP_VIEW_EXIT } from '../../../helpers/custom_events';
import { GCNView } from './gcn_view';

const popupMarginDefault = 40;
const popupMarginMobile = 10;

/**
 * @description
 * This view encapsulates popup rendering logic. Supports different styles of popup, such as
 * positioned popups and undismissable modals.
 * To use this class, create and render it, and call setBoundingRect to constrain it to a showable
 * area. Then call show() with the content to display it in the popup - this can be called
 * multiple times with different content.
 */
export const GCNPopup = GCNView.extend({
  className() {
    return 'touch-blocker-overlay';
  },

  initialize(options, ...args) {
    GCNView.prototype.initialize.apply(this, [options, ...args]);

    this.popupId = `${Date.now()}${Math.random()}`;

    this._options = options || {};

    const evtName = gcn.isTouchDevice() && navigator.platform !== 'Win32' ? 'touchstart' : 'click';
    this.$el.on(evtName, (e) => {
      const $target = $(e.target);
      if ($target.is(this.$el)) {
        this._attemptToDismiss();
      } else if (this._options.dismissOnTapAnywhere) {
        this.dismiss();
      }
    });
  },

  _attemptToDismiss() {
    if ((this._contentView || {}).overlayWasClicked) {
      this._contentView.overlayWasClicked();
    } else if (this._options.dismissOnTapOutside || this._options.dismissOnTapAnywhere) {
      this.dismiss();
    }
  },

  /**
   * @description Set the bounds that the popup content must appear within.
   * This must be called once, after the popup is rendered in the DOM where it will overlay other
   * content, and before calling show to display content.
   * @param {Object} rect An object with Rect values (x, y, width, height). This is the containing
   * bounds for the popup.
   */
  setBoundingRect(rect) {
    this._boundingRect = rect;

    // The root $el is the max constraint of the popup area, and _$popup is the actual UI surface
    // for the contents, so set maximums to prevent the content from spilling out.
    this._$popup.css('max-width', rect.width);
    this._$popup.css('max-height', rect.height);
  },

  _contentHidesCloseLabel() {
    return (
      this._contentView &&
      this._contentView.shouldHideCloseLabel &&
      this._contentView.shouldHideCloseLabel()
    );
  },

  _alignCloseLabel() {
    if (!this._$closeLabel) {
      return;
    }

    if (GCNPopup.shouldUseCloseButton() || this._contentHidesCloseLabel()) {
      this._$closeLabel.hide();
    } else {
      this._$closeLabel.show();
      const popupMaxY = this._$popup.position().top + this._$popup.height();
      this._$closeLabel.css('top', popupMaxY);
      const left =
        this._$popup.position().left +
        (this._$popup.outerWidth() - this._$closeLabel.outerWidth()) / 2;
      this._$closeLabel.css('left', `${left}px`);
    }
  },

  /**
   * @description Set and constrain a DOM element within the popup.
   * @param {HTMLElement} $content The element to set.
   */
  _setContent($content) {
    this._$popup.html('<div class="popup-inner"></div>');

    // Must clear all these adjustable properties between setting different content.
    this._$popup.css('width', '');
    this._$popup.css('left', '');
    this._$popup.css('right', '');
    this._$popup.css('top', '');
    this._$popup.find('.popup-inner').html($content);

    // Constrain the content to the popup so it doesn't overflow horizontally.
    $content.css('max-width', this.$el.width());
    // To ensure that the content can scroll vertically
    const heightToDeduct = 64 + 80; // cart-top-bar-height, bottom-bar-height
    $content.css('max-height', `${$('body').height() - (popupMarginDefault + heightToDeduct)}px`);
  },

  _destroyContentView() {
    if (this._contentView) {
      this._contentView.destroy();
      this.stopListening(this._contentView);
      this._contentView = null;
    }
  },

  /**
   * @description Displays this popup with the provided content.
   * @param {View} contentView The view instance, not yet rendered, to display in the popup.
   * @param {Object} alignmentCss Object with positioning css properties (same as for an element
   * with position set to absolute: top, left, right). Used to position the content, anchored to
   * some point. The css should be relative to the rect set by setBoundingRect.
   * This call will automatically re-position the popup so it fits within the bounds set by
   * setBoundingRect.
   */
  show(contentView, alignmentCss, customClassName) {
    document.dispatchEvent(
      new CustomEvent(POPUP_VIEW_ENTER, {
        detail: {
          id: this.popupId,
        },
      }),
    );
    const self = this;

    if (this._customClassName) {
      // If the popup already has a custom class, then it is a remnant from a previous popup that
      // wasn't dismissed properly. Remove it.
      this.$el.removeClass(this._customClassName);
    }

    this._customClassName = customClassName;
    if (this._customClassName) {
      this.$el.addClass(this._customClassName);
    }

    // Wait for our parent to tell us we're ready to be shown.
    // TODO: Untangle this from menuView.
    if (!this._boundingRect) {
      this.listenToOnce(gcn.menuView, BackboneEvents.GCNMenuView.MenuViewReady, () => {
        self.show(contentView, alignmentCss);
      });
      return;
    }

    this._contentView = contentView;
    const $content = this._contentView.render().$el;
    this.listenTo(
      this._contentView,
      BackboneEvents.GCNView.DidChangeContent,
      this._alignCloseLabel,
    );

    this._setContent($content);

    if (
      this._options.showsCloseLabel &&
      GCNPopup.shouldUseCloseButton() &&
      !this._contentHidesCloseLabel()
    ) {
      const $closeButton = $('<div class="close-button">×</div>');
      this._$popup.append($closeButton);
      $closeButton.onButtonTapOrHold('popupClose', () => {
        self._attemptToDismiss();
      });
    }

    if (!gcn.isTouchDevice()) {
      // Constrain the popup to variable body screen heights, mostly for desktop.
      const heightToDeduct = 64 + 80; // cart-top-bar-height, bottom-bar-height
      this._$popup
        .find('.popup-inner')
        .css('max-height', `${$('body').height() - (popupMarginDefault + heightToDeduct)}px`);
    }

    this.trigger(BackboneEvents.GCNPopup.PopupWillAppear, this);

    this.$el.prepareCssFadeIn('flex');

    _.each(alignmentCss || {}, (value, key) => {
      self._$popup.css(key, value);
    });

    // If the popup is to be positioned, fit the popup within the constraints of the rect.
    const boundingRect = this._boundingRect;
    if (Object.keys(alignmentCss || {}).length) {
      const popupMaxX = this._$popup.offset().left + this._$popup.width();
      const frameMaxX = boundingRect.x + boundingRect.width;
      const popupMaxY = this._$popup.offset().top + this._$popup.height();
      const frameMaxY = boundingRect.y + boundingRect.height;
      const popupMargin = gcn.isMobile() ? popupMarginMobile : popupMarginDefault;

      // Reposition the popup if it goes offscreen to the right.
      if (!alignmentCss.right && popupMaxX > frameMaxX) {
        let left = boundingRect.width - this._$popup.width() - popupMargin;
        if (left < popupMargin) {
          left = popupMargin;
          this._$popup.css('width', boundingRect.width - popupMargin * 2);
        }
        // Set the left position so it's centered.
        this._$popup.css('left', left);
      }

      // Reposition the popup if it goes offscreen past the top.
      if (this._$popup.offset().top < boundingRect.y + popupMargin) {
        // TODO: what's the real value of --bottom-bar-space instead of 130
        // We need to decide the max value in JS because css max()
        // function isn't available until Chrome 79.
        this._$popup.css('top', `${Math.max(boundingRect.y - popupMargin - 130, 0)}px`);
      }

      // Reposition the popup if it goes offscreen through the bottom.
      if (popupMaxY > frameMaxY) {
        // TODO: what's the real value of --bottom-bar-space instead of 130
        // We need to decide the max value in JS because css max()
        // function isn't available until Chrome 79.
        this._$popup.css(
          'top',
          `${Math.max(frameMaxY - this._$popup.height() - popupMargin - 130, 0)}px`,
        );
      }
    }

    // Assign the content background color to the popup.
    // This color will be seen when the user drags content down.
    if ($content.css) {
      const backgroundColor = $content.css('background-color');
      if (backgroundColor) {
        this._$popup.css('background-color', backgroundColor);
      }
    }

    this._alignCloseLabel();

    this.$el.cssFadeIn('flex', () => {
      if (gcn.menuView.showingMaxOverlay()) {
        gcn.menuView.removeOverlay();
      }
      if (this._options.onTransitionEnd) {
        this._options.onTransitionEnd();
      }
    });
    this._$popup.focus();
  },

  isShown() {
    return this.$el?.css('display') !== 'none';
  },

  _showDiningOptionModal() {
    this.$el.toggleClass('dining-option', true);
    this._$popup.toggleClass('showing-title', true);

    const isDesktop = window.isFlash && window.width > 600;
    const diningOptionBackgroundImageUrl = gcn.menu.getDiningOptionBackgroundImageUrl(isDesktop);
    if (diningOptionBackgroundImageUrl) {
      gcn.requestImageByUrl(diningOptionBackgroundImageUrl, (err, imgPath) => {
        if (err) {
          return;
        }
        this.$el.css('background-image', `url(${imgPath})`);
      });
    }

    this._$title = $('<div class="overlay-logo"></div>');
    this.$el.append(this._$title);
    const titleBarImage = gcn.menu.getTitleBarImage();
    if (titleBarImage && titleBarImage.url) {
      gcn.requestImageByUrl(titleBarImage.url, (err, imgPath) => {
        this.$('.overlay-logo').css('background-image', `url(${imgPath})`);
      });
    } else if ((gcn.menu.getMenuTitle() || '').length) {
      this.$('.overlay-logo').text(gcn.menu.getMenuTitle());
    } else {
      this.$('.overlay-logo').text(gcn.location.get('orgName'));
    }
    this.$el.append(this._$popup);
  },

  /**
   * @description Adjusts popup position based on virtual keyboard presence.
   * Behavior is active in scenarios where a virtual keyboard is present
   * Includes: physical kiosk, kiosk emulator
   * Excludes: flash, kiosk-preview
   * @param {Boolean} isKeyboardOpen if true, popup will return to original position, otherwise
   * we add the transition class
   */
  adjustPopupForKeyboard(isKeyboardOpen) {
    // BITE-7115: Keep popup in place when keyboard is open for Tizen based kiosks to compensate
    // for tiny keyboard and tall screen, until we know the height of the virtual keyboard (Vitrine).
    if (
      gcn.isTouchDevice() &&
      !window.isFlash &&
      window.platform !== BitePlatform.KioskSignageOsGarcon
    ) {
      const activeElement = document.activeElement;
      const inputs = ['input', 'textarea'];

      // BITE-7257: need to check if the active element is the special requests input
      // if not, we don't need to adjust the popup
      const activeElementIsSpecialRequestsInput = document.activeElement?.id === 'special-request';

      if (isKeyboardOpen) {
        // If the keyboard is open, we need to adjust the popup if the active element is not an input
        if (activeElement && inputs.indexOf(activeElement.tagName.toLowerCase()) === -1) {
          this.setPopupTopPosition('');
        }
      }
      // If the keyboard is closed, we need to adjust the popup if the active element is an input
      else if (
        activeElementIsSpecialRequestsInput &&
        inputs.indexOf(activeElement.tagName.toLowerCase()) !== -1
      ) {
        this.setPopupTopPosition(0);
      }
    }
  },

  /**
   * @description Sets the top position of the popup and adjusts the close label position.
   */
  setPopupTopPosition(topPosition) {
    if (this._$closeLabel) {
      this._$closeLabel.hide();
    }
    this._$popup.css('top', topPosition);

    setTimeout(() => {
      this._alignCloseLabel();
    }, 500);
  },

  /**
   * @description Hides this popup with an animation.
   */
  dismiss(callback) {
    document.dispatchEvent(
      new CustomEvent(POPUP_VIEW_EXIT, {
        detail: {
          id: this.popupId,
        },
      }),
    );

    this.trigger(BackboneEvents.GCNPopup.PopupWillDisappear, this);
    const self = this;

    this._destroyContentView();

    // apply a dismiss overlay to prevent any touch events from being triggered
    gcn.menuView.addOverlay();
    this.$el.cssFadeOut(() => {
      self._$popup.html('');
      setTimeout(() => {
        if (this._customClassName) {
          this.$el.removeClass(this._customClassName);
          this._customClassName = null;
        }
        gcn.menuView.removeOverlay();

        if (callback) {
          callback();
        }
      }, 50);
    }, true);
  },

  /**
   * @description Hides this popup immediately.
   */
  hideUnanimated() {
    document.dispatchEvent(
      new CustomEvent(POPUP_VIEW_EXIT, {
        detail: {
          id: this.popupId,
        },
      }),
    );
    this.trigger(BackboneEvents.GCNPopup.PopupWillDisappear, this);

    this._destroyContentView();
    if (this._$popup) {
      this._$popup.html('');
    }
    if (this._customClassName) {
      this.$el.removeClass(this._customClassName);
      this._customClassName = null;
    }
    this.$el.hide();
  },

  render() {
    const self = this;
    this._$popup = $('<div class="popup" role="dialog"></div>');

    // Dining option modals may have a overlay background that is stored on the locations
    // appearance settings. There is also a title image to be used which, if not available,
    // is replaced with text, similar to the navbar title image.
    if (this._options.isDiningOptionModal && gcn.menu.settings.get('useNewFulfillmentModalView')) {
      this._showDiningOptionModal();
    } else {
      this.$('.touch-blocker-overlay').toggleClass('dining-option', false);
      this.$el.html(this._$popup);
    }

    if (this._options.showsCloseLabel) {
      this._$closeLabel = $(
        `<div class="close-label font-body" role="button" aria-hidden="true">${localizeStr(
          Strings.TAP_OUTSIDE_CLOSE,
        )}</div>`,
      );
      this.$el.append(this._$closeLabel);

      this._$popup.resize(() => {
        self._alignCloseLabel();
      });
    }

    this._$popup.focusin(() => {
      self.adjustPopupForKeyboard(false);
    });

    this._$popup.focusout(() => {
      setTimeout(() => {
        self.adjustPopupForKeyboard(true);
      }, 150);
    });

    return this;
  },
});

GCNPopup.shouldUseCloseButton = function shouldUseCloseButton() {
  // !isTouch - Pointer (often desktop) devices should always show the familiar close button.
  // mobile - Actual mobile devices prefer it as well.
  // What's left are kiosk devices where reaching up high sucks and it's convenient to tap away
  // on the scrim.
  return !gcn.isTouchDevice() || gcn.isMobile() || gcn.menu.settings.get('usePopupCloseButton');
};

export const GCNSpinnerView = GCNView.extend({
  setImageClass(imageClass) {
    this.$('.loader').hide();
    this.$('.spinner-image').toggleClass(imageClass, true);
  },

  setMessage(msg) {
    this.$('.message').html(msg);
  },

  render() {
    this.$el.html(
      // prettier-ignore
      `<div class="loader"></div>` +
      `<div class="spinner-image"></div>` +
      `<div class="message">${this.msg}</div>`,
    );
    return this;
  },
});

export const GCNSpinner = GCNPopup.extend({
  className(...args) {
    const className = GCNPopup.prototype.className.apply(this, args);
    return `${className} spinner`;
  },

  show(msg) {
    this.spinnerView = new GCNSpinnerView();
    this.spinnerView.msg = msg;
    return GCNPopup.prototype.show.apply(this, [this.spinnerView]);
  },

  setMessage(msg) {
    if (this.spinnerView) {
      this.spinnerView.setMessage(msg);
    }
  },

  isShowingMessage(msg) {
    return this.isShown() && this.spinnerView?.msg === msg;
  },

  setImageClass(imageClass) {
    if (this.spinnerView) {
      this.spinnerView.setImageClass(imageClass);
    }
  },

  render() {
    this._$popup = $('<div class="spinner-overlay"></div>');
    this.$el.html(this._$popup);
    return this;
  },
});
