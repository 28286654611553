import { LocationUtils } from '~/helpers';
import type { Store } from '~/stores';
import { useSettings, useStore } from '~/stores';

export module BottomBarUtils {
  export const bottomBarVariants = {
    visible: {
      opacity: 1,
      y: 0,
    },
    hidden: {
      opacity: 0,
      y: '100%',
    },
  };

  export const totalsVariants = {
    visible: {
      opacity: 1,
      x: 0,
    },
    hidden: {
      opacity: 0,
      x: '100%',
    },
  };

  export type CartBottomBarProps = {
    order: Store['cart'];
    config: Store['config'];
  };

  export function useShouldHideBottomBar(
    props: CartBottomBarProps & {
      inCheckoutView: boolean;
      inNavigationView: boolean;
      inFSF: boolean;
    },
  ): boolean {
    const settings = useSettings();
    const config = useStore((state) => state.config);

    const hasBottomBarButtons = LocationUtils.hasBottomBarButtons(
      settings,
      config.screenReaderIsActive,
    );

    const shouldHide =
      !hasBottomBarButtons &&
      (props.order.orderedItems.length === 0 || props.inCheckoutView || props.inNavigationView);

    return shouldHide;
  }
}
