import { IonCol, IonImg, IonRow } from '@ionic/react';
import React from 'react';
import ReactDOM from 'react-dom/client';

import GcnView from './gcn_view_ts';

export default class GcnAdaInstructionsView extends GcnView {
  className = 'ada-instructions-view';

  root: ReactDOM.Root;

  destroy(): void {
    super.destroy();

    this.root.unmount();
  }

  render(): this {
    this.root = ReactDOM.createRoot(this.$el[0]);
    this.root.render(
      React.createElement(
        'div',
        { className: 'ada-instructions-view' },
        React.createElement(
          IonCol,
          {},
          React.createElement(
            IonRow,
            {},
            React.createElement(IonImg, {
              className: 'instruction-image',
              src: 'https://assets.getbite.com/images-default/ada-zoom-pinch.png',
            }),
          ),
          React.createElement(
            IonRow,
            { className: 'line-separated' },
            React.createElement('p', { className: 'instruction-text' }, 'Pinch to zoom'),
          ),
          React.createElement(IonRow, { className: 'break' }),
          React.createElement(
            IonRow,
            {},
            React.createElement(IonImg, {
              className: 'instruction-image',
              src: 'https://assets.getbite.com/images-default/ada-zoom-two-fingers-move.png',
            }),
          ),
          React.createElement(
            IonRow,
            { className: 'line-separated' },
            React.createElement(
              'p',
              { className: 'instruction-text' },
              'Drag with two fingers to move around',
            ),
          ),
          React.createElement(IonRow, { className: 'break' }),
          React.createElement(
            IonRow,
            {},
            React.createElement(IonImg, {
              className: 'instruction-image',
              src: 'https://assets.getbite.com/images-default/ada-zoom-magnify-reference.png',
            }),
          ),
          React.createElement(
            IonRow,
            {},
            React.createElement(
              'p',
              { className: 'instruction-text bottom' },
              'Tap the zoom icon again to exit',
            ),
          ),
        ),
      ),
    );
    return this;
  }
}
