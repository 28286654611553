// a lot of cyclical dependencies are resolved by using this file
export const BackboneEvents = {
  GCNMenuItemOrderView: {
    DonePressed: 'donePressed',
    CancelPressed: 'cancelPressed',
    OverlayPressed: 'overlayPressed',
  },
  GCNAddonSetPickerView: {
    AddonTextChanged: 'addonTextChanged',
    AddonOptionCodeSelectionComplete: 'addonOptionCodeSelectionComplete',
    AddonOptionSelectionComplete: 'addonOptionSelectionComplete',
    AddonOptionCodeSelectionUnset: 'addonOptionCodeSelectionUnset',
    NestedPickerViewShown: 'nestedPickerViewShown',
    AddonSelectionComplete: 'addonSectionComplete',
    AddonSetDidChange: 'addonSetDidChange',
    AddonErrorMessageShown: 'addonErrorMessageShown',
    NestedPickerNewPageAdded: 'nestedPickerNewPageView',
    NestedPickerNewPageRemoved: 'nestedPickerNewPageRemoved',
  },
  GCNAddonTextFieldView: {
    TextChanged: 'textChanged',
  },
  GCNConfirmationStepView: {
    QuickCheckoutButtonWasTapped: 'quickCheckoutButtonWasTapped',
  },
  GCNCustomizeFlowView: {
    QuickCheckoutButtonWasTapped: 'quickCheckoutButtonWasTapped',
  },
  GCNFulfillmentMethodPickerView: {
    DidCancelPickingFulfillmentMethod: 'didCancelPickingFulfillmentMethod',
    DidPickFulfillmentMethod: 'didPickFulfillmentMethod',
  },
  GcnFutureOrderPickerView: {
    BackedOut: 'backedOut',
    DidPickAsapOrder: 'didPickAsapOrder',
    DidPickFutureOrder: 'didPickFutureOrder',
  },
  GCNGuestNameEntryView: {
    DidEnterName: 'didEnterName',
    BackedOut: 'backedOut',
  },
  GCNLoyaltyBarcodeView: {
    DidUpdateAuthGuest: 'didUpdateAuthGuest',
  },
  GCNLoyaltyManager: {
    DidUpdateAuthGuest: 'didUpdateAuthGuest',
    DidFailAuth: 'didFailAuth',
    DidFetchRewards: 'didFetchRewards',
    DidFetchRewardsNoOrder: 'didFetchRewardsNoOrder',
    DidFailFetchRewards: 'didFailFetchRewards',
    DidApplyReward: 'didApplyReward',
    DidFailApplyReward: 'didFailApplyReward',
    DidRemoveReward: 'didRemoveReward',
    DidFailRemoveReward: 'didFailRemoveReward',
  },
  GCNLoyaltyManualAuthView: {
    DidUpdateAuthGuest: 'didUpdateAuthGuest',
  },
  GCNMarketingConsentView: {
    DidUpdateValue: 'didUpdateValue',
  },
  GCNMenuAppView: {
    AmountConfirmationWasStarted: 'amountConfirmationWasStarted',
    CardWasSwiped: 'cardWasSwiped',
    CoverWasOpened: 'coverWasOpened',
    LocalPrintDidFail: 'localPrintDidFail',
    LocationDidUpdate: 'locationDidUpdate',
    MenuDidUpdate: 'menuDidUpdate',
    OrderDidChange: 'orderDidChange',
    OrderDidComplete: 'orderDidComplete',
    OrderFailedToGet: 'orderFailedToGet',
    PaymentFlowDidStart: 'paymentFlowDidStart',
    PaymentProcessingDidStart: 'paymentProcessingDidStart',
    RefundDidFail: 'refundDidFail',
    RefundDidFailToPrintReceipt: 'refundDidFailToPrintReceipt',
    RefundDidFinish: 'refundDidFinish',
    RefundDidStartPrintingReceipt: 'refundDidStartPrintingReceipt',
    ScaleDidReadData: 'scaleDidReadData',
    SendingOrderDidStart: 'sendingOrderDidStart',
    SmartCardWasInserted: 'smartCardWasInserted',
    TransactionDidCancel: 'transactionDidCancel',
  },
  GCNMenuItemCustomizeView: {
    ComboButtonTapped: 'comboButtonTapped',
    ContentsExpanded: 'contentsExpanded',
    SelectedRecosChanged: 'selectedRecosChanged',
    AddonSetSelectionComplete: 'addonSetSelectionComplete',
    AddonOrPriceOptionChanged: 'addonOrPriceOptionChanged',
    SpecialRequestTextChanged: 'specialRequestTextChanged',
  },
  GCNMenuPageView: {
    DidSelectMenuPageId: 'DidSelectMenuPageId',
    DidScrollSectionIntoView: 'didScrollSectionIntoView',
  },
  GCNMenuQuickNavView: {
    DidSelectMenuSection: 'didSelectMenuSection',
  },
  GCNMenuView: {
    MenuViewReady: 'menuViewReady',
  },
  GCNNumberEntryView: {
    ValueUpdated: 'valueUpdated',
  },
  GCNNumberPadView: {
    DigitWasPressed: 'digitWasPressed',
    DeleteWasPressed: 'deleteWasPressed',
  },
  GCNOrderCellView: {
    CancelOrderButtonWasTapped: 'cancelOrderButtonWasTapped',
    ReceiptButtonWasTapped: 'receiptButtonWasTapped',
    RefundButtonWasTapped: 'refundButtonWasTapped',
  },
  GCNOrderManager: {
    OrderDidChange: 'orderDidChange',
    OrderDidValidate: 'orderDidValidate',
    OrderDidValidateWithChanges: 'orderDidValidateWithChanges',
    OrderDidFailValidation: 'orderDidFailValidation',
    OrderDidFailCreation: 'orderDidFailCreation',
    TipDidValidate: 'tipDidValidate',
    TipDidFailValidation: 'tipDidFailValidation',
    EmailReceiptDidSend: 'emailReceiptDidSend',
    EmailReceiptFailedSend: 'emailReceiptFailedSend',
    TextReceiptDidSend: 'textReceiptDidSend',
    TextReceiptFailedSend: 'textReceiptFailedSend',
    CouponDidValidate: 'couponDidValidate',
    CouponDidFailValidation: 'couponDidFailValidation',
    AboutToClearCheckout: 'aboutToClearCheckout',
    CustomerDoesExist: 'customerDoesExist',
    TransactionsDidSet: 'TransactionsDidSet',
    RevertOrderedItems: 'revertOrderedItems',
    FulfillmentDidChange: 'fulfillmentDidChange',
  },
  GCNOutpostPickerView: {
    BackedOut: 'backedOut',
    DidPickOutpost: 'didPickOutpost',
    DidEnterRoom: 'didEnterRoom',
  },
  GCNPageNavigationView: {
    DidSelectMenuPageId: 'DidSelectMenuPageId',
    DidSelectSectionId: 'DidSelectSectionId',
  },
  GCNPaymentTypePickerView: {
    DidPickPaymentType: 'didPickPaymentType',
    BackedOut: 'backedOut',
  },
  GCNPopup: {
    PopupWillAppear: 'PopupWillAppear',
    PopupWillDisappear: 'PopupWillDisappear',
  },
  GCNQuantitySelectionView: {
    QuantityChanged: 'quantityChanged',
    IncrementPressed: 'incrementPressed',
    DecrementPressed: 'decrementPressed',
  },
  GCNRecommendationsView: {
    SelectedItemsChanged: 'selectedItemsChanged',
    ProceedToNextStep: 'proceedToNextStep',
  },
  GCNScrollingNavView: {
    DidSelectMenuPageId: 'DidSelectMenuPageId',
    DidSelectSectionId: 'didSelectSectionId',
    DidScrollMenuPage: 'DidScrollMenuPage',
  },
  GCNSimpleCover: {
    IntroImageDisappear: 'IntroImageDisappear',
  },
  GCNSimpleLoyaltySignupView: {
    SmsInvitationWasSent: 'smsInvitationWasSent',
  },
  GCNSmsPromoView: {
    DidCompleteLookup: 'didCompleteLookup',
  },
  GCNSpecialRequestView: {
    SubmitButtonPressed: 'submitButtonPressed',
    SpecialRequestTextChanged: 'specialRequestTextChanged',
  },
  GCNTableNumberEntryView: {
    DidEnterTableNumber: 'didEnterTableNumber',
    BackedOut: 'backedOut',
  },
  GCNTextWhenReadyView: {
    CompletedFlow: 'completedFlow',
    BackedOut: 'backedOut',
  },
  GCNTopNavView: {
    DidTapBackButton: 'didTapBackButton',
  },
  GCNTransactionCellView: {
    RefundButtonWasTapped: 'refundButtonWasTapped',
  },
  GCNUpsellInterstitialView: {
    DonePressed: 'donePressed',
  },
  GCNVehicleDescriptionEntryView: {
    DidEnterVehicleDescription: 'didEnterVehicleDescription',
    BackedOut: 'backedOut',
  },
  GCNView: {
    DidChangeContent: 'DidChangeContent',
  },
};
