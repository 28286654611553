import $ from 'jquery';

import { TimeHelper } from '@biteinc/core-react';

import pkg from '../../../../package.json';
import { GCNView } from './gcn_view';

export const GCNBiteLogoView = GCNView.extend({
  className: 'bite-logo-container show-logo',

  _touchCalibrationDebugWindowHtml:
    // prettier-ignore
    '<div id="touch-calibration-debug-window">' +
      '<pre class="log-pane"></pre>' +
      '<div class="close-button">Close Me</div>' +
    '</div>',

  initialize(...args) {
    GCNView.prototype.initialize.apply(this, ...args);

    this._clickCount = 0;
    this._logoTimeout = 0;
  },

  _startTimeout() {
    clearTimeout(this._logoTimeout);
    this._logoTimeout = setTimeout(() => {
      this._restoreToOriginalState();
    }, 60 * TimeHelper.SECOND);
  },

  _restoreToOriginalState() {
    this._clickCount = 0;
    clearTimeout(this._logoTimeout);
    this.$logo.show();
    this.$debugPanel.hide();
    this.$('.touch-calibration-controls').hide();
  },

  _updateDurationLabelWithValue() {
    this.$('label.touch-duration').text(
      `Max Touch Duration: ${gcn.touchCalibrationConfig.minTouchDurationThresholdForClick}ms`,
    );
  },

  _updateXYMovementLabelWithValue() {
    this.$('label.touch-xy-movement').text(
      `Max Touch XY Movement: ${gcn.touchCalibrationConfig.minTouchXYMovementThresholdForClick}px`,
    );
  },

  _updateFlingDurationValue() {
    this.$('label.fling-duration').text(
      `Fling Duration: ${gcn.touchCalibrationConfig.maxFlingDuration}ms`,
    );
  },

  _updateScrollResponseBoundaryValue() {
    this.$('label.scroll-response-boundary').text(
      `Scroll Boundary for Visual Scroll: ${gcn.touchCalibrationConfig.scrollResponseBoundary} pixels`,
    );
  },

  _updateScrollBoundaryValue() {
    this.$('label.scroll-boundary').text(
      `Scroll Boundary for Fling Scroll: ${gcn.touchCalibrationConfig.scrollBoundary} pixels`,
    );
  },

  _updateHoldToClickTimeLabelWithValue() {
    this.$('label.hold-to-click-time').text(
      `Max Hold-to-Click Time: ${gcn.touchCalibrationConfig.maxHoldToClickTimeMs}ms`,
    );
  },

  render() {
    this.$el.html(
      // prettier-ignore
      '<div class="bite-logo"></div>' +
      '<div class="debug-panel">' +
        `<div class="gcn-version">Current version: ${pkg.version}</div>` +
        '<div class="debug-message"></div>' +
      '</div>' +
      '<div class="touch-calibration-controls">' +
        '<label class="touch-duration" htmlFor="touch-duration-threshold"></label>' +
        '<input id="touch-duration-threshold" type="range" min="0" max="350" value="0" />' +
        '<br />' +
        '<label class="touch-xy-movement" htmlFor="touch-xy-movement-threshold"></label>' +
        '<input id="touch-xy-movement-threshold" type="range" min="0" max="100" value="0" />' +
        '<br />' +
        '<label class="hold-to-click-time" htmlFor="hold-to-click-time-threshold"></label>' +
        '<input id="hold-to-click-time-threshold" type="range" min="0" max="350" value="0" />' +
        '<br />' +
        '<label class="fling-duration" htmlFor="fling-duration-threshold"></label>' +
        '<input id="fling-duration-threshold" type="range" min="0" max="5000" value="0" />' +
        '<label class="scroll-response-boundary" htmlFor="scroll-response-boundary-threshold"></label>' +
        '<input id="scroll-response-boundary-threshold" type="range" min="0" max="100" value="0" />' +
        '<label class="scroll-boundary" htmlFor="scroll-boundary-threshold"></label>' +
        '<input id="scroll-boundary-threshold" type="range" min="0" max="100" value="0" />' +
      '</div>',
    );

    const $touchDurationSlider = this.$('#touch-duration-threshold');
    const $touchXYMovementSlider = this.$('#touch-xy-movement-threshold');
    const $holdToClickTimeSlider = this.$('#hold-to-click-time-threshold');
    const $flingDurationSlider = this.$('#fling-duration-threshold');
    const $scrollResponseBoundarySlider = this.$('#scroll-response-boundary-threshold');
    const $scrollBoundarySlider = this.$('#scroll-boundary-threshold');
    $touchDurationSlider.on('change', () => {
      const value = parseInt($touchDurationSlider.val(), 10);
      gcn.touchCalibrationConfig = {
        ...gcn.touchCalibrationConfig,
        minTouchDurationThresholdForClick: value,
        isAdjustedLocally: true,
      };
      this._updateDurationLabelWithValue();
      return false;
    });
    $touchXYMovementSlider.on('change', () => {
      const value = parseInt($touchXYMovementSlider.val(), 10);
      gcn.touchCalibrationConfig = {
        ...gcn.touchCalibrationConfig,
        minTouchXYMovementThresholdForClick: value,
        isAdjustedLocally: true,
      };
      this._updateXYMovementLabelWithValue();
      return false;
    });
    $holdToClickTimeSlider.on('change', () => {
      const value = parseInt($holdToClickTimeSlider.val(), 10);
      gcn.touchCalibrationConfig = {
        ...gcn.touchCalibrationConfig,
        maxHoldToClickTimeMs: value,
        isAdjustedLocally: true,
      };
      this._updateHoldToClickTimeLabelWithValue();
      return false;
    });

    $flingDurationSlider.on('change', () => {
      const value = parseInt($flingDurationSlider.val(), 10);
      gcn.touchCalibrationConfig = {
        ...gcn.touchCalibrationConfig,
        maxFlingDuration: value,
        isAdjustedLocally: true,
      };
      this._updateFlingDurationValue();
      return false;
    });

    $scrollResponseBoundarySlider.on('change', () => {
      const value = parseInt($scrollResponseBoundarySlider.val(), 10);
      gcn.touchCalibrationConfig = {
        ...gcn.touchCalibrationConfig,
        scrollResponseBoundary: value,
        isAdjustedLocally: true,
      };
      this._updateScrollResponseBoundaryValue();
      return false;
    });

    $scrollBoundarySlider.on('change', () => {
      const value = parseInt($scrollBoundarySlider.val(), 10);
      gcn.touchCalibrationConfig = {
        ...gcn.touchCalibrationConfig,
        scrollBoundary: value,
        isAdjustedLocally: true,
      };
      this._updateScrollBoundaryValue();
      return false;
    });

    this.$logo = this.$('.bite-logo');
    this.$debugPanel = this.$('.debug-panel');
    this.$debugMessage = this.$('.debug-message');

    this.$logo.on('click', () => {
      this._clickCount++;
      if (this._clickCount === 5) {
        this._startTimeout();
        this.$logo.hide();
        this.$debugPanel.show();
        this.$debugMessage.text('Tap again to simulate a JS crash.');
        this.$('.touch-calibration-controls').show();

        if (!gcn.$touchCalibrationDebugWindow) {
          gcn.$touchCalibrationDebugWindow = $(this._touchCalibrationDebugWindowHtml);
          gcn.$touchCalibrationDebugLogPane = gcn.$touchCalibrationDebugWindow.find('.log-pane');
          $('.gcn').append(gcn.$touchCalibrationDebugWindow);
          gcn.$touchCalibrationDebugWindow.find('.close-button').on('click', () => {
            gcn.$touchCalibrationDebugWindow.remove();
            gcn.$touchCalibrationDebugWindow = null;
            gcn.$touchCalibrationDebugLogPane = null;
            return false;
          });
        }

        $touchDurationSlider.val(gcn.touchCalibrationConfig.minTouchDurationThresholdForClick);
        $touchXYMovementSlider.val(gcn.touchCalibrationConfig.minTouchXYMovementThresholdForClick);
        $holdToClickTimeSlider.val(gcn.touchCalibrationConfig.maxHoldToClickTimeMs);
        $flingDurationSlider.val(gcn.touchCalibrationConfig.maxFlingDuration);
        $scrollResponseBoundarySlider.val(gcn.touchCalibrationConfig.scrollResponseBoundary);
        $scrollBoundarySlider.val(gcn.touchCalibrationConfig.scrollBoundary);
        this._updateDurationLabelWithValue();
        this._updateXYMovementLabelWithValue();
        this._updateHoldToClickTimeLabelWithValue();
        this._updateFlingDurationValue();
        this._updateScrollResponseBoundaryValue();
        this._updateScrollBoundaryValue();
      }
    });

    this.$debugPanel.on('click', () => {
      this._clickCount++;
      if (this._clickCount === 6) {
        this._startTimeout();
        try {
          this.$el.crash();
        } catch (e) {
          this.$debugMessage.text(`Crashed: ${e.message}`);
          throw e;
        }
      } else if (this._clickCount === 7) {
        this._restoreToOriginalState();
      }
    });

    return this;
  },
});
