import { IntegrationSystem } from '@biteinc/enums';

import type GcnLocation from '../models/gcn_location';
import type { I9nClient } from './i9n_client';
import { LrsTableTrackerClient } from './lrs_table_tracker_client';

export function makeI9nClient(partialI9n: GcnLocation.PartialI9n): I9nClient {
  switch (partialI9n.system) {
    case IntegrationSystem.LrsTableTracker:
      return new LrsTableTrackerClient(partialI9n);
    default:
      throw new Error(`Unsupported integration system: ${partialI9n.system}`);
  }
}
