import { GCNModel } from './gcn_model';

class GcnModelTs<Data extends GcnModelData> extends GCNModel<Data> {
  constructor(attributes: Data, options: any) {
    super(attributes, options);

    this.id = this.get('_id')!;
  }

  // Override since backbone always says get can return undefined.
  get<Prop extends keyof Data>(key: Prop): Data[Prop] {
    return super.get(key);
  }

  displayName: (skipHtml?: boolean) => string;
}

export type GcnModelData = Readonly<{
  _id: string;
}>;

export default GcnModelTs;
