import $ from 'jquery';
import _ from 'underscore';

import { ErrorCode, Strings } from '@biteinc/common';
import { LocationPresetTipType, LocationTipsLevel, OrderClientEventName } from '@biteinc/enums';

import { BackboneEvents } from '~/app/js/backbone-events';
import { localizeStr } from '~/app/js/localization/localization';

import GcnHelper from '../gcn_helper';
import Analytics from '../utils/analytics';
import { GCNNumberPadView } from './gcn_number_pad_view';
import { GCNView } from './gcn_view';

// Dumb view that presents tip amounts, including a freeform field, and returns an amount selected
// by user.
export const GCNTipView = GCNView.extend({
  className: 'tip-view',

  template: _.template(
    // prettier-ignore
    '<div class="header">' +
      '<div class="icon"></div>' +
      '<div class="title"></div>' +
      '<div class="subtitle"></div>' +
    '</div>' +
    '<div class="subtotal"></div>' +
    '<div class="preset-section"></div>' +
    '<div class="no-tip-section"></div>',
  ),

  initialize(options, ...args) {
    GCNView.prototype.initialize.apply(this, [options, ...args]);

    this._successCallback = () => {
      gcn.orderManager.eventRepo.track(OrderClientEventName.TipViewSuccess);
      options.successCallback();
    };
    this._orderSubTotal = gcn.orderManager.getSubTotal();
    this._tipTotal = gcn.orderManager.getTipTotal();
    this._presetTips = gcn.location.getPresetTips();
    this.listenToOnce(
      gcn.orderManager,
      BackboneEvents.GCNOrderManager.TipDidValidate,
      this._tipDidValidate,
    );
    this.listenToOnce(
      gcn.orderManager,
      BackboneEvents.GCNOrderManager.TipDidFailValidation,
      this._tipDidFailValidation,
    );
    this.addRefreshOnLanguageChangeSubscription();
  },

  destroy(...args) {
    GCNView.prototype.destroy.apply(this, args);

    gcn.orderManager.eventRepo.track(OrderClientEventName.TipViewDismiss);
  },

  _submitTipAmount(amount) {
    gcn.orderManager.setTipTotal(amount);
    gcn.menuView.showSpinner(localizeStr(Strings.TIPPING));
  },

  _tipDidValidate() {
    gcn.menuView.dismissSpinner();
    this._successCallback();
  },

  _tipDidFailValidation(err) {
    gcn.menuView.dismissSpinner();
    if (err.code === ErrorCode.CannotModifyTipAfterPayment) {
      gcn.menuView.showSimpleAlert(localizeStr(Strings.TIP_CANNOT_BE_MODIFIED));
    } else {
      gcn.menuView.showSimpleAlert(localizeStr(Strings.TIP_FAILED));
    }
  },

  _showCustomTipView() {
    if (this.$('.custom-tip-view').length) {
      return;
    }
    const customTipPlaceholder = `$0.00`;
    const $customTipView = $(`
      <div class="custom-tip-view">
        <div class="custom-tip-display">
          <span class="custom-tip-display-placeholder">${customTipPlaceholder}</span>
          <span class="custom-tip-display-value"></span>
          <input class="custom-tip-display-input" type="text" placeholder="${customTipPlaceholder}" />
        </div>
        <span class="custom-tip-error-message"></span>
        <div class="custom-tip-number-pad"></div>
        <button class="custom-tip-submit-button">${localizeStr(Strings.ADD_TIP)}</button>
      </div>
    `);

    const $customTipInput = $customTipView.find('.custom-tip-display input');
    const $customTipInputDisplayValue = $customTipView.find('.custom-tip-display-value');
    const $customTipInputPlaceholder = $customTipView.find('.custom-tip-display-placeholder');
    const $customTipErrorMessage = $customTipView.find('.custom-tip-error-message');
    $customTipErrorMessage.hide();

    // the custom input itself is never manipulated
    // whenever a user taps on the number pad, we dispatch an input event with the new updated value
    // this allows us to use the input event to update the display
    // if there's no value, we show the placeholder otherwise we hide it
    $customTipInput.on('input', (e) => {
      const customTipAmount = e.target.value;
      if (customTipAmount) {
        $customTipInputDisplayValue.text(`$${GcnHelper.stringFromTotal(customTipAmount)}`);
        $customTipInputPlaceholder.hide();
      } else {
        $customTipInputDisplayValue.text('');
        $customTipInputPlaceholder.show();
      }
      $customTipErrorMessage.hide();
    });

    const $customTipNumberPad = $customTipView.find('.custom-tip-number-pad');
    const numberPad = new GCNNumberPadView();
    this.listenTo(numberPad, BackboneEvents.GCNNumberPadView.DigitWasPressed, (digit) => {
      $customTipInput.val($customTipInput.val() + digit);
      const event = new Event('input', { bubbles: true, cancelable: true });
      $customTipInput[0].dispatchEvent(event);
    });
    this.listenTo(numberPad, BackboneEvents.GCNNumberPadView.DeleteWasPressed, () => {
      $customTipInput.val($customTipInput.val().slice(0, -1));
      const event = new Event('input', { bubbles: true, cancelable: true });
      $customTipInput[0].dispatchEvent(event);
    });
    $customTipNumberPad.append(numberPad.render().el);

    const $customTipSubmitButton = $customTipView.find('.custom-tip-submit-button');
    $customTipSubmitButton.onButtonTapOrHold('customTipSubmit', () => {
      const customTipAmount = $customTipInput.val();
      if (customTipAmount) {
        const customTipAmountInCents = Number.parseInt(customTipAmount);
        if (customTipAmountInCents > this._orderSubTotal) {
          $customTipErrorMessage.htmlOrText(localizeStr(Strings.TIP_AMOUNT_TOO_HIGH));
          $customTipErrorMessage.show();
          return;
        }
        // this is not possible with our number pad, but just in case
        if (customTipAmountInCents < 0) {
          $customTipErrorMessage.htmlOrText(localizeStr(Strings.TIP_AMOUNT_TOO_LOW));
          $customTipErrorMessage.show();
          return;
        }
        this._hideCustomTipView();
        Analytics.trackEvent({
          eventName: Analytics.EventName.CustomerTipCustomAmountSelected,
          eventData: {
            amount: customTipAmountInCents,
            wasForced: gcn.location.get('tipsLevel') === LocationTipsLevel.Forced,
          },
        });
        this._submitTipAmount(customTipAmountInCents);
      }
    });

    this.$el.append($customTipView);
  },

  _hideCustomTipView() {
    this.$('.custom-tip-view').remove();
  },

  render() {
    const self = this;

    gcn.orderManager.eventRepo.track(OrderClientEventName.TipViewRender);

    this.$el.html(this.template());

    const wasForced = gcn.location.get('tipsLevel') === LocationTipsLevel.Forced;

    const $customTipButton = $(
      `<div class="tip-button tip-button-custom">${localizeStr(Strings.CUSTOM_TIP)}</div>`,
    );

    $customTipButton.onButtonTapOrHold('customTip', () => {
      $customTipButton.attr('data-active', 'true');
      self._showCustomTipView();
    });

    const tippableTotal = gcn.orderManager.getTippableTotal();

    // gift card number form
    const presetTips = this._presetTips;
    _.each(presetTips, (presetTip) => {
      if (presetTip.type === LocationPresetTipType.Percentage) {
        const presetPercentageText = `${presetTip.amount}%`;
        // Always round up tip amount, for simplicity.
        const presetAmountInCents = Math.ceil(self._orderSubTotal * (presetTip.amount / 100));
        const presetAmountText = GcnHelper.stringFromTotal(presetAmountInCents);
        const $presetButton = $(
          // prettier-ignore
          `<div class="tip-button" ${presetAmountInCents === self._tipTotal ? `data-active="true"` : ''}>` +
            `<div class="percentage">${presetPercentageText}</div>` +
            `<div class="amount">($${presetAmountText})</div>` +
          `</div>`,
        );
        self.$('.preset-section').append($presetButton);
        $presetButton.onButtonTapOrHold(`tipButton${presetTip.amount}`, () => {
          $customTipButton.attr('data-active', 'false');
          self._hideCustomTipView();
          Analytics.trackEvent({
            eventName: Analytics.EventName.CustomerTipPresetPercentageSelected,
            eventData: {
              percent: presetPercentageText,
              amount: presetAmountInCents,
              wasForced,
            },
          });
          self._submitTipAmount(presetAmountInCents);
        });
      } else {
        const presetTipAmountInCents = presetTip.amount * 100;

        if (presetTipAmountInCents > tippableTotal) {
          return;
        }

        const presetTipAmountText = GcnHelper.stringFromTotal(presetTipAmountInCents);
        const $presetButton = $(`
          <div class="tip-button tip-button-absolute" ${presetTipAmountInCents === self._tipTotal ? `data-active="true"` : ''}>
            $${presetTipAmountText}
          </div>
        `);
        self.$('.preset-section').append($presetButton);
        $presetButton.onButtonTapOrHold(`tipButton${presetTipAmountInCents}`, () => {
          $customTipButton.attr('data-active', 'false');
          self._hideCustomTipView();
          Analytics.trackEvent({
            eventName: Analytics.EventName.CustomerTipPresetValueSelected,
            eventData: {
              amount: presetTipAmountInCents,
              wasForced,
            },
          });
          self._submitTipAmount(presetTipAmountInCents);
        });
      }
    });

    self.$('.preset-section').append($customTipButton);

    const $noTipButton = $(`<div class="tip-button">${localizeStr(Strings.NO_TIP)}</div>`);
    $noTipButton.onButtonTapOrHold('noTip', () => {
      Analytics.trackEvent({
        eventName: Analytics.EventName.CustomerNoTip,
        eventData: {
          wasForced,
        },
      });
      if (self._tipTotal > 0) {
        gcn.orderManager.eventRepo.track(OrderClientEventName.TipRemove);

        self._submitTipAmount(0);
      } else {
        self._successCallback();
      }
    });
    self.$('.no-tip-section').append($noTipButton);

    this.$('.subtotal').htmlOrText(
      `${localizeStr(Strings.YOUR_SUBTOTAL)}: $${GcnHelper.stringFromTotal(this._orderSubTotal)}`,
    );
    this.$('.title').htmlOrText(localizeStr(Strings.ADD_TIP));

    this.$subtitle = this.$('.subtitle');
    this.$subtitle.htmlOrText(localizeStr(Strings.THANK_YOU));
    this.$subtitle.delayedScaleIn();

    return this;
  },
});
