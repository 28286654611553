import $ from 'jquery';

import { Log, Strings } from '@biteinc/common';
import { LocationRecommendationsLevel } from '@biteinc/enums';

import { BackboneEvents } from '~/app/js/backbone-events';
import { localizeStr, str } from '~/app/js/localization/localization';

import { RecommendationDisplayLocationDescription } from '../../../../types/recommendation';
import GcnHtml from '../../gcn_html';
import GcnRecoTracker from '../../gcn_reco_tracker';
import ScreenReaderHelper from '../../screen_reader_helper';
import Analytics from '../../utils/analytics';
import { asCallback } from '../../utils/promises';
import { GCNOrderedItemView } from '../gcn_ordered_item_view';
import { GCNRecommendationsView } from '../gcn_recommendations_view';
import { GCNSpecialRequestView } from '../gcn_special_request_view';
import { GCNCustomizeFlowStepView } from './gcn_customize_flow_step_view';
import { GCNFullScreenFlowStepView } from './gcn_full_screen_flow_step_view';

export const GCNConfirmationStepView = GCNCustomizeFlowStepView.extend({
  className: 'customize-flow-step-view confirmation',
  stepType: GCNFullScreenFlowStepView.StepType.Confirmation,

  animateOutOrderedItem(callback) {
    gcn.menuView.dismissStablePopupAndAnimateOrderedItemIntoCart(this.orderedItemView, callback);
  },

  initialize(options, ...args) {
    GCNCustomizeFlowStepView.prototype.initialize.apply(this, [options, ...args]);

    this._fetchAndRenderRecommendations();
  },

  _fetchAndRenderRecommendations() {
    if (gcn.menu.settings.get('recommendationsLevel') === LocationRecommendationsLevel.Off) {
      return;
    }

    asCallback(
      GcnRecoTracker.getItemRecommendations(gcn.maitred, this.parent.orderedItem),
      (err, recommendations) => {
        if (err) {
          Log.error('error loading recos');
          return;
        }

        const recoItems = gcn.menu.getMenuItemsFromRecommendations(recommendations, {
          displayRecosLimit: 2,
          pickSimpleItemsOnly: !!gcn.screenReaderIsActive,
        });
        // render recos
        this._renderRecommendations(recoItems);
      },
    );
  },

  _renderRecommendations(recoItems) {
    if (!recoItems.length) {
      return;
    }
    this.recosView = new GCNRecommendationsView({
      upsellScreen: 'GCNConfirmationStepView',
      recos: recoItems,
      usePopup: true,
    });

    GcnRecoTracker.trackDisplayRecommendations(
      gcn.maitred,
      RecommendationDisplayLocationDescription.MENU_ITEM_CONFIRMATION_STEP,
      recoItems.map((item) => {
        return item.id;
      }),
    );

    this.$el.toggleClass('with-recos', true);
    this.$recosWrapper.append(this.recosView.render().$el);
  },

  confirmStep() {
    if (this._specialRequestView) {
      const specialRequest = this._specialRequestView.getValue();
      if ((specialRequest || '').length) {
        this.parent.orderedItem.set('specialRequest', specialRequest);
      }
    }
    return true;
  },

  getSpecialRequest() {
    if (this._specialRequestView) {
      return this._specialRequestView.getValue();
    }
    return '';
  },

  getOrderedRecommendations() {
    return {
      recommendationDisplayLocationDescription:
        RecommendationDisplayLocationDescription.MENU_ITEM_CONFIRMATION_STEP,
      orderedItems: this.recosView?.getOrderedItems() || [],
    };
  },

  setCheckoutButtonDisabledState(nextState) {
    this.$('.button.checkout').toggleClass('disabled', nextState);
    this.$('.button.next').toggleClass('disabled', nextState);
  },

  setNextButtonTextAriaWithPrice(quantity) {
    const priceInCents = this.parent.orderedItem.getRecalculatedTotal(quantity);
    const displayPrice = GcnHtml.stringFromPrice(priceInCents);
    this.setNextButtonAriaLabel(`$${displayPrice}, ${this._nextButtonText}`);
  },

  render(...args) {
    GCNCustomizeFlowStepView.prototype.render.apply(this, args);
    const self = this;

    this.$stepBody.append(
      // prettier-ignore
      '<div class="header-container">' +
        `<div id="confirmation-step-view-header" class="header bg-color-spot-1" role="heading" aria-level="1" tabindex="-1" aria-label="${ScreenReaderHelper.prepareAriaLabel(str(Strings.CONFIRM_YOUR_ITEM))}">
          <span aria-hidden="true">${localizeStr(Strings.CONFIRM_YOUR_ITEM)}</span>
         </div>` +
      '</div>',
    );

    if (gcn.screenReaderIsActive) {
      this.$stepBody.find('#confirmation-step-view-header').requestFocusAfterDelay();
    }

    this.orderedItemView = new GCNOrderedItemView({
      model: this.parent.orderedItem,
    });
    this.$stepBody.append(this.orderedItemView.render().$el);

    if (gcn.menu.settings.get('allowSpecialRequests')) {
      this._specialRequestView = new GCNSpecialRequestView({});
      this.listenTo(
        this._specialRequestView,
        BackboneEvents.GCNSpecialRequestView.SpecialRequestTextChanged,
        (isProfane) => {
          self.setCheckoutButtonDisabledState(isProfane);
        },
      );
      this.$stepBody.append(this._specialRequestView.render().$el);
    }

    if (gcn.menu.settings.get('quickCheckoutEnabled')) {
      const $quickCheckoutButton = $('<div role="button" class="button checkout"></div>');
      $quickCheckoutButton.onButtonTapOrHold('csvQuick', () => {
        Analytics.trackEvent({
          eventName: Analytics.EventName.QuickCheckout,
          eventData: {
            source: 'confirmation_step_view',
          },
        });
        this.trigger(BackboneEvents.GCNConfirmationStepView.QuickCheckoutButtonWasTapped, this);
      });
      this.$buttonsRight.prepend($quickCheckoutButton);
    }

    if (gcn.menu.settings.get('recommendationsLevel') !== LocationRecommendationsLevel.Off) {
      this.$recosWrapper = $('<div class="recommendations-wrapper"></div>');
      this.$stepBody.append(this.$recosWrapper);
    }
    // If flash/web mobile view, we shorten text to add and checkout.
    if (window.isFlash && $(window).width() <= 600) {
      this._nextButtonText = str(Strings.ADD);
      this.setNextButtonText(localizeStr(Strings.ADD));
      this.$('.button.checkout').htmlOrText(localizeStr(Strings.CHECKOUT));
      this.$('.button.checkout').attr(
        'aria-label',
        ScreenReaderHelper.prepareAriaLabel(str(Strings.CHECKOUT)),
      );
    } else {
      this._nextButtonText = str(Strings.ADD_TO_ORDER);
      this.setNextButtonText(localizeStr(Strings.ADD_TO_ORDER));
      this.$('.button.checkout').htmlOrText(localizeStr(Strings.QUICK_CHECKOUT));
      this.$('.button.checkout').attr(
        'aria-label',
        ScreenReaderHelper.prepareAriaLabel(str(Strings.QUICK_CHECKOUT)),
      );
    }

    // Some products don't allow quantity edits, defaulting to 1 on render.
    // If quantity is editable, the price updates based on the selected quantity
    // via _onQuantityChangedForConfirmationStep
    this.setNextButtonTextAriaWithPrice(1);

    return this;
  },
});
