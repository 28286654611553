import { BiteUrl, ErrorCode, Log } from '@biteinc/common';
import type { OrderChannel } from '@biteinc/enums';
import { ApiHeader, ClientApiVersion } from '@biteinc/enums';

import type { BridgeCallback, BridgeResponse } from './gcn_bridge_interface';
import { ApiService } from './gcn_maitred_request_manager';
import LocalStorage from './utils/local_storage';

function shouldLogOut(errorCode: number): boolean {
  return [ErrorCode.AuthNotLoggedIn, ErrorCode.AuthForcedLogOut].includes(errorCode);
}
export default class GcnFlashMaitredClient {
  constructor(
    private readonly apiHost: string,
    private readonly orgIdStr: string,
    private readonly orderChannel: OrderChannel,
    private readonly locationIdStr: string,
  ) {}

  static setCustomerToken(token: string): void {
    return LocalStorage.setCustomKeyItem(`${window.customerScope}:token`, token);
  }

  static getCustomerToken(): string | undefined | null {
    return LocalStorage.getCustomKeyItem(`${window.customerScope}:token`);
  }

  private getHostFromApiService(apiService: ApiService): string {
    switch (apiService) {
      case ApiService.LogsApiV1:
      case ApiService.LoyaltyApiV1:
      case ApiService.LoyaltyApiV2:
      case ApiService.Maitred:
      case ApiService.OrdersApiV1:
      case ApiService.OrdersApiV2:
      case ApiService.PaymentsApiV2:
      case ApiService.RecommendationsApiMaitred:
        return this.apiHost;
      case ApiService.RecommendationsApi:
        return BiteUrl.biteGatewayUrl(window.env);
    }
  }

  makeApiRequest(
    apiService: ApiService,
    method: string,
    path: string,
    data: object,
    timeout: number,
    callback: BridgeCallback,
  ): void {
    const url = `${this.getHostFromApiService(apiService)}${path}`;
    Log.info(`maitred -> ${method}: ${url}`);
    const token = GcnFlashMaitredClient.getCustomerToken();
    const ajaxOptions: any = {
      url,
      type: method,
      dataType: 'json',
      timeout,
      contentType: 'application/json',
      headers: {
        [ApiHeader.ApiVersion]: `${ClientApiVersion.SupportsErrorMessage}`,
        [ApiHeader.LocationId]: this.locationIdStr,
        [ApiHeader.OrgId]: this.orgIdStr,
        ...(this.orderChannel && {
          [ApiHeader.OrderChannel]: this.orderChannel,
        }),
        ...(window.customerScope && {
          [ApiHeader.CustomerAppScope]: window.customerScope,
        }),
        ...(token && {
          [ApiHeader.CustomerToken]: token,
        }),
      },
      success(result: BridgeResponse /* status, xhr */) {
        Log.info(`maitred <- ${method}: ${url} 200`);
        if (callback) {
          callback(result);
        }
      },
      error(result: any) {
        Log.info(`maitred <- ${method}: ${url} ${result.status}`);
        const error = {
          code: ErrorCode.NetworkError,
          ...(result.responseJSON || {}),
        };

        if (shouldLogOut(error.code)) {
          window.location.reload();
          return;
        }

        if (callback) {
          callback({ error });
        }
      },
    };
    if (['POST', 'DELETE', 'PUT'].includes(ajaxOptions.type) && data) {
      ajaxOptions.data = JSON.stringify(data);
    }

    // We think the jQuery types are bad and this thing doesn't actually return a promise.
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    $.ajax(ajaxOptions);
  }
}
