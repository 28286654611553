import Backbone from 'backbone';

import { StringHelper } from '@biteinc/core-react';
import { LanguageCode } from '@biteinc/enums';

import GcnHtml from '../gcn_html';

export const GCNModel = Backbone.Model.extend({
  idAttribute: '_id',

  hasArr(key) {
    return (this.get(key) || '').length > 0;
  },

  hasStr(key) {
    return (this.get(key) || '').length > 0;
  },

  displayName() {
    return this.get('name') || this.get('posName');
  },

  displayNameHtml() {
    return GcnHtml.htmlFromString(this.displayName());
  },

  getSlugName() {
    return StringHelper.slugify(this.displayName());
  },

  displayDescription() {
    return this.get('description') || this.get('posDescription');
  },

  displayDescriptionHtml() {
    return GcnHtml.htmlFromString(this.displayDescription());
  },

  // Override to localize.
  get(key, ...args) {
    const language = gcn.getLanguage();
    const enValue = Backbone.Model.prototype.get.apply(this, [key, ...args]);

    if (language !== LanguageCode.EN_US) {
      const languageOverrides = this.attributes.localizationOverrides?.find((overrides) => {
        return overrides.language === language;
      });

      if (!languageOverrides) {
        return enValue;
      }

      return languageOverrides.overrideByFieldName[key] || enValue;
    }

    return enValue;
  },
});
