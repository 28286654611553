import $ from 'jquery';
import _ from 'underscore';

import { Log, Strings } from '@biteinc/common';

import { localizeStr, str } from '~/app/js/localization/localization';

import GcnHardwareManager from '../gcn_hardware_manager';
import GcnHelper from '../gcn_helper';
import GcnPrintJob from '../gcn_print_job';
import Analytics from '../utils/analytics';
import { GCNAlertView } from './gcn_alert_view';
import { GCNOrderedItemView } from './gcn_ordered_item_view';
import { GCNView } from './gcn_view';

const kTimeoutAfterAction = 8000;
export const GCNOrderFailedView = GCNView.extend({
  initialize(errMessage, ...args) {
    GCNView.prototype.initialize.apply(this, [errMessage, ...args]);

    this.errMessage = errMessage;
  },

  className: 'order-failed-view',
  template: _.template(
    // prettier-ignore
    '<div class="messages">' +
      '<div class="message-1"></div>' +
      '<div class="message-2"></div>' +
    '</div>' +
    '<div class="order-title"></div>' +
    '<div class="body">' +
      '<div class="item-list"></div>' +
    '</div>' +
    '<div class="bottom-nav">' +
      '<div class="showing-receipt-options receipt-buttons-container"></div>'+
      '<span role="button" class="button done"><%= doneStr %></span>' +
    '</div>',
  ),

  _renderOrder() {
    const self = this;
    const orderedItems = gcn.orderManager.getOrderedItems();
    Log.info('rendering order', orderedItems);
    self.$itemsList.html('');
    _.each(orderedItems, (orderedItem) => {
      const orderedItemView = new GCNOrderedItemView({
        showPrice: true,
        model: orderedItem,
      });
      self.$itemsList.append(orderedItemView.render().$el);
    });
  },

  _showConfirmCloseModal() {
    const alertText = localizeStr(Strings.SHOW_STAFF_FIRST, [], function (string) {
      return string.split('\n').join('<br />');
    });
    const confirmView = new GCNAlertView({
      text: alertText,
      okCallback() {
        gcn.goHome();
      },
      cancelCallback() {
        gcn.menuView.dismissModalPopup();
      },
    });
    gcn.menuView.showModalPopup(confirmView);
  },

  overlayWasClicked() {
    // Do nothing. Define to prevent dismissal.
    this._showConfirmCloseModal();
  },

  shouldHideCloseLabel() {
    return true;
  },

  render() {
    this.$el.html(
      this.template({
        doneStr: str(Strings.DONE),
      }),
    );

    this.$itemsList = this.$('.body .item-list');
    const $doneButton = this.$('.button.done');

    this.$('.message-1').htmlOrText(this.errMessage); // used to be please call staff
    this.$('.message-2').htmlOrText(localizeStr(Strings.WE_APOLOGIZE));
    this.$('.order-title').htmlOrText(localizeStr(Strings.YOUR_ORDER));

    $doneButton.onButtonTapOrHold('ofvDone', () => {
      Analytics.track(Analytics.EventName.OrderSendErrorDoneButtonTapped);
      this._showConfirmCloseModal();
    });

    const printReceiptEnabled =
      gcn.location.showReprintButton() && GcnHardwareManager.hasReceiptPrinter();

    if (printReceiptEnabled) {
      const receiptButtonsContainer = this.$('.receipt-buttons-container');
      const $printButton = $(`
      <div class="receipt-option print button">
        <div class="icon"></div>
        <div class="text">${localizeStr(Strings.PRINT_RECEIPT)}</div>
      </div>
    `);
      receiptButtonsContainer.append($printButton);
      $printButton.onButtonTapOrHold('pcsvPrint', () => {
        Analytics.track(Analytics.EventName.CheckoutCompletePrintReceiptTapped);
        this._printButtonOnTap($printButton, gcn.orderManager.getOrder());
      });
    }

    this._renderOrder();

    return this;
  },

  _printButtonOnTap($printButton, order) {
    if ($printButton.hasClass('disabled')) {
      return;
    }
    this.parent?.cancelTimeout();
    gcn.menuView.showSpinner(localizeStr(Strings.PRINTING_RECEIPT));

    const receiptBase64Images = GcnHelper.getReceiptBase64Images(gcn.location);
    const printPayload = {
      printJobs: [
        GcnPrintJob.receiptPrintJob(gcn.location, gcn.menu, order, { ...receiptBase64Images }),
      ],
    };
    gcn.requestToPrintReceipt(printPayload, (response) => {
      this.parent?.startTimeout(kTimeoutAfterAction);
      gcn.menuView.dismissSpinner();
      if (response.error) {
        gcn.menuView.showSimpleAlert(localizeStr(Strings.PRINT_RECEIPT_ERROR));
      } else {
        $printButton.addClass('disabled');
        $printButton.find('.text').htmlOrText(localizeStr(Strings.RECEIPT_PRINTED));
      }
    });
  },
});
