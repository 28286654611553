import { IonIcon } from '@ionic/react';
import { backspaceOutline } from 'ionicons/icons';
import React from 'react';

import { ReactAriaButton } from './button';

const stripNonDigits = (str: string): string => {
  return str.replace(/\D/g, '');
};

const formatPhoneNumber = (str: string | null): string => {
  if (!str) {
    return '';
  }
  if (str.length === 0) {
    return '';
  }
  if (str.length < 4) {
    return `(${str}`;
  }
  if (str.length < 7) {
    return `(${str.slice(0, 3)}) ${str.slice(3)}`;
  }
  return `(${str.slice(0, 3)}) ${str.slice(3, 6)}-${str.slice(6, 10)}`;
};

const PhonePadButton: React.FC<{
  onClick: () => void;
  numberLabel?: string;
  icon?: string;
  iconColor?: string;
  className?: string;
}> = ({ onClick, numberLabel, icon, iconColor, className }) => {
  return (
    <ReactAriaButton
      onPress={onClick}
      className={`tw-rounded-[50%] tw-border tw-border-solid tw-border-[#999999] tw-bg-white tw-w-[58px] tw-h-[58px] tw-text-center tw-items-center tw-flex tw-justify-center tw-p-1 tw-m-1 tw-text-4xl active:tw-bg-[#E6E6E6] ${
        className ?? ''
      }`}
    >
      {numberLabel || (
        <IonIcon
          icon={icon}
          color={iconColor}
        />
      )}
    </ReactAriaButton>
  );
};

interface PhonePadProps {
  value: string | null;
  setValue: (str: string) => void;
  credentialsError?: boolean;
}

const PhonePadRow: React.FC<{
  children: React.ReactNode;
  twJustifyContent?: string;
}> = ({ children, twJustifyContent = 'tw-justify-between' }) => {
  return (
    <div className={`tw-flex tw-flex-row ${twJustifyContent} tw-py-0 tw-px-5`}>{children}</div>
  );
};

export const PhonePad: React.FC<PhonePadProps> = ({ value, setValue, credentialsError }) => {
  const appendToPhoneNumberIfValid = (digit: number): void => {
    if (value && value.length < 14) {
      setValue(formatPhoneNumber(`${stripNonDigits(value)}${digit}`));
    } else if (!value) {
      setValue(formatPhoneNumber(`${digit}`));
    }
  };

  const removeLastDigit = (): void => {
    if (value?.length) {
      setValue(formatPhoneNumber(stripNonDigits(value).slice(0, -1)));
    }
  };

  return (
    <React.Fragment key={'phone-pad'}>
      <div className={`tw-text-5xl tw-w-full tw-text-center`}>
        <input
          type="tel"
          value={value || ''}
          placeholder={'(000) 000-0000'}
          className="phone-number tw-w-full tw-text-center tw-text-5xl tw-border-none"
          disabled={true}
        />
        {credentialsError && (
          <p
            className="tw-text-sm tw-text-red-600 tw-text-center tw-w-full tw-px-2 tw-m-auto"
            slot="error"
          >
            Invalid Phone Number
          </p>
        )}
      </div>

      <div className="tw-w-[250px] tw-justify-center tw-m-auto tw-pt-8">
        <PhonePadRow>
          <PhonePadButton
            onClick={() => appendToPhoneNumberIfValid(1)}
            numberLabel="1"
          />
          <PhonePadButton
            onClick={() => appendToPhoneNumberIfValid(2)}
            numberLabel="2"
          />
          <PhonePadButton
            onClick={() => appendToPhoneNumberIfValid(3)}
            numberLabel="3"
          />
        </PhonePadRow>
        <PhonePadRow>
          <PhonePadButton
            onClick={() => appendToPhoneNumberIfValid(4)}
            numberLabel="4"
          />
          <PhonePadButton
            onClick={() => appendToPhoneNumberIfValid(5)}
            numberLabel="5"
          />
          <PhonePadButton
            onClick={() => appendToPhoneNumberIfValid(6)}
            numberLabel="6"
          />
        </PhonePadRow>
        <PhonePadRow>
          <PhonePadButton
            onClick={() => appendToPhoneNumberIfValid(7)}
            numberLabel="7"
          />
          <PhonePadButton
            onClick={() => appendToPhoneNumberIfValid(8)}
            numberLabel="8"
          />
          <PhonePadButton
            onClick={() => appendToPhoneNumberIfValid(9)}
            numberLabel="9"
          />
        </PhonePadRow>
        <PhonePadRow twJustifyContent="tw-justify-end">
          <PhonePadButton
            onClick={() => appendToPhoneNumberIfValid(0)}
            numberLabel="0"
            className="tw-mr-2"
          />
          <PhonePadButton
            onClick={() => removeLastDigit()}
            icon={backspaceOutline}
            iconColor={'black'}
          />
        </PhonePadRow>
      </div>
    </React.Fragment>
  );
};
