import { BackboneEvents } from '~/app/js/backbone-events';

import ScreenReaderHelper from '../screen_reader_helper';
import Analytics from '../utils/analytics';
import { GCNPopup } from './gcn_popup';

export const GCNSimpleCover = GCNPopup.extend({
  initialize(options, ...args) {
    this._url = options.url;
    this._text = options.text;

    GCNPopup.prototype.initialize.apply(this, [options, ...args]);
  },

  className(...args) {
    const className = GCNPopup.prototype.className.apply(this, args);

    if (gcn.location.hasBottomBarButtons()) {
      return `${className} simple-cover has-bottom-bar`;
    }
    return `${className} simple-cover`;
  },

  show() {
    this.$el.show();
    this.$el.css('transform', 'translateY(0px)');
  },

  isShown() {
    return this.$el.css('display') !== 'none';
  },

  hide() {
    this.$el.delay(200).fadeOut(400);
    this.$el.css('transform', `translateY(-${this.$el.height()}px)`);
    this.trigger(BackboneEvents.GCNSimpleCover.IntroImageDisappear, this);
  },

  reloadImages() {
    const self = this;
    setTimeout(() => {
      const imageValue = self.$el.css('background-image');
      self.$el.css('background-image', '');
      self.$el.css('background-image', imageValue);
    }, 1);
  },

  render() {
    const self = this;
    this.$el.html('<span></span>');
    this.$el.click(() => {
      Analytics.track(Analytics.EventName.SimpleCoverDismissed);
      self.hide();
      return false;
    });

    if (this._url) {
      gcn.requestImageByUrl(this._url, (err, imgPath) => {
        if (!err) {
          self.$el.css('background-image', `url(${imgPath})`);
          self.$el.attr('aria-hidden', 'false');
          self.$el.attr('aria-label', ScreenReaderHelper.prepareAriaLabel(self._text));
          self.$el.attr('aria-labelledby', ScreenReaderHelper.prepareAriaLabel(self._text));
          self.$el.attr('role', 'img');
          self.$el.attr('tabindex', '-1');
          self.$el.focus();
        }
      });
    }

    return this;
  },
});
