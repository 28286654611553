/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import type { AriaButtonProps } from 'react-aria';
import { useButton } from 'react-aria';

import { useComposedRefs } from '@biteinc/ui';

type ButtonProps = React.ComponentProps<'button'> & {
  onPress?: AriaButtonProps<'button'>['onPress'];
  useOnPress?: boolean;
};

// TODO: this was made as a quick fix for touch sensitivity issues on gcn - long term plan TBD
// DO NOT USE UNLESS HAVING A TOUCH SENSITIVITY CRISIS

// useOnPress: This prop is only to be used in scenarios where
// 1. There has been reports of touch sensitivity issue
// 2. The button in question is on the menu level. If the button is in a dialog do not use this prop

export const ReactAriaButton = React.forwardRef(function Button(
  { disabled: isDisabled, className, onPress, useOnPress = false, ...props }: ButtonProps,
  forwardedRef,
) {
  const ownRef = React.useRef<HTMLButtonElement>(null);
  const ref = useComposedRefs(ownRef, forwardedRef);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { buttonProps, isPressed } = useButton(
    // to avoid the chromium bug https://bugs.chromium.org/p/chromium/issues/detail?id=1150073 (this event extends to native browsers)
    // if we're using onClick, then thats fine, but if we're using onPress, we need to alias it to onClick
    { onClick: onPress, ...props, isDisabled },
    ref as any,
  );

  const handleTouchEnd = (e: React.TouchEvent<HTMLButtonElement>): any => {
    e.preventDefault(); // Prevents mouse event from firing

    if (useOnPress) {
      onPress?.(e as any);
    } else {
      buttonProps.onClick?.(e as any);
    }
  };

  return (
    <button
      ref={ref}
      onTouchEnd={handleTouchEnd}
      data-pressed={isPressed ? '' : undefined}
      {...buttonProps}
      {...props}
      className={className}
    />
  );
});
