import _ from 'underscore';

import { GCNView } from '../gcn_view';

export const GCNAsterHallHeaderView = GCNView.extend({
  className: 'header-container aster-hall',
  template: _.template(
    // prettier-ignore
    '<div class="logo"></div>' +
    '<div class="title-outer">' +
      '<div class="title"><%= title %></div>' +
    '</div>' +
    '<div class="balancer"></div>',
  ),

  initialize(options, ...args) {
    GCNView.prototype.initialize.apply(this, [options, ...args]);

    this.title = options.title || '';
  },

  render() {
    this.$el.html(
      this.template({
        title: this.title,
      }),
    );

    return this;
  },
});
