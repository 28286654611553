import { Strings } from '@biteinc/common';

import type { GcnMenuItem } from '~/types/gcn_menu_item';

import { str } from '../localization/localization';
import GcnView from './gcn_view_ts';

export default class GcnModifierUpsellView extends GcnView {
  model: GcnMenuItem;
  onAccept: () => void;
  onDismiss: () => void;

  constructor(options: {
    // modifier item that is being upsold
    model: GcnMenuItem;

    onAccept: () => void;
    onDismiss: () => void;
  }) {
    super(options);
    this.model = options.model;

    this.onAccept = options.onAccept;
    this.onDismiss = options.onDismiss;
  }

  onNavToItemWithModifier(): void {
    if (this.onAccept) {
      this.onAccept();
    }
  }

  onNavToItemWithoutModifier(): void {
    if (this.onDismiss) {
      this.onDismiss();
    }
  }

  render(): this {
    const imageUrl = this.model.getImageUrlOrPlaceholder();
    // prettier-ignore
    const html =
    '<div class="make-it-a-combo-view">' +
      `<div class="header">${str(Strings.MAKE_IT_A_COMBO_MOD)}</div>` +
        (imageUrl ? `<div class="image" style="background-image: url('${imageUrl}')"></div>` : '') +
      '<div class="item-details">' +
        `<div class="name">${this.model.displayName()}</div>` +
      '</div>' +
      '<div class="button-container">' +
        `<div role="button" class="button yes" id="yes-combo-button">${str(Strings.MAKE_IT_A_COMBO_ACCEPT)}</div>` +
        `<div role="button" class="button no" id="no-combo-button">${str(Strings.MAKE_IT_A_COMBO_REJECT)}</div>` +
      '</div>' +
    '</div>';

    this.$el.html(html);

    this.$('#yes-combo-button').onButtonTapOrHold('cuvYesCombo', () => {
      this.onNavToItemWithModifier();
    });

    this.$('.button.no').onButtonTapOrHold('cuvNoCombo', () => {
      this.onNavToItemWithoutModifier();
    });

    return this;
  }
}
