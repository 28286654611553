import type { GcnMenuItem } from '~/types/gcn_menu_item';

export namespace GcnUtils {
  export function getImagePath(imageUrl: string): Promise<string> {
    return new Promise((resolve, reject) => {
      gcn.requestImageByUrl(imageUrl, (err, imagePath) => {
        if (err) {
          reject();
        }
        resolve(imagePath);
      });
    });
  }

  export function recommendationTrackingLabel(
    upsellScreen: string,
    position: number,
    item: GcnMenuItem,
    recoType?: string,
  ): string {
    return [
      upsellScreen,
      ...(recoType ? [`type-${recoType}`] : []),
      `position-${position}`,
      `img-${item.hasImages() ? 1 : 0}`,
    ].join(':');
  }
}
