import $ from 'jquery';
import _ from 'underscore';

import { BackboneEvents } from '~/app/js/backbone-events';

import { GCNView } from './gcn_view';

export const GCNNumberPadView = GCNView.extend({
  className: 'number-pad-view',

  template: _.template(
    // prettier-ignore
    '<div class="row">' +
      '<div role="button" class="button num num-1" id="1">1</div>' +
      '<div role="button" class="button num num-2" id="2">2</div>' +
      '<div role="button" class="button num num-3" id="3">3</div>' +
    '</div>' +
    '<div class="row">' +
      '<div role="button" class="button num num-4" id="4">4</div>' +
      '<div role="button" class="button num num-5" id="5">5</div>' +
      '<div role="button" class="button num num-6" id="6">6</div>' +
    '</div>' +
    '<div class="row">' +
      '<div role="button" class="button num num-7" id="7">7</div>' +
      '<div role="button" class="button num num-8" id="8">8</div>' +
      '<div role="button" class="button num num-9" id="9">9</div>' +
    '</div>' +
    '<div class="row">' +
      '<div role="button" class="button num num-0" id="0">0</div>' +
      '<div role="button" class="button del" id="del" arial-label="delete">x</div>' +
    '</div>',
  ),

  initialize(...args) {
    GCNView.prototype.initialize.apply(this, args);

    this.addRefreshOnLanguageChangeSubscription();
  },

  render() {
    const self = this;

    this.$el.html(this.template());
    // Number buttons.
    this.$('.button.num').each((key, buttonDiv) => {
      const $button = $(buttonDiv);
      $button.onButtonTapOrHold(
        `button_${key}`,
        () => {
          self.trigger(BackboneEvents.GCNNumberPadView.DigitWasPressed, $button.text());
        },
        { allowDoubleTap: true },
      );
    });

    this.$('.button.del').onButtonTapOrHold(
      'button_del',
      () => {
        self.trigger(BackboneEvents.GCNNumberPadView.DeleteWasPressed);
      },
      { allowDoubleTap: true },
    );

    return this;
  },
});
