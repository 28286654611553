import { IonButton, IonCol, IonItem, IonLabel, IonList, IonRow } from '@ionic/react';
import React, { Component } from 'react';

import { ErrorCode, Log, Strings } from '@biteinc/common';
import { CustomerAuthProvider, LoyaltyAuthEntryMethod } from '@biteinc/enums';

import { localizeStr } from '~/app/js/localization/localization';
import { GCNLoyaltyManualAuthView } from '~/app/js/views/gcn_loyalty_manual_auth_view';
import { GCNLoyaltyPhoneNumberView } from '~/app/js/views/gcn_loyalty_phone_number_view';
import { GcnCustomerAccountHelper } from '~/helpers';
import type { Store } from '~/stores';

import type { GcnError } from '../app/js/gcn_bridge_interface';
import { AuthService } from '../services';
import type { GcnReward } from '../types/gcn_reward';
import RewardView from './reward';

type LoyaltyProps = {
  orderIsPartiallyPaid: boolean;
  location: Store['bridge']['location'];
} & Store['loyalty'];

export class Loyalty extends Component<LoyaltyProps> {
  private appliedRewardByMapKey: Record<string, GcnReward>;

  private handleError(err?: GcnError): void {
    if (!err) {
      return;
    }
    /**
     * This error most likely occurs when we are using Paytronix/Olo and the olo oauth token has expired
     */
    if (err.code === ErrorCode.AuthInvalidToken) {
      gcn.loyaltyManager.clearCustomerAuthData();
      this.startLoyaltyLogin();
    }
  }

  private fetchRewards(): void {
    if (gcn.loyaltyManager.hasLoyaltyAuthData()) {
      gcn.loyaltyManager.fetchRewards(this.handleError.bind(this));
      return;
    }
    const customerLoyaltyAuthData = gcn.loyaltyManager.getCustomerAuthData();
    Log.debug('submitting loyalty data', customerLoyaltyAuthData);
    gcn.loyaltyManager.submitLoyaltyAuth(
      customerLoyaltyAuthData.identifier!,
      customerLoyaltyAuthData.authValue,
      customerLoyaltyAuthData.authEntryMethod as LoyaltyAuthEntryMethod,
      (err: GcnError) => {
        // Fallback to manual entry on auth failure
        if (err) {
          gcn.loyaltyManager.clearCustomerAuthData();
          this.startLoyaltyLogin();
          return;
        }
        gcn.loyaltyManager.fetchRewards(this.handleError.bind(this));
      },
    );
  }

  private startLoyaltyLogin(): void {
    if (gcn.loyaltyManager.hasLoyaltyAuthData()) {
      this.fetchRewards();
      return;
    }

    /**
     * As of right now, we only support loyalty login tied to customer accounts if paytronix is used
     */
    if (
      GcnCustomerAccountHelper.customerAccountsAreEnabled() &&
      [CustomerAuthProvider.Paytronix].includes(gcn.org.customerAuthProvider)
    ) {
      // Login
      AuthService.showLogin(this.props.location, () => {
        this.fetchRewards();
      });
      return;
    }

    if (gcn.loyaltyManager.loyaltyUsesPhoneAuth()) {
      gcn.menuView.showStablePopup(
        new GCNLoyaltyPhoneNumberView({
          useNativeInput: true,
          lookupUserMethod: (phoneNumber: string) => {
            if (gcn.loyaltyManager.loyaltyUsesTokenAuth()) {
              gcn.loyaltyManager.submitLoyaltyTokenData(
                phoneNumber,
                LoyaltyAuthEntryMethod.PhoneNumberManuallyEntered,
              );
              Log.debug('guest-authed --> fetch rewards');
              gcn.loyaltyManager.fetchRewards();
            } else {
              gcn.menuView.showSpinner(localizeStr(Strings.LOOKING_UP_ACCOUNT));
              gcn.loyaltyManager.submitLoyaltyAuth(
                phoneNumber,
                null,
                LoyaltyAuthEntryMethod.PhoneNumberManuallyEntered,
                (err: GcnError) => {
                  gcn.menuView.dismissSpinner();
                  if (err) {
                    return;
                  }
                  Log.debug('guest-authed --> fetch rewards');
                  gcn.loyaltyManager.fetchRewards();
                },
              );
            }
          },
        }),
        'loyalty-phone-number-view',
      );
      return;
    }
    const loyaltyManualAuthView = new GCNLoyaltyManualAuthView({
      didAuthGuestCallback: () => {
        gcn.menuView.dismissStablePopup();
        Log.debug('guest-authed --> fetch rewards');
        gcn.loyaltyManager.fetchRewards();
      },
    });
    gcn.menuView.showStablePopup(loyaltyManualAuthView, 'loyalty-manual-auth-view');
  }

  private getStatus(): React.ReactNode {
    const guestName = gcn.loyaltyManager.getAuthedGuestFriendlyName();
    let authedGuestText;
    if (this.props.couponApplied) {
      authedGuestText = localizeStr(Strings.LOYALTY_COUPON_BURNING_INCOMPATIBLE);
    } else if (this.props.rewards?.length) {
      authedGuestText = localizeStr(Strings.LOYALTY_AVAILABLE_REWARDS_LABEL);
    } else {
      authedGuestText = localizeStr(Strings.LOYALTY_NO_REWARDS_LABEL);
    }
    if (guestName) {
      authedGuestText += localizeStr(Strings.LOYALTY_REWARDS_FOR) + ` ${guestName}`;
    }

    return (
      <>
        <IonItem className="status-item">
          <IonLabel className="status-label">
            <h3 className="status">
              <IonRow className="status-row">
                <IonCol className="status-label">
                  {localizeStr(Strings.LOYALTY_BALANCE_LABEL)}
                </IonCol>
                <IonCol className="status-value">{this.props.status}</IonCol>
              </IonRow>
            </h3>
            {localizeStr(Strings.LOYALTY_BALANCE_DESCRIPTION) ? (
              <p className="description">{localizeStr(Strings.LOYALTY_BALANCE_DESCRIPTION)}</p>
            ) : undefined}
          </IonLabel>
        </IonItem>
        <IonItem className="guest-text-item">
          <IonLabel className="guest-text-label">
            <h3 className="guest-text">{authedGuestText}</h3>
          </IonLabel>
        </IonItem>
      </>
    );
  }

  private getRewards(): React.ReactNode | React.ReactNode[] {
    if (!this.props.rewards.length) {
      return (
        <IonItem className="no-rewards">
          <IonLabel>{localizeStr(Strings.LOYALTY_NO_REWARDS_TEXT)}</IonLabel>
        </IonItem>
      );
    }

    const rewardItems: React.ReactNode[] = [];
    this.props.rewards.forEach((reward) => {
      const isClaimed = !!this.appliedRewardByMapKey[reward.appliedRewardMapKey()];
      const disableButton =
        this.props.orderIsPartiallyPaid ||
        (!isClaimed &&
          this.props.appliedRewards.length > 0 &&
          !gcn.loyaltyManager.canRedeemMultipleRewards());
      const rewardView = (
        <RewardView
          reward={reward}
          isDisabled={disableButton}
          hasBeenApplied={isClaimed}
        />
      );
      rewardItems.push(rewardView);
    });
    return rewardItems;
  }

  private getLoyaltyButton(): React.ReactNode {
    return (
      <IonItem>
        <IonButton
          className="get-rewards-button"
          expand="block"
          fill="outline"
          size="default"
          onClick={() => {
            this.startLoyaltyLogin();
          }}
        >
          {localizeStr(Strings.GET_REWARDS)}
        </IonButton>
      </IonItem>
    );
  }

  private mapAppliedRewards(): void {
    if (this.props.appliedRewards) {
      this.appliedRewardByMapKey = this.props.appliedRewards.reduce(
        (rewardById: Record<string, GcnReward>, reward: GcnReward) => {
          rewardById[reward.appliedRewardMapKey()] = reward;
          return rewardById;
        },
        {},
      );
    }
  }

  private getLoyaltyInfo(): React.ReactNode {
    // Don't show loyalty if its not enabled
    if (!gcn.loyaltyManager.supportsAuthedRewards()) {
      return [];
    }
    if (this.props.hasFetchedRewards) {
      this.mapAppliedRewards();
      return (
        <>
          {this.getStatus()}
          {this.getRewards()}
        </>
      );
    }
    return this.getLoyaltyButton();
  }

  componentDidMount(): void {
    if (gcn.loyaltyManager.hasLoyaltyAuthData()) {
      this.fetchRewards();
    }
  }

  render(): React.ReactNode {
    return (
      <IonList
        lines="none"
        className={`loyalty ${this.props.hasFetchedRewards ? 'authed' : ''}`}
      >
        {this.getLoyaltyInfo()}
      </IonList>
    );
  }
}
