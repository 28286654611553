import * as React from 'react';

type AnyFunction = (...args: any[]) => any;

const useLatest = <T extends AnyFunction>(callback?: T): T => {
  const ref = React.useRef<AnyFunction | undefined>(() => {
    throw new Error('Cannot call useLatest handler while rendering.');
  });

  // useInsertionEffect once we're React 18
  React.useLayoutEffect(() => {
    ref.current = callback;
  });

  return React.useCallback<AnyFunction>((...args) => ref.current?.(...args), []) as T;
};

export default useLatest;
