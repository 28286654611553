import { motion } from 'framer-motion';
import * as React from 'react';
import { MdDelete, MdRemoveCircle } from 'react-icons/md';

import { Strings } from '@biteinc/common';
import type { UIOrderedItem } from '@biteinc/core-react';
import { GroupOrderingNameType } from '@biteinc/enums';

import { useLocalize } from '~/app/js/localization/localization';
import ScreenReaderHelper from '~/app/js/screen_reader_helper';
import { ReactAriaButton } from '~/components/button';

import GcnHtml from '../../../app/js/gcn_html';
import Analytics from '../../../app/js/utils/analytics';
import { useMeasure } from '../../../app/js/utils/use-measure';
import { useStore } from '../../../stores';
import { CartUtils } from '../cart.utils';

type OrderedItemProps = {
  item: UIOrderedItem;
};

export function OrderedItem({ item }: OrderedItemProps): JSX.Element {
  const config = useStore((state) => state.config);
  const str = useLocalize();
  const removeItem = useStore((state) => state.cart.removeOrderedItem);
  const editItem = useStore((state) => state.cart.editOrderedItem);
  const itemRef = React.useRef<HTMLDivElement>(null);
  const nameRef = React.useRef<HTMLDivElement>(null);
  const actionsRef = React.useRef<HTMLDivElement>(null);
  const quantityRef = React.useRef<HTMLDivElement>(null);
  const itemRecipientRef = React.useRef<HTMLDivElement>(null);
  const enableMultipleQuantityItems = !!gcn.menu.settings.get('enableMultipleQuantityItems');

  const itemModifierNames = CartUtils.getModsFromPriceOption(item.priceOption)
    .map((mod) => {
      return enableMultipleQuantityItems && mod.priceOption.quantity
        ? `${mod.priceOption.quantity}× ${mod.name}`
        : mod.name;
    })
    .join(', ');

  let recipient: string | undefined = undefined;
  if (gcn.orderManager.getGroupOrderingModeActive()) {
    if (gcn.menu.settings.get('groupOrderingNameType') === GroupOrderingNameType.Name) {
      recipient = item.recipientName!;
    } else if (gcn.menu.settings.get('groupOrderingNameType') === GroupOrderingNameType.Number) {
      recipient = str(Strings.GROUP_ORDERING_GUEST_NUMBER_PREFIX, [
        `${item.recipientNumber}`,
      ]) as string;
    }
  }

  const itemBounds = useMeasure(itemRef);
  const nameBounds = useMeasure(nameRef);
  const actionsBounds = useMeasure(actionsRef);
  const quantityBounds = useMeasure(quantityRef);

  const handleRemoveItemScreenReaderActions = (): void => {
    if (config.screenReaderIsActive) {
      const itemsInCart = document.querySelectorAll(
        '.cart-view__collapsible-view__orders__item-container',
      );
      const cartAmountSpan = document.querySelector('.cart-view__top-bar__cart__amount');
      if (cartAmountSpan instanceof HTMLElement && itemsInCart.length > 1) {
        // If not removing the last item, focus on the amount span after a delay.
        setTimeout(() => {
          cartAmountSpan?.focus();
        }, 1000);
      } else {
        // Notify user its the main menu, when removing the last item.
        gcn.showNativeToast('0 items in cart. This is the main menu.');
      }
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="cart-view__collapsible-view__orders__item-container"
      key={item._id}
    >
      <div className="cart-view__collapsible-view__orders__item-delete-indicator">
        <span>
          <MdDelete className="tw-h-8 tw-w-8" />
        </span>
        <span>
          <MdDelete className="tw-h-8 tw-w-8" />
        </span>
      </div>
      <motion.div
        ref={itemRef}
        drag="x"
        dragConstraints={{ left: 0, right: 0 }}
        dragElastic={0.8}
        onDragEnd={(event, info) => {
          const y = Math.abs(info.delta.y);
          // means theres a lot of drag on the y axis
          if (y > 200) {
            return;
          }
          // if its higher than 30% of the width, remove it
          if (info.offset.x > 0.3 * info.point.x || info.offset.x < -0.3 * info.point.x) {
            // bite-9973
            // Used to prevent trying to delete an item twice when swiping with the remove button being active
            // If the button is active but we swipe to delete, we should not delete the item again
            // eslint-disable-next-line no-underscore-dangle
            if (gcn.orderManager.hasOrderedItemWithId(item._id)) {
              // eslint-disable-next-line no-underscore-dangle
              removeItem(item._uid);
            }
          }
        }}
        className="cart-view__collapsible-view__orders__item"
      >
        <div
          className="cart-view__collapsible-view__orders__item__name-modifier"
          aria-label={`${ScreenReaderHelper.prepareAriaLabel(
            `${item.name}, ${itemModifierNames}`,
          )}, quantity selected is ${item.priceOption.quantity}`}
        >
          <span
            ref={quantityRef}
            className="cart-view__collapsible-view__orders__item__quantity"
            aria-hidden={true}
          >
            {item.priceOption.quantity}×
          </span>
          <span
            ref={nameRef}
            className="cart-view__collapsible-view__orders__item__name-modifier__name"
            aria-hidden={true}
          >
            {item.name}
          </span>
          <span
            className="cart-view__collapsible-view__orders__item__name-modifier__modifier"
            style={{
              // the modifier's max width should be item width - name width - actions width - quantity width - padding
              maxWidth: `${
                itemBounds.width -
                nameBounds.width -
                actionsBounds.width -
                quantityBounds.width -
                42
              }px`,
            }}
            aria-hidden={true}
          >
            {itemModifierNames}
          </span>
          {recipient ? (
            <span
              className="cart-view__collapsible-view__orders__item__name-modifier__recipient"
              ref={itemRecipientRef}
            >{`${str(Strings.ITEM_RECIPIENT)}: ${recipient}`}</span>
          ) : null}
        </div>
        <div
          ref={actionsRef}
          className="cart-view__collapsible-view__orders__item__options"
        >
          <span
            className="cart-view__collapsible-view__orders__item__options__price"
            aria-label={`Price: $${GcnHtml.stringFromPrice(item.total)}`}
          >
            {CartUtils.formatPrice(item.total)}
          </span>
          {!config.screenReaderIsActive && (
            <ReactAriaButton
              aria-label={ScreenReaderHelper.prepareAriaLabel(`Edit ${item.name} from cart`)}
              className="tw-text-xl tw-underline tw-bg-transparent cart-view__collapsible-view__orders__item__options__edit"
              onClick={() => {
                Analytics.trackEvent({
                  eventName: Analytics.EventName.CartMenuItemEditStart,
                  eventData: {
                    itemId: item._id,
                    itemName: item.name,
                    itemPosId: item.posId,
                  },
                });

                // eslint-disable-next-line no-underscore-dangle
                editItem(item._uid);
              }}
            >
              {str(Strings.EDIT)}
            </ReactAriaButton>
          )}
          <ReactAriaButton
            aria-label={ScreenReaderHelper.prepareAriaLabel(`Remove ${item.name} from cart`)}
            className="cart-view__collapsible-view__orders__item__options__remove"
            onPress={() => {
              // eslint-disable-next-line no-underscore-dangle
              removeItem(item._uid);
              handleRemoveItemScreenReaderActions();
            }}
          >
            <MdRemoveCircle className="tw-h-8 tw-w-8" />
          </ReactAriaButton>
        </div>
      </motion.div>
    </motion.div>
  );
}
