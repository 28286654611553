import $ from 'jquery';

import { Log } from '@biteinc/common';
import { BitePlatform } from '@biteinc/enums';

window.clickCountByButtonName = {};
window.tapCountByButtonName = {};
window.touchStartCountByButtonName = {};
window.touchEndCountByButtonName = {};
window.touchHoldCountByButtonName = {};

function addTouchCalibrationLog(buttonName, message) {
  if (!gcn.$touchCalibrationDebugLogPane) {
    return;
  }
  const existingText = gcn.$touchCalibrationDebugLogPane.text().trim();
  const newLogLine = `[${buttonName}] ${message}`;
  if (existingText) {
    gcn.$touchCalibrationDebugLogPane.text(`${existingText}\n${newLogLine}`);
  } else {
    gcn.$touchCalibrationDebugLogPane.text(newLogLine);
  }
  gcn.$touchCalibrationDebugLogPane.animate(
    {
      scrollTop: gcn.$touchCalibrationDebugLogPane[0].scrollHeight,
    },
    100,
  );
}

$.fn.onButtonTapOrHold = function buttonTapOrHoldHandler(buttonName, handler, options = {}) {
  const { allowDoubleTap } = options;
  /**
   * This regex is related to the one in analytics.ts but omits:
   * - the first char requirement since the eventual event param name won't start with buttonName
   * - maxLength is shorted to 24 to make room for the event param name prefix and a _
   */
  const regexCondition = new RegExp(/^[a-zA-Z0-9_]{1,24}$/);
  if (!regexCondition.test(buttonName)) {
    Log.error(`Non GA-Complaint button name found: ${buttonName}`);
    throw new Error(`Non GA-Complaint button name found: ${buttonName}`);
  }

  // Use only on kiosk
  if (window.isFlash || window.platform === BitePlatform.KioskSignageOsGarcon) {
    $(this).off('click');
    $(this).on('click', handler);
    return;
  }

  let holdToClickTimeout;
  let touchStartedAt = 0;
  let lastClickAt = 0;
  let skipOnClick = false;

  $(this).off('click');
  $(this).on('click', (e) => {
    if (holdToClickTimeout) {
      clearTimeout(holdToClickTimeout);
      holdToClickTimeout = null;
    }
    addTouchCalibrationLog(buttonName, 'actual click');
    const timeSinceLastClick = Date.now() - lastClickAt;
    // I haven't been able to get a lag of more than 550ms when trying to double-tap myself.
    if (!allowDoubleTap && timeSinceLastClick < 550) {
      addTouchCalibrationLog(buttonName, 'ignoring second click');
    } else {
      if (skipOnClick) {
        e.preventDefault();
        e.stopPropagation();
      } else {
        handler(e);
      }
    }

    lastClickAt = Date.now();
    setTimeout(() => {
      window.clickCountByButtonName[buttonName] =
        (window.clickCountByButtonName[buttonName] || 0) + 1;
    }, 1);
    return false;
  });

  $(this).off('touchstart');
  $(this).on('touchstart', (e) => {
    skipOnClick = false;
    touchStartedAt = Date.now();
    addTouchCalibrationLog(buttonName, 'hold-start');
    holdToClickTimeout = setTimeout(() => {
      const touchDuration = Date.now() - touchStartedAt;
      addTouchCalibrationLog(buttonName, `hold->click after ${touchDuration}`);
      e.preventDefault();

      const timeSinceLastClick = Date.now() - lastClickAt;
      // I haven't been able to get a lag of more than 550ms when trying to double-tap myself.
      if (!allowDoubleTap && timeSinceLastClick < 550) {
        addTouchCalibrationLog(buttonName, 'ignoring second click');
      } else {
        addTouchCalibrationLog(buttonName, `touchstart handler ${Date.now() - lastClickAt}`);
        handler(e);
      }

      lastClickAt = Date.now();
      holdToClickTimeout = null;
      touchStartedAt = 0;

      setTimeout(() => {
        window.touchHoldCountByButtonName[buttonName] =
          (window.touchHoldCountByButtonName[buttonName] || 0) + 1;
      }, 1);
    }, gcn.touchCalibrationConfig.maxHoldToClickTimeMs);

    setTimeout(() => {
      window.touchStartCountByButtonName[buttonName] =
        (window.touchStartCountByButtonName[buttonName] || 0) + 1;
    }, 1);
  });

  $(this).off('touchend');
  $(this).on('touchend', (e) => {
    const touchDuration = Date.now() - (touchStartedAt || lastClickAt);
    addTouchCalibrationLog(buttonName, `hold-end w/o click after ${touchDuration}`);
    touchStartedAt = 0;

    if (holdToClickTimeout) {
      clearTimeout(holdToClickTimeout);
      holdToClickTimeout = null;

      // If touchStartedAt and lastClick are both zero, but we captured a touchend event,
      // then the user tapped and released before touchstart fired. We should fire the
      // handler in this case.
      if (touchStartedAt + lastClickAt === 0) {
        addTouchCalibrationLog(buttonName, 'handle light click from touchend');
        skipOnClick = true;
        e.preventDefault();
        handler(e);
      }
    } else {
      // If we don't prevent default, the click event will fire after a hold-to-click
      // already fired above (since the timeout reached it's end).
      e.preventDefault();
    }

    setTimeout(() => {
      window.touchEndCountByButtonName[buttonName] =
        (window.touchEndCountByButtonName[buttonName] || 0) + 1;
    }, 1);
  });
};

function getTouchCoordinates(event) {
  if (event.originalEvent.changedTouches?.length) {
    const touch = event.originalEvent.changedTouches[0];
    return {
      x: touch.pageX,
      y: touch.pageY,
    };
  }
  if (event.originalEvent.pageX >= 0 && event.originalEvent.pageY >= 0) {
    return {
      x: event.originalEvent.pageX,
      y: event.originalEvent.pageY,
    };
  }
  return null;
}

// a function here to add either htmlstring or string
// call this function for customStrings
$.fn.htmlOrText = function htmlOrText(content) {
  const containsHTML = /<\/?[a-z][\s\S]*>/i.test(content);
  if (containsHTML) {
    return $(this).html(content);
  }
  return $(this).text(content);
};

// We have discovered that even on iOS, taps aren't being registered with enough fidelity due to the
// angle at which the guest usually stands to the kiosk. It's quite different from how we touch our
// phones. It seems that quite if a kiosk is directly in front the of the guest than their touch
// will use a different part of their finger and the OS will interpret it as more of a micro scroll
// than a tap.
// To remedy this, we have a defined a touchCalibrationConfig for each OS that defines if we can
// interpret a micro scroll as a tap. So in addition to listening to `click` events, we also listen
// for `touchstart` and `touchend` (that precede every `click`) to see if the time between those two
// events and the distance that the touch traveled in between fall below the thresholds defined in
// the touchCalibrationConfig.
$.fn.onTapInScrollableAreaWithCalibration = function onTapInScrollableAreaWithCalibration(
  buttonName,
  handler,
) {
  // Use only on kiosk
  if (window.isFlash || window.platform === BitePlatform.KioskSignageOsGarcon) {
    $(this).off('click');
    $(this).on('click', handler);
    return;
  }

  let touchStartedAt = 0;
  let touchStartPoint = null;

  $(this).off('touchstart');
  $(this).on('touchstart', (event) => {
    event.stopPropagation();

    addTouchCalibrationLog(buttonName, 'TouchCalibration: touchstart');
    touchStartPoint = getTouchCoordinates(event);
    touchStartedAt = Date.now();
  });

  $(this).off('touchend');
  $(this).on('touchend', (event) => {
    event.stopPropagation();

    Log.debug(`TouchCalibration: touchend [${buttonName}]`);

    const touchDuration = Date.now() - touchStartedAt;
    const touchEndPoint = getTouchCoordinates(event);
    if (!touchEndPoint || !touchStartPoint) {
      addTouchCalibrationLog(buttonName, 'TouchCalibration: missing touch start/end points');
      return;
    }

    // Calculate the distance between the two touches using the Pythagorean
    const touchXMovement = touchEndPoint.x - touchStartPoint.x;
    const touchYMovement = touchEndPoint.y - touchStartPoint.y;
    const touchXYMovement = Math.round(Math.sqrt(touchXMovement ** 2 + touchYMovement ** 2));

    const dX = Math.round(touchXMovement);
    const dY = Math.round(touchYMovement);
    addTouchCalibrationLog(
      buttonName,
      `TouchEnd. dX=${dX} dY=${dY} dXY=${touchXYMovement} dT=${touchDuration}`,
    );

    if (
      touchDuration < gcn.touchCalibrationConfig.minTouchDurationThresholdForClick &&
      touchXYMovement < gcn.touchCalibrationConfig.minTouchXYMovementThresholdForClick
    ) {
      Log.debug(`[${buttonName}] TouchCalibration: converting touchend into a tap`);
      addTouchCalibrationLog(buttonName, 'touch-end -> TAPPED');

      handler(event);

      // Make sure the click isn't triggered after this
      event.preventDefault();

      // Update stats to say that we had a calculated tap here
      setTimeout(() => {
        window.tapCountByButtonName[buttonName] =
          (window.tapCountByButtonName[buttonName] || 0) + 1;
      }, 1);
    }
  });

  $(this).off('click');
  $(this).on('click', (event) => {
    event.stopPropagation();

    Log.debug(`[${buttonName}] TouchCalibration: click`);

    const touchDuration = Date.now() - touchStartedAt;
    addTouchCalibrationLog(buttonName, `Clicked after: ${touchDuration}`);

    handler(event);

    // Update stats to say that we had a proper click here
    setTimeout(() => {
      window.clickCountByButtonName[buttonName] =
        (window.clickCountByButtonName[buttonName] || 0) + 1;
    }, 1);
  });
};

$.fn.fastTouchFeedback = function fastTouchFeedbackHandler() {
  $(this).on('touchstart', function fastTouchFeedbackTouchStartHandler() {
    $(this).toggleClass('touched', true);
  });
  $(this).on('touchend', function fastTouchFeedbackTouchEndHandler() {
    $(this).toggleClass('touched', false);
  });
};

$.fn.canVerticalScroll = function canVerticalScrollHandler(threshold) {
  return (
    this[0].scrollHeight - (window.innerHeight || this[0].clientHeight) >
    (threshold === undefined ? 0 : threshold)
  );
};

/*
 * Helper for animating a CSS property that provides a couple things
 * - Guarantees a callback
 * - Automatically cleans up old callbacks when starting a new animation of the same property
 *
 * [options] will currently accept a few fields
 * - [duration]: Duration in ms
 * - [beforeTransition]: Function that will be executed before transition. Useful for setup
 * - [afterTransition]: Function that will be executed after transition. Useful for teardown
 *
 * Example usage:
 *
 * animateProperty($element, 'opacity', 1, {
 *   duration: 200,
 *   beforeTransition() {
 *     $element.css('display', 'block');
 *   }
 * }, callback)
 */
function animateProperty($element, property, value, options, callback) {
  const kBackupCallbackKey = 'backupCallback';
  const kTransitionEndEvent = 'transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd';

  const animatingFlag = `animating_${property}`;
  const duration = options.duration || 400;
  const beforeTransition = options.beforeTransition || function noOpBeforeFunction() {};
  const afterTransition = options.afterTransition || function noOpAfterFunction() {};

  // If the end-state of the animation is identical to the current state, then the transitionEnd
  // callback won't be fired. In this case, we need to set a timeout that runs shortly after we
  // would have expected the transition to end, and if we didn't receive the callback, then we
  // can now still perform the post-transition operations
  let done = false;
  function transitionEnded() {
    $element.data(animatingFlag, false);
    $element.unbind(kTransitionEndEvent);
    clearTimeout($element.data(kBackupCallbackKey));

    afterTransition();

    if (callback) {
      callback();
    }
  }

  if ($element.data(animatingFlag) === true) {
    // Kill the previous listener
    $element.unbind(kTransitionEndEvent);
    clearTimeout($element.data(kBackupCallbackKey));
  }

  $element.data(animatingFlag, true);
  $element.bind(kTransitionEndEvent, (e) => {
    if (e.originalEvent.propertyName !== property) {
      return;
    }

    done = true;
    transitionEnded();
  });

  $element.data(
    kBackupCallbackKey,
    setTimeout(() => {
      if (!done) {
        transitionEnded();
      }
    }, duration + 100),
  );

  beforeTransition();
  $element.css('transition', `${property} ${duration}ms`);
  $element.css(property, value);
}

/*
 * Adds an element to the DOM in preparation for cssFadeIn(), so that you can make last-minute
 * changes which depend on the element size/position on the screen. If you don't need to do this,
 * just call cssFadeIn() directly
 */
$.fn.prepareCssFadeIn = function prepareCssFadeInHandler(display) {
  const self = $(this);

  self.css('display', display || 'block');
  self.css('opacity', '0');
};

/*
 * Pure CSS alternative to JQuery fadeIn()
 */
$.fn.cssFadeIn = function cssFadeInHandler(display, callback) {
  const self = $(this);

  animateProperty(
    self,
    'opacity',
    1,
    {
      beforeTransition() {
        self.prepareCssFadeIn(display);
      },
    },
    callback,
  );
};

/*
 * Pure CSS alternative to JQuery fadeOut()
 */
$.fn.cssFadeOut = function cssFadeOutHandler(callback) {
  const self = $(this);

  animateProperty(
    self,
    'opacity',
    0,
    {
      afterTransition() {
        self.css('display', 'none');
      },
    },
    callback,
  );
};

/*
 * Appear with a delayed scale-in animation. Useful for nice icon transitions to grab attention.
 */
$.fn.delayedScaleIn = function delayedScaleInHandler() {
  const self = $(this);

  self.css('transform', 'scale(0.0)');
  setTimeout(() => {
    self.css('transition', 'transform 0.3s');
    self.css('transform', 'scale(1.0)');
  }, 600);
};

/**
 * @description This function is mainly used when the screen reader is active.
 * Call it to request focus for headers or other important elements so that the screen reader
 * announces them when they appear on the screen.
 */
$.fn.requestFocusAfterDelay = function requestFocusAfterDelayHandler() {
  const $el = $(this);
  window.setTimeout(() => {
    $el.trigger('focus');
  }, 500);
};
