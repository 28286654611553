import $ from 'jquery';
import _ from 'underscore';

import { Strings } from '@biteinc/common';
import { MenuItemDisplayStyle } from '@biteinc/enums';

import { localizeStr } from '~/app/js/localization/localization';
import { useStore } from '~/stores';

import ScreenReaderHelper from '../screen_reader_helper';
import Analytics from '../utils/analytics';
import { GCNAlertView } from './gcn_alert_view';
import { GCNMenuItemCellView } from './gcn_menu_item_cell_view';
import { GCNView } from './gcn_view';

export const GCNMenuSectionView = GCNView.extend({
  className: 'section-view clearfix',
  template: _.template(
    // prettier-ignore
    '<div class="section-header" role="heading" aria-level="1" aria-label="<%= ariaLabelName %>" tabindex="-1">' +
      '<div class="scrim" aria-hidden="true"></div>' +
      '<span class="name font-title" aria-hidden="true"><%= name %></span>' +
    '</div>' +
    '<% if (description) { %>' +
      '<div class="section-description font-body" aria-label="<%= ariaLabelDescription %>"><%= description %></div>' +
    '<% } %>' +
    '<div class="section-body"></div>' +
    '<% if (footnote) { %>' +
      '<div class="section-footnote" aria-label="<%= ariaLabelFootnote %>"><%= footnote %></div>' +
    '<% } %>' +
    '<% if (optOutOfDelphi) { %>' +
      '<div class="opt-out-button"><%= optOutOfDelphiStr %></div>' +
    '<% } %>',
  ),

  initialize(options, ...args) {
    GCNView.prototype.initialize.apply(this, [options, ...args]);

    $(window).on('resize', this._adjustColumnWidths.bind(this));
    this._showAllImages = this.model.get('showAllPhotoPreviews');
    this._maxItemDisplayStyle = options.maxItemDisplayStyle || MenuItemDisplayStyle.Wide;
  },

  _numberOfColumnsForWidth() {
    const bodyWidth = $('body').width();
    if (bodyWidth < 600) {
      return 1;
    }
    if (bodyWidth > 800 && !gcn.location.useSideNavMenu()) {
      return 3;
    }
    return 2;
  },

  _displayStyleForItem(item) {
    const itemDisplayStyle = item.get('displayStyle');
    if (itemDisplayStyle === MenuItemDisplayStyle.NameDescriptionAndImage) {
      return itemDisplayStyle;
    }

    if (
      Math.min(itemDisplayStyle, this._maxItemDisplayStyle) ===
        MenuItemDisplayStyle.NameAndDescription &&
      this._showAllImages
    ) {
      return MenuItemDisplayStyle.NameAndImage;
    }

    return itemDisplayStyle;
  },

  // Returns the width in pixels of cells that fit across `numberOfColumns` columns. Must be
  // called after the cells are rendered in the container.
  _calculateCellWidth(numberOfColumns) {
    const $container = this.$('.column-container .column:first');
    const $firstCell = $container.find('.item-cell-view:first');

    const width = $container.width();
    const padding =
      parseInt($firstCell.css('margin-left'), 10) + parseInt($firstCell.css('margin-right'), 10);
    return Math.floor((width - padding * numberOfColumns) / numberOfColumns) - 1;
  },

  _adjustColumnWidths() {
    const cellWidth = this._calculateCellWidth(this._numberOfColumnsForWidth());
    if (cellWidth > 0) {
      this.$('.column-container .column .item-cell-view:not(.wide)').css('width', cellWidth);
    }
  },

  _unEnrollGuest() {
    const alertText = localizeStr(Strings.DELPHI_OPT_OUT, [], function (string) {
      return string.split('\n').join('<br />');
    });
    const confirmView = new GCNAlertView({
      text: alertText,
      okCallback() {
        gcn.menuView.dismissModalPopup();
        gcn.menuView.showSpinner(localizeStr(Strings.DELPHI_OPT_OUT_SPINNER));
        gcn.guestManager.forgetGuest((err) => {
          if (err) {
            gcn.menuView.showSimpleAlert(localizeStr(Strings.DELPHI_OPT_OUT_FAILURE));
          } else {
            gcn.menuView.showSimpleAlert(localizeStr(Strings.DELPHI_OPT_OUT_SUCCESS));
          }
          gcn.menuView.dismissSpinner();
        });
      },
      cancelCallback() {
        gcn.menuView.dismissModalPopup();
      },
    });
    gcn.menuView.showModalPopup(confirmView);
  },

  render() {
    const self = this;

    const name = this.model.displayNameHtml();
    const params = {
      name,
      ariaLabelName: ScreenReaderHelper.prepareAriaLabel(
        `${name}, Press down to hear menu items for this category.`,
      ),
      description: null,
      ariaLabelDescription: null,
      footnote: null,
      optOutOfDelphi: false,
      optOutOfDelphiStr: localizeStr(Strings.OPT_OUT_OF_RECOMMENDATIONS),
    };
    if (this.model.displayDescriptionHtml().length) {
      params.description = this.model.displayDescriptionHtml();
      params.ariaLabelDescription = ScreenReaderHelper.prepareAriaLabel(
        this.model.displayDescription(),
      );
    }
    if (this.model.get('footnote')) {
      params.footnote = this.model.get('footnote');
      params.ariaLabelFootnote = ScreenReaderHelper.prepareAriaLabel(this.model.get('footnote'));
    }
    if (
      this.model.get('isPromoSection') &&
      gcn.guestManager.guestWasRecognized() &&
      gcn.location.get('usesDelphi') &&
      gcn.menu.settings.get('canOptOutOfDelphi')
    ) {
      params.optOutOfDelphi = true;
    }
    this.$el.html(this.template(params));
    this.$sectionHeader = this.$('.section-header');
    this.$sectionBody = this.$('.section-body');

    this.$el.addClass(`section-${this.model.id}`);
    this.$el.addClass(`section-${this.model.getSlugName()}`);

    const images = this.model.get('images');
    const headerUrl = this.model.hasImages()
      ? images[images.length - 1].url
      : gcn.menu.getDefaultSectionHeaderImages()[0]?.url;
    if (headerUrl) {
      gcn.requestImageByUrl(headerUrl, (err, imgPath) => {
        this.$sectionHeader.css('background-image', `url(${imgPath})`);
      });
    }

    /**
     * Enable a user to opt of Delphi if enabled
     */
    if (
      this.model.get('isPromoSection') &&
      gcn.guestManager.guestWasRecognized() &&
      gcn.location.get('usesDelphi') &&
      gcn.menu.settings.get('canOptOutOfDelphi')
    ) {
      const $optOutButton = this.$('.opt-out-button');
      $optOutButton.onButtonTapOrHold('msvOptOut', () => {
        Analytics.track(Analytics.EventName.DelphiOptOut);
        self._unEnrollGuest();
      });
    }

    const menuFilters = useStore.getState().config.menuFilters;

    const hasFilters =
      this.model.canBeFiltered() && _.some(menuFilters, (value) => value.length > 0);

    // There are two types of filters for badges: Exclusive and Inclusive
    // Any 'Restrictive' filters are exclusive: We hide all items with these badges from the menu
    // 'Dietary' and 'Other' filters are inclusive: We only show items with the matching badge
    const filteredItems = hasFilters
      ? this.model.items.filter((item) => {
          return item.matchesBadgesInSet(menuFilters);
        })
      : this.model.items;

    if (!filteredItems.length) {
      const $emptyDiv = $('<div class="no-matching-items font-body"></div>');
      $emptyDiv.html(localizeStr(Strings.NO_ITEMS_MATCH_FILTERS));
      self.$sectionBody.append($emptyDiv);
      return this;
    }

    const $columnContainer = $('<div class="column-container clearfix"></div>');
    self.$sectionBody.append($columnContainer);

    const $column = $('<div class="column clearfix"></div>');
    $columnContainer.append($column);

    filteredItems.forEach((item) => {
      const displayStyle = self._displayStyleForItem(item);
      const itemView = new GCNMenuItemCellView({
        model: item,
        sectionId: self.model.id,
        displayStyle,
        priceDisplay: self.model.get('priceDisplay'),
        hideCalories: gcn.menu.shouldDeemphasizeCalories(),
      });

      $column.append(itemView.render().$el);
    });

    setTimeout(() => {
      self._adjustColumnWidths();
    }, 1);

    if (this.model.attributes.hideFromMenu) {
      this.$el.hide();
    }

    return this;
  },
});
