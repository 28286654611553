import * as React from 'react';

import { OrderDetailsUtils } from './order-details.utils';

export function CheckInInstructions({
  futureTimeStr,
  checkinUrl,
}: {
  futureTimeStr: string;
  checkinUrl: string;
}): JSX.Element | null {
  return (
    <div className="v2-checkin tw-flex tw-flex-col tw-space-y-4 tw-text-lg tw-border-b tw-border-b-gray-300 tw-pb-4">
      <div>
        <h2 className="v2-checkin-title tw-text-xl tw-font-bold tw-text-gray-800 tw-m-0">
          Order Instructions
        </h2>
        <span className="v2-checkin-time">Order set for: {futureTimeStr}</span>
      </div>

      <p className="v2-checkin-instructions">{OrderDetailsUtils.getCheckinMessage()}</p>

      <a
        className="v2-checkin-cta tw-bg-[var(--color-primary)] tw-text-white tw-p-2 tw-rounded tw-text-center tw-font-bold tw-text-lg tw-w-full tw-block tw-mt-4 hover:tw-opacity-40"
        href={checkinUrl}
      >
        Check-In Now
      </a>
    </div>
  );
}
