import type { OrderedItem } from '@biteinc/core-react';
import type {
  DeprecatedRecommendationSource,
  FulfillmentMethod,
  RecommendationEventName,
  RecommendationEventSource,
  RecommendationSource,
  RecommendationVersion,
  TriggerEventName,
} from '@biteinc/enums';

import type { GcnMenuItem } from './gcn_menu_item';
import type { GcnOrderedItem } from './gcn_ordered_item';

// gcn-only enum
export enum RecommendationDisplayLocationDescription {
  MENU_SECTION_MENU_ITEM_HIGHLIGHT = 'menu-section-menu-item-highlight',
  MENU_ITEM_CUSTOMIZE_VIEW = 'menu-item-customize-view',
  PRE_CHECKOUT_POPUP = 'pre-checkout-popup',
  MENU_ITEM_CONFIRMATION_STEP = 'menu-item-confirmation-step',
  MENU_ITEM_MINI_RECOS = 'menu-item-mini-recos',
  CART_VIEW = 'cart-view',
}

// keep in sync with recommendation_types
type RecommendationMetadata = Readonly<{
  formatName: string;
  data: Record<string, any>;
}>;

// keep in sync with recommendation_types
type RecommendationModifier = Readonly<{
  id: string;
  name: string;
  price: number;
  metadata: RecommendationMetadata[];
}>;

// keep in sync with recommendation_types
export type SelectedItem = Readonly<{
  id: string;
  name: string;
  price?: number;
  sectionId?: string;
  // flattened
  modifiers: RecommendationModifier[];
  metadata: RecommendationMetadata[];
}>;

// keep in sync with recommendation_types
export type CartItem = Readonly<{
  id: string;
  name: string;
  price: number;
  sectionId?: string;
  // flattened
  modifiers: RecommendationModifier[];
  metadata: RecommendationMetadata[];
}>;

// keep in sync with recommendations_request_interfaces
export type SessionRecommendationBody = Readonly<{
  triggerEventName: TriggerEventName;
  guestId?: string;
  guestPhoneNumber?: string | null;
  fulfillmentMethod: FulfillmentMethod | null;
  menuStructureId: string;
  pickupAt?: number;
  selectedItem?: SelectedItem;
  cartItems?: CartItem[];
  restrictionBadgeIds?: string[];
  dietaryBadgeIds?: string[];
  preferenceBadgeIds?: string[];
  recommendationVariationId?: string;
}>;

// keep in sync with recommendations_request_interfaces
export type ModifierRecommendationBody = Readonly<{
  triggerEventName: TriggerEventName.ViewModGroup;
  guestId?: string;
  guestPhoneNumber?: string | null;
  fulfillmentMethod: FulfillmentMethod | null;
  pickupAt?: number;

  selectedItem: SelectedItem;
  modGroupId: string;
  virtualSubGroupId?: string;
  menuStructureId: string;

  restrictionBadgeIds?: string[];
  dietaryBadgeIds?: string[];
  preferenceBadgeIds?: string[];
  recommendationVariationId?: string;
}>;

export type ItemAndModRecommendationBody = Readonly<{
  triggerEventName: TriggerEventName.ViewItem;
  guestId?: string;
  guestPhoneNumber?: string | null;
  fulfillmentMethod: FulfillmentMethod;
  pickupAt?: number;
  cartItems: CartItem[];

  recommendationVariationId?: string;

  menuStructureId: string;

  modGroups: {
    id: string;
    virtualSubGroupId?: string;
  }[];

  selectedItem: SelectedItem;

  restrictionBadgeIds?: string[];
  dietaryBadgeIds?: string[];
  preferenceBadgeIds?: string[];
}>;

export type Recommendation = Readonly<{
  recommendationVersion: RecommendationVersion;
  recoId: string;
  itemId: string;
  source: RecommendationSource | DeprecatedRecommendationSource;
  orderedItem?: OrderedItem;
}>;

// keep in sync with recommendation_types
export type RecommendationEventGuestAddToCartBody = Readonly<{
  selectedRecommendation: Recommendation;
  clientItemReference: {
    biteId: string;
    posId?: string;
    subTotal: number;
    quantity: number;
  };
}>;

// keep in sync with recommendation_types
export type RecommendationEventOrderReferenceBody = Readonly<{
  orderReference: {
    biteId: string;
  };
}>;

// keep in sync with recommendation_types
export type RecommendationEventDisplayRecommendationsBody = Readonly<{
  displayLocationDescription: string;
  displayedRecommendations: Recommendation[];
  displayContext?: Record<string, any>;
}>;

// keep in sync with recommendation_types
export type RecommendationEventSessionReferenceBody = Readonly<{
  sessionReference: {
    sessionId: string;
  };
}>;

// keep in sync with recommendation_types
export type RecommendationEventSessionDurationBody = Readonly<{
  startAt: number;
  endAt: number;
  duration: number;
}>;

// keep in sync with recommendation_types
export type RecommendationEventBody =
  | RecommendationEventGuestAddToCartBody
  | RecommendationEventDisplayRecommendationsBody
  | RecommendationEventOrderReferenceBody
  | RecommendationEventSessionReferenceBody
  | RecommendationEventSessionDurationBody;

export type RecommendationEvent<Body extends RecommendationEventBody> = Readonly<{
  name: RecommendationEventName;
  eventSource: RecommendationEventSource;
  eventSourceVersion: string;
  eventAt: number;
  recommendationResultId: string;
  body: Body;
}>;

// dependency injection types
// keep in sync with recommendations_request_interfaces
export type FetchRecommendationsResponse = Readonly<{
  recommendationResultId: string;
  recommendations: Recommendation[];
  recommendationVariationId: string;
  guestId?: string;
}>;

export type FetchItemAndModGroupRecommendationsResponse = Readonly<{
  itemRecommendations: Recommendation[];
  modGroupRecommendationsById: Record<string, Recommendation[]>;
  recommendationResultId: string;
  recommendationVariationId: string;
  guestId?: string;
}>;

export type GuestIdentifiers = {
  guestId?: string;
  guestPhoneNumber?: string | null;
};

type FetchFirstLoadRecommendations = (
  menuStructureId: string,
  guestIdentifiers: GuestIdentifiers,
  cartOrderedItems: GcnOrderedItem[],
) => Promise<FetchRecommendationsResponse>;

type FetchItemRecommendations = (
  guestIdentifiers: GuestIdentifiers,
  selectedOrderedItem: GcnOrderedItem,
  cartOrderedItems: GcnOrderedItem[],
  recommendationVariationId: string,
) => Promise<FetchRecommendationsResponse>;

type FetchPreCheckoutRecommendations = (
  guestIdentifiers: GuestIdentifiers,
  cartOrderedItems: GcnOrderedItem[],
  recommendationVariationId: string,
) => Promise<FetchRecommendationsResponse>;

type FetchSideCartRecommendations = (
  guestIdentifiers: GuestIdentifiers,
  cartOrderedItems: GcnOrderedItem[],
  recommendationVariationId: string,
) => Promise<FetchRecommendationsResponse>;

type FetchModGroupRecommendations = (
  menuStructureId: string,
  selectedOrderedItem: GcnMenuItem,
  modGroupId: string,
  virtualSubGroupId?: string,
) => Promise<FetchRecommendationsResponse>;

type FetchBatchedRecommendations = (
  recommendationVariationId: string,
  guestIdentifiers: GuestIdentifiers,
  selectedOrderedItem: GcnOrderedItem,
  cartOrderedItems: GcnOrderedItem[],
  modGroupIds: string[],
  virtualSubGroupIds: (string | undefined)[],
) => Promise<FetchItemAndModGroupRecommendationsResponse>;

type SendRecommendationEventDisplayRecommendations = (
  displayLocationDescription: RecommendationDisplayLocationDescription,
  recommendations: Recommendation[],
  recommendationResultId: string,
  displayContext?: Record<string, any>,
) => Promise<void>;

type SendRecommendationEventOrderReference = (
  recommendationResultId: string,
  orderId: string,
) => Promise<void>;

export type RecommendationEventGuestAddToCartParams = Readonly<{
  recommendationResultId: string;
  selectedRecommendation: Recommendation;
  itemId: string;
  subTotal: number;
  quantity: number;
  posId?: string;
}>;

type SendRecommendationEventGuestAddToCart = (
  params: RecommendationEventGuestAddToCartParams,
) => Promise<void>;

export type RecommendationEngine = {
  fetchFirstLoadRecommendations: FetchFirstLoadRecommendations;
  fetchItemRecommendations: FetchItemRecommendations;
  fetchPreCheckoutRecommendations: FetchPreCheckoutRecommendations;
  fetchSideCartRecommendations: FetchSideCartRecommendations;
  fetchModGroupRecommendations: FetchModGroupRecommendations;
  fetchBatchedRecommendations: FetchBatchedRecommendations;
  sendRecommendationEventDisplayRecommendations: SendRecommendationEventDisplayRecommendations;
  sendRecommendationEventOrderReference: SendRecommendationEventOrderReference;
  sendRecommendationEventGuestAddToCart: SendRecommendationEventGuestAddToCart;
};
