import * as Sentry from '@sentry/browser';
import async from 'async';
import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom/client';
import _ from 'underscore';

import { Log, Strings } from '@biteinc/common';
import { TimeHelper } from '@biteinc/core-react';
import {
  BitePlatform,
  FulfillmentMethodHelper,
  OrderClientEventName,
  OrderPaymentDestination,
  PromotionThresholdType,
  UserRight,
} from '@biteinc/enums';

import { BackboneEvents } from '~/app/js/backbone-events';
import { localizeStr } from '~/app/js/localization/localization';
import { CustomerIdentifierService } from '~/services';

import { MenuFilterHeader } from '../../../components';
import { CartV2 } from '../../../components/cart-v2';
import CheckoutPage from '../../../pages/checkout';
import { useStore } from '../../../stores';
import { RecommendationDisplayLocationDescription } from '../../../types/recommendation';
import GcnOpeningSequenceManager from '../gcn_opening_sequence_manager';
import GcnRecoTracker from '../gcn_reco_tracker';
import { GCNRouterHelper } from '../gcn_router_helper';
import { GCNOrderedItem } from '../models/gcn_ordered_item';
import Analytics from '../utils/analytics';
import getQueryParam from '../utils/get_query_param';
import { asCallback } from '../utils/promises';
import TrackingStepCounter from '../utils/tracking_step_counter';
import { GCNAlertView } from './gcn_alert_view';
import GcnBagOptInView from './gcn_bag_opt_in_view';
import { GCNCheckoutFlowView } from './gcn_checkout_flow_view';
import { GCNClosedScreenView } from './gcn_closed_screen_view';
import { GCNCustomizeFlowView } from './gcn_customize_flow_view';
import { GCNMenuItemOrderView } from './gcn_menu_item_order_view';
import { GCNMenuPageView } from './gcn_menu_page_view';
import { GCNOrderFailedView } from './gcn_order_failed_view';
import { GCNPageNavigationView } from './gcn_page_navigation_view';
import { GCNPasscodeView } from './gcn_passcode_view';
import { GCNPaymentTypePickerView } from './gcn_payment_type_picker_view';
import { GCNPopup, GCNSpinner } from './gcn_popup';
import { GCNScannerToastView } from './gcn_scanner_toast_view';
import { GCNScrollingNavView } from './gcn_scrolling_nav_view';
import { GCNSideNavView } from './gcn_side_nav_view';
import { GCNSimpleCover } from './gcn_simple_cover';
import { GCNTopNavView } from './gcn_top_nav_view';
import { GCNUpsellInterstitialView } from './gcn_upsell_interstitial_view';
import { GCNView } from './gcn_view';

const farDistanceThreshold = 1024 * 4;
export const GCNMenuView = GCNView.extend({
  className() {
    let className = 'gcn scroll';
    if (window.isKioskPreview || window.isFlash) {
      className += ' embed';
    }
    if (window.isMobileApp) {
      className += ' mobile-app';
    }
    if (window.platform === BitePlatform.KioskAndroid) {
      // Android Elo (Tournant)
      className += ' elo';
    }
    return className;
  },
  _isShowingNavBar: true,
  _isShowingBottomPane: false,
  _backgroundImageUrl: null,
  _menuPageId: null,

  initialize(...args) {
    GCNView.prototype.initialize.apply(this, args);

    if (gcn.location.useSideNavMenu()) {
      this.sideNavView = new GCNSideNavView();
    } else {
      this._scrollingNavView = new GCNScrollingNavView();
    }

    this._topNavView = new GCNTopNavView();
    this._menuPageView = new GCNMenuPageView();

    this.addSubscription(
      useStore.subscribe((state, prevState) => {
        this._languageDidChange(state.config.language, prevState.config.language);
      }),
    );

    this.listenTo(gcn, BackboneEvents.GCNMenuAppView.MenuDidUpdate, this._menuDidUpdate);
    this.listenTo(gcn, BackboneEvents.GCNMenuAppView.CoverWasOpened, this._menuCoverWasOpened);
    if (gcn.location.useSideNavMenu()) {
      this.listenTo(
        this.sideNavView,
        BackboneEvents.GCNScrollingNavView.DidSelectMenuPageId,
        this._navDidSelectMenuPage,
      );
      this.listenTo(
        this.sideNavView,
        BackboneEvents.GCNScrollingNavView.DidSelectSectionId,
        this.scrollToSectionWithId,
      );
    } else {
      this.listenTo(
        this._scrollingNavView,
        BackboneEvents.GCNScrollingNavView.DidSelectMenuPageId,
        this._navDidSelectMenuPage,
      );
      this.listenTo(
        this._scrollingNavView,
        BackboneEvents.GCNScrollingNavView.DidSelectSectionId,
        this.scrollToSectionWithId,
      );
    }
    this.listenTo(
      this._menuPageView,
      BackboneEvents.GCNMenuPageView.DidSelectMenuPageId,
      this._navDidSelectMenuPage,
    );
    this.listenTo(
      this._menuPageView,
      BackboneEvents.GCNMenuPageView.DidScrollSectionIntoView,
      this._navDidScrollSectionIntoView,
    );
    this.listenTo(
      this._topNavView,
      BackboneEvents.GCNTopNavView.DidTapBackButton,
      this._navDidTapBackButton,
    );

    this.listenTo(
      gcn.orderManager,
      BackboneEvents.GCNOrderManager.OrderDidChange,
      this._orderDidChange,
    );

    $(window).on('resize', this._adjustSizeToFitWindow.bind(this));
  },

  initializeNavigationMenuView() {
    const locationUsesSideNavMenu = gcn.location.useSideNavMenu();

    if (
      (locationUsesSideNavMenu && this.sideNavView) ||
      (!locationUsesSideNavMenu && this._scrollingNavView)
    ) {
      return;
    }

    if (locationUsesSideNavMenu) {
      this.sideNavView = new GCNSideNavView();
      this._scrollingNavView = undefined;
      this.listenTo(
        this.sideNavView,
        BackboneEvents.GCNScrollingNavView.DidSelectMenuPageId,
        this._navDidSelectMenuPage,
      );
      this.listenTo(
        this.sideNavView,
        BackboneEvents.GCNScrollingNavView.DidSelectSectionId,
        this.scrollToSectionWithId,
      );
    } else {
      this._scrollingNavView = new GCNScrollingNavView();
      this.sideNavView = undefined;
      this.listenTo(
        this._scrollingNavView,
        BackboneEvents.GCNScrollingNavView.DidSelectMenuPageId,
        this._navDidSelectMenuPage,
      );
      this.listenTo(
        this._scrollingNavView,
        BackboneEvents.GCNScrollingNavView.DidSelectSectionId,
        this.scrollToSectionWithId,
      );
    }
    // we render the menu view so that either the scrollingNav view or
    // the sideNav view are rendered in the correct order
    this.render();
  },

  addOverlay() {
    const dismissOverlayDiv = $('<div class="touch-blocker-transition-overlay"></div>');

    this.$el.prepend(dismissOverlayDiv);
  },

  removeOverlay() {
    this.$('.touch-blocker-transition-overlay').remove();
  },

  showingMaxOverlay() {
    return this.$('.touch-blocker-transition-overlay').length > 0;
  },

  adjustMenuViewForScreenReader(screenReaderIsActive) {
    if (screenReaderIsActive) {
      if (this._pageNavView) {
        // We only need to hide the page nav view as it will unhide every time a session starts.
        this._pageNavView.hide();
        // The quick nav view only renders on session ends, we need to re-render it if using
        // the screen reader on start.
        this._menuPageView.render();
      }
      if (this._introImageView) {
        this._introImageView.hide();
        this._introImageViewDismissed();
      }
      this._topNavView.render();
    }
  },

  _menuDidUpdate() {
    Log.info('_menuDidUpdate');
    const wasShowingNavBar = this._isShowingNavBar;
    if (this._shouldShowNavBar()) {
      if (gcn.location.useSideNavMenu()) {
        this.sideNavView?.$el.show();
      } else {
        this._scrollingNavView.$el.show();
      }
    } else {
      this._scrollingNavView.$el.hide();
      this._isShowingNavBar = false;
    }

    if (this._shouldShowNavBar() !== wasShowingNavBar) {
      this._adjustSizeToFitWindow();
    }

    const bgImageUrl = gcn.menu.getBackgroundImageUrl();
    if (bgImageUrl) {
      if (this._backgroundImageUrl !== bgImageUrl) {
        this._backgroundImageUrl = bgImageUrl;
        gcn.requestImageByUrl(bgImageUrl, (err, imgPath) => {
          this._menuPageView.setBackgroundImage(imgPath);
        });
      }
    } else {
      this._backgroundImageUrl = null;
      this._menuPageView.setBackgroundImage(null);
    }

    this._refreshNavView();

    // Navigate to the menu page.
    const menuPage = gcn.menu.getMenuPageWithId(this._menuPageId) || gcn.menu.getFirstMenuPage();
    this._navDidSelectMenuPage(menuPage.id);

    /**
       * Typically we are listening for MenuCoverWasOpened to start the session & opening sequence.
       * The fulfillment method bug happens when we've rendered our menu view, but re-render due to
       * a received menu over the bridge (once a session has already started/menu cover already opened).
       *
       * The menu view is re-rendered after menu cover is opened, and we re-render the dining option
       * modal/popup which effectively clears out the entire contents of the modal - because this
       * modal lives inside the menu view.
       * This in turn causes the guest to miss the fulfillment opening sequence.
       *
       * TODO: Build the opening sequence components in React which would live outside the HTML
       * of our menu view - thus we could explicity control when it is dismissed/re-rendered.

       * Ensure we only render
       *  if a) session was started,
       *  b) no fulfillment method is set,
       *  c) and the popup is not currently displayed.
       */
    if (
      gcn.orderManager.eventRepo.hasEvent(OrderClientEventName.SessionStart) &&
      !gcn.orderManager.getFulfillmentMethod() &&
      !this._diningOptionPopup.isShown()
    ) {
      Log.info('fulfillment render again');
      this._startOpeningSequence();
    }

    const imageUrlsToCache = [];
    /** fetch and cache receipt Header & footer images */
    if (gcn.location.hasArr('receiptHeaderImage')) {
      const receiptHeaderImageUrl = gcn.location.get('receiptHeaderImage')[0].url;
      imageUrlsToCache.push(receiptHeaderImageUrl);
    }
    if (gcn.location.hasArr('receiptFooterImage')) {
      const receiptFooterImageUrl = gcn.location.get('receiptFooterImage')[0].url;
      imageUrlsToCache.push(receiptFooterImageUrl);
    }
    if (imageUrlsToCache.length) {
      // this is best effort, so if the images fail to cache, we don't need to do anything
      async.each(imageUrlsToCache, (imageUrl) => {
        gcn.requestBase64ImageByUrl(imageUrl);
      });
    }
  },

  clearSession() {
    this.hideAllPopups();
    this.dismissSpinner();
    this.showIntroSequence();

    this.hideFullScreen();

    const firstMenuPageId = gcn.menu.getFirstMenuPage().id;
    this.setMenuPageId(firstMenuPageId, false, true);
    this._menuPageView.clearSession();

    this.initializeNavigationMenuView();
    // if gcn.location.useSideNavMenu is set we don't have a scrolling nav view
    this._scrollingNavView?.resetScrollPosition();
  },

  getMenuPageId() {
    return this._menuPageId;
  },

  // we want to figure out if current session has side-nav rendered
  // if it is rendered then we perform whatever operation on this.sideNavView
  // otherwise we wait till go Home to initialize the session with side nav.
  menuHasSideNav() {
    const isSideNavRendered = document.getElementsByClassName('side-nav-view').length !== 0;
    return isSideNavRendered;
  },

  setMenuPageId(menuPageId, shouldTrack, scrollToTop) {
    const menuPage = gcn.menu.getMenuPageWithId(menuPageId);
    if (!menuPage) {
      return;
    }

    if (this._menuPageId === menuPageId && !scrollToTop) {
      return;
    }

    const prevMenuPageId = this._menuPageId;
    this._menuPageId = menuPage.id;

    this._topNavView.setMenuPage(menuPage);
    if (this.menuHasSideNav()) {
      this.sideNavView.setMenuPage(menuPage);
    } else {
      this._scrollingNavView.setMenuPage(menuPage, scrollToTop);
    }
    this._menuPageView.setMenuPage(menuPage, scrollToTop);

    if (prevMenuPageId && prevMenuPageId !== this._menuPageId && shouldTrack) {
      Log.info('didSelectMenuPage', menuPageId, menuPage.get('name'));
    }
  },

  scrollToTop() {
    this._menuPageView.scrollTo(0, true);
  },

  scrollToSectionWithId(menuSectionId) {
    this._setAriaHiddenOnMenuAndNav(false);
    if (!window.isFlash) {
      this._menuPageView.scrollToSectionWithId(menuSectionId);
      return;
    }
    const sectionView = this._menuPageView.sectionViewsById[menuSectionId];

    if (sectionView) {
      this.scrollTo(this.$el.scrollTop() + sectionView.$el.offset().top, true, true);
    }
  },

  scrollTo(y, animated, jumpIfFar) {
    const offset =
      y -
      this.$el.offset().top -
      (this._scrollingNavView?.$el[0].clientHeight || 0) -
      this._topNavView.$el[0].clientHeight;
    const far = Math.abs(this.$el.scrollTop() - offset) > farDistanceThreshold;
    if (!animated || (jumpIfFar && far)) {
      this.$el.scrollTop(offset);
    } else {
      this._currentlyScrolling = true;
      this.$el.animate(
        {
          scrollTop: offset,
        },
        'slow',
        () => {
          this._currentlyScrolling = false;
        },
      );
    }
  },

  showItemDetails(section, item, priceOptionId, upsellScreen, options) {
    const useFullScreen =
      (section && section.get('useFullScreenCustomizationFlow')) ||
      item.get('useFullScreenCustomizationFlow') ||
      gcn.screenReaderIsActive;
    gcn.orderManager.eventRepo.trackMenuItemView(item.id, item.displayName(), !!useFullScreen);
    Analytics.trackEvent({
      eventName: Analytics.EventName.MenuItemView,
      eventData: {
        itemName: item.displayName(),
      },
    });
    if (useFullScreen) {
      const customizeFlowView = new GCNCustomizeFlowView({
        item,
        section,
        priceOptionId,
        extras: options,
      });
      this.listenTo(
        customizeFlowView,
        BackboneEvents.GCNCustomizeFlowView.QuickCheckoutButtonWasTapped,
        this.startCheckoutSequence,
      );
      this.showFullScreen(customizeFlowView);
    } else {
      const itemOrderView = new GCNMenuItemOrderView({
        item,
        section,
        upsellScreen,
        extras: options,
      });

      const itemImageWidth = this._getItemImageWidth();
      const itemImageHeight = Math.ceil((itemImageWidth * 2) / 3);

      itemOrderView.setMaxImageSize(
        Math.min(itemImageWidth, this.$el.width()),
        Math.min(itemImageHeight, this.$el.height()),
      );
      // we add a cart-shown class to the item order view if there are items in the cart
      // this is so that the item order view can be styled differently if there are items in the cart
      const itemOrderViewClass =
        gcn.orderManager.getOrderSize() > 0 || gcn.location.hasBottomBarButtons()
          ? 'menu-item-order-view cart-shown'
          : 'menu-item-order-view';
      this.showStablePopup(itemOrderView, itemOrderViewClass);
    }
  },

  showOrderFailedRecovery(errMessage) {
    this.showStablePopup(new GCNOrderFailedView(errMessage), 'order-failed-view');
  },

  /**
   * @description Show a centered popup.
   * @param {View} contentView The view to render. The view may set its own desired width, but it
   * will be constrained within the popup.
   * @param {string} customClassName A custom class to be added to the popup
   * @param {(popup: app.GCNPopup) => void} onDisappear
   */
  showStablePopup(contentView, customClassName, onDisappear) {
    this._stablePopup.show(contentView, {}, customClassName);

    if (onDisappear) {
      this.listenToOnce(this._stablePopup, BackboneEvents.GCNPopup.PopupWillDisappear, onDisappear);
    }
  },

  showAdaPopup(contentView) {
    this._adaPopup.show(contentView, {}, 'ada-instruction-modal');
  },

  /**
   * @description Show a positioned popup.
   * @param {View} contentView The view to render. The view may set its own desired width, but it
   * will be constrained within the popup.
   * @param {Object} alignmentCss Object with positioning css properties (same as for an element
   * with position set to absolute: top, bottom, left, right). Used to position the content
   * anchored to some point.
   * @param {string} customClassName A custom class to be added to the popup
   */
  showPopup(contentView, alignmentCss, customClassName) {
    this._popup.show(contentView, alignmentCss, customClassName);
  },

  /**
   * @description Show a centered popup that controls dismissal.
   * @param {View} contentView The view to render. The view may set its own desired width, but it
   * will be constrained within the popup.
   */
  showModalPopup(contentView) {
    this._modalPopup.show(contentView);
  },

  showTopDismissibleModalPopup(contentView) {
    this._topDismissibleModalPopup.show(contentView);
  },

  /**
   * @description Show centered dining option popup that controls dismissal.
   * @param {View} contentView The dining option view to render.
   * @param {string} customClassName A custom class for the popup.
   * The view may set its own desired width, but it will be constrained within the popup.
   */
  showDiningOptionModalPopup(contentView, customClassName) {
    if (customClassName) {
      this._diningOptionPopup.customClassName = customClassName;
      this._diningOptionPopup.$el.toggleClass(customClassName, true);
    }
    this._diningOptionPopup.show(contentView);
  },

  showToast(item) {
    if (this._toastTimer) {
      clearTimeout(this._toastTimer);
      this._toastTimer = null;
    }

    // Figure out css for where to align the content view.
    Log.info(`showToast for: ${item.id}`, item);
    const contentView = new GCNScannerToastView({
      model: item,
    });

    this._$toastContainer.html(contentView.render().$el);
    this._$toastTouchBlocker.show();
    this._$toastContainer.stop().fadeIn(150);

    this._toastTimer = setTimeout(() => {
      this._$toastContainer.stop().fadeOut(500);
      this._$toastTouchBlocker.stop().fadeOut(500);
      this._toastTimer = null;
    }, 2500);

    // listen to taps and remove the toast
    this._$toastTouchBlocker.on('click', () => {
      this._$toastContainer.stop().fadeOut(500);
      this._$toastTouchBlocker.stop().fadeOut(500);

      if (this._toastTimer) {
        clearTimeout(this._toastTimer);
        this._toastTimer = null;
      }
    });
  },

  showToastMessage(header, body, className) {
    if (this._toastTimer) {
      clearTimeout(this._toastTimer);
      this._toastTimer = null;
    }

    // Figure out css for where to align the content view.
    Log.info(`showToast with header: ${header}`);
    const contentView = new GCNScannerToastView({
      header,
      body,
    });

    this._$toastTouchBlocker.show();
    if (className) {
      this._$toastContainer.toggleClass(className, true);
    }
    this._$toastContainer.html(contentView.render().$el);
    this._$toastContainer.stop().fadeIn(150);

    this._toastTimer = setTimeout(() => {
      this._$toastContainer.stop().fadeOut(500);
      this._$toastTouchBlocker.stop().fadeOut(500);

      this._toastTimer = null;

      setTimeout(() => {
        this._$toastContainer.toggleClass(className, false);
      }, 500);
    }, 2500);

    // listen to taps and remove the toast
    this._$toastTouchBlocker.on('click', () => {
      this._$toastContainer.stop().fadeOut(500);
      this._$toastTouchBlocker.stop().fadeOut(500);

      setTimeout(() => {
        this._$toastContainer.toggleClass(className, false);
      }, 500);

      if (this._toastTimer) {
        clearTimeout(this._toastTimer);
        this._toastTimer = null;
      }
    });
  },

  isShowingPopup() {
    return this.$el.hasClass('with-popup');
  },

  hideAllPopups() {
    this._stablePopup.hideUnanimated();
    this._popup.hideUnanimated();
    this._modalPopup.hideUnanimated();
    this._topDismissibleModalPopup.hideUnanimated();
    this._diningOptionPopup.hideUnanimated();
    this._adaPopup.hideUnanimated();
  },

  dismissStablePopup(callback) {
    this._stablePopup.dismiss(callback);
  },

  dismissPopup() {
    this._popup.dismiss();
  },

  dismissModalPopup() {
    this._modalPopup.dismiss();
  },

  dismissTopDismissibleModalPopup() {
    this._topDismissibleModalPopup.dismiss();
  },

  dismissDiningOptionModalPopup() {
    this._diningOptionPopup.dismiss();
  },

  dismissAdaPopup() {
    this._adaPopup.dismiss();
  },

  dismissStablePopupAndAnimateOrderedItemIntoCart(orderedItemView, callback) {
    const $orderedItemView = orderedItemView.$el;
    $orderedItemView.css('position', 'fixed');
    $orderedItemView.css('left', `${$orderedItemView.offset().left}px`);
    $orderedItemView.css('top', `${$orderedItemView.offset().top}px`);
    // TODO(steve): Hack. Should figure this out from the actual element.
    $orderedItemView.css('width', '600px');
    const $clone = $orderedItemView.clone();
    this._$animationOverlay.show();
    this._$animationOverlay.empty();
    this._$animationOverlay.append($clone);
    this.dismissStablePopup();

    // If the order view is already showing, animate just to the top of it.
    const contentHeight = window.innerHeight || $('body')[0].clientHeight;
    const topValue = contentHeight - (gcn.orderManager.getOrderSize() ? 96 : 0);
    $clone.animate(
      {
        // TODO(steve): Hack. Should figure this out from the actual content.
        top: `${topValue}px`,
      },
      {
        duration: 1100,
        specialEasing: {
          top: 'easeInOutBack',
        },
        complete: () => {
          this._$animationOverlay.hide();
          callback();
        },
      },
    );
  },

  _lockViewport() {
    // In menus where we are likely on mobile web, we need to lock in the
    // viewport to prevent bad layouts when the onscreen keyboard is shown.
    if (window.isKioskPreview || window.isFlash) {
      const viewHeight = $(window).height();
      const viewWidth = $(window).width();
      const viewport = document.querySelector('meta[name=viewport]');
      this._prevViewportContent = viewport.getAttribute('content');
      viewport.setAttribute(
        'content',
        `height=${viewHeight}px, width=${viewWidth}px, initial-scale=1.0, user-scalable=no`,
      );
    }
  },

  _unlockViewport() {
    if ((window.isKioskPreview || window.isFlash) && this._prevViewportContent) {
      const viewport = document.querySelector('meta[name=viewport]');
      viewport.setAttribute('content', this._prevViewportContent);
    }
  },

  showFullScreen(fullScreenView, notifyCriticalPeriod) {
    this._setAriaHiddenOnMenuAndNav(true);

    this._fullScreenView = fullScreenView;
    this._$fullScreenOverlay.html('');
    this._$fullScreenOverlay.append(fullScreenView.render().$el);

    this._$fullScreenOverlay.show();
    this._$fullScreenOverlay.css('transition', '');
    this._$fullScreenOverlay.css('opacity', '0');
    this._$fullScreenOverlay.css('transition', 'opacity 0.3s linear');
    this._$fullScreenOverlay.css('opacity', '1');

    if (notifyCriticalPeriod) {
      gcn.notifyUserDidEnterCriticalPeriod(notifyCriticalPeriod);
    }

    this._lockViewport();
  },

  showFullScreenReactPage(ReactPageClass) {
    this._setAriaHiddenOnMenuAndNav(true);

    this._$fullScreenOverlay.html('');

    this._$fullScreenOverlay.show();
    this._$fullScreenOverlay.css('transition', '');
    this._$fullScreenOverlay.css('opacity', '0');
    this._$fullScreenOverlay.css('transition', 'opacity 0.3s linear');
    this._$fullScreenOverlay.css('opacity', '1');

    const root = ReactDOM.createRoot(this._$fullScreenOverlay[0]);
    root.render(React.createElement(ReactPageClass));

    this._fullScreenView = {
      destroy: () => {
        root.unmount();
      },
    };
    this._lockViewport();
  },

  hideFullScreen(notifyCriticalPeriod) {
    const transitionEndEvents = 'transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd';
    this._setAriaHiddenOnMenuAndNav(false);
    if (this._fullScreenView) {
      this._fullScreenView.destroy();
    }
    this._fullScreenView = null;

    // Only animate if the item is currently shown.
    if (this._$fullScreenOverlay.is(':visible')) {
      this._$fullScreenOverlay.on(transitionEndEvents, () => {
        this._$fullScreenOverlay.off(transitionEndEvents);
        this._$fullScreenOverlay.css('transition', '');
        this._$fullScreenOverlay.hide();
      });
      this._$fullScreenOverlay.css('opacity', '0');
    }

    if (notifyCriticalPeriod) {
      gcn.notifyUserDidLeaveCriticalPeriod(notifyCriticalPeriod);
      // this handles situation where the aria-hidden is reset for
      // bottom-bar that way VoiceOver only reads the bottom-bar
    }

    this._unlockViewport();
  },

  inFullScreen() {
    return !!this._fullScreenView;
  },

  showSpinner(msg, imageClass) {
    this._spinner.show(msg);
    if (imageClass) {
      this._spinner.setImageClass(imageClass);
    }
  },

  setSpinnerMessage(msg) {
    this._spinner.setMessage(msg);
  },

  isSpinnerShowingMessage(msg) {
    return this._spinner.isShowingMessage(msg);
  },

  showSimpleAlert(msg, okCallback) {
    const formattedMessage = msg.split('\n').join('<br />');
    const alertView = new GCNAlertView({
      text: formattedMessage,
      okCallback: () => {
        this.dismissModalPopup();
        if (okCallback) {
          okCallback();
        }
      },
      okText: localizeStr(Strings.OK),
    });
    this.showModalPopup(alertView);
  },

  dismissSpinner() {
    this._spinner.dismiss();
  },

  _startOpeningSequence() {
    window.openingSequenceStartedAt = performance.now();
    Log.info('OSM started from menuView._startOpeningSequence', window.openingSequenceStartedAt);

    const openingSequenceManager = new GcnOpeningSequenceManager(true);
    openingSequenceManager.start();
  },

  // TODO: remove this and keep in GcnOpeningSequenceManager when we remove non-dining option workflow
  _createCustomerIdentifierTask(
    InputMethodViewClass,
    viewOptions,
    successEvent,
    backedOutEvent,
    backedOutUnsetter,
    isBeginning,
  ) {
    const self = this;
    return (innerCb) => {
      const inputMethodView = new InputMethodViewClass(viewOptions);
      self.listenToOnce(inputMethodView, successEvent, () => {
        gcn.menuView.dismissModalPopup();
        innerCb();
      });
      self.listenToOnce(inputMethodView, backedOutEvent, () => {
        gcn.orderManager.eventRepo.track(
          isBeginning
            ? OrderClientEventName.FulfillmentOpeningCancel
            : OrderClientEventName.FulfillmentCheckoutCancel,
        );
        Analytics.track(
          isBeginning
            ? Analytics.EventName.FulfillmentAtOpeningBackOut
            : Analytics.EventName.FulfillmentAtCheckoutBackOut,
        );

        gcn.menuView.dismissModalPopup();
        innerCb(true);
      });
      gcn.menuView.showModalPopup(inputMethodView);
    };
  },

  // Run a series of pre-checkout flow user prompts, such as asking for the order destination.
  // Then start the actual checkout flow if everything is successful.
  startCheckoutSequence() {
    // TODO: remove this once it's been debugged.
    // Don't allow a checkout to kick off without any items in cart.
    if (!gcn.orderManager.getOrderedItems()?.length) {
      const err = new Error('Tried to start checkout sequence with no ordered items');
      Sentry.captureException(err);
      return;
    }
    if (!gcn.orderManager.getFulfillmentMethod()) {
      const openingSequenceManager = new GcnOpeningSequenceManager(false, () => {
        // If the fulfillment method is still not set, we need set the sessionWasStartedAt
        // Try to get the sessionWasStartedAt from the first event in the event repo
        // If no events exist, set the sessionWasStartedAt to now - 1 minute
        const events = gcn.orderManager.eventRepo.getEvents();
        gcn.sessionWasStartedAt =
          events.length > 0 ? events[0].createdAt : Date.now() - TimeHelper.MINUTE;
        this.startCheckoutSequence();
      });
      openingSequenceManager.start();
      return;
    }
    Log.info('_startCheckoutSequenceWithDiningOptions');

    // keep a copy of ordered items to fall back to if order checkout is cancelled
    gcn.orderManager.clonePreCheckoutOrderedItems();

    // Check if the cart needs updating because the menu changed.
    const removedOutdatedOrderedItems = gcn.orderManager.removeOutdatedOrderedItems();
    if (removedOutdatedOrderedItems.length) {
      Log.info('Removed one or more unavailable items.');

      const uniqueItemNames = [];
      removedOutdatedOrderedItems.forEach((removedItemName) => {
        if (!uniqueItemNames.includes(removedItemName)) {
          uniqueItemNames.push(removedItemName);
        }
      });
      if (uniqueItemNames.length <= 3) {
        gcn.menuView.showSimpleAlert(
          `Sorry! <strong>${uniqueItemNames.join(', ')}</strong> ${
            uniqueItemNames.length === 1 ? 'is' : 'are'
          } no longer available. ${
            uniqueItemNames.length === 1 ? 'It has' : 'They have'
          } been removed from your order.`,
        );
      } else {
        gcn.menuView.showSimpleAlert(
          `Sorry! <strong>${uniqueItemNames.length}</strong> items are no longer available. They have been removed from your order.`,
        );
      }
      return;
    }

    // Set up the pre-checkout steps.
    const tasks = [];

    // 1 - Pick fulfillment method if we haven't done so on Orders API v1.
    // Related to https://getbite.atlassian.net/browse/BITE-3876 and
    // https://getbite.atlassian.net/browse/BITE-2379
    if (!gcn.orderManager.getFulfillmentMethod() && !gcn.location.useOrdersApiV2()) {
      Log.info('checkoutSequence: missing fulfillmentMethod');
      tasks.push((cb) => {
        const openingSequenceManager = new GcnOpeningSequenceManager(false, cb);
        openingSequenceManager.start();
      });
    }

    const fulfillmentMethod = gcn.orderManager.getFulfillmentMethod();
    const diningOption = gcn.location.getDiningOption(fulfillmentMethod);
    // Bail if we can't find this dining option
    if (!diningOption) {
      return false;
    }

    const isToGoKiosk =
      FulfillmentMethodHelper.isToGo(fulfillmentMethod) &&
      FulfillmentMethodHelper.isKiosk(fulfillmentMethod);
    const bagOptInItemId = diningOption.toGoBagItemId;
    const bagOptInItem = bagOptInItemId && gcn.menu.getMenuItemWithId(bagOptInItemId);
    const bagInCartAlready = gcn.orderManager.hasOrderedItemWithId(bagOptInItemId);
    if (isToGoKiosk && bagOptInItem && !bagInCartAlready) {
      tasks.push((cb) => {
        const bagOptInView = new GcnBagOptInView({
          onOptIn: () => {
            const orderedBag = new GCNOrderedItem(
              {},
              {
                item: bagOptInItem,
              },
            );
            gcn.orderManager.addToOrder(orderedBag);
            gcn.menuView.dismissPopup();
            cb();
          },
          onOptOut: () => {
            gcn.menuView.dismissPopup();
            cb();
          },
        });
        gcn.menuView.showPopup(bagOptInView, {}, 'centered');
      });
    }

    // 2 - Checkout upsell interstitial.
    if (gcn.menu.settings.get('showUpsellAtCheckout') && !gcn.screenReaderIsActive) {
      tasks.push((cb) => {
        // make sure sections are recommendable at checkout (setting)
        const displayRecosLimit = gcn.menu.settings.get('upsellCountAtCheckout') || 4;
        gcn.menuView.showSpinner(localizeStr(Strings.FETCHING_RECOMMENDATIONS));
        asCallback(
          GcnRecoTracker.getPreCheckoutRecommendations(gcn.maitred),
          (err, recommendations) => {
            gcn.menuView.dismissSpinner();

            if (err) {
              Log.error('failed to load pre checkout recommendations');
              cb();
              return;
            }

            if (!recommendations.length) {
              cb();
              return;
            }

            const recommendedMenuItems = gcn.menu.getMenuItemsFromRecommendations(recommendations, {
              displayRecosLimit,
            });
            const interstitialView = new GCNUpsellInterstitialView({
              recos: recommendedMenuItems,
              recommendationDisplayLocationDescription:
                RecommendationDisplayLocationDescription.PRE_CHECKOUT_POPUP,
            });

            gcn.menuView.showStablePopup(interstitialView, 'upsell-interstitial-view');
            this.listenToOnce(
              interstitialView,
              BackboneEvents.GCNUpsellInterstitialView.DonePressed,
              () => {
                const { orderedItems, recommendationDisplayLocationDescription } =
                  interstitialView.getOrderedRecommendations();
                _.each(orderedItems, (orderedItem) => {
                  gcn.orderManager.addToOrder(orderedItem, orderedItem.orderedPO.get('quantity'), {
                    recommendationDisplayLocationDescription,
                  });
                });
                gcn.orderManager.clonePreCheckoutOrderedItems();
                gcn.menuView.dismissStablePopup();
                cb();
              },
            );
          },
        );
      });
    }

    const fulfillmentTrackingStepCounter = new TrackingStepCounter(() => {
      gcn.orderManager.eventRepo.track(OrderClientEventName.FulfillmentCheckoutStart);
      Analytics.track(Analytics.EventName.FulfillmentAtCheckoutStart);
    });

    // 3 - Alcohol ID check.
    // Need to check after the checkout upsell in case alcohol gets selected then
    tasks.push((cb) => {
      if (
        gcn.orderManager.getCurrentAlcoholBeverageCount() &&
        gcn.menu.settings.get('requireManagerCodeForAlcohol') &&
        !gcn.orderManager.hasPassedAlcoholPasscode()
      ) {
        fulfillmentTrackingStepCounter.addStep();

        // TODO: listen for cancel to save a cancel checkout event
        const passcodeView = new GCNPasscodeView({
          checkCallback: (userCode) => {
            return gcn.hasUserWithAccessCode(userCode, UserRight.VerifyAlcoholPurchases);
          },
          successCallback: () => {
            gcn.menuView.dismissStablePopup();
            gcn.orderManager.setPassedAlcoholPasscode();
            cb();
          },
          customMessage: localizeStr(Strings.ID_CHECK_CODE_MESSAGE),
        });
        gcn.menuView.showStablePopup(passcodeView, 'passcode-view');
        return;
      }

      cb();
    });

    // 4.1 - Break out the cashier step from OrderDestView.
    /**
     * In Flash force the order to have the payment destination to be set so the api doesn't
     * reject the order for using cash due to the location settings potentially being set to
     * cash only
     */
    if (window.isFlash) {
      gcn.orderManager.setPaymentDestination(OrderPaymentDestination.CreditCard);
    } else if (gcn.maitred.allowPayAtCashier()) {
      tasks.push((cb) => {
        fulfillmentTrackingStepCounter.addStep();

        Log.info('checking allowPayAtCashier');
        gcn.orderManager.clearPaymentDestination();

        const paymentTypePickerView = new GCNPaymentTypePickerView();
        this.listenToOnce(
          paymentTypePickerView,
          BackboneEvents.GCNPaymentTypePickerView.DidPickPaymentType,
          () => {
            gcn.menuView.dismissModalPopup();
            cb();
          },
        );
        this.listenToOnce(
          paymentTypePickerView,
          BackboneEvents.GCNPaymentTypePickerView.BackedOut,
          () => {
            gcn.menuView.dismissModalPopup();
            gcn.orderManager.eventRepo.track(OrderClientEventName.FulfillmentCheckoutCancel);
            Analytics.track(Analytics.EventName.FulfillmentAtCheckoutBackOut);

            cb(true);
          },
        );
        gcn.menuView.showModalPopup(paymentTypePickerView);
      });
    }

    // 4.2 - Customer identifiers.
    tasks.push((cb) => {
      const customerIdentifierTasks = GcnOpeningSequenceManager.generateCustomerIdentifiersTasks(
        false,
        false,
        this._createCustomerIdentifierTask.bind(this),
      );
      if (!customerIdentifierTasks.length) {
        cb();
        return;
      }

      fulfillmentTrackingStepCounter.addStep();

      // Start the customer identifiers sequence.
      // If any steps in the sequence returns an error, fail the entire sequence.
      async.waterfall(customerIdentifierTasks, cb);
    });

    // Start the sequence.
    async.waterfall(tasks, (err) => {
      if (err) {
        // This just means someone cancelled and `true` was passed in. Ugh
        return;
      }

      // Checkout start was instance since we had no steps
      if (fulfillmentTrackingStepCounter.getStepCount() === 0) {
        gcn.orderManager.eventRepo.track(OrderClientEventName.FulfillmentCheckoutStart);
      }
      gcn.orderManager.eventRepo.track(OrderClientEventName.FulfillmentCheckoutEnd);
      Analytics.trackEvent({
        eventName: Analytics.EventName.FulfillmentAtCheckoutComplete,
        eventData: {
          stepCount: fulfillmentTrackingStepCounter.getStepCount(),
        },
      });

      this.proceedToCheckout();
    });
  },

  proceedToCheckout(checkoutOptions) {
    // [BITE-3440]
    const freeItemPromotions = gcn.location.get('freeItemPromotions');
    if (Array.isArray(freeItemPromotions)) {
      const cartFulfillmentMethod = gcn.orderManager.getFulfillmentMethod();
      const orderQuantity = gcn.orderManager.getOrderedItems().length;
      const orderSubTotal = gcn.orderManager.getSubTotal();
      const relevantPromotion = freeItemPromotions.find((promotion) => {
        if (promotion.fulfillmentMethods.indexOf(cartFulfillmentMethod) === -1) {
          // `cartFulfillmentMethod` isn't part of the promotion's fulfillmentMethod
          return false;
        }

        // `promotion.menuItemId` is not part of the menu
        if (!gcn.menu.getMenuItemWithId(promotion.menuItemId)) {
          return false;
        }

        // return `true` IFF cart's `orderQuantity` or `orderSubTotal`
        // crosses promotion's `thresholdValue` based on the promotion's `thresholdType`
        switch (promotion.thresholdType) {
          case PromotionThresholdType.CartSize: {
            if (promotion.thresholdValue <= orderQuantity) {
              return true;
            }
            break;
          }
          case PromotionThresholdType.CartValue: {
            if (promotion.thresholdValue <= orderSubTotal / 100) {
              return true;
            }
            break;
          }
        }
        return false;
      });

      // `relevantPromotion` is Expected to be of only one valid
      // promotion that can be applied to this order; if more than one
      // is received, only the first one will be consumed
      if (relevantPromotion) {
        const freeItemToOrder = gcn.menu.getOrderedItemForMenuItemId(relevantPromotion.menuItemId);
        if (freeItemToOrder) {
          gcn.orderManager.addToOrder(freeItemToOrder, 1, {
            skipTracking: false,
            reason: 'auto-add-promo',
          });
        }
      }
    }

    if (getQueryParam('forcedMenuStructureId')) {
      gcn.menuView.showSimpleAlert(localizeStr(Strings.CHECKOUT_WITH_FORCED_MENU));
      return;
    }

    if (window.isFlash) {
      // TODO: is there a better place to "init" the checkout store?
      // Kicking off this event in CheckoutPage constructor didn't update the state fast enough
      // Update hash for GA tracking

      /**
       * When the checkout page is created, we want to check if we already have some guest info from
       * the logged in user in the checkout state as we have no app state.
       *
       * Do this here instead of in the constructor of the checkout page so that the guest info prop
       * is updated before it is sent to the sub-components.
       *
       * @todo Move once we have app state
       */
      useStore
        .getState()
        .checkout.onCustomerIdentifiersUpdated(
          CustomerIdentifierService.getCustomerIdentifierState(),
        );

      useStore.getState().checkout.onStart();
      GCNRouterHelper.navToCheckout();
      this.showFullScreenReactPage(CheckoutPage);
      return;
    }

    const checkoutFlowView = new GCNCheckoutFlowView(checkoutOptions || {});

    const criticalPeriodName = checkoutFlowView.criticalPeriodName;

    setTimeout(() => {
      this.showFullScreen(checkoutFlowView, criticalPeriodName);
    }, 500);
  },

  _shouldShowNavBar() {
    if (!gcn.menu) {
      return false;
    }
    if (gcn.location.canChangeDiningOptions()) {
      // nav-bar contains the widget for changing the dining option
      // We can't hide the full nav-bar if the user can change the dining option
      return true;
    }
    if (gcn.menu.getPageCount() === 1) {
      // If there are multiple sections on one page, the top nav will be re-purposed to navigate
      // across sections.
      return gcn.menu.getFirstMenuPage().sections.length > 1;
    }
    return true;
  },

  _navDidSelectMenuPage(menuPageId) {
    this.setMenuPageId(menuPageId, true);
  },

  _navDidScrollSectionIntoView(sectionId) {
    if (this.menuHasSideNav()) {
      this.sideNavView.setSelectedSectionWithId(sectionId, true);
    } else {
      this._scrollingNavView.setSelectedSectionWithId(sectionId, true);
    }
  },

  _navDidTapBackButton() {
    if (gcn.menu.getMenuPageLevel(this._menuPageId) === 1) {
      // Only show page view when going back
      if (this._pageNavView) {
        this._pageNavView.show();
        this._setAriaHiddenOnMenuAndNav(true);
        return;
      }

      if (gcn.orderManager.getOrderSize() || gcn.guestManager.guestWasRecognized()) {
        const alertText = localizeStr(Strings.CONFIRM_QUIT, [], function (string) {
          return string.split('\n').join('<br />');
        });
        const confirmView = new GCNAlertView({
          text: alertText,
          okCallback: () => {
            gcn.menuView.dismissModalPopup();
            gcn.orderManager.eventRepo.track(OrderClientEventName.SessionExit);
            Analytics.track(Analytics.EventName.ExitMenuTapped);
            gcn.updateSessionData({
              reason: 'userExit',
            });
            gcn.goHome();
          },
          cancelCallback: () => {
            gcn.menuView.dismissModalPopup();
          },
        });
        gcn.menuView.showModalPopup(confirmView);
      } else {
        gcn.orderManager.eventRepo.track(OrderClientEventName.SessionExit);
        Analytics.track(Analytics.EventName.ExitMenuTapped);
        gcn.updateSessionData({
          reason: 'userExit',
        });
        gcn.goHome();
      }
    } else {
      const parentPage = gcn.menu.getMenuPageParent(this._menuPageId);
      this.setMenuPageId(parentPage, true);
    }
  },

  _languageDidChange(language, prevLanguage) {
    if (language === prevLanguage) {
      return;
    }

    setTimeout(() => {
      this._topNavView.render();
      if (this.menuHasSideNav()) {
        this.sideNavView.render();
      } else {
        this._scrollingNavView.render();
      }
      this._menuPageView.render();
      this._pageNavView?.render();
    }, 1);
  },

  _orderDidChange() {
    if (this._$fullScreenOverlay.is(':visible')) {
      // this is to set the correct aria-hidden if a fullscreenOverlay is visible
      this._setAriaHiddenOnMenuAndNav(true);
    }

    this._menuPageView.setPageViewHeight();

    if (this.sideNavView) {
      this.sideNavView.setSideNavHeightLevel();
    }
  },

  _adjustSizeToFitWindow(e) {
    const $body = $('body');
    // NOTE: It looks like as of iOS 11, calling body.width() before a window
    // resize event causes a crash. The crash (div.style is undefined) happens
    // inside jQuery when it tries to calculate some css values. Magically,
    // logging clientWidth solves the problem, presumably by pre-calculating
    // some stuff so that jQuery doesn't have to.
    Log.info('body height', $body[0].clientHeight);
    Log.info('body width', $body[0].clientWidth);
    const bodyHeight = $body.height() || window.innerHeight || $body[0].clientHeight;
    const bodyWidth = $body.width() || window.innerWidth || $body[0].clientWidth;

    let topOffset = this._topNavView.$el.height();
    if (this._shouldShowNavBar() && !gcn.location.useSideNavMenu()) {
      topOffset += this._scrollingNavView.$el.height();
    }

    let menuPageHeight = bodyHeight - topOffset;
    this._menuPageView.$el.css('height', `${menuPageHeight}px`);
    this._menuPageView.$el.css('top', `${topOffset}px`);

    const menuViewHeight = gcn.menuView.$el.outerHeight();
    const popupBoundingRect = {
      x: 0,
      y: 0,
      width: gcn.menuView.$el.outerWidth(),
      height: menuViewHeight,
    };

    this._spinner.setBoundingRect(popupBoundingRect);
    this._stablePopup.setBoundingRect({
      ...popupBoundingRect,
      // Ensure there's at least a 20px margin on top/bottom
      height: popupBoundingRect.height - 40,
    });
    this._popup.setBoundingRect(popupBoundingRect);
    this._modalPopup.setBoundingRect(popupBoundingRect);
    this._topDismissibleModalPopup.setBoundingRect(popupBoundingRect);
    this._diningOptionPopup.setBoundingRect(popupBoundingRect);
    this._adaPopup.setBoundingRect(popupBoundingRect);
    if (e === undefined) {
      this._spinner.hideUnanimated();
      this._stablePopup.hideUnanimated();
      this._popup.hideUnanimated();
      this._modalPopup.hideUnanimated();
      this._topDismissibleModalPopup.hideUnanimated();
      this._diningOptionPopup.hideUnanimated();
      this._adaPopup.hideUnanimated();
    }

    this._$bottomPaneOverlay.css('width', bodyWidth);
    this._$bottomPaneOverlay.css('height', menuViewHeight);
    if (e === undefined) {
      this._$bottomPaneOverlay.hide();
    }

    this._$fullScreenOverlay.css('width', bodyWidth);
    this._$fullScreenOverlay.css('height', menuViewHeight);
    if (e === undefined) {
      this._$fullScreenOverlay.hide();
    }

    this.trigger(GCNMenuView.MenuViewReady, this);
  },

  _hasPageNav() {
    return this._pageNavView && !gcn.screenReaderIsActive;
  },

  _setAriaHiddenOnMenuAndNav(hidden) {
    this._topNavView.$el.attr('aria-hidden', hidden ? 'true' : 'false');
    if (this.menuHasSideNav()) {
      this.sideNavView.$el.attr('aria-hidden', hidden ? 'true' : 'false');
    } else {
      this._scrollingNavView.$el.attr('aria-hidden', hidden ? 'true' : 'false');
    }
    this._menuPageView.$el.attr('aria-hidden', hidden ? 'true' : 'false');
  },

  _shownPopups: [],
  _popupWillAppear(popup) {
    if (this._shownPopups.indexOf(popup) < 0) {
      this._shownPopups.push(popup);
      if (this._shownPopups.length === 1) {
        this.$el.toggleClass('with-popup', true);
        this._setAriaHiddenOnMenuAndNav(true);
        this.$el.toggleClass('scroll', false);
      }
    }
  },

  _popupWillDisappear(popup) {
    const popupIndex = this._shownPopups.indexOf(popup);
    if (popupIndex >= 0) {
      const [disappearingPopup] = this._shownPopups.splice(popupIndex, 1);
      if (disappearingPopup.customClassName) {
        disappearingPopup.$el.toggleClass(disappearingPopup.customClassName, false);
        disappearingPopup.customClassName = null;
      }
      if (!this._shownPopups.length) {
        this.$el.toggleClass('with-popup', false);
        /**
         * we are setting ariaHiddenOnMenuAndNav if either `_fullScreenView` (scenarios when a
         * Popup is on top of a fullScreenView) or `_pageNavView` is closed
         */
        const isViewAvailable = this._fullScreenView || this._hasPageNav();
        this._setAriaHiddenOnMenuAndNav(!!isViewAvailable);
        this.$el.toggleClass('scroll', true);
      }
    }
  },

  _introImageViewDismissed() {
    // we start the opening sequence because the IntroImageView was just
    // dismissed, that usually indicates that the whole process has reset
    gcn.trigger(BackboneEvents.GCNMenuAppView.CoverWasOpened, gcn);
  },

  showIntroSequence() {
    let someViewDisplayed = false;
    if (this._closedScreenView) {
      this._closedScreenView.show();
      someViewDisplayed = true;
    }

    if (this._pageNavView) {
      this._pageNavView.show();
      this._setAriaHiddenOnMenuAndNav(true);
      someViewDisplayed = true;
    }

    if (this._introImageView) {
      this._introImageView.show();
      someViewDisplayed = true;
    }
    return someViewDisplayed;
  },

  hideIntroSequence() {
    if (this._pageNavView) {
      this._pageNavView.hide();
      this._setAriaHiddenOnMenuAndNav(false);
    }

    this._introImageView?.hide();
  },

  _refreshNavView() {
    this._pageNavView?.render();
  },

  _menuCoverWasOpened() {
    Log.info('_menuCoverWasOpened');

    if (this._introImageView) {
      this._introImageView.reloadImages();
    }

    if (gcn.delayedErrorMessage) {
      Log.info('OSM delayedErrorMessage _startOpeningSequence');
      this.showSimpleAlert(gcn.delayedErrorMessage, () => {
        this._startOpeningSequence();
      });
      gcn.delayedErrorMessage = null;
      return;
    }

    if (window.isIE) {
      this.showSimpleAlert(localizeStr(Strings.IE_WARNING), () => {
        this._startOpeningSequence();
      });
      return;
    }

    this._startOpeningSequence();
  },

  showClosedScreen() {
    this._closedScreenView = new GCNClosedScreenView();
    this.$el.append(this._closedScreenView.render().$el);
  },

  _getItemImageWidth() {
    /**
     * Tizen screen – width: calc(100vw - 60px) = 1020px
     * iOS/768px screen - width: calc(100vw - 200px) = 568px
     * Elo/768px screen – width: calc(100vw - 60px) = 708px
     */
    switch (window.platform) {
      case BitePlatform.KioskSignageOsGarcon:
      case BitePlatform.KioskChromeOsGarcon:
        return 1020;
      case BitePlatform.KioskIos:
        return 568;
      default:
        return 708;
    }
  },

  prependMenuFilterHeader(view) {
    if (!gcn.menu.getFilterableBadgeIdsWithItems().length) {
      return;
    }
    const $view = view.$el;
    $view.addClass('has-menu-filters-header');
    const $menuFilter = $('<div class="menu-filters-header"></div>');
    const root = ReactDOM.createRoot($menuFilter[0]);
    root.render(React.createElement(MenuFilterHeader));

    $view.prepend($menuFilter);
  },

  render() {
    this.$el.html('');

    // Hack for iOS 11
    // On iOS 11, there's a 64px bar at the bottom of the page.
    // WKScrollView (i.e. webview.scrollView) has contentSize set to 960px.
    // If the bbc website is loaded then contentHeight is 1024px. So something
    // about our rendering isn't right. Let's just force min height to 1024.
    if (window.platform === BitePlatform.KioskIos) {
      this.$el.attr('style', 'min-height: 1024px; ');
    }

    this.$el.toggleClass('has-page-images', !!gcn.menu.structure.hasMenuPageImages());
    this.$el.toggleClass('uses-2-row-nav-bar', !!gcn.menu.settings.get('useTwoRowNavBar'));

    if (window.isMobileApp) {
      const mobileAppCartContainer = document.createElement('div');
      mobileAppCartContainer.className = 'mobile-app-cart-container';
      this.$el.append(mobileAppCartContainer);

      this._topNavView._$mobileAppCartContainer = mobileAppCartContainer;
    }

    this.$el.append(this._topNavView.render().el);
    if (gcn.location.useSideNavMenu()) {
      this.$el.append(this.sideNavView.render().el);

      const menuPageView = this._menuPageView.render();
      this.prependMenuFilterHeader(menuPageView);
      this.$el.append(menuPageView.el);
    } else {
      const scrollingNavView = this._scrollingNavView.render();
      this.prependMenuFilterHeader(scrollingNavView);
      this.$el.append(scrollingNavView.el);

      this.$el.append(this._menuPageView.render().el);
    }

    const evtName = gcn.isTouchDevice() && navigator.platform !== 'Win32' ? 'touchstart' : 'click';

    this._spinner = new GCNSpinner();

    // Animation overlay.
    this._$animationOverlay = $('<div class="animation-overlay"></div>');
    this.$el.append(this._$animationOverlay);

    // Stable Popup overlay.
    this._stablePopup = new GCNPopup({
      dismissOnTapOutside: true,
      showsCloseLabel: true,
    });
    this._stablePopup.$el.addClass('animate');

    this._adaPopup = new GCNPopup({
      dismissOnTapAnywhere: true,
      showsCloseLabel: false,
    });

    // Popup overlay.
    this._popup = new GCNPopup({
      // Only set this to true if we are going to end up showing the close button
      showsCloseLabel: !!GCNPopup.shouldUseCloseButton(),
      dismissOnTapOutside: true,
    });

    // Modal Popup overlay.
    this._modalPopup = new GCNPopup();
    this._modalPopup.$el.addClass('generic modal');

    // Dining Option Popup overlay
    this._diningOptionPopup = new GCNPopup({
      isDiningOptionModal: true,
      onTransitionEnd: () => {
        const duration = Math.round(performance.now() - window.openingSequenceStartedAt);
        Analytics.trackEvent({
          eventName: Analytics.EventName.FulfillmentAtOpeningDiningOptionShown,
          eventData: {
            duration,
          },
        });
      },
    });
    this._diningOptionPopup.$el.addClass('dining-option modal');

    // Top Level Popup
    this._topDismissibleModalPopup = new GCNPopup({
      showsCloseLabel: true,
      dismissOnTapOutside: true,
    });
    this._topDismissibleModalPopup.$el.addClass('generic modal');
    _.each(
      [
        this._spinner,
        this._stablePopup,
        this._popup,
        this._modalPopup,
        this._topDismissibleModalPopup,
        this._diningOptionPopup,
        this._adaPopup,
      ],
      (popup) => {
        this.listenTo(popup, BackboneEvents.GCNPopup.PopupWillAppear, this._popupWillAppear);
        this.listenTo(popup, BackboneEvents.GCNPopup.PopupWillDisappear, this._popupWillDisappear);
        this.$el.append(popup.render().$el);
        popup.hideUnanimated();
      },
    );

    this._$bottomPaneOverlay = $('<div class="touch-blocker-overlay pane"></div>');

    const cartContainer = $('<div></div>');
    if (!window.isFlash) {
      const root = ReactDOM.createRoot(cartContainer[0]);
      root.render(React.createElement(CartV2));
      this.$el.append(this._$bottomPaneOverlay);
      this.$el.append(cartContainer);

      this.$bottomPane = $('#cart-v2');
    }

    this._$toastTouchBlocker = $(
      '<div class="touch-blocker-overlay toast"><div class="toast-container"></div></div>',
    );
    this.$el.append(this._$toastTouchBlocker);
    this._$toastTouchBlocker.hide();

    this._$toastContainer = this.$el.find('.toast-container');

    this._$fullScreenOverlay = $('<div class="full-screen-overlay bg-color-spot-1"></div>');
    this._$fullScreenOverlay.on(evtName, (e) => {
      const $target = $(e.target);
      if ($target.is(this._$fullScreenOverlay)) {
        this.hideFullScreen();
      }
    });
    this.$el.append(this._$fullScreenOverlay);

    if (gcn.location.shouldShowClosedScreen()) {
      this.showClosedScreen();
    }

    if (gcn.menu.settings.get('showPageNavigation')) {
      this._pageNavView = new GCNPageNavigationView();
      this.listenTo(
        this._pageNavView,
        BackboneEvents.GCNPageNavigationView.DidSelectMenuPageId,
        this._navDidSelectMenuPage,
      );
      this.listenTo(
        this._pageNavView,
        BackboneEvents.GCNPageNavigationView.DidSelectSectionId,
        this.scrollToSectionWithId,
      );
      this.$el.append(this._pageNavView.render().$el);
    }

    let introImagesArray;
    if (
      [
        BitePlatform.KioskAndroid,
        BitePlatform.KioskSignageOsGarcon,
        BitePlatform.KioskChromeOsGarcon,
      ].includes(window.platform) &&
      gcn.location.get('introImages1080')?.length
    ) {
      introImagesArray = gcn.location.get('introImages1080');
    } else if (gcn.location.get('introImages')?.length) {
      introImagesArray = gcn.location.get('introImages');
    }
    if (introImagesArray) {
      // default to the string if IntroImageText is not set
      const introImageText =
        gcn.location.get('introImageText') || 'Tap anywhere on the screen to start';
      this._introImageView = new GCNSimpleCover({
        url: introImagesArray[0].url,
        text: introImageText,
      });
      this.$el.append(this._introImageView.render().$el);
      this.listenTo(
        this._introImageView,
        BackboneEvents.GCNSimpleCover.IntroImageDisappear,
        this._introImageViewDismissed,
      );
    }

    if (gcn.location.usesTallScreenUI()) {
      this.$el.addClass('tall');
    }

    setTimeout(() => {
      this._adjustSizeToFitWindow();

      // routing for flash
      GCNRouterHelper.initialize();
      GCNRouterHelper.listenForRouting();
    }, 1);

    if (window.isFlash) {
      this.$el.scroll(() => {
        gcn.trigger(BackboneEvents.GCNScrollingNavView.DidScrollMenuPage, this.$el.scrollTop());

        // Calculate the visible section and notify.
        // TODO: Improve the algorithm so it always catches the last page, even if it's short.
        let visibleSection = null;
        let cumulativeHeight = 0;

        const sections = this._menuPageView.menuPage.sections.filter(
          (section) => !section.attributes.hideFromMenu,
        );
        if (!sections.length) {
          // abandoned scroll jacking for menu pages with only subPages etc
          return;
        }
        const $firstSectionEl = this._menuPageView.sectionViewsById[sections[0].id].$el;

        // We subtract the top position of the first section view to account for anything that could
        // appear above it such as menu disclaimers, quick nav or a recommendations section.
        const scrollTop = this.$el.scrollTop() - $firstSectionEl.position().top;
        sections.forEach((section) => {
          const $sectionEl = this._menuPageView.sectionViewsById[section.id].$el;
          // Start showing the "next section" a little bit earlier than when it's first pixel hits
          // the top of the screen. Something like 125px to account for the whitespace at the bottom
          // of the "previous section".
          if (cumulativeHeight > scrollTop + 125) {
            return;
          }
          // if the section is hidden, dont add to the cumulative height
          // otherwise the navbar will highlight the wrong section
          if (!section.attributes.hideFromMenu) {
            cumulativeHeight += $sectionEl.height();
          }
          visibleSection = section;
        });
        if (visibleSection && visibleSection !== this._lastVisibleSection) {
          this._navDidScrollSectionIntoView(visibleSection.id);
          this._lastVisibleSection = visibleSection;
        }
      });
    }

    return this;
  },
});

GCNMenuView.bottomBarHeight = 80;
