import { Log } from '@biteinc/common';
import type { PrintJob } from '@biteinc/common';
import type { OrderedMod, OrderedPriceOption } from '@biteinc/core-react';

import type { GcnAddonSet, GcnMenu } from '~/types/gcn_menu';
import type { GcnOrder } from '~/types/gcn_order';
import type { GcnOrderedItem } from '~/types/gcn_ordered_item';

import GcnHelper from './gcn_helper';
import GcnPrintJob from './gcn_print_job';
import GcnRecoTracker from './gcn_reco_tracker';
import { GCNOrder } from './models/gcn_order';
import Analytics from './utils/analytics';

module OrderHelper {
  export function addToOrder(
    orderedItems: GcnOrderedItem[],
    orderedItem: GcnOrderedItem,
    quantity: number = 1,
    { skipTracking, reason = '', recommendationDisplayLocationDescription = '' }: any,
  ): { orderedItems: GcnOrderedItem[] } {
    const enableMultipleQuantityItems = gcn.menu.settings.get('enableMultipleQuantityItems');
    const menuItemId = orderedItem.item.id;
    const menuItemName = orderedItem.item.displayName();
    const upsellScreen = orderedItem.get('upsellScreen');

    // `enableMultipleQuantityItems` sets quantity > 1 otherwise we use the old logic of
    // repeating addition of `itemToAdd` to the `_orderedItems` `quantity` times
    if (enableMultipleQuantityItems) {
      const itemToAdd = orderedItem;

      const defaultItemAddedFromMenu = gcn.menu.settings.get('addMenuItemFromMenuView')
        ? orderedItems.find((item: GcnOrderedItem) => {
            return (
              item.id === itemToAdd.id &&
              item.getSelectionsInStringForm() === itemToAdd.getSelectionsInStringForm()
            );
          })
        : undefined;

      if (defaultItemAddedFromMenu) {
        itemToAdd.setQuantityOnSelectedPriceOption(
          quantity + defaultItemAddedFromMenu.orderedPO.attributes.quantity,
        );
        gcn.orderManager.replaceInOrder(defaultItemAddedFromMenu, itemToAdd);
      } else {
        itemToAdd.setQuantityOnSelectedPriceOption(quantity);
        itemToAdd.clearSelectedAddonsForOtherPriceOptions();
        orderedItems.push(itemToAdd);
      }

      if (!skipTracking) {
        if (recommendationDisplayLocationDescription) {
          // track recommendations from child views
          GcnRecoTracker.trackGuestAddToCart(
            gcn.maitred,
            recommendationDisplayLocationDescription,
            itemToAdd,
          );
        }

        Analytics.trackEvent({
          eventName: Analytics.EventName.CartMenuItemAdded,
          eventData: {
            itemName: menuItemName,
            itemQuantity: quantity,
          },
        });
        gcn.orderManager.eventRepo.trackMenuItemAddToCart(
          menuItemId,
          menuItemName,
          upsellScreen,
          reason,
        );
        Log.info('added to order:', itemToAdd.id, itemToAdd);
      }
    } else {
      let numberToAdd = quantity;
      let itemToAdd = orderedItem;
      itemToAdd.clearSelectedAddonsForOtherPriceOptions();
      while (numberToAdd > 0) {
        // setting the quantity to one as the loop will run `quantity` number of times
        itemToAdd.setQuantityOnSelectedPriceOption(1);
        orderedItems.push(itemToAdd);
        if (!skipTracking) {
          if (recommendationDisplayLocationDescription) {
            // track recommendations from child views
            GcnRecoTracker.trackGuestAddToCart(
              gcn.maitred,
              recommendationDisplayLocationDescription,
              itemToAdd,
            );
          }

          Analytics.trackEvent({
            eventName: Analytics.EventName.CartMenuItemAdded,
            eventData: {
              itemName: menuItemName,
              itemQuantity: 1,
            },
          });
          gcn.orderManager.eventRepo.trackMenuItemAddToCart(
            menuItemId,
            menuItemName,
            upsellScreen,
            reason,
          );
          Log.info('added to order: ', itemToAdd.id, itemToAdd);
        }

        numberToAdd--;
        if (numberToAdd > 0) {
          itemToAdd = orderedItem.createCopy();
        }
      }
    }

    return { orderedItems };
  }

  export function orderFromJson(
    orderJson: any,
    orderedItems: GcnOrderedItem[],
  ): {
    order: GcnOrder;
  } {
    const order = new GCNOrder(orderJson);

    order.orderedItems.forEach((orderedItem: GcnOrderedItem) => {
      const quantity = orderedItem.get('priceOption').quantity;
      addToOrder(orderedItems, orderedItem, quantity, { skipTracking: true });
    });

    return { order };
  }

  export function getPrintPayload(
    order: GcnOrder,
    sendKioskOrderErr: any,
  ): { printJobs: PrintJob.Payload[] } {
    // Create the print payload in the right format to send over the bridge.
    const printJobs = [];
    if (!gcn.location.isPrintingOptional()) {
      const receiptBase64Images = GcnHelper.getReceiptBase64Images(gcn.location);
      printJobs.push(
        GcnPrintJob.receiptPrintJob(gcn.location, gcn.menu, order, {
          sendKioskOrderErr,
          ...receiptBase64Images,
        }),
      );
    }
    printJobs.push(GcnPrintJob.kitchenPrintJob(gcn.location, gcn.menu, order));

    return { printJobs };
  }

  export function shouldPrintOrderedModInModGroup(
    orderedMod: OrderedMod,
    modGroup?: GcnAddonSet,
  ): boolean {
    const { posFields = {} } = orderedMod;
    // TODO: it's possible that modGroup is undefined if the menu has virtual subgroups,
    // but they get converted to their original mod group in the NewOrderTransformer
    const dontSendIfSelected = modGroup?.addonWontSendIfSelected(orderedMod._id);
    if (posFields.mustBeIgnored || dontSendIfSelected) {
      return false;
    }
    return true;
  }

  export function getAllSelectedOrderedMods(
    menu: GcnMenu,
    orderedPriceOption: OrderedPriceOption,
    skipIgnoredMods: boolean,
  ): OrderedMod[] {
    const mods: OrderedMod[] = [];
    orderedPriceOption.addonSets?.forEach((orderedModGroup) => {
      const modGroup = menu.getAddonSetWithId(orderedModGroup._id);
      orderedModGroup.items?.forEach((orderedMod) => {
        if (skipIgnoredMods && !shouldPrintOrderedModInModGroup(orderedMod, modGroup)) {
          return;
        }

        mods.push(orderedMod);
        mods.push(...getAllSelectedOrderedMods(menu, orderedMod.priceOption, skipIgnoredMods));
      });
    });
    return mods;
  }
}

export default OrderHelper;
