import { IonButton, IonCol, IonContent, IonImg, IonItem, IonList, IonRow } from '@ionic/react';
import React, { Component } from 'react';
import { shallow } from 'zustand/shallow';

import { ErrorCode, Log, Strings } from '@biteinc/common';
import type { Order } from '@biteinc/core-react';
import { AppearanceHelper, Footer, Header, ModalService, TimeHelper } from '@biteinc/core-react';
import type { CustomStringKey } from '@biteinc/enums';
import { BiteLogDeviceEvent, OrderChannel, OrderClientEventName } from '@biteinc/enums';

import type { ApiError } from '~/app/js/gcn_maitred_request_manager';
import { GCNRouterHelper } from '~/app/js/gcn_router_helper';
import { localizeStr } from '~/app/js/localization/localization';
import { GCNAlertView } from '~/app/js/views/gcn_alert_view';

import GcnOpeningSequenceManager from '../app/js/gcn_opening_sequence_manager';
import GcnRecoTracker from '../app/js/gcn_reco_tracker';
import type GcnLocation from '../app/js/models/gcn_location';
import OrderSender from '../app/js/order_sender';
import Analytics from '../app/js/utils/analytics';
import Errors from '../app/js/utils/errors';
import {
  CustomerIdentifiers,
  GuestSurvey,
  LoginWall,
  OrderDetails,
  OrderSummary,
  OrderTotals,
  PaymentDetails,
} from '../components';
import { GcnCustomerAccountHelper, LocationUtils } from '../helpers';
import { AuthService } from '../services';
import type { Store } from '../stores';
import { useStore, withStore } from '../stores';

const promoImageKeyByOrderChannel: Record<OrderChannel, string> = {
  [OrderChannel.Web]: 'webCheckoutDesktopPromoImage',
  [OrderChannel.Catering]: 'cateringCheckoutDesktopPromoImage',
  [OrderChannel.DriveThru]: '',
  [OrderChannel.Kiosk]: '',
  [OrderChannel.Flash]: '',
  [OrderChannel.Linebuster]: '',
};

interface CheckoutProps {
  checkoutState: Store['checkout'];
  loyaltyState: Store['loyalty'];
  location: Store['bridge']['location'];
  menu: Store['bridge']['menu'];
}

interface CheckoutPageState {
  hasLeftCheckout?: boolean;
  isLoggedIn: boolean;
}

const columnSizes = {
  sizeXs: '12',
  sizeSm: '12',
  sizeMd: '6',
  sizeLg: '6',
  sizeXl: '6',
};

class CheckoutPage extends Component<CheckoutProps, CheckoutPageState> {
  inactivityTimer: ReturnType<typeof setTimeout> | null;

  unloadListener: (e: any) => void;

  constructor(props: CheckoutProps) {
    super(props);
    this.inactivityTimer = null;
    this.state = {
      isLoggedIn: !!gcn.orderManager.getCustomer(),
    };
    this.unloadListener = (e: any): void => {
      if (!['test'].includes(window.env)) {
        e.preventDefault();
        gcn.goHome(true);
      }
    };
  }

  componentDidMount(): void {
    gcn.checkoutFlowView = this;
    GCNRouterHelper.navToCheckout();
    // Close all active modals if back button is hit
    window.onhashchange = () => {
      ModalService.closeAll();
    };
  }

  componentWillUnmount(): void {
    // Needed for when the checkout page is navigated away from with refresh or back button
    if (gcn.checkoutFlowView) {
      this.exitCheckoutFlow();
    }
  }

  // Single exit point for the checkout flow, which clears all checkout state.
  exitCheckoutFlow(nextStep?: 'pickupAt' | 'fulfillmentMethod'): void {
    gcn.checkoutFlowView = undefined;
    gcn.orderManager.eventRepo.track(OrderClientEventName.CheckoutCancel);
    Analytics.track(Analytics.EventName.CheckoutCancel);

    gcn.orderManager.clearCheckout();
    gcn.loyaltyManager.exitCheckoutClearRewards();
    gcn.orderManager.revertOrderedItems();
    gcn.menuView.hideFullScreen();
    GCNRouterHelper.navToMenu();

    this.setState({
      ...this.state,
      hasLeftCheckout: true,
    });

    if ('pickupAt' === nextStep) {
      const openingSeqManager = new GcnOpeningSequenceManager();
      const selectedFulfillmentMethod = this.props.checkoutState.order.fulfillmentMethod!;
      const diningOption = LocationUtils.getDiningOptionForFulfillmentMethodWithoutLocalizedName(
        this.props.location,
        selectedFulfillmentMethod,
      );
      if (diningOption.futureOrdersEnabled) {
        // If this was an asap option that's getting throttled, don't offer the asap option again
        const ignoreAsapOption = !this.props.checkoutState.order.pickupAtIso;
        openingSeqManager.startFutureOrderPicker(ignoreAsapOption);
      } else {
        openingSeqManager.start();
      }
    } else if ('fulfillmentMethod' === nextStep) {
      const openingSeqManager = new GcnOpeningSequenceManager();
      openingSeqManager.start();
    }
  }

  /**
   * Exit point for the checkout flow, but specifically for when an order operation fails due to a
   * network timeout error
   */
  exitCheckoutFlowDueToTimeout(): void {
    gcn.menuView.dismissSpinner();
    gcn.menuView.dismissStablePopup();
    const alertText = localizeStr(Strings.ERROR_ORDER_OPERATION, [], function (string: string) {
      return string.split('\n').join('<br />');
    });
    const confirmView = new GCNAlertView({
      text: alertText,
      okCallback: () => {
        gcn.menuView.dismissModalPopup();
        this.exitCheckoutFlow();
        GCNRouterHelper.navToMenu();
      },
    });
    gcn.menuView.showModalPopup(confirmView);
  }

  private createOrder(): void {
    const { orderUpdatedAt } = this.props.checkoutState;
    gcn.menuView.showSpinner(localizeStr(Strings.SENDING_ORDER_VALIDATION));
    gcn.maitred.createOrderRequest((err, data) => {
      // Bail if the order has since changed
      if (this.props.checkoutState.orderUpdatedAt > orderUpdatedAt) {
        gcn.menuView.dismissSpinner();
        return;
      }
      if (this.state.hasLeftCheckout) {
        gcn.menuView.dismissSpinner();
        return;
      }

      if (err) {
        Log.debug('err creating', err);
        gcn.sendDeviceLog(BiteLogDeviceEvent.FailedOrderCreation, err.code);
        this.onCreateOrValidateOrderErr(err);
        return;
      }

      Log.debug('created order', data);
      const order = data!.order;

      // immediately create order and recommendation references
      GcnRecoTracker.createOrderReferences(gcn.maitred, order._id);

      this.validateOrder(order._id);
    });
  }

  private onCreateOrValidateOrderErr(err: ApiError): void {
    gcn.menuView.dismissSpinner();

    // Since no payment is made, we display error and send them back to start of flow.
    if (Errors.isPosValidationCode(err.code)) {
      let errorMessage = '';
      if (err.message) {
        errorMessage = err.message;
      } else {
        let errorStringKey: (typeof Strings)[CustomStringKey] = Strings.GENERIC_ERROR;
        if (Errors.isPOSValidation86dError(err.code)) {
          errorStringKey = Strings.ITEM_86D_VALIDATION;
        }
        errorMessage = localizeStr(errorStringKey);
      }

      errorMessage = errorMessage.split('\n').join('<br />');

      const confirmView = new GCNAlertView({
        text: errorMessage,
        okCallback: () => {
          gcn.menuView.dismissModalPopup();
          this.exitCheckoutFlow();
        },
      });
      gcn.menuView.showModalPopup(confirmView);
      return;
    }

    let errorMessage: string;
    switch (err.code) {
      case ErrorCode.OrderTotalAboveMax:
        errorMessage = Errors.stringFromErrorCode(err.code);
        break;
      default:
        errorMessage = err.message || Errors.stringFromErrorCode(err.code);
        break;
    }

    errorMessage = errorMessage.split('\n').join('<br />');
    const confirmView = new GCNAlertView({
      text: errorMessage,
      okCallback: () => {
        gcn.menuView.dismissModalPopup();
        this.exitCheckoutFlow(
          Errors.isBadTimeSlotOrThrottlingError(err.code) ? 'pickupAt' : undefined,
        );
      },
    });
    gcn.menuView.showModalPopup(confirmView);
  }

  private validateOrder(orderId: string): void {
    const { orderUpdatedAt } = this.props.checkoutState;

    gcn.maitred
      .validateOrder(orderId)
      .then((data) => {
        gcn.menuView.dismissSpinner();

        // Bail if the order has since changed
        if (this.props.checkoutState.orderUpdatedAt > orderUpdatedAt) {
          return;
        }
        if (this.state.hasLeftCheckout) {
          return;
        }

        Log.debug('validated order', data);

        const order = data.order;
        const pickupTime = order.pickupAt;
        if (
          pickupTime &&
          order.orderedItemsLeadTime &&
          pickupTime - Date.now() < order.orderedItemsLeadTime
        ) {
          const readyTime = TimeHelper.millisecondsToFriendlyDescription(
            order.orderedItemsLeadTime,
          );
          gcn.menuView.showSimpleAlert(`${localizeStr(Strings.LEAD_TIME_LONG)} (${readyTime})`);
        }

        const subTotalChange = order.subTotal - this.props.checkoutState.order.subTotal;

        this.props.checkoutState.onOrderUpdated(order);

        if (
          subTotalChange === 0 ||
          (subTotalChange > 0 &&
            Math.abs(subTotalChange) <
              (this.props.menu.settings.minOrderSubTotalIncreaseForWarning || 0)) ||
          (subTotalChange < 0 &&
            Math.abs(subTotalChange) <
              (this.props.menu.settings.minOrderSubTotalDecreaseForWarning || 0))
        ) {
          return;
        }

        const confirmCorrectionsView = new GCNAlertView({
          content: gcn.orderManager.getSubtotalChangeContent(),
          okCallback: () => {
            gcn.menuView.dismissModalPopup();
          },
          cancelCallback: () => {
            gcn.orderManager.revertPriceChanges();
            gcn.menuView.dismissModalPopup();
            this.exitCheckoutFlow();
          },
        });
        gcn.menuView.showModalPopup(confirmCorrectionsView);
      })
      .catch((err) => {
        Log.debug('err validating', err);
        gcn.sendDeviceLog(BiteLogDeviceEvent.FailedOrderValidation, err.code);
        this.onCreateOrValidateOrderErr(err);
      });
  }

  private listenForUnload(): void {
    window.addEventListener('beforeunload', this.unloadListener);
  }

  private removeUnloadListener(): void {
    window.removeEventListener('beforeunload', this.unloadListener);
  }

  private static async submitOrder(): Promise<void> {
    gcn.orderManager.persistGuestDataAndId();

    gcn.menuView.showSpinner(localizeStr(Strings.SENDING_ORDER_KITCHEN));
    const orderPayload = gcn.orderManager.getOrderPayload();
    const orderUpdatePayload = gcn.orderManager.getOrderUpdatePayload();
    const currentOrder = gcn.orderManager.getOrder()!.clone();
    const hasEcommPayment = gcn.orderManager.hasEcommPaymentMethod();
    await OrderSender.sendFlashOrder(
      currentOrder,
      orderPayload,
      orderUpdatePayload,
      hasEcommPayment,
      true,
    );

    Analytics.trackEvent({
      eventName: Analytics.EventName.CheckoutComplete,
      eventData: {
        cartSize: gcn.orderManager.getOrderSize(),
        cartSubTotal: gcn.orderManager.getSubTotal(),
      },
    });
    gcn.orderManager.eventRepo.track(OrderClientEventName.CheckoutEnd);
    gcn.orderManager.commitEvents();
  }

  private getPromoImage(): React.ReactNode {
    const { order } = this.props.checkoutState;
    const orderIsValidated = !!order.wasValidated;
    if (orderIsValidated) {
      return undefined;
    }
    const channelImageKey = promoImageKeyByOrderChannel[this.props.location.orderChannel];
    return (
      <div className="checkout-promo-image">
        <IonImg src={AppearanceHelper.getOrgImage(gcn.org, channelImageKey)} />
      </div>
    );
  }

  private rightColumnElements(orderIsClosed: boolean): JSX.Element {
    if (!orderIsClosed) {
      return (
        <PaymentDetails
          ecommI9nConfig={gcn.ecommI9n!}
          onSubmit={async () => {
            await CheckoutPage.submitOrder();
            this.listenForUnload();
          }}
          checkoutState={this.props.checkoutState}
          loyaltyState={this.props.loyaltyState}
        />
      );
    }

    const order = this.props.checkoutState.order as Order;

    return (
      <>
        <OrderTotals
          order={order}
          transactions={this.props.checkoutState.transactions}
        />
        {this.props.menu.settings.showGuestSurvey ? <GuestSurvey orderId={order._id} /> : null}
        <IonList
          lines="none"
          className="post-close-buttons"
        >
          {!gcn.orderManager.getCustomer() &&
            GcnCustomerAccountHelper.customerAccountsAreEnabled() && (
              <IonItem>
                <IonButton
                  className="sign-up-button cta-button"
                  expand="block"
                  size="default"
                  onClick={() => {
                    if (window.signupUrl) {
                      window.location.href = window.signupUrl;
                      return;
                    }
                    AuthService.showSignup(this.props.location, {
                      orderId: order._id,
                    });
                  }}
                >
                  {localizeStr(Strings.SIGNUP_CTA)}
                </IonButton>
              </IonItem>
            )}
          <IonItem>
            <IonButton
              className="new-order-button"
              expand="block"
              fill="outline"
              size="default"
              onClick={() => {
                Log.info('Go Home: new order button');
                this.removeUnloadListener();
                gcn.goHome();
              }}
            >
              {localizeStr(Strings.START_NEW_ORDER)}
            </IonButton>
          </IonItem>
        </IonList>
      </>
    );
  }

  private getHeaderButtons(): JSX.Element | null {
    if (this.state.isLoggedIn || !GcnCustomerAccountHelper.customerAccountsAreEnabled()) {
      return null;
    }
    return (
      <IonButton
        fill="clear"
        size="default"
        slot="end"
        color="primary"
        onClick={async () => {
          // We probably want to do something with customer identifiers here and loyalty
          AuthService.showLogin(this.props.location, () => {
            this.setState({ isLoggedIn: true });
          });
        }}
      >
        {localizeStr(Strings.CUSTOMER_ACCOUNT_BUTTON_LOG_IN_TOP_BAR) ||
          localizeStr(Strings.CUSTOMER_ACCOUNT_BUTTON_LOG_IN)}
      </IonButton>
    );
  }

  private getHeader(): JSX.Element {
    return (
      <Header
        lang={useStore.getState().config.language}
        customStrings={useStore.getState().bridge.menu.settings.customStrings}
        host={`https://${this.props.location.orgDomain}`}
        org={gcn.org}
        buttons={this.getHeaderButtons()}
        logo={{
          slot: 'start',
        }}
        color="clear"
        hideBackToMenu
      />
    );
  }

  private showKioskInactivityTimerModal(): void {
    // This timer and popup only makes sense on a kiosk because we need to potentially clear the
    // screen/session for the next guest. In flash/web, on a user's personal device, they can look
    // at a checkout screen for as long as they want.
    if (window.isFlash) {
      return;
    }
    const alertText = localizeStr(Strings.NEED_MORE_TIME, [], function (string: string) {
      return string.split('\n').join('<br />');
    });
    const confirmView = new GCNAlertView({
      text: alertText,
      okCallback: () => {
        gcn.menuView.dismissModalPopup();
        this.restartInactivityTimer();
      },
      okText: localizeStr(Strings.YES),
      cancelCallback() {
        gcn.orderManager.eventRepo.trackSessionAbandon('order-summary-inactivity');
        Analytics.trackEvent({
          eventName: Analytics.EventName.MenuAbandoned,
          eventData: {
            reason: 'order-summary-inactivity',
          },
        });

        // TODO: remove when we have resolved goHome loop in flash
        Log.info('Go Home: cancel callback');

        gcn.goHome();
      },
      cancelText: localizeStr(Strings.EXIT),
      timeout: 15 * TimeHelper.SECOND,
    });
    gcn.menuView.showModalPopup(confirmView);
  }

  private restartInactivityTimer(): void {
    // Clear any previous timers
    this.clearInactivityTimer();
    // Get time remaining for pickup. If it isn't a pickup order,
    // we default to two minutes.
    const pickupTimeRemaining = this.props.checkoutState.order.pickupAt
      ? this.props.checkoutState.order.pickupAt - Date.now()
      : 2 * TimeHelper.MINUTE;

    // In case we fail to clear the timeout, use this to ensure it doesn't affect a future session
    const expectedClientOrderId = gcn.orderManager.getClientOrderId();

    this.inactivityTimer = setTimeout(
      () => {
        if (expectedClientOrderId !== gcn.orderManager.getClientOrderId()) {
          // If the client IDs don't match, then this timeout is from a previous session
          return;
        }

        if (
          this.props.checkoutState.order.pickupAt &&
          Date.now() > this.props.checkoutState.order.pickupAt
        ) {
          // TODO: remove when we have resolved goHome loop in flash
          Log.info('Go Home: inactivity timer expired');
          gcn.goHome();
        } else {
          this.showKioskInactivityTimerModal();
        }
        // We set the timeout to whatever is sooner, the pickup time (If pickup) or 2 minutes.
      },
      Math.min(2 * TimeHelper.MINUTE, pickupTimeRemaining),
    );
  }

  private clearInactivityTimer(): void {
    if (this.inactivityTimer) {
      clearTimeout(this.inactivityTimer);
    }
    this.inactivityTimer = null;
  }

  private getLoginWall(
    customerIdentifierOptions: GcnLocation.CustomerIdentifierOption[],
  ): React.ReactNode {
    if (GcnCustomerAccountHelper.customerAccountsAreEnabled() && !this.state.isLoggedIn) {
      return (
        <LoginWall
          location={this.props.location}
          settings={this.props.menu.settings}
          customerIdentifierOptions={customerIdentifierOptions}
          createOrder={() => {
            this.createOrder();
          }}
          guestInfo={this.props.checkoutState.guestInfo}
        />
      );
    }
  }

  render(): React.ReactNode {
    const { order } = this.props.checkoutState;
    const orderIsClosed = !!order.wasValidated && !!order.isClosed;
    const flashPrefix = this.props.location.orgDomain ? '' : `/${this.props.location.urlSlug}`;
    const diningOption = LocationUtils.getDiningOptionForFulfillmentMethodWithoutLocalizedName(
      this.props.location,
      this.props.checkoutState.order.fulfillmentMethod!,
    );
    const customerIdentifierOptions = diningOption.customerIdentifierOptions;

    // Start inactivity timer on render.
    this.restartInactivityTimer();

    return (
      <div className="checkout-page">
        {this.getHeader()}
        <IonContent className="checkout-content">
          <IonRow className="checkout-col-container no-padding">
            {/* LEFT */}
            <IonCol
              className="no-padding left-side"
              {...columnSizes}
            >
              <div className="col-content">
                {this.getLoginWall(customerIdentifierOptions)}
                <OrderDetails
                  onEdit={() => {
                    this.exitCheckoutFlow('fulfillmentMethod');
                  }}
                />
                <CustomerIdentifiers
                  location={this.props.location}
                  settings={this.props.menu.settings}
                  orderIsClosed={orderIsClosed}
                  customerIdentifierOptions={customerIdentifierOptions}
                  createOrder={() => {
                    this.createOrder();
                  }}
                  validateOrder={() => {
                    this.validateOrder(gcn.orderManager.getOrderId());
                  }}
                  guestInfo={this.props.checkoutState.guestInfo}
                />
                <OrderSummary
                  canEdit={!orderIsClosed}
                  onEdit={() => {
                    this.exitCheckoutFlow();
                  }}
                  order={order}
                />
              </div>
            </IonCol>
            {/* RIGHT */}
            <IonCol
              className="no-padding right-side"
              {...columnSizes}
            >
              <div className="col-content">
                {this.getPromoImage()}
                {this.rightColumnElements(orderIsClosed)}
              </div>
            </IonCol>
          </IonRow>
          <IonRow className="footer-container">
            <Footer
              flashPrefix={flashPrefix}
              org={gcn.org}
            />
          </IonRow>
        </IonContent>
      </div>
    );
  }
}

const mapStateToProps = (state: Store): CheckoutProps => {
  return {
    checkoutState: state.checkout,
    loyaltyState: state.loyalty,
    location: state.bridge.location,
    menu: state.bridge.menu,
  };
};

// this is an optimization to prevent unnecessary re-renders
// https://github.com/pmndrs/zustand#selecting-multiple-state-slices
export default withStore(mapStateToProps, shallow)(CheckoutPage);
