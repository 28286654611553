import type { I9nSchema, I9nSchemaLoyalty, I9nSchemaPos } from '@biteinc/common';
import { I9nSchemaBySystem } from '@biteinc/common';
import { LocalizationHelper } from '@biteinc/core-react';
import type { FulfillmentMethod, IntegrationSystem } from '@biteinc/enums';
import {
  BitePlatform,
  ClientCapability,
  CouponProvider,
  FulfillmentMethodHelper,
} from '@biteinc/enums';

import { str } from '~/app/js/localization/localization';
import type GcnLocation from '~/app/js/models/gcn_location';
import type { KioskLocation, KioskMenuSettings } from '~/types';

export type EnhancedLocation = {
  canChangeDiningOptions(): boolean;
  getDiningOptionForFulfillmentMethodWithoutLocalizedName(
    fulfillmentMethod: FulfillmentMethod,
  ): GcnLocation.DiningOption & {
    name: string;
  };
  getDiningOptionForFulfillmentMethod(
    fulfillmentMethod: FulfillmentMethod,
  ): GcnLocation.DiningOption;
  getLoyaltyI9nSchema(): I9nSchemaLoyalty<IntegrationSystem.Loyalty> | undefined;
  getPosI9nSchemas(): I9nSchemaPos<IntegrationSystem.Pos>[];
  hasCouponProvider(): boolean;
  hasStoredValueIntegration(): boolean;
  hasCompCardIntegration(): boolean;
  isClosedForFulfillmentMethod(fulfillmentMethod: FulfillmentMethod): boolean;
  isOpenForFulfillmentMethod(fulfillmentMethod: FulfillmentMethod): boolean;
  getLoyaltyIntegration(): GcnLocation.PartialLoyaltyI9n | undefined;
  displayName(): string;
  brandName(): string;
} & KioskLocation;

export namespace LocationUtils {
  // this is a helper function that enhances the location object with some useful methods
  // while also allowing for each method to be able to be used separately
  export function bind(location: KioskLocation, settings: KioskMenuSettings): EnhancedLocation {
    return {
      ...location,
      canChangeDiningOptions: canChangeDiningOptions.bind(null, location),
      getDiningOptionForFulfillmentMethod: getDiningOptionForFulfillmentMethod.bind(null, location),
      getDiningOptionForFulfillmentMethodWithoutLocalizedName:
        getDiningOptionForFulfillmentMethodWithoutLocalizedName.bind(null, location),
      getLoyaltyI9nSchema: getLoyaltyI9nSchema.bind(null, location),
      getPosI9nSchemas: getPosI9nSchemas.bind(null, location),
      hasCouponProvider: hasCouponProvider.bind(null, location),
      hasStoredValueIntegration: hasStoredValueIntegration.bind(null, location),
      hasCompCardIntegration: hasCompCardIntegration.bind(null, location),
      isClosedForFulfillmentMethod: isClosedForFulfillmentMethod.bind(null, location),
      isOpenForFulfillmentMethod: isOpenForFulfillmentMethod.bind(null, location),
      getLoyaltyIntegration: getLoyaltyIntegration.bind(null, location),
      displayName: displayName.bind(null, location),
      brandName: brandName.bind(null, location, settings),
    };
  }
  export function hasLocalization(
    settings: KioskMenuSettings,
    screenReaderIsActive: boolean,
  ): boolean {
    return settings.supportedLanguages.length > 1 && !screenReaderIsActive;
  }
  export function allowsReducedHeight(
    settings: KioskMenuSettings,
    screenReaderIsActive: boolean,
  ): boolean {
    return (
      !!settings.allowAccessibleMenu &&
      !screenReaderIsActive &&
      // Enable reduced height on Android only for now since our CSS only works with Android 50% height
      [
        BitePlatform.KioskAndroid,
        BitePlatform.KioskChromeOsGarcon,
        BitePlatform.KioskSignageOsGarcon,
      ].includes(window.platform) &&
      window.gcnCapability >= ClientCapability.ReducedViewportHeight
    );
  }
  export function allowsZoomFeature(
    settings: KioskMenuSettings,
    screenReaderIsActive: boolean,
  ): boolean {
    if (screenReaderIsActive) {
      return false;
    }
    if (!settings.adaZoomEnabled) {
      return false;
    }
    switch (window.platform) {
      case BitePlatform.KioskAndroid:
      case BitePlatform.KioskIos:
      case BitePlatform.KioskChromeOsGarcon:
        return true;
      case BitePlatform.Flash:
      case BitePlatform.KioskAndroidGarcon:
      case BitePlatform.KioskIosGarcon:
      case BitePlatform.KioskSignageOsGarcon:
        return false;
    }
  }
  export function hasBottomBarButtons(
    settings: KioskMenuSettings,
    screenReaderIsActive: boolean,
  ): boolean {
    if (window.isFlash) {
      return false;
    }
    return (
      allowsZoomFeature(settings, screenReaderIsActive) ||
      allowsReducedHeight(settings, screenReaderIsActive) ||
      hasLocalization(settings, screenReaderIsActive)
    );
  }

  export function canChangeDiningOptions(location: KioskLocation): boolean {
    // Can change dining options if:
    const diningOptions = location.diningOptions;

    // There are multiple to choose from
    if (diningOptions.length > 1) {
      return true;
    }

    // There's only one, but...
    // It's delivery so we can change the address
    if (FulfillmentMethodHelper.isDelivery(diningOptions[0].fulfillmentMethod)) {
      return true;
    }

    // OR...
    // It's an outpost with more than 1 outpost location
    if (
      FulfillmentMethodHelper.isAnOutpost(diningOptions[0].fulfillmentMethod) &&
      (diningOptions[0].outposts || []).length > 1
    ) {
      return true;
    }

    // OR...
    // It allows future orders so we can change pickup time
    if (diningOptions[0].futureOrdersEnabled) {
      return true;
    }

    return false;
  }

  export function getDiningOptionForFulfillmentMethodWithoutLocalizedName(
    location: KioskLocation,
    fulfillmentMethod: FulfillmentMethod,
  ): GcnLocation.DiningOption & {
    name: string;
  } {
    const diningOption = location.diningOptions.find((option) => {
      return option.fulfillmentMethod === fulfillmentMethod;
    })!;

    return {
      ...diningOption,
      name:
        diningOption.name ??
        str(LocalizationHelper.localizeEnum.FulfillmentMethod(fulfillmentMethod)),
    };
  }

  export function getDiningOptionForFulfillmentMethod(
    location: KioskLocation,
    fulfillmentMethod: FulfillmentMethod,
  ): GcnLocation.DiningOption {
    return location.diningOptions.find((option) => {
      return option.fulfillmentMethod === fulfillmentMethod;
    })!;
  }

  export function shouldSkipIncludeUtensils(
    location: KioskLocation,
    fulfillmentMethod: FulfillmentMethod,
  ): boolean {
    const diningOption = getDiningOptionForFulfillmentMethod(location, fulfillmentMethod);
    return (
      !!diningOption?.skipIncludeUtensilsOnFlashOrders ||
      !!diningOption?.skipIncludeUtensilsOnKioskOrders
    );
  }

  function getIntegrationSchemas(location: KioskLocation): I9nSchema[] {
    return Object.values(location.integrationById).map((i9n) => {
      return I9nSchemaBySystem[i9n.system];
    });
  }

  export function getLoyaltyI9nSchema(
    location: KioskLocation,
  ): I9nSchemaLoyalty<IntegrationSystem.Loyalty> | undefined {
    return getIntegrationSchemas(location).find(
      (schema) => schema.type === 'loyalty',
    ) as I9nSchemaLoyalty<IntegrationSystem.Loyalty>;
  }

  export function getPosI9nSchemas(location: KioskLocation): I9nSchemaPos<IntegrationSystem.Pos>[] {
    return getIntegrationSchemas(location).filter(
      (schema) => schema.type === 'pos',
    ) as I9nSchemaPos<IntegrationSystem.Pos>[];
  }

  export function hasCouponProvider(location: KioskLocation): boolean {
    return location.couponProvider !== CouponProvider.None;
  }

  export function hasStoredValueIntegration(location: KioskLocation): boolean {
    return !!getIntegrationSchemas(location).find(
      (schema) => schema.system === location.giftCardI9n,
    );
  }

  export function hasCompCardIntegration(location: KioskLocation): boolean {
    return !!getIntegrationSchemas(location).find(
      (schema) => schema.system === location.compCardI9n,
    );
  }

  export function isClosedForFulfillmentMethod(
    location: KioskLocation,
    fulfillmentMethod: FulfillmentMethod,
  ): boolean {
    return !isOpenForFulfillmentMethod(location, fulfillmentMethod);
  }

  export function isOpenForFulfillmentMethod(
    location: KioskLocation,
    fulfillmentMethod: FulfillmentMethod,
  ): boolean {
    return location.activeFulfillmentMethods.includes(fulfillmentMethod);
  }

  export function getLoyaltyIntegration(
    location: KioskLocation,
  ): GcnLocation.PartialLoyaltyI9n | undefined {
    const partialLoyaltyI9n = Object.values(location.integrationById).find((partialI9n) => {
      return I9nSchemaBySystem[partialI9n.system].type === 'loyalty';
    }) as GcnLocation.PartialLoyaltyI9n | undefined;
    return partialLoyaltyI9n;
  }

  export function displayName(location: KioskLocation): string {
    return location.name;
  }

  export function brandName(location: KioskLocation, settings: KioskMenuSettings): string {
    return settings.brandName || location.orgName;
  }
}
