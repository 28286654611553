import React from 'react';
import ReactDOM from 'react-dom/client';

import { TimeHelper } from '@biteinc/core-react';
import type { FulfillmentMethod } from '@biteinc/enums';

import { FutureDateTimePicker } from '../../../components';
import { BackboneEvents } from '../backbone-events';
import Analytics from '../utils/analytics';
import GcnView from './gcn_view_ts';

export default class GcnFutureOrderTimeCalendarView extends GcnView {
  className = 'order-future-time-calendar-view';

  root: ReactDOM.Root;

  static Events = {
    DidEnterFutureDate: 'didEnterFutureDate',
    BackedOut: 'backedOut',
  };

  constructor(
    private futureOrderSlots: any,
    private ignoreAsapOption: boolean,
    private fulfillmentMethod: FulfillmentMethod,
  ) {
    super();
  }

  destroy(): void {
    super.destroy();

    this.root.unmount();
  }

  render(): this {
    this.root = ReactDOM.createRoot(this.$el[0]);
    this.root.render(
      React.createElement(FutureDateTimePicker, {
        futureOrderSlots: this.futureOrderSlots,
        ignoreAsapOption: this.ignoreAsapOption,
        fulfillmentMethod: this.fulfillmentMethod,
        goBack: () => {
          Analytics.track(Analytics.EventName.FutureOrderTimeBackOut);
          this.trigger(GcnFutureOrderTimeCalendarView.Events.BackedOut);
        },
        orderAsap: () => {
          Analytics.track(Analytics.EventName.FutureOrderTimeSelectedAsap);
          this.trigger(BackboneEvents.GcnFutureOrderPickerView.DidPickAsapOrder, this);
        },
        confirm: (timestamp: string) => {
          Analytics.trackEvent({
            eventName: Analytics.EventName.FutureOrderTimeSelectedSlot,
            eventData: {
              value: TimeHelper.formatIsoString(timestamp, 'LT'),
            },
          });
          this.trigger(BackboneEvents.GcnFutureOrderPickerView.DidPickFutureOrder, timestamp);
        },
      }),
    );
    return this;
  }
}
