import { Strings } from '@biteinc/common';

import { localizeStr } from '~/app/js/localization/localization';

import { GCNAsterHallHeaderView } from './gcn_aster_hall_header_view';
import { GCNHandlePaymentStepView } from './gcn_handle_payment_step_view';

export const GCNAsterHallPaymentStepView = GCNHandlePaymentStepView.extend({
  // Append custom Aster Hall header.
  __renderHeader() {
    this.$stepBody.append(
      new GCNAsterHallHeaderView({
        title: localizeStr(Strings.PAYMENT),
      }).render().$el,
    );
  },
});
