import type { IonTextareaCustomEvent, TextareaChangeEventDetail } from '@ionic/core';
import { IonItem, IonLabel, IonTextarea } from '@ionic/react';
import * as React from 'react';

import { Validators } from '@biteinc/common';

import { useStore } from '~/stores';

function DeliveryInstructionsLayout(props: { children: React.ReactNode }): JSX.Element {
  return (
    <IonItem
      lines="none"
      className="delivery-instructions"
    >
      <IonLabel position="stacked">Delivery Instructions</IonLabel>
      {props.children}
    </IonItem>
  );
}

export function DeliveryInstructions(props: {
  deliveryInstructions: string | undefined;
  orderIsClosed: boolean;
}): JSX.Element {
  const onUpdateDeliveryInstructions = useStore(
    (state) => state.checkout.onUpdateDeliveryInstructions,
  );
  const regex = new RegExp(
    Validators.simpleStringRegexString({
      allowCommas: true,
      allowLineBreaks: true,
      inverse: true,
    }),
    'g',
  );

  return (
    <DeliveryInstructionsLayout>
      {props.orderIsClosed ? (
        <div>{props.deliveryInstructions}</div>
      ) : (
        <IonTextarea
          value={props.deliveryInstructions || ''}
          autoGrow={false}
          inputmode="text"
          // Olo max
          maxlength={128}
          rows={2}
          spellcheck={true}
          onIonChange={(event: IonTextareaCustomEvent<TextareaChangeEventDetail>) => {
            const deliveryInstructions = (event.target.value || '')
              .replace(regex, '')
              // Replace unnecessary whitespace
              .replace(/\s\s+/g, ' ')
              // Replace leading whitespace and line breaks
              .replace(/^[\s\r\n]/, '');

            onUpdateDeliveryInstructions(deliveryInstructions);
          }}
        />
      )}
    </DeliveryInstructionsLayout>
  );
}
